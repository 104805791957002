import en from 'src/constants/en'

export const labelByMentionType = (mentionType: string): string | undefined => {
  const mentionLower = mentionType.toLowerCase()

  switch (mentionLower) {
    case 'pos':
    case 'positive':
      return en.positive
    case 'neg':
    case 'negative':
      return en.negative
    case 'neu':
    case 'neutral':
      return en.neutral
    default:
      return undefined
  }
}
