import { FC, ReactNode } from 'react'
import classNames from 'classnames'
import { QuestionMarkTooltip } from 'src/components/QuestionMarkTooltip'
import styles from './sectionTitle.module.scss'
import { TitleColor } from './enums/titleColor.enum'
import { ReactComponent as LiveIcon } from '../../assets/svg/live.svg'

export interface SectionTitleProps {
  endItem?: ReactNode
  isLoading?: boolean
  liveIcon?: boolean
  title?: string
  titleColor?: TitleColor
  tooltipContent?: string
}

export const SectionTitle: FC<SectionTitleProps> = ({
  title,
  titleColor = TitleColor.BLACK,
  tooltipContent,
  liveIcon,
  isLoading,
  endItem,
}) => {
  return (
    <div className={styles.sectionTitle}>
      {liveIcon && (
        <span className={styles.liveIcon}>
          <LiveIcon />
        </span>
      )}

      <span className={classNames(styles.title, styles[titleColor])}>
        {title}
      </span>

      {!!tooltipContent && (
        <span className={styles.tooltip}>
          <QuestionMarkTooltip overlay={tooltipContent} />
        </span>
      )}

      {isLoading ? (
        <div className={styles.loaderRing}>
          <div />
          <div />
          <div />
          <div />
        </div>
      ) : (
        endItem
      )}
    </div>
  )
}
