import moment from 'moment'
import { FC } from 'react'
import en from 'src/constants/en'
import { BASE_API_DATE_FORMAT } from 'src/constants/dateFormats'
import { AnalyticsFetchCalendarDetailsResponse } from 'src/services/__generated__/api'
import { PositivityLegend } from 'src/components/PositivityLegend'
import { PositivityTypes } from 'src/components/PositivityLegend/enums/positivityTypes.enum'
import { useGetInformationTooltipsQuery } from 'src/reactQuery/useGetInformationTooltips'
import { SectionTitle } from 'src/components/SectionTitle'
import styles from './calendarDetails.module.scss'
import { Mentions } from './Mentions'
import { TopSources } from './TopSources'
import { TopCampaigns } from './TopCampaigns'
import { MentionsChart } from './MentionsChart'
import { ChartNavigation } from '../ChartNavigation'

interface CalendarDetailsProps {
  date?: string
  isLoading?: boolean
  navigateDays: (date: string) => void
  selectedDateData: AnalyticsFetchCalendarDetailsResponse
}

export const CalendarDetails: FC<CalendarDetailsProps> = ({
  selectedDateData,
  navigateDays,
  date,
  isLoading,
}) => {
  const selectedDate = moment(date)
  const parsedDate = selectedDate.format('MMMM DD, YYYY')
  const { data: informationTooltips } = useGetInformationTooltipsQuery()

  const negatives = selectedDateData?.result?.negatives
  const neutrals = selectedDateData?.result?.neutrals
  const positives = selectedDateData?.result?.positives
  const total = selectedDateData?.result?.total
  const negPercentage = selectedDateData?.result?.negPercentage
  const posPercentage = selectedDateData?.result?.posPercentage
  const neuPercentage = selectedDateData?.result?.neuPercentage

  return (
    <div className={styles.calendarDetails}>
      <SectionTitle
        isLoading={isLoading}
        title={en.calendarDetails}
        tooltipContent={informationTooltips?.calendarDetails}
      />

      <div className={styles.navigation}>
        <ChartNavigation
          onLeftClick={() =>
            navigateDays(
              selectedDate.subtract(1, 'day').format(BASE_API_DATE_FORMAT)
            )
          }
          onRightClick={() =>
            navigateDays(
              selectedDate.add(1, 'day').format(BASE_API_DATE_FORMAT)
            )
          }
          title={parsedDate}
        />
      </div>

      <div className={styles.container}>
        <div>
          {(!!negatives || !!neutrals || !!positives) && (
            <MentionsChart
              negatives={negatives}
              negativesPercentage={negPercentage ?? 0}
              neutrals={neutrals}
              neutralsPercentage={neuPercentage ?? 0}
              positives={positives}
              positivesPercentage={posPercentage ?? 0}
            />
          )}
        </div>

        <div className={styles.lists}>
          {!!total && (
            <div className={styles.mentions}>
              <Mentions
                negativeCount={negatives}
                neutralCount={neutrals}
                positiveCount={positives}
                total={total}
              />
            </div>
          )}

          {!!selectedDateData?.topSources?.length && (
            <div className={styles.sources}>
              <TopSources sources={selectedDateData.topSources} />
            </div>
          )}

          {!!selectedDateData?.topCampaigns?.length && (
            <div className={styles.campaigns}>
              <TopCampaigns campaigns={selectedDateData.topCampaigns} />
            </div>
          )}
        </div>
      </div>

      <div className={styles.footer}>
        <PositivityLegend
          types={[
            PositivityTypes.POSITIVE,
            PositivityTypes.NEUTRAL,
            PositivityTypes.NEGATIVE,
          ]}
        />
      </div>
    </div>
  )
}
