import { FC, useEffect } from 'react'
import classNames from 'classnames'
import en from 'src/constants/en'
import { SingleSelect } from 'src/components/Select/SingleSelect'
import { useQueryParams } from 'src/hooks/useQueryParams'
import { FilterSelect } from 'src/components/FilterSelect'
import { FilterSelectOption } from 'src/components/FilterSelect/interfaces/filterSelectOption.interface'
import { QueryParams } from 'src/pages/Campaigns/interfaces/queryParams.interface'
import styles from './campaignsFilter.module.scss'

interface CampaignsFilterProps {
  campaignOptions?: FilterSelectOption[]
  categoriesData?: string[]
  open: boolean
  orderCallback: (val: string) => void
  orderOptions?: string[]
  selectedCampaignOptions?: FilterSelectOption[]
  themesCallback: (val: string[]) => void
}

const CampaignsFilter: FC<CampaignsFilterProps> = ({
  open,
  categoriesData,
  orderOptions,
  orderCallback,
  themesCallback,
  campaignOptions,
  selectedCampaignOptions,
}) => {
  const { queryParams, updateQueryParams, removeQueryParams } =
    useQueryParams<QueryParams>({
      qsStringifyOptions: {
        arrayFormat: 'comma',
      },
    })

  const categories = queryParams.categories || []
  const orderBy = queryParams.orderBy || 'A-Z'

  const filterByCategories = (arr: string[]) => {
    return arr.filter((item) => !categories.length || categories.includes(item))
  }

  const onOrderByChange = (value: string) => {
    updateQueryParams({ orderBy: value })
  }

  const onCampaignsChange = (selected: string[]) => {
    if (!selected.length) {
      removeQueryParams(['campaigns'])

      return
    }

    updateQueryParams({
      campaigns: selected,
    })
  }

  useEffect(() => {
    if (categoriesData) {
      const tempArr = categoriesData.map((category) => ({
        key: category,
        label: category,
      }))

      themesCallback(filterByCategories(tempArr.map((item) => item.label)))
    }
  }, [categoriesData])

  useEffect(() => {
    orderCallback(orderBy)
  }, [orderBy])

  return (
    <div className={classNames(styles.campaignsFilter, open && styles.open)}>
      <div className={styles.content}>
        <div className={styles.column}>
          <div className={styles.title}>{en.orderBy}</div>

          <SingleSelect
            onChange={(newValue) => {
              if (newValue) {
                onOrderByChange(newValue.value)
              }
            }}
            options={orderOptions?.map((item) => ({
              label: item,
              value: item,
            }))}
            value={{ label: orderBy, value: orderBy }}
          />
        </div>

        {!!campaignOptions?.length && (
          <div className={styles.column}>
            <div className={styles.title}>{en.campaigns}</div>

            <div className={styles.selectWrapper}>
              <FilterSelect
                countLabel={en.campaigns}
                onSelect={(selectedOptions) =>
                  onCampaignsChange(selectedOptions.map((item) => item.value))
                }
                options={campaignOptions}
                placeholder={en.campaigns}
                selectedOptions={selectedCampaignOptions}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default CampaignsFilter
