import React, { FC, useState } from 'react'
import classNames from 'classnames'
import en from 'src/constants/en'
import styles from './filterSearchDropdownWithTabs.module.scss'
import { FilterSearchDropdown } from '../FilterSearchDropdown'
import { FilterDropdownSelectOption } from '../FilterSearchDropdown/interfaces/filterDropdownSelectOption.interface'

export interface FilterSearchDropdownWithTabsProps {
  countLabel?: string
  onSelect?: (options: FilterDropdownSelectOption[]) => void
  onTabSelect?: (tab: string) => void
  options?: FilterDropdownSelectOption[]
  placeholder?: string
  selectedOptions?: FilterDropdownSelectOption[]
  selectedTab?: string
  tabs?: string[]
}

export const FilterSearchDropdownWithTabs: FC<
  FilterSearchDropdownWithTabsProps
> = ({
  countLabel,
  placeholder,
  options,
  onSelect,
  selectedOptions,
  tabs,
  selectedTab,
  onTabSelect,
}) => {
  const [searchPattern, setSearchPattern] = useState('')

  const isAllSelected =
    !!selectedOptions?.length && selectedOptions?.length === options?.length

  const filteredOptions = options?.filter(
    (option) =>
      option.label.toLowerCase().includes(searchPattern.toLowerCase()) ||
      option.value.toLowerCase().includes(searchPattern.toLowerCase())
  )

  const handleChange = (val: string) => {
    setSearchPattern(val)
  }

  return (
    <div className={styles.filterSearchDropdownWithTabs}>
      <div className={styles.inputWrapper}>
        <input
          onChange={(e) => handleChange(e.target.value)}
          onClick={(e) => e.stopPropagation()}
          placeholder={placeholder}
          type="text"
          value={searchPattern}
        />

        <div className={styles.selectedLabel}>
          <span className={styles.selectedLabelCount}>
            {isAllSelected ? en.all : selectedOptions?.length || 0}
          </span>
          {!!countLabel && (
            <>
              &nbsp;
              <span>{countLabel}</span>
            </>
          )}
          &nbsp;
          <span>{en.selected}</span>
        </div>
      </div>

      <div className={styles.dropdown}>
        <div className={styles.divider} />

        {!!tabs?.length && (
          <div className={styles.sectionTabs}>
            {tabs.map((tab) => {
              return (
                <div
                  className={classNames(styles.tab, {
                    [styles.active]: tab === selectedTab,
                  })}
                  key={tab}
                  onClick={() => onTabSelect?.(tab)}
                >
                  {tab}
                </div>
              )
            })}
          </div>
        )}

        <FilterSearchDropdown
          countLabel={
            selectedTab && selectedTab !== en.all
              ? `${selectedTab} ${countLabel}`
              : countLabel
          }
          isAllOptionHidden={!!searchPattern.length}
          onSelect={onSelect}
          options={filteredOptions}
          selectedOptions={selectedOptions}
        />
      </div>
    </div>
  )
}
