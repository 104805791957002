import { FC } from 'react'
import en from 'src/constants/en'
import { capitalizeFirstLetter } from 'src/utils/capitalizeFirstLetter'
import { SOCIAL_ICONS } from 'src/constants/socialIcons'
import styles from './sourcesList.module.scss'

interface SourcesListProps {
  topSources?: string[]
}

export const SourcesList: FC<SourcesListProps> = ({ topSources }) => {
  return (
    <div className={styles.sourcesList}>
      <div className={styles.title}>{en.topSources}</div>

      {topSources?.map((topSource) => (
        <div className={styles.item} key={topSource}>
          {SOCIAL_ICONS[topSource]}

          {capitalizeFirstLetter(topSource)}
        </div>
      ))}
    </div>
  )
}
