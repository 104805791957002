import { useQuery } from '@tanstack/react-query'
import { QueryKey } from 'src/reactQuery/enums/queryKey.enum'
import { AnalyticsApi } from 'src/services/analyticsApi'
import { AnalyticsFetchCustomCalendarRangeResponse } from 'src/services/__generated__/api'

const getCustomCalendarRange =
  async (): Promise<AnalyticsFetchCustomCalendarRangeResponse> => {
    const { data } =
      await AnalyticsApi.v1Private.analyticsFetchCustomCalendarRangeList()

    return data
  }

export const useGetCustomCalendarRangeQuery = () => {
  return useQuery({
    queryFn: () => getCustomCalendarRange(),
    queryKey: [QueryKey.CUSTOM_CALENDAR_RANGE],
  })
}
