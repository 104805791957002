import { CampaignType } from 'src/services/__generated__/api'

export const groupCampaignsByCategories = (
  campaigns: CampaignType[]
): Record<string, CampaignType[]> => {
  const newOrder: Record<string, CampaignType[]> = {
    'Themes With No Categories': [],
  }

  campaigns.forEach((campaign) => {
    if (!campaign.categories?.length) {
      newOrder['Themes With No Categories'].push(campaign)
    } else {
      campaign.categories?.forEach((category) => {
        if (!newOrder[category]) {
          newOrder[category] = []
        }

        newOrder[category].push(campaign)
      })
    }
  })

  if (!newOrder['Themes With No Categories'].length) {
    delete newOrder['Themes With No Categories']
  }

  return newOrder
}
