import {
  UpdateSelfResponse,
  UpdateSelfParams,
} from './interfaces/updateSelf.interface'
import { nApi } from '../index'

export const updateSelf = async (
  params: UpdateSelfParams
): Promise<UpdateSelfResponse> => {
  const result = await nApi().post<UpdateSelfResponse>(
    `/v2_private/auth/self/update`,
    params
  )

  return result.data
}
