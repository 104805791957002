import React, { FC, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { ReactComponent as FilterSelectArrow } from 'src/assets/svg/filter-select-arrow.svg'
import en from 'src/constants/en'
import useOutsideClick from 'src/hooks/useOutsideClick'
import styles from './filterSelect.module.scss'
import { FilterSelectOption } from './interfaces/filterSelectOption.interface'
import { CheckBox } from '../CheckBox'
import { ScrollBarContainer } from '../ScrollBarContainer'

export interface FilterSelectProps {
  countLabel?: string
  onChange?: (val: string) => void
  onSelect?: (options: FilterSelectOption[]) => void
  options?: FilterSelectOption[]
  placeholder?: string
  selectedOptions?: FilterSelectOption[]
  value?: string
}

export const FilterSelect: FC<FilterSelectProps> = ({
  placeholder,
  onChange,
  value,
  options,
  selectedOptions,
  countLabel,
  onSelect,
}) => {
  const selectRef = useRef<HTMLDivElement>(null)

  const [searchPattern, setSearchPattern] = useState('')

  const [isOpened, setIsOpened] = useState(false)

  const isAllSelected =
    !!selectedOptions?.length && selectedOptions?.length === options?.length

  const filteredOptions = options?.filter(
    (option) =>
      option.label.toLowerCase().includes(searchPattern.toLowerCase()) ||
      option.value.toLowerCase().includes(searchPattern.toLowerCase())
  )

  const handleChange = (val: string) => {
    setIsOpened(true)

    setSearchPattern(val)

    onChange?.(val)
  }

  const handleSelect = (option: FilterSelectOption, isActive: boolean) => {
    if (isAllSelected) {
      onSelect?.([option])

      return
    }

    if (isActive) {
      onSelect?.(
        selectedOptions?.filter(
          (selectedOption) => selectedOption.value !== option.value
        ) || []
      )

      return
    }

    onSelect?.([...(selectedOptions || []), option])
  }

  useOutsideClick({
    onOutsideClick: () => {
      setIsOpened(false)
    },
    ref: selectRef,
  })

  useEffect(() => {
    if (value !== searchPattern) {
      setSearchPattern(value || '')
    }
  }, [value])

  return (
    <div
      className={classNames(
        styles.filterSelect,
        isOpened && styles.filterSelectOpen
      )}
      ref={selectRef}
    >
      <div className={styles.inputWrapper}>
        <input
          onChange={(e) => handleChange(e.target.value)}
          placeholder={placeholder}
          type="text"
          value={searchPattern}
        />

        <div className={styles.selectedLabel}>
          <span className={styles.selectedLabelCount}>
            {isAllSelected ? en.all : selectedOptions?.length || 0}
          </span>
          {!!countLabel && (
            <>
              &nbsp;
              <span>{countLabel}</span>
            </>
          )}
          &nbsp;
          <span>{en.selected}</span>
        </div>

        <FilterSelectArrow
          className={classNames(styles.arrow, isOpened && styles.arrowOpen)}
          onClick={() => setIsOpened((prevState) => !prevState)}
        />
      </div>

      {isOpened && (
        <div className={styles.dropdown}>
          <div className={styles.divider} />

          <ScrollBarContainer className={styles.options}>
            <div
              className={classNames(
                styles.option,
                isAllSelected && styles.optionActive
              )}
              onClick={() => onSelect?.(isAllSelected ? [] : options || [])}
            >
              <CheckBox isChecked={isAllSelected} isDouble />

              <span>
                {en.all}&nbsp;{countLabel}
              </span>
            </div>

            {filteredOptions?.map((option) => {
              const isActive = !!selectedOptions?.some(
                (selectedOption) => selectedOption.value === option.value
              )

              return (
                <div
                  className={classNames(
                    styles.option,
                    isActive && styles.optionActive
                  )}
                  key={option.value}
                  onClick={() => handleSelect(option, isActive)}
                >
                  <CheckBox isChecked={isActive} isPassive={isAllSelected} />

                  <span>{option.label}</span>
                </div>
              )
            })}
          </ScrollBarContainer>
        </div>
      )}
    </div>
  )
}
