import React, { FC, useEffect, useState } from 'react'
import en from 'src/constants/en'
import { FilterModalContentWrapper } from 'src/containers/FilterModalContentWrapper'
import { FilterSearchDropdownSection } from 'src/containers/FilterSearchDropdownSection'
import {
  AnalyticsFetchSentimentAnalysisResponseDataKeywordItem,
  AnalyticsKeywordsAnalysisResponseItem,
} from 'src/services/__generated__/api'
import { ReactComponent as ToggleLineSvg } from 'src/assets/svg/toggle-line.svg'
import { ReactComponent as ToggleFillSvg } from 'src/assets/svg/toggle-fill.svg'
import { MentionItem } from 'src/components/MentionItem'
import { FilterDropdownSelectOption } from 'src/containers/FilterSearchDropdown/interfaces/filterDropdownSelectOption.interface'
import { Switch } from 'src/components/Switch'
import { CheckBox } from 'src/components/CheckBox'
import { UseGetKeywordsAnalysisParams } from 'src/reactQuery/useGetKeywordsAnalysis/interfaces/useGetKeywordsAnalysis.interface'
import styles from './filterModalContent.module.scss'

export interface FilterModalContentProps {
  allKeywords?: AnalyticsKeywordsAnalysisResponseItem[]
  keywordAnalysesFilters?: UseGetKeywordsAnalysisParams
  onClose: () => void
  onSave: (params: {
    amount?: string
    selectedKeywords?: string[]
    type?: string
  }) => void
  selectedKeywords?: string[]
}

export const FilterModalContent: FC<FilterModalContentProps> = ({
  onSave,
  onClose,
  allKeywords,
  selectedKeywords,
  keywordAnalysesFilters,
}) => {
  const [selectedType, setSelectedType] = useState<string | undefined>(
    keywordAnalysesFilters?.filterChoice
  )

  const [isAdditionalFiltersOpen, setIsAdditionalFiltersOpen] = useState(false)

  const [selectedAmount, setSelectedAmount] = useState<string | undefined>(
    keywordAnalysesFilters?.filterCount
  )

  const keywordsToOption = (
    keywords?: AnalyticsFetchSentimentAnalysisResponseDataKeywordItem[]
  ) => {
    return (
      keywords?.map((keywordItem) => ({
        label: keywordItem.keyword || '',
        labelAddition: keywordItem.total && (
          <MentionItem totalMentions={keywordItem.total} />
        ),
        value: keywordItem.keyword || '',
      })) || []
    )
  }

  const optionToValue = (options?: FilterDropdownSelectOption[]) => {
    return options?.map((option) => option.value) || []
  }

  const mappedSelectedKeywords = keywordsToOption(
    allKeywords?.filter(
      (keywordItem) =>
        keywordItem.keyword && selectedKeywords?.includes(keywordItem.keyword)
    ) || []
  )

  const [selectedKeywordsOptions, setSelectedKeywordsOptions] = useState<
    FilterDropdownSelectOption[]
  >(mappedSelectedKeywords)

  const keywordOptions = keywordsToOption(allKeywords)

  const handleReset = () => {
    setSelectedKeywordsOptions(keywordOptions)

    setSelectedAmount('')

    setSelectedType(undefined)
  }

  useEffect(() => {
    setSelectedKeywordsOptions(mappedSelectedKeywords)
  }, [selectedKeywords?.toString()])

  return (
    <FilterModalContentWrapper
      onClose={() => onClose()}
      onReset={() => handleReset()}
      onSave={() =>
        onSave({
          amount: selectedAmount,
          selectedKeywords: optionToValue(selectedKeywordsOptions),
          type: selectedType,
        })
      }
    >
      <div className={styles.filterModalContent}>
        <FilterSearchDropdownSection
          countLabel={en.keywords}
          isOpen
          onSelect={setSelectedKeywordsOptions}
          options={keywordOptions}
          placeholder={en.keywords}
          selectedOptions={selectedKeywordsOptions}
        />
      </div>

      <div className={styles.sectionTitleWrapper}>
        <div className={styles.sectionTitle}>
          {isAdditionalFiltersOpen ? <ToggleFillSvg /> : <ToggleLineSvg />}

          {en.mostMentionsAmount}
        </div>

        <Switch
          isChecked={isAdditionalFiltersOpen}
          onChange={(isChecked) => setIsAdditionalFiltersOpen(isChecked)}
        />
      </div>

      {isAdditionalFiltersOpen && (
        <div className={styles.amountFilterSection}>
          <div className={styles.typeWrapper}>
            <div
              className={styles.radio}
              onClick={() => setSelectedType('more')}
            >
              <CheckBox isChecked={selectedType === 'more'} />

              <span className={styles.label}>{en.more}</span>
            </div>

            <div
              className={styles.radio}
              onClick={() => setSelectedType('less')}
            >
              <CheckBox isChecked={selectedType === 'less'} />

              <span className={styles.label}>{en.less}</span>
            </div>
          </div>

          <div className={styles.mainLabel}>{en.than}</div>

          <div className={styles.inputWrapper}>
            <input
              onChange={(e) => setSelectedAmount(e.target.value)}
              onKeyDown={(e) => {
                if (
                  !['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight'].includes(
                    e.key
                  ) &&
                  !/^\d+$/.test(e.key)
                ) {
                  e.preventDefault()
                }
              }}
              placeholder={en.number}
              type="text"
              value={selectedAmount}
            />
          </div>
        </div>
      )}
    </FilterModalContentWrapper>
  )
}
