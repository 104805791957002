import { useQuery } from '@tanstack/react-query'
import { QueryKey } from 'src/reactQuery/enums/queryKey.enum'
import { AnalyticsApi } from 'src/services/analyticsApi'
import { AnalyticsFetchQuickDetailsResponse } from 'src/services/__generated__/api'
import { UseGetQuickDetailsParams } from './interfaces/useGetQuickDetailsQuery.interface'

const getQuickDetails = async (
  start?: string,
  end?: string
): Promise<AnalyticsFetchQuickDetailsResponse | null> => {
  const { data } = await AnalyticsApi.v1Private.analyticsFetchQuickDetailsList({
    fromDate: start,
    toDate: end,
  })

  return data
}

export const useGetQuickDetailsQuery = (params: UseGetQuickDetailsParams) => {
  return useQuery({
    queryFn: () => getQuickDetails(params.start, params.end),
    queryKey: [QueryKey.GET_QUICK_DETAILS],
  })
}
