import { useMutation } from '@tanstack/react-query'
import { AnalyticsApi } from 'src/services/analyticsApi'

export interface removeThreadResponse {
  success?: boolean
}

export interface useRemoveThreadMutationParams {
  onError?: () => void
  onSuccess?: () => void
}

const removeThread = async (
  ids: string[]
): Promise<removeThreadResponse | undefined> => {
  const { data } = await AnalyticsApi.v1Private.campaignRemoveThreadDelete({
    ids,
  })

  return data
}

export const useRemoveThreadMutation = ({
  onError,
  onSuccess,
}: useRemoveThreadMutationParams = {}) => {
  return useMutation({
    mutationFn: removeThread,
    onError: () => {
      if (onError) {
        onError()
      }
    },
    onSuccess: () => {
      if (onSuccess) {
        onSuccess()
      }
    },
  })
}
