import { FC, MouseEvent as ReactMouseEvent, FormEvent, useState } from 'react'
import { toast } from 'react-toastify'
import { Navigate, useNavigate } from 'react-router-dom'
import { HOME_ROUTE } from 'src/constants/routes'
import en from 'src/constants/en'
import { useLoginMutation } from 'src/reactQuery/useLogInMutation'
import { setDataToLocalStorage } from 'src/localStorage/setDataToLocalStorage'
import { LocalStorageKey } from 'src/localStorage/enums/localStorageKey.enum'
import { AnalyticsApi } from 'src/services/analyticsApi'
import { SuggestionsApi } from 'src/services/suggestionsApi'
import { useGetSelfQuery } from 'src/reactQuery/useGetSelfQuery'
import { AdminApi } from 'src/services/adminApi'
import { CheckBox } from 'src/components/CheckBox'
import Button from 'src/components/Button'
import styles from './login.module.scss'
import EyeIcon from '../../assets/svg/eye.svg'
import EyeClosedIcon from '../../assets/svg/eyeclosed.svg'

export const Login: FC = () => {
  const navigate = useNavigate()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [checked, setChecked] = useState(true)
  const [passwordVisible, setPasswordVisible] = useState(false)

  type MouseEventType = ReactMouseEvent<HTMLButtonElement, MouseEvent>

  const { data: userData } = useGetSelfQuery()

  const loginMutation = useLoginMutation({
    onError: (error) => {
      if (error?.response?.status === 401) {
        toast.error(en.INVALID_CREDENTIALS)
      }
    },
    onSuccess: (data) => {
      const { sessionId } = data

      setDataToLocalStorage(LocalStorageKey.SESSION_ID, sessionId)

      AdminApi.refresh()

      AnalyticsApi.refresh()

      SuggestionsApi.refresh()

      navigate(HOME_ROUTE)
    },
  })

  const onLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    loginMutation.mutate({
      password,
      username: email,
    })
  }

  const isAuthenticated = !!userData?.user

  const handleChangeCheck = () => {
    setChecked(!checked)
  }

  if (isAuthenticated) {
    return (
      <Navigate
        to={{
          pathname: HOME_ROUTE,
        }}
      />
    )
  }

  const togglePassordVisibility = (e: MouseEventType) => {
    e.preventDefault()
    e.stopPropagation()

    setPasswordVisible(!passwordVisible)
  }

  return (
    <div className={styles.loginContainer}>
      <div className={styles.card}>
        <h2 className={styles.title}>{en.login}</h2>

        <div className={styles.subtitle}>{en.signInToAccessYourAccount}</div>

        <form className={styles.form} onSubmit={(e) => onLogin(e)}>
          <div className={styles.inputContainer}>
            <label className={styles.label}>{en.email}</label>

            <input
              className={styles.input}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="mail@website.com"
              type="email"
            />
          </div>

          <div className={styles.inputContainer}>
            <label className={styles.label}>{en.password}</label>

            <div className={styles.passwordWrapper}>
              <input
                className={styles.input}
                name=""
                onChange={(e) => setPassword(e.target.value)}
                placeholder={en.min8Characters}
                type={passwordVisible ? 'text' : 'password'}
              />

              <button
                className={styles.passwordVisibilityButton}
                onClick={(e) => togglePassordVisibility(e)}
                type="button"
              >
                <img
                  alt="password visibility"
                  className={styles.passwordVisibilityIcon}
                  src={passwordVisible ? EyeIcon : EyeClosedIcon}
                />
              </button>
            </div>
          </div>

          <div className={styles.additionalOptions}>
            <div className={styles.checkBox}>
              <CheckBox isChecked={checked} onChange={handleChangeCheck} />

              <label className={styles.checkboxLabel} htmlFor="check">
                {en.rememberMe}
              </label>
            </div>

            <a className={styles.forgotPasswordLink} href="#">
              {en.forgotPassword}
            </a>
          </div>

          <Button className={styles.submit} type="submit">
            {en.login}
          </Button>

          {/* TODO: Add after sign up will be live */}
          {/* <div className={styles.signUpLink}}>
            Not registered?{' '}
            <span>
              <Link
                to={SIGNUP_ROUTE}
              >
                Create an account
              </Link>
            </span>
          </div> */}
        </form>
      </div>
    </div>
  )
}
