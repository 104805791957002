import { FC, useMemo, useState } from 'react'
import { EChartOption } from 'echarts'
import classNames from 'classnames'
import { getNumberOrZero } from 'src/utils/getNumberOrZero'
import { COLORS } from 'src/constants/colors'
import en from 'src/constants/en'
import { ReactComponent as LoudspeakerSvg } from 'src/assets/svg/loudspeaker.svg'
import { numberWithCommas } from 'src/utils/numberFormat'
import { AnalyticsFetchQuickDetailsResponseItem } from 'src/services/__generated__/api'
import styles from './quickDetailsChart.module.scss'
import { ChartInfo } from './interfaces/chartInfo.interface'
import { PieDataItem } from './interfaces/pideData.interface'
import { EChart } from '../../EChart'

interface QuickDetailsChartProps {
  campaign?: AnalyticsFetchQuickDetailsResponseItem
  isActive?: boolean
}

export const QuickDetailsChart: FC<QuickDetailsChartProps> = ({
  campaign,
  isActive,
}) => {
  const maxDataItem = [
    {
      percentage: getNumberOrZero(campaign?.negativePerc),
      value: getNumberOrZero(campaign?.negatives),
    },
    {
      percentage: getNumberOrZero(campaign?.positivePerc),
      value: getNumberOrZero(campaign?.positives),
    },
    {
      percentage: getNumberOrZero(campaign?.neutralPerc),
      value: getNumberOrZero(campaign?.neutrals),
    },
  ].reduce((prev, current) => {
    return prev.value > current.value ? prev : current
  })

  const [chartInfo, setChartInfo] = useState<ChartInfo>(maxDataItem)

  const options: EChartOption<EChartOption.SeriesPie> = useMemo(() => {
    const pieData: PieDataItem[] = [
      {
        color: COLORS.malibu,
        name: en.positive,
        percentage: getNumberOrZero(campaign?.positivePerc),
        value: getNumberOrZero(campaign?.positives),
      },
      {
        color: COLORS.burningOrange,
        name: en.negative,
        percentage: getNumberOrZero(campaign?.negativePerc),
        value: getNumberOrZero(campaign?.negatives),
      },
      {
        color: COLORS.grey200,
        name: en.neutral,
        percentage: getNumberOrZero(campaign?.neutralPerc),
        value: getNumberOrZero(campaign?.neutrals),
      },
    ]

    const options: EChartOption<EChartOption.SeriesPie> = {
      color: pieData.map((item) => item.color),
      series: [
        {
          avoidLabelOverlap: true,
          data: pieData,
          emphasis: {
            // @ts-ignore-next-line
            scale: false,
          },
          itemStyle: {
            borderColor: COLORS.white,
            borderWidth: 7,
          },
          label: {
            show: false,
          },
          labelLine: {
            normal: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          pointer: { show: false },
          radius: isActive ? [62, 82] : [42, 62],
          type: 'pie',
        },
      ],
    }

    return options
  }, [campaign, isActive])

  return (
    <div
      className={classNames(
        styles.quickDetailsChart,
        isActive && styles.active
      )}
    >
      <EChart<PieDataItem>
        onMouseOut={() => {
          setChartInfo(maxDataItem)
        }}
        onMouseOver={(event) => {
          setChartInfo({
            percentage: event?.data?.percentage,
            value: event?.data?.value,
          })
        }}
        options={options}
      />

      <div className={styles.chartInfo}>
        <div
          className={classNames(
            styles.chartTitle,
            isActive && styles.chartTitleActive
          )}
        >
          {chartInfo.percentage.toFixed(0)}%
        </div>

        <div
          className={classNames(
            styles.chartSubTitle,
            isActive && styles.chartSubTitleActive
          )}
        >
          {numberWithCommas(chartInfo.value)}

          <LoudspeakerSvg className={styles.loudspeakerIcon} />
        </div>
      </div>
    </div>
  )
}
