import { useQuery } from '@tanstack/react-query'
import { QueryKey } from 'src/reactQuery/enums/queryKey.enum'
import { AnalyticsApi } from 'src/services/analyticsApi'
import { KeywordFetchSentimentChart } from 'src/services/__generated__/api'
import { UseGetSentimentChartByKeywordQueryParams } from './interfaces/useGetSentimentChartByKeywordQuery.interface'

const getSentimentChartByKeyword = async (
  campaign?: string,
  keyword?: string,
  start?: string,
  end?: string,
  campaigns?: string[],
  sources?: string[]
): Promise<KeywordFetchSentimentChart | undefined> => {
  const { data } = await AnalyticsApi.v1Private.keywordFetchSentimentChartList({
    campaign,
    campaigns: campaigns?.join(','),
    fromDate: start,
    keyword,
    sources: sources?.join(','),
    toDate: end,
  })

  return data
}

export const useGetSentimentChartByKeywordQuery = (
  params: UseGetSentimentChartByKeywordQueryParams
) => {
  return useQuery({
    queryFn: () =>
      getSentimentChartByKeyword(
        params.campaign,
        params.keyword,
        params.start,
        params.end,
        params.campaigns,
        params.sources
      ),
    queryKey: [QueryKey.GET_SENTIMENT_CHART_BY_KEYWORD, params],
  })
}
