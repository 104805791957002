import { useEffect } from 'react'
import { UseOutsideClickParams } from './interfaces/useOutsideClick.interface'

const useOutsideClick = ({ ref, onOutsideClick }: UseOutsideClickParams) => {
  useEffect(() => {
    const handleListener = (event: MouseEvent | TouchEvent) => {
      if (ref.current && ref.current.contains(event.target as Node)) {
        return
      }
      onOutsideClick(event)
    }

    document.addEventListener('mousedown', handleListener)
    document.addEventListener('touchstart', handleListener)

    return () => {
      document.removeEventListener('mousedown', handleListener)
      document.removeEventListener('touchstart', handleListener)
    }
  }, [])
}

export default useOutsideClick
