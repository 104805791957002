import { useQuery } from '@tanstack/react-query'
import { QueryKey } from 'src/reactQuery/enums/queryKey.enum'
import { AnalyticsApi } from 'src/services/analyticsApi'
import { KeywordFetchTopSources } from 'src/services/__generated__/api'
import { UseGetTopSourcesByKeywordQueryParams } from './interfaces/useGetTopSourcesByKeywordQuery.interface'

const getTopSourcesByKeyword = async (
  campaign?: string,
  keyword?: string,
  start?: string,
  end?: string
): Promise<KeywordFetchTopSources[] | undefined> => {
  const { data } = await AnalyticsApi.v1Private.keywordFetchTopSourcesList({
    campaign,
    fromDate: start,
    keyword,
    toDate: end,
  })

  return data
}

export const useGetTopSourcesByKeywordQuery = (
  params: UseGetTopSourcesByKeywordQueryParams
) => {
  return useQuery({
    queryFn: () =>
      getTopSourcesByKeyword(
        params.campaign,
        params.keyword,
        params.start,
        params.end
      ),
    queryKey: [QueryKey.GET_TOP_SOURCES_BY_KEYWORD, params],
  })
}
