import { FC, useEffect, useState } from 'react'
import { EChartOption } from 'echarts'
import { Textfit } from 'react-textfit'
import classNames from 'classnames'
import { EChart } from 'src/charts/EChart'
import DiagonalArrowUp from 'src/assets/svg/diagonalArrowUp.svg'
import DiagonalArrowDown from 'src/assets/svg/diagonalArrowDown.svg'
import { COLORS } from 'src/constants/colors'
import en from 'src/constants/en'
import { getNumberOrZero } from 'src/utils/getNumberOrZero'
import hexToRgbA from 'src/utils/hexToRgba'
import VioletLoudspeaker from 'src/assets/svg/violetLoudspeaker.svg'
import VioletThumbUp from 'src/assets/svg/violetThumbUp.svg'
import OrangeThumbDown from 'src/assets/svg/orangeThumbDown.svg'
import PositiveTotal from 'src/assets/svg/positiveTotal.svg'
import NegativeTotal from 'src/assets/svg/negativeTotal.svg'
import SmallPieChart from 'src/assets/svg/smallPieChart.svg'
import PositiveTheme from 'src/assets/svg/positiveTheme.svg'
import TopSourceTheme from 'src/assets/svg/topSourceTheme.svg'
import YoutubeIcon from 'src/assets/svg/youtube-icon.svg'
import FacebookIcon from 'src/assets/svg/facebook-fill.svg'
import InstagramIcon from 'src/assets/svg/instagram-fill.svg'
import GoogleIcon from 'src/assets/svg/google-fill.svg'
import RedditIcon from 'src/assets/svg/reddit-fill.svg'
import DefaultIcon from 'src/assets/svg/website-fill.svg'
import TopLocationTheme from 'src/assets/svg/topLocationTheme.svg'
import LocationPin from 'src/assets/svg/locationPin.svg'
import TwitterIcon from 'src/assets/svg/twitter.svg'
import NewspaperIcon from 'src/assets/svg/newspaper-fill.svg'
import TiktokIcon from 'src/assets/svg/tiktok-fill.svg'
import {
  CampaignFetchTopLocationData,
  CampaignFetchTopSourceData,
} from 'src/services/__generated__/api'
import { MentionCardInfoTypes } from './interfaces/mentionCardInfoTypes.interface'
import styles from './mentionCard.module.scss'

interface MentionsCardProps {
  blockText?: string
  footNote?: string
  footNotePercent?: number
  infoType?: MentionCardInfoTypes
  locationChartData?: CampaignFetchTopLocationData
  percent?: number
  title?: string
  topSourceChartData?: CampaignFetchTopSourceData
}

const colorPalette = COLORS.burningOrange

export const MentionCard: FC<MentionsCardProps> = ({
  blockText,
  footNote,
  footNotePercent,
  infoType,
  locationChartData,
  topSourceChartData,
  percent,
  title,
}) => {
  const [options, setOptions] =
    useState<EChartOption<EChartOption.SeriesPie> | null>(null)

  const [label, setLabel] = useState<number>()

  const [isPageLoaded, setIsPageLoaded] = useState<boolean>(false)

  const shouldShowPercentInBlockText =
    percent && (infoType === 'positive' || infoType === 'negative')

  const maxRatioPercentage = Math.max(
    getNumberOrZero(topSourceChartData?.positivePercentage),
    getNumberOrZero(topSourceChartData?.negativePercentage),
    getNumberOrZero(topSourceChartData?.neutralPercentage)
  )

  const backgroundColor = hexToRgbA(
    infoType === 'negative' ? COLORS.burningOrange : COLORS.malibu,
    0.1
  )

  const iconOptions: Record<string, string> = {
    negative: OrangeThumbDown,
    positive: VioletThumbUp,
    positiveTheme: VioletThumbUp,
    topLocationTheme: LocationPin,
    topSourceTheme: DefaultIcon,
    total: VioletLoudspeaker,
  }

  const chartIconOptions: Record<string, string> = {
    negative: NegativeTotal,
    positive: PositiveTotal,
    positiveTheme: PositiveTheme,
    topLocationTheme: TopLocationTheme,
    topSourceTheme: TopSourceTheme,
    total: SmallPieChart,
  }

  switch (blockText) {
    case 'Twitter':
      iconOptions.topSourceTheme = TwitterIcon
      break
    case 'Youtube':
      iconOptions.topSourceTheme = YoutubeIcon
      break
    case 'Facebook':
      iconOptions.topSourceTheme = FacebookIcon
      break
    case 'Instagram':
      iconOptions.topSourceTheme = InstagramIcon
      break
    case 'Google':
      iconOptions.topSourceTheme = GoogleIcon
      break
    case 'Reddit':
      iconOptions.topSourceTheme = RedditIcon
      break
    case 'Newspaper':
      iconOptions.topSourceTheme = NewspaperIcon
      break
    case 'Tiktok':
      iconOptions.topSourceTheme = TiktokIcon
      break
    default:
      iconOptions.topSourceTheme = DefaultIcon
      break
  }
  useEffect(() => {
    function getOption() {
      setOptions({
        geo: {
          itemStyle: {
            emphasis: {
              areaColor: '#EDF0F4',
              borderColor: '#5A8DE9',
              borderWidth: '1',
              label: { show: false },
            },
            normal: {
              areaColor: '#EDF0F4',
              borderColor: '#5A8DE9',
              borderWidth: 0,
              label: { show: false },
            },
          },
          label: {
            emphasis: {
              show: false,
            },
          },
          left: 'center',
          map: 'world',
          name: 'Top Location',
          roam: false,
          type: 'map',
        },
        grid: {
          height: 72,
          width: 105,
        },
        series: [
          {
            coordinateSystem: 'geo',
            data: [
              {
                country: locationChartData?.country,
                itemStyle: {
                  // @ts-ignore-next-line
                  normal: {
                    borderColor: hexToRgbA(colorPalette, 1),
                    borderWidth: 1,
                    color: hexToRgbA(colorPalette, 0.35),
                  },
                },
                name: 'Top Location',
                // @ts-ignore-next-line
                value: [
                  locationChartData?.lng,
                  locationChartData?.lat,
                  locationChartData?.mentions,
                ],
              },
            ],
            name: locationChartData?.country,
            type: 'scatter',
          },
        ],
        tooltip: {
          formatter: (params) => {
            // @ts-ignore-next-line
            if (params && params.data) {
              // @ts-ignore-next-line
              return `${params.data.country}: ${params.data.value[2]}`
            }

            return ''
          },
          showDelay: 0,
          transitionDuration: 0.2,
          trigger: 'item',
        },
        visualMap: {
          // @ts-ignore-next-line
          inRange: {
            symbolSize: [6, 15],
          },
          max: locationChartData?.mentions,
          min: 0,
          show: false,
        },
      })
    }

    if (locationChartData && infoType === 'topLocationTheme') {
      getOption()
    }
  }, [locationChartData, infoType])

  useEffect(() => {
    const getOptions = () => {
      const allMentionsCount = getNumberOrZero(topSourceChartData?.mentions)

      const positivePercentage = getNumberOrZero(
        topSourceChartData?.positivePercentage
      )
      const negativePercentage = getNumberOrZero(
        topSourceChartData?.negativePercentage
      )
      const neutralPercentage = getNumberOrZero(
        topSourceChartData?.neutralPercentage
      )

      const positiveMentionsCount = positivePercentage
        ? Math.round((allMentionsCount * positivePercentage) / 100)
        : 0
      const negativeMentionsCount = negativePercentage
        ? Math.round((allMentionsCount * negativePercentage) / 100)
        : 0
      const neutralMentionsCount = neutralPercentage
        ? Math.round((allMentionsCount * neutralPercentage) / 100)
        : 0

      const data: EChartOption<EChartOption.SeriesPie> = {
        color: [COLORS.malibu, COLORS.burningOrange, COLORS.grey200],
        grid: {
          height: '125',
          left: 'center',
          width: '125',
        },
        series: [
          {
            avoidLabelOverlap: true,
            data: [
              {
                name: en.positive,
                value: positiveMentionsCount,
              },
              {
                name: en.negative,
                value: negativeMentionsCount,
              },
              {
                name: en.neutral,
                value: neutralMentionsCount,
              },
            ],
            emphasis: {
              // @ts-ignore-next-line
              scale: false,
            },
            itemStyle: {
              borderColor: COLORS.white,
              borderWidth: 1.5,
            },
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            radius: ['68%', '90%'],
            type: 'pie',
          },
        ],
        tooltip: {
          trigger: 'item',
        },
      }

      setOptions(data)
    }

    if (topSourceChartData && infoType === 'topSourceTheme') {
      getOptions()
    }
  }, [topSourceChartData, infoType])

  useEffect(() => {
    setLabel(maxRatioPercentage)
  }, [maxRatioPercentage])

  useEffect(() => {
    setIsPageLoaded(true)
  }, [])

  return (
    <div className={styles.card}>
      <div className={styles.leading} style={{ backgroundColor }}>
        <img src={infoType ? iconOptions[infoType] : ''} />
      </div>

      <div className={styles.infoColumn}>
        <div className={styles.title}>{title}</div>

        <div className={styles.middle}>
          {blockText && (
            <div
              className={classNames(
                styles.blockText,
                !shouldShowPercentInBlockText && styles.fullWidthBlockText
              )}
            >
              {!shouldShowPercentInBlockText && isPageLoaded ? (
                <Textfit max={24} mode="single">
                  {blockText}
                </Textfit>
              ) : (
                blockText
              )}
            </div>
          )}

          {shouldShowPercentInBlockText && (
            <span className={classNames(styles.percent, styles[infoType])}>
              <span className={styles.arrow}>
                <img
                  alt="arrow icon"
                  src={
                    infoType === 'positive'
                      ? DiagonalArrowUp
                      : DiagonalArrowDown
                  }
                />
              </span>

              {`${Math.abs(percent)}%`}
            </span>
          )}
        </div>

        <div className={styles.footNote}>
          {footNote ?? 'Avg. Per Keyword'}

          {infoType === 'positiveTheme' && footNotePercent && (
            <span
              className={classNames(
                styles.percent,
                footNotePercent < 0 ? styles.negative : styles.positive
              )}
            >
              <span className={styles.arrow}>
                <img
                  alt="arrow icon"
                  src={
                    footNotePercent < 0 ? DiagonalArrowDown : DiagonalArrowUp
                  }
                />
              </span>

              {`${footNotePercent}%`}
            </span>
          )}
        </div>
      </div>

      <div className={styles.trailing}>
        {infoType === 'topLocationTheme' && options && (
          <div className={styles.topLocationChartWrapper}>
            <EChart height={72} options={options} width={160} />
          </div>
        )}

        {infoType === 'topSourceTheme' && options && (
          <div className={styles.topSourceChartWrapper}>
            <div className={styles.label}>{label?.toFixed(0)}%</div>

            <EChart
              height={80}
              onMouseOut={() => setLabel(maxRatioPercentage)}
              onMouseOver={(event) => setLabel(event.percent)}
              options={options}
              width={80}
            />
          </div>
        )}

        {infoType !== 'topLocationTheme' && infoType !== 'topSourceTheme' && (
          <span
            className={
              infoType === 'total'
                ? styles.totalChartWrapper
                : styles.defaultChartWrapper
            }
          >
            <img alt="" src={infoType ? chartIconOptions[infoType] : ''} />
          </span>
        )}
      </div>
    </div>
  )
}
