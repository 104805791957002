import { useQuery } from '@tanstack/react-query'
import { QueryKey } from 'src/reactQuery/enums/queryKey.enum'
import { AnalyticsApi } from 'src/services/analyticsApi'
import {
  TripAdvisorPlace,
  UseGetTripAdvisorPlacesQueryParams,
} from './interfaces/useGetTripAdvisorPlacesQuery.interface'

const getTripAdvisorPlaces = async (
  language: string,
  searchQuery: string
): Promise<TripAdvisorPlace[] | undefined> => {
  if (searchQuery) {
    const { data } =
      await AnalyticsApi.v1Private.tripadvisorSearchTripAdvisorList({
        language,
        searchQuery,
      })

    return data.data
  }
  return []
}

export const useGetTripAdvisorPlacesQuery = (
  params: UseGetTripAdvisorPlacesQueryParams
) => {
  return useQuery({
    queryFn: () => getTripAdvisorPlaces(params.language, params.searchQuery),
    queryKey: [QueryKey.GET_TRIPADVISOR_PLACES, params],
  })
}
