import { OptionProps, StylesConfig } from 'react-select'
import { COLORS } from 'src/constants/colors'
import hexToRgbA from 'src/utils/hexToRgba'

const getOptionBackgroundColor = (option: OptionProps) => {
  if (option.isSelected) {
    return COLORS.malibu
  }

  if (option.isFocused) {
    return hexToRgbA(COLORS.malibu, 0.3)
  }

  return COLORS.white
}

export const colourStyles: StylesConfig = {
  control: (styles, { isFocused }) => ({
    ...styles,
    '&:hover': {
      borderColor: isFocused ? COLORS.malibu : styles.borderColor,
    },
    borderColor: isFocused ? COLORS.malibu : styles.borderColor,
    boxShadow: isFocused ? `0 0 0 1px ${COLORS.malibu}` : styles.boxShadow,
  }),
  input: (styles) => ({
    ...styles,
    width: '100%',
  }),
  option: (styles, option) => ({
    ...styles,
    ':active': {
      ...styles[':active'],
      backgroundColor: COLORS.malibu,
      color: COLORS.white,
    },
    backgroundColor: getOptionBackgroundColor(option),
    color: option.isSelected ? COLORS.white : styles.color,
    cursor: 'pointer',
  }),
}
