import { useQuery } from '@tanstack/react-query'
import { QueryKey } from 'src/reactQuery/enums/queryKey.enum'
import { AnalyticsApi } from 'src/services/analyticsApi'
import {
  AnalyticsFetchThreadsResponseDataItem,
  AnalyticsFetchThreadsSentimentType,
  AnalyticsFetchThreadsVectorType,
} from 'src/services/__generated__/api'
import { UseGetNegativeLiveNotificationsQueryParams } from './interfaces/useGetNegativeLiveNotificationsQuery.interface'

const getNegativeLiveNotifications = async (
  lastDate?: string,
  limit?: number
): Promise<AnalyticsFetchThreadsResponseDataItem[] | null> => {
  if (!lastDate || !limit) {
    return null
  }

  const {
    data: { data },
  } = await AnalyticsApi.v1Private.analyticsFetchThreadsList({
    lastDate,
    limit,
    sentiment: AnalyticsFetchThreadsSentimentType.NEG,
    vector: AnalyticsFetchThreadsVectorType.Desc,
  })

  return data ?? null
}

export const useGetNegativeLiveNotificationsQuery = (
  params: UseGetNegativeLiveNotificationsQueryParams
) => {
  return useQuery({
    queryFn: () => getNegativeLiveNotifications(params.lastDate, params.limit),
    queryKey: [
      QueryKey.GET_NEGATIVE_LIVE_NOTIFICATIONS,
      ...Object.values(params),
    ],
    refetchInterval: Infinity,
  })
}
