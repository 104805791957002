import React, { FC, useState } from 'react'
import en from 'src/constants/en'
import { Card } from 'src/components/Card'
import Button from 'src/components/Button'
import { CampaignInput } from 'src/components/CampaignInput'
import styles from './renameCampaignModalContent.module.scss'

export interface RenameCampaignModalContentProps {
  name?: string
  onCancel: () => void
  onConfirm: (name: string) => void
}

export const RenameCampaignModalContent: FC<
  RenameCampaignModalContentProps
> = ({ name, onConfirm, onCancel }) => {
  const [campaignName, setCampaignName] = useState(name || '')

  return (
    <Card className={styles.renameCampaignModalContent}>
      <label className={styles.label}>{en.campaignName}</label>

      <CampaignInput
        onChange={setCampaignName}
        placeholder={en.enterNameOfCampaign}
        themeName={campaignName}
      />

      <div className={styles.buttons}>
        <button className={styles.cancel} onClick={() => onCancel()}>
          {en.cancel}
        </button>

        <Button
          className={styles.confirm}
          onClick={() => onConfirm(campaignName)}
        >
          {en.confirm}
        </Button>
      </div>
    </Card>
  )
}
