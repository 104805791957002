import React, { FC, ReactNode } from 'react'
import styles from './headerCell.module.scss'

interface HeaderCellProps {
  children?: ReactNode
}

export const HeaderCell: FC<HeaderCellProps> = ({ children }) => {
  return <span className={styles.headerCell}>{children}</span>
}
