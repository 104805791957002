import { FC, FormEvent, useState, MouseEvent } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { validateEmail, validatePassword } from 'src/utils/validations'
import { LOGIN_ROUTE } from 'src/constants/routes'
import en from 'src/constants/en'
import { HAPTTIC_WEB } from 'src/constants/links'
import { useSignUpMutation } from 'src/reactQuery/useSignUpMutation'
import Button from 'src/components/Button'
import styles from './signup.module.scss'
import EyeIcon from '../../assets/svg/eye.svg'
import EyeClosedIcon from '../../assets/svg/eyeclosed.svg'

const SignUp: FC = () => {
  const [user, setUser] = useState({
    company: '',
    email: '',
    firstName: '',
    lastName: '',
    password: '',
  })

  const [passwordVisible, setPasswordVisible] = useState(false)

  const mutation = useSignUpMutation({
    onSuccess: () => {
      window.location.href = HAPTTIC_WEB
    },
  })

  const onLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (
      validateEmail(user.email) &&
      !validatePassword(user.password).errors.length
    ) {
      mutation.mutate(user)
    }
  }

  const togglePassordVisibility = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()

    setPasswordVisible(!passwordVisible)
  }

  return (
    <div className={styles.signupWrapper}>
      <div className={styles.signinContainer}>
        <div className={styles.card}>
          <div className={styles.header}>
            <h2 className={styles.title}>{en.signup}</h2>

            <div className={styles.subtitle}>
              {en.haveAccount}

              <Link className={styles.subtitleLink} to={LOGIN_ROUTE}>
                {en.login}
              </Link>
            </div>
          </div>

          <form onSubmit={(e) => onLogin(e)}>
            <div className={styles.nameWrapper}>
              <div
                className={classNames(
                  styles.inputContainer,
                  styles.inputContainerHalf
                )}
              >
                <label className={styles.label}>{en.firstName}</label>

                <input
                  className={styles.input}
                  onChange={(e) =>
                    setUser({ ...user, firstName: e.target.value })
                  }
                  placeholder={en.name}
                  type="text"
                />
              </div>

              <div
                className={classNames(
                  styles.inputContainer,
                  styles.inputContainerHalf
                )}
              >
                <label className={styles.label}>{en.lastName}</label>

                <input
                  className={styles.input}
                  onChange={(e) =>
                    setUser({ ...user, lastName: e.target.value })
                  }
                  placeholder={en.lastName}
                  type="text"
                />
              </div>
            </div>

            <div className={styles.inputContainer}>
              <label className={styles.label}>{en.email}</label>

              <input
                className={styles.input}
                onChange={(e) => setUser({ ...user, email: e.target.value })}
                placeholder={en.EMAIL_EXAMPLE}
                type="text"
              />
            </div>

            <div className={styles.inputContainer}>
              <label className={styles.label}>{en.company}</label>

              <input
                className={styles.input}
                onChange={(e) => setUser({ ...user, company: e.target.value })}
                placeholder={en.companyName}
                type="text"
              />
            </div>

            <div className={styles.inputContainer}>
              <label className={styles.label}>{en.password}</label>

              <div className={styles.passwordWrapper}>
                <input
                  autoComplete="on"
                  className={styles.input}
                  onChange={(e) =>
                    setUser({ ...user, password: e.target.value })
                  }
                  placeholder={en.MIN_CHARACTERS_PASSWORD}
                  type={passwordVisible ? 'text' : 'password'}
                />

                <button
                  className={styles.passwordButton}
                  onClick={(e) => togglePassordVisibility(e)}
                >
                  <img
                    alt="password visibility"
                    src={passwordVisible ? EyeIcon : EyeClosedIcon}
                  />
                </button>
              </div>
            </div>

            <Link className={styles.forgotPasswordLink} to="#">
              {en.forgotPassword}
            </Link>

            <Button className={styles.createButton} type="submit">
              {en.createAccount}
            </Button>
          </form>
        </div>
      </div>
    </div>
  )
}
export default SignUp
