import { FC } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const NotificationMessages: FC = () => {
  return (
    <ToastContainer
      autoClose={3000}
      closeButton={false}
      closeOnClick
      hideProgressBar
      icon={false}
      position="bottom-left"
    />
  )
}
export default NotificationMessages
