import { useQuery } from '@tanstack/react-query'
import { QueryKey } from 'src/reactQuery/enums/queryKey.enum'
import { AnalyticsApi } from 'src/services/analyticsApi'
import { AnalyticsFetchHotLinksResponse } from 'src/services/__generated__/api'
import { UseGetHotLinksParams } from './interfaces/useGetHotLinksQuery.interface'

const getHotLinks = async (
  start?: string,
  end?: string
): Promise<AnalyticsFetchHotLinksResponse | null> => {
  const { data } = await AnalyticsApi.v1Private.analyticsFetchHotLinksList({
    fromDate: start,
    toDate: end,
  })

  return data
}

export const useGetHotLinksQuery = (params: UseGetHotLinksParams) => {
  return useQuery({
    queryFn: () => getHotLinks(params.start, params.end),
    queryKey: [QueryKey.GET_HOT_LINKS],
  })
}
