import { FC, UIEvent, useMemo, useState } from 'react'
import en from 'src/constants/en'
import { LiveNotificationItem } from 'src/components/LiveNotificationItem'
import { Type } from 'src/components/LiveNotificationItem/enums/type.enum'
import { useGetInformationTooltipsQuery } from 'src/reactQuery/useGetInformationTooltips'
import {
  AnalyticsFetchThreadsResponseDataItem,
  AnalyticsFetchThreadsSentimentType,
} from 'src/services/__generated__/api'
import { SectionTitle } from 'src/components/SectionTitle'
import styles from './liveNotifications.module.scss'

interface LiveNotificationsSectionProps {
  handleNotificationsScroll: (
    event: UIEvent,
    sentiment: AnalyticsFetchThreadsSentimentType
  ) => void
  isLoading?: boolean
  negativeData: AnalyticsFetchThreadsResponseDataItem[] | null
  neutralData: AnalyticsFetchThreadsResponseDataItem[] | null
  positiveData: AnalyticsFetchThreadsResponseDataItem[] | null
}

export const LiveNotificationsSection: FC<LiveNotificationsSectionProps> = ({
  handleNotificationsScroll,
  isLoading,
  negativeData,
  neutralData,
  positiveData,
}) => {
  const { data: informationTooltips } = useGetInformationTooltipsQuery()

  const [activeTitle, setActiveTitle] = useState('All')

  const getDataToRender = () => {
    switch (activeTitle) {
      case 'Positive':
        return [...(positiveData || [])]
      case 'Neutral':
        return [...(neutralData || [])]
      case 'Negative':
        return [...(negativeData || [])]
      case 'All':
      default:
        return [
          ...(positiveData || []),
          ...(neutralData || []),
          ...(negativeData || []),
        ]
    }
  }

  const dataToRender = useMemo(
    () => getDataToRender(),
    [activeTitle, positiveData, neutralData, negativeData]
  )

  const determineType = (activeTitle: string): Type => {
    switch (activeTitle) {
      case 'Positive':
        return Type.POSITIVE
      case 'Negative':
        return Type.NEGATIVE
      case 'Neutral':
        return Type.NEUTRAL
      case 'All':
      default:
        return Type.OTHER
    }
  }

  return (
    <div className={styles.liveNotifications}>
      <SectionTitle
        isLoading={isLoading}
        liveIcon
        title={en.liveNotifications}
        tooltipContent={informationTooltips?.liveNotifications}
      />

      <div className={styles.innerContainer}>
        <div className={styles.notificationsContainer}>
          <div className={styles.notificationsContainerTitleWrapper}>
            <span
              className={
                activeTitle === 'All'
                  ? styles.notificationsContainerTitleActive
                  : styles.notificationsContainerTitle
              }
              onClick={() => setActiveTitle('All')}
            >
              All
            </span>
            <span
              className={
                activeTitle === 'Positive'
                  ? styles.notificationsContainerTitleActive
                  : styles.notificationsContainerTitle
              }
              onClick={() => setActiveTitle('Positive')}
            >
              Positive
            </span>
            <span
              className={
                activeTitle === 'Neutral'
                  ? styles.notificationsContainerTitleActive
                  : styles.notificationsContainerTitle
              }
              onClick={() => setActiveTitle('Neutral')}
            >
              Neutral
            </span>
            <span
              className={
                activeTitle === 'Negative'
                  ? styles.notificationsContainerTitleActive
                  : styles.notificationsContainerTitle
              }
              onClick={() => setActiveTitle('Negative')}
            >
              Negative
            </span>
          </div>

          <div
            className={styles.scrollbarContainer}
            onScroll={(e) =>
              handleNotificationsScroll(
                e,
                AnalyticsFetchThreadsSentimentType.POS
              )
            }
          >
            {dataToRender?.map((item, index) => (
              <LiveNotificationItem
                campaign={item.campaign_name}
                hideCheckboxOnHover
                key={index}
                keyword={item.keyword}
                language={item.language}
                link={item.link}
                originalLangCode={item.original_lang_code}
                originalText={item.text}
                score={item.score}
                sentiments={item.positive_keywords}
                source={item.source}
                time={item.published_at}
                translatedText={item.translated}
                type={determineType(activeTitle)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
