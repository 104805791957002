import { FC } from 'react'
import en from 'src/constants/en'
import { ReactComponent as LoudspeakerSvg } from 'src/assets/svg/loudspeaker.svg'
import { numberWithCommas } from 'src/utils/numberFormat'
import styles from './mentions.module.scss'

interface MentionsProps {
  negativeCount?: number
  neutralCount?: number
  positiveCount?: number
  total?: number
}

export const Mentions: FC<MentionsProps> = ({
  negativeCount = 0,
  neutralCount = 0,
  positiveCount = 0,
  total = 0,
}) => {
  return (
    <div className={styles.mentions}>
      <div className={styles.title}>{en.mentions}</div>

      {!!total && (
        <div className={styles.listItem}>
          <span className={styles.label}>{en.total}</span>

          <span className={styles.value}>{numberWithCommas(total)}</span>

          <LoudspeakerSvg />
        </div>
      )}

      <div className={styles.divider} />

      {!!positiveCount && (
        <div className={styles.listItem}>
          <span className={styles.label}>{en.positive}</span>

          <span className={styles.value}>
            {numberWithCommas(positiveCount)}
          </span>

          <LoudspeakerSvg />
        </div>
      )}

      {!!neutralCount && (
        <div className={styles.listItem}>
          <span className={styles.label}>{en.neutral}</span>

          <span className={styles.value}>{numberWithCommas(neutralCount)}</span>

          <LoudspeakerSvg />
        </div>
      )}

      {!!negativeCount && (
        <div className={styles.listItem}>
          <span className={styles.label}>{en.negative}</span>

          <span className={styles.value}>
            {numberWithCommas(negativeCount)}
          </span>

          <LoudspeakerSvg />
        </div>
      )}
    </div>
  )
}
