import { ReactNode, FC } from 'react'
import BackToTopButton from 'src/components/BackToTopButton'
import styles from './layout.module.scss'
import { TopBar } from './TopBar'
import { Sidebar } from './Sidebar'

interface LayoutProps {
  children: ReactNode
}

export const Layout: FC<LayoutProps> = ({ children }) => {
  return (
    <div className={styles.mainContainer}>
      <Sidebar />

      <div className={styles.mainContainerInner}>
        <TopBar />

        <BackToTopButton />

        {children}
      </div>
    </div>
  )
}
