import React, { FC, ReactElement } from 'react'
import RcTooltip from 'rc-tooltip'
import styles from './tooltip.module.scss'

interface TooltipProps {
  children: ReactElement
  mouseEnterDelay?: number
  overlay: string
  placement?: string
}

export const Tooltip: FC<TooltipProps> = ({
  overlay,
  children,
  placement = 'top',
  mouseEnterDelay,
}) => {
  return (
    <RcTooltip
      mouseEnterDelay={mouseEnterDelay}
      overlay={<div className={styles.overlay}>{overlay}</div>}
      overlayClassName={styles.tooltip}
      placement={placement}
    >
      {children}
    </RcTooltip>
  )
}
