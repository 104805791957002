import { FC } from 'react'
import PlusIcon from 'src/assets/svg/plusicon.svg'
import styles from './addNewCard.module.scss'

interface AddNewCardProps {
  handleClick?: () => void
  text?: string
}

export const AddNewCard: FC<AddNewCardProps> = ({ text, handleClick }) => {
  return (
    <div className={styles.addNewCard} onClick={handleClick}>
      <div className={styles.addNewCardInner}>
        <img alt="cross icon" src={PlusIcon} />

        <div className={styles.text}>{text}</div>
      </div>
    </div>
  )
}
