import { useQuery } from '@tanstack/react-query'
import { QueryKey } from 'src/reactQuery/enums/queryKey.enum'
import { AnalyticsApi } from 'src/services/analyticsApi'
import {
  AnalyticsFetchSentimentAnalysisResponseData,
  AnalyticsFetchThreadsIsLostType,
} from 'src/services/__generated__/api'
import { UseGetSentimentAnalysisByCampaignQueryParams } from './interfaces/useGetSentimentAnalysisByCampaignQuery.interface'

const getSentimentAnalysisByCampaign = async (
  start?: string,
  end?: string,
  campaign?: string,
  selectedSources?: string,
  selectedKeywords?: string,
  languages?: string,
  mentionFormat?: string,
  isLost?: AnalyticsFetchThreadsIsLostType
): Promise<AnalyticsFetchSentimentAnalysisResponseData | null> => {
  if (!campaign) {
    return null
  }

  const {
    data: { data },
  } = await AnalyticsApi.v1Private.analyticsFetchSentimentAnalysisList({
    campaign,
    fromDate: start,
    isLost,
    languages,
    mentionFormat,
    selectedKeywords,
    selectedSources,
    toDate: end,
  })

  return data || null
}

export const useGetSentimentAnalysisByCampaignQuery = (
  params: UseGetSentimentAnalysisByCampaignQueryParams
) => {
  return useQuery({
    enabled: !!params.campaign,
    queryFn: () =>
      getSentimentAnalysisByCampaign(
        params.start,
        params.end,
        params.campaign,
        params.selectedSources,
        params.selectedKeywords,
        params.selectedLanguages,
        params.selectedMentionFormats,
        params.isLost
      ),
    queryKey: [
      QueryKey.GET_SENTIMENT_ANALYSIS_BY_CAMPAIGN,
      params.start,
      params.end,
      params.campaign,
      params.selectedSources?.toString(),
      params.selectedKeywords?.toString(),
      params.selectedLanguages?.toString(),
      params.selectedMentionFormats?.toString(),
      params.isLost,
    ],
  })
}
