import { FC, ReactNode } from 'react'
import classNames from 'classnames'
import { QuestionMarkTooltip } from 'src/components/QuestionMarkTooltip'
import styles from './sectionTabs.module.scss'
import { SectionTab } from './interfaces/sectionTab.interface'

export interface SectionTabsProps {
  activeTab: string
  endItem?: ReactNode
  isLoading?: boolean
  onTabClick: (tab: SectionTab) => void
  tabs: SectionTab[]
}

export const SectionTabs: FC<SectionTabsProps> = ({
  isLoading,
  tabs,
  activeTab,
  onTabClick,
  endItem,
}) => {
  return (
    <div className={styles.sectionTabs}>
      <div className={styles.sectionTabsWrapper}>
        {tabs.map((tab) => {
          return (
            <div
              className={classNames(styles.tab, {
                [styles.active]: tab.value === activeTab,
              })}
              key={tab.value}
              onClick={() => onTabClick(tab)}
            >
              <span>{tab.label}</span>

              {!!tab.tooltip && (
                <span className={styles.tooltip}>
                  <QuestionMarkTooltip overlay={tab.tooltip} />
                </span>
              )}
            </div>
          )
        })}
      </div>

      {isLoading ? (
        <div className={styles.loaderRing}>
          <div />
          <div />
          <div />
          <div />
        </div>
      ) : (
        endItem
      )}
    </div>
  )
}
