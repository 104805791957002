import { FC } from 'react'
import styles from './campaignInput.module.scss'

interface CampaignInputProps {
  onChange: (value: string) => void
  placeholder: string
  themeName: string
}

export const CampaignInput: FC<CampaignInputProps> = ({
  placeholder,
  onChange,
  themeName,
}) => {
  return (
    <input
      className={styles.input}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      type="text"
      value={themeName}
    />
  )
}
