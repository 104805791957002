import { ChangeEvent, FC, useRef } from 'react'
import useOutsideClick from 'src/hooks/useOutsideClick'
import { useDisableScroll } from 'src/hooks/useDisableScroll'
import { ReactComponent as ModalClose } from 'src/assets/svg/modal-close.svg'
import { ReactComponent as Background } from 'src/assets/svg/addKeywordbackground.svg'
import { ReactComponent as FileDownload } from 'src/assets/svg/blue-file-download.svg'
import styles from './uploadKeyword.module.scss'

interface SmallRoundTagProps {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const UploadKeywordModal: FC<SmallRoundTagProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useOutsideClick({
    onOutsideClick: () => setIsOpen(false),
    ref,
  })

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      console.log('File uploaded:', file.name)
    }
  }

  useDisableScroll(isOpen || false)

  return (
    <div className={styles.modalWrapper}>
      <div className={styles.content} ref={ref}>
        <div className={styles.topSection}>
          <div className={styles.newBtn}>NEW</div>

          <img alt="logo" className={styles.logo} src="/logo.png" />

          <div className="cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
            <ModalClose />
          </div>
        </div>

        <div className={styles.textSection}>
          <h2 className={styles.title}>
            Introducing the new way to upload keywords quickly
          </h2>

          <h3 className={styles.subtitle}>
            For quicker access, download the sample file and make any necessary
            changes.
          </h3>
        </div>

        <div className={styles.blueBackground}>
          <Background className={styles.backgroundSvg} />
        </div>

        <div className={styles.btnsWrapper}>
          <a
            className={styles.sampleBtn}
            download="SampleFile.xlsx"
            href="./sampleFile.xlsx"
          >
            <FileDownload />
            <span className={styles.sampleText}>Download Sample File</span>
          </a>

          <div className={styles.rightBtns}>
            <button className={styles.cancel} onClick={() => setIsOpen(false)}>
              Cancel
            </button>

            <label className={styles.uploadBtn}>
              Upload Keywords
              <input
                onChange={handleFileChange}
                style={{ display: 'none' }}
                type="file"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}
