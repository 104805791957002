import React, { FC } from 'react'
import en from 'src/constants/en'
import Button from 'src/components/Button'
import { CampaignPayloadKeyword } from 'src/services/__generated__/api'
import { Card } from 'src/components/Card'
import KeywordFormCard from 'src/pages/AddNewCampaign/KeywordFormCard'
import styles from './addNewKeywordModalContent.module.scss'

export interface AddNewKeywordModalContentProps {
  addNewKeyword: () => void
  addedKeywordsArr?: string[]
  closeAndClear: () => void
  currentKeywordInput: string
  handleChangeKeywordSentimentsValue: (
    keywordData: CampaignPayloadKeyword
  ) => void
  handleDeleteKeyword?: (keyword: string) => void
  handleFinalizeKeywordForm: () => void
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void
  setCurrentKeywordInput: (value: string) => void
}

export const AddNewKeywordModalContent: FC<AddNewKeywordModalContentProps> = ({
  addedKeywordsArr,
  setCurrentKeywordInput,
  handleKeyDown,
  currentKeywordInput,
  addNewKeyword,
  handleChangeKeywordSentimentsValue,
  closeAndClear,
  handleFinalizeKeywordForm,
  handleDeleteKeyword,
}) => {
  return (
    <Card className={styles.addNewKeywordModalContent}>
      <div className={styles.modalHeader}>{en.addNewKeyword}</div>

      <div className={styles.modalBody}>
        <div className={styles.title}>{en.keyword}</div>

        <div className={styles.inputWrapper}>
          <input
            className={styles.input}
            onChange={(e) => setCurrentKeywordInput(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder={en.enterAKeyword}
            type="text"
            value={currentKeywordInput}
          />

          <Button onClick={addNewKeyword} type="button">
            {en.add}
          </Button>
        </div>

        {!!addedKeywordsArr?.length && (
          <div className={styles.list}>
            {addedKeywordsArr.reverse().map((item) => (
              <KeywordFormCard
                bookmark={false}
                handleDelete={handleDeleteKeyword}
                key={item}
                keyword={item}
                onChangeValue={(keywordData) =>
                  handleChangeKeywordSentimentsValue(keywordData)
                }
                saveAsTemplate={false}
              />
            ))}
          </div>
        )}
      </div>

      <div className={styles.modalFooter}>
        <button
          className={styles.cancel}
          onClick={() => closeAndClear()}
          type="button"
        >
          {en.cancel}
        </button>

        <Button onClick={() => handleFinalizeKeywordForm()} type="button">
          {en.next}
        </Button>
      </div>
    </Card>
  )
}
