import { ChangeEvent, FC, useCallback, useState } from 'react'
import { debounce } from 'lodash'
import SearchIcon from 'src/assets/svg/searchIconGray.svg'
import en from 'src/constants/en'
import styles from './searchInput.module.scss'

interface SearchInputProps {
  debouncedChange?: (input: string) => void
  onChange?: (input: string) => void
}

export const SearchInput: FC<SearchInputProps> = ({
  debouncedChange,
  onChange,
}) => {
  const [searchValue, setSearchValue] = useState('')

  const updateURL = useCallback(
    debounce((search: string) => {
      debouncedChange?.(search)
    }, 250),
    []
  )

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const vaule = event.target.value

    onChange?.(vaule)

    setSearchValue(vaule)

    updateURL(vaule)
  }

  return (
    <div className={styles.searchInput}>
      <img className={styles.searchIcon} src={SearchIcon} />

      <input
        aria-label="Search"
        className={styles.searchInputField}
        id="searchform1"
        onChange={onSearchChange}
        placeholder={en.search}
        type="search"
        value={searchValue}
      />
    </div>
  )
}
