import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Autocomplete } from '@react-google-maps/api'

import {
  faInfoCircle,
  faMinusCircle,
  faBullseye,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Switch } from 'src/components/Switch'
import {
  BOOKING_SUGGESTIONS,
  FB_SUGGESTIONS,
  GOOGLE_SUGGESTIONS,
  HACKERNEWS_SUGGESTIONS,
  IG_SUGGESTIONS,
  NEWS_SUGGESTIONS,
  REDDIT_SUGGESTIONS,
  TIKTOK_SUGGESTIONS,
  TRIPADVISOR_SUGGESTIONS,
  TWITTER_SUGGESTIONS,
  WEBSITES_SUGGESTIONS,
  YANDEX_SUGGESTIONS,
  YOUTUBE_SUGGESTIONS,
} from 'src/constants/campaigns'
import en from 'src/constants/en'

import PlacesMap from 'src/components/GoogleMapsPlaceIDFinder'

import styles from './targetPopup.module.scss'
import DynamicInput from './DynamicInput'

interface TargetPupupProps {
  excludeValues: any
  isOpen: boolean
  onClose: () => void
  setExcludeValues: Dispatch<SetStateAction<{ [x: string]: string[] }>>
  setTargetValues: Dispatch<SetStateAction<{ [x: string]: string[] }>>
  targetName: string
  targetValues: any
}

const TargetPopup: FC<TargetPupupProps> = ({
  isOpen,
  onClose,
  targetValues,
  targetName,
  excludeValues,
  setExcludeValues,
  setTargetValues,
}) => {
  const [switchStates, setSwitchStates] = useState({
    excludes: false,
    targets: true,
  })
  const popupRef = useRef<HTMLDivElement>(null)
  const nestedRef = useRef<Autocomplete | null>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const node = event.target as HTMLElement
      if (
        popupRef.current &&
        !popupRef.current.contains(node) &&
        !node.closest('.pac-container')
      ) {
        onClose()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [onClose])

  const handleSwitchToggle = (name: 'excludes' | 'targets') => {
    setSwitchStates((prev) => ({
      ...prev,
      [name]: !prev[name],
      [name === 'excludes' ? 'targets' : 'excludes']: false,
    }))

    setTargetValues((prev) => {
      return { ...prev, [targetName]: [] }
    })
    setExcludeValues((prev) => {
      return { ...prev, [targetName]: [] }
    })
  }

  const getSuggestions = (targetName: string) => {
    switch (targetName) {
      case 'Facebook':
        return FB_SUGGESTIONS
      case 'Instagram':
        return IG_SUGGESTIONS
      case 'Twitter':
        return TWITTER_SUGGESTIONS
      case 'Reddit':
        return REDDIT_SUGGESTIONS
      case 'Youtube':
        return YOUTUBE_SUGGESTIONS
      case 'News & Blogs':
        return NEWS_SUGGESTIONS
      case 'Google':
        return GOOGLE_SUGGESTIONS
      case 'Hackernews':
        return HACKERNEWS_SUGGESTIONS
      case 'Websites':
        return WEBSITES_SUGGESTIONS
      case 'Google Reviews':
        return GOOGLE_SUGGESTIONS
      case 'Tripadvisor':
        return TRIPADVISOR_SUGGESTIONS
      case 'TikTok':
        return TIKTOK_SUGGESTIONS
      case 'Booking':
        return BOOKING_SUGGESTIONS
      case 'Yandex':
        return YANDEX_SUGGESTIONS
      default:
        return []
    }
  }

  const suggestions = getSuggestions(targetName)

  if (!isOpen) return null

  return (
    <div className={styles.popupContainer} ref={popupRef}>
      {targetName !== 'Google Reviews' && targetName !== 'Tripadvisor' ? (
        <>
          <div>
            <span className={styles.popupTitle}>Target or Exclude Pages</span>
            <p className={styles.popupDescription}>
              {en.suggested}: <span>{suggestions.join(', ')}</span>
            </p>
          </div>
          <div className={styles.separatorLine} />
        </>
      ) : null}

      <div>
        <div className={styles.inputLabelContainer}>
          <span className={styles.inputLabel}>
            <FontAwesomeIcon
              className={styles.sidebarMenuItemIcon}
              icon={faBullseye}
            />{' '}
            {targetName === 'Google Reviews'
              ? 'Enter Place ID to Target'
              : targetName === 'Tripadvisor'
                ? 'Search For Your Place to Target'
                : 'Enter Links to Target'}
          </span>
          {targetName !== 'Google Reviews' && targetName !== 'Tripadvisor' && (
            <Switch
              isChecked={switchStates.targets}
              onChange={() => handleSwitchToggle('targets')}
            />
          )}
        </div>
        <div style={{ display: switchStates.targets ? 'block' : 'none' }}>
          <DynamicInput
            name={targetName}
            nestedRef={nestedRef}
            setValues={setTargetValues}
            values={targetValues[targetName]}
          />
        </div>
      </div>
      <div className={styles.separatorLine} />

      {targetName === 'Google Reviews' ? (
        <PlacesMap
          placeId={
            targetValues[targetName][targetValues[targetName].length - 1]
          }
        />
      ) : targetName !== 'Tripadvisor' ? (
        <>
          <div className={styles.inputLabelContainer}>
            <span className={styles.inputLabel}>
              {' '}
              <FontAwesomeIcon
                className={styles.sidebarMenuItemIcon}
                icon={faMinusCircle}
              />{' '}
              Enter Links to Exclude
            </span>
            <Switch
              isChecked={switchStates.excludes}
              onChange={() => handleSwitchToggle('excludes')}
            />
          </div>
          <div style={{ display: switchStates.excludes ? 'block' : 'none' }}>
            <DynamicInput
              name={targetName}
              setValues={setExcludeValues}
              values={excludeValues[targetName]}
            />
          </div>
        </>
      ) : null}
      {targetName === 'Tripadvisor' || targetName === 'Google Reviews' ? (
        <div className={styles.infoBox}>
          <FontAwesomeIcon className={styles.infoIcon} icon={faInfoCircle} />{' '}
          {targetName === 'Tripadvisor' ? (
            'Search and select your venue from the options'
          ) : (
            <div>
              Search for your venue on map above and copy your Place ID.
              Suggested format:
              <span className={styles.formatString}>
                {' '}
                ChIJu7yy4DlzREARbpVJRWLU1y0
              </span>
            </div>
          )}
        </div>
      ) : null}
    </div>
  )
}

export default TargetPopup
