import { FC, useState, UIEvent, useMemo } from 'react'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { CheckBox } from 'src/components/CheckBox'
import en from 'src/constants/en'
import { ReactComponent as ExternalLinkSvg } from 'src/assets/svg/external-link.svg'
import { ReactComponent as CalendarSvg } from 'src/assets/svg/calendar.svg'
import { useGetInformationTooltipsQuery } from 'src/reactQuery/useGetInformationTooltips'
import {
  AdminFetchThreadsResponseDataItem,
  AdminFetchThreadsSentimentType,
} from 'src/services/__generated__/api'
import { SectionTitle } from 'src/components/SectionTitle'
import { TARGETS } from 'src/constants/campaigns'
import { getThreadExpandedText } from 'src/utils/getThreadExpandedText'
import { getDate, getEndOfToday } from 'src/utils/date'
import { Type as LiveNotificationItemType } from 'src/components/LiveNotificationItem/enums/type.enum'
import { Card } from 'src/components/Card'
import { SOCIAL_ICONS } from 'src/constants/socialIcons'
import { capitalizeFirstLetter } from 'src/utils/capitalizeFirstLetter'
import HighlightedText from 'src/components/HighlightedText'
import { AdminThreadsFilter } from './AdminThreadsFilter'
import { AdminThreadsFilterChangeParams } from './interfaces/adminThreadsFilterChangeParams.interface'
import classes from './adminThreadsSection.module.scss'
import { AdminThreadsTableItem } from './interfaces/adminThreadsTableItem.interface'

const SENTIMENT_TYPE_TO_DISPLAY_NAME_MAP = {
  ALL: en.all,
  [AdminFetchThreadsSentimentType.NEG]: en.negative,
  [AdminFetchThreadsSentimentType.NEU]: en.neutral,
  [AdminFetchThreadsSentimentType.POS]: en.positive,
}

interface AdminThreadsSectionProps {
  handleThreadsScroll: (event: UIEvent) => void
  isLoading?: boolean
  onFilterChange: (filters: AdminThreadsFilterChangeParams) => void
  threads?: AdminFetchThreadsResponseDataItem[]
}

export const AdminThreadsSection: FC<AdminThreadsSectionProps> = ({
  threads,
  handleThreadsScroll,
  isLoading,
  onFilterChange,
}) => {
  const { data: informationTooltips } = useGetInformationTooltipsQuery()

  const [selectedSentiment, setSelectedSentiment] =
    useState<AdminFetchThreadsSentimentType>()

  const [selectedSource, setSelectedSource] = useState<string>()

  const [selectedLastDate, setSelectedLastDate] =
    useState<string>(getEndOfToday())

  const changeSelectedSentiment = (
    sentiment?: AdminFetchThreadsSentimentType
  ): void => {
    setSelectedSentiment(sentiment)

    onFilterChange({
      lastDate: selectedLastDate,
      sentiment,
      source: selectedSource,
    })
  }

  const changeSelectedSource = (source?: string): void => {
    setSelectedSource(source)

    onFilterChange({
      lastDate: selectedLastDate,
      sentiment: selectedSentiment,
      source,
    })
  }

  const changeSelectedLastDate = (lastDate: string): void => {
    setSelectedLastDate(lastDate)

    onFilterChange({
      lastDate,
      sentiment: selectedSentiment,
      source: selectedSource,
    })
  }

  const getThreadTypeFromItemOutput = (
    output?: string
  ): LiveNotificationItemType => {
    return (
      {
        [AdminFetchThreadsSentimentType.POS]: LiveNotificationItemType.POSITIVE,
        [AdminFetchThreadsSentimentType.NEG]: LiveNotificationItemType.NEGATIVE,
      }[output ?? ''] ?? LiveNotificationItemType.OTHER
    )
  }

  const getSentimentsFromItem = (
    item: AdminFetchThreadsResponseDataItem
  ): string[] | undefined => {
    return {
      [AdminFetchThreadsSentimentType.POS]: item.positive_keywords,
      [AdminFetchThreadsSentimentType.NEG]: item.negative_keywords,
    }[item.output ?? '']
  }

  const tableData = useMemo<AdminThreadsTableItem[]>(() => {
    if (!threads) {
      return []
    }

    return threads.map((thread) => {
      const { keyword, link, source, output, text } = thread

      const sentiments = getSentimentsFromItem(thread) || []

      return {
        campaignName: thread.campaign_name,
        keyword,
        output,
        publishedAt: thread.published_at
          ? getDate(thread.published_at) ?? ''
          : '',
        sentiments,
        sourceWithLink: { link, source },
        text: getThreadExpandedText({ keyword, sentiments, text }),
      }
    })
  }, [threads])

  const columnHelper = createColumnHelper<AdminThreadsTableItem>()

  const columns = useMemo(
    () => [
      columnHelper.accessor('campaignName', {
        cell: (info) => info.getValue(),
        header: () => 'Campaign',
        id: 'campaignName',
      }),
      columnHelper.accessor('keyword', {
        cell: (info) => info.getValue(),
        header: () => 'Keyword',
        id: 'keyword',
      }),
      columnHelper.accessor('output', {
        cell: (info) => info.getValue(),
        header: () => 'Output',
        id: 'output',
      }),
      columnHelper.accessor('sourceWithLink', {
        cell: (info) => {
          const data = info.getValue()

          const { link, source } = data
          return (
            <div className={classes.sourceWrapper}>
              <div className={classes.icon}>
                {source ? SOCIAL_ICONS[source.toLowerCase()] : null}
              </div>

              {capitalizeFirstLetter(source ?? '')}

              <a
                className={classes.externalIconWrapper}
                href={link}
                rel="noreferrer"
                target="_blank"
              >
                <ExternalLinkSvg className={classes.externalIcon} />
              </a>
            </div>
          )
        },
        header: () => 'Output',
        id: 'sourceWithLink',
      }),
      columnHelper.accessor('keyword', {
        cell: (info) => {
          const { keyword, output, sentiments, text } = info.row.original

          return (
            <HighlightedText
              keyword={keyword}
              sentiments={sentiments ?? []}
              textToHighlight={text}
              type={getThreadTypeFromItemOutput(output)}
            />
          )
        },
        header: () => 'Text',
        id: 'text',
      }),
      columnHelper.accessor('publishedAt', {
        cell: (info) => info.getValue(),
        header: () => 'Published at',
        id: 'publishedAt',
      }),
    ],
    []
  )

  const { getHeaderGroups, getRowModel } = useReactTable({
    columns,
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <Card className={classes.horizontalContainer}>
      <SectionTitle
        isLoading={isLoading}
        title={en.threads}
        tooltipContent={informationTooltips?.adminThreads}
      />

      <div className={classes.contentWrapper}>
        <div className={classes.navMenu}>
          <AdminThreadsFilter name="Sentiment">
            <div className={classes.submenuInner}>
              {[
                undefined,
                AdminFetchThreadsSentimentType.POS,
                AdminFetchThreadsSentimentType.NEG,
                AdminFetchThreadsSentimentType.NEU,
              ].map((sentiment) => (
                <div
                  className={classes.submenuItem}
                  key={`${sentiment}`}
                  onClick={() => changeSelectedSentiment(sentiment)}
                >
                  <CheckBox isChecked={selectedSentiment === sentiment} />

                  <span className={classes.submenuLabel}>
                    {SENTIMENT_TYPE_TO_DISPLAY_NAME_MAP[sentiment ?? 'ALL']}
                  </span>
                </div>
              ))}
            </div>
          </AdminThreadsFilter>

          <AdminThreadsFilter name="Source">
            <div className={classes.submenuInner}>
              {[undefined, ...TARGETS].map((source) => (
                <div
                  className={classes.submenuItem}
                  key={`${source}`}
                  onClick={() => changeSelectedSource(source)}
                >
                  <CheckBox isChecked={selectedSource === source} />

                  <span className={classes.submenuLabel}>
                    {source === undefined ? 'All' : source}
                  </span>
                </div>
              ))}
            </div>
          </AdminThreadsFilter>

          <div className={classes.datePickerWrapper}>
            <div className={classes.datePickerLabel}>Last Date: </div>

            <CalendarSvg className={classes.datePickerIcon} />

            <DatePicker
              className={classes.datePicker}
              maxDate={new Date(getEndOfToday())}
              onChange={(date) =>
                changeSelectedLastDate(
                  moment(date?.toISOString()).toISOString(true)
                )
              }
              selected={new Date(selectedLastDate)}
            />
          </div>
        </div>

        <div
          className={classes.scrollbarContainer}
          onScroll={handleThreadsScroll}
        >
          {tableData.length ? (
            <table className={classes.table}>
              <thead>
                {getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th key={header.id}>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody>
                {getRowModel().rows.map((row) => {
                  return (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          ) : (
            <div className={classes.info}>
              <div>{isLoading ? en.loading : en.noPostsAvailable}</div>
            </div>
          )}
        </div>
      </div>
    </Card>
  )
}
