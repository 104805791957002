export const adjustZoomToAPI = (zoom: number): string => {
  if (zoom <= 2) {
    return '0'
  }

  if (zoom <= 4) {
    return '1'
  }

  if (zoom <= 6) {
    return '2'
  }

  return '2'
}
