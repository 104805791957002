import { FC, useEffect, useState, UIEvent } from 'react'
import { toast } from 'react-toastify'
import en from 'src/constants/en'
import { AdminThreadsFilterChangeParams } from 'src/charts/AdminThreadsSection/interfaces/adminThreadsFilterChangeParams.interface'
import {
  AdminFetchThreadsResponseDataItem,
  FetchThreadsCSVVectorType,
} from 'src/services/__generated__/api'
import { useGetAdminThreadsQuery } from 'src/reactQuery/useGetAdminThreadsQuery'
import { getSessionIdFromLocalStorage } from 'src/localStorage/sessionId/getSessionIdFromLocalStorage/getSessionIdFromLocalStorage'
import { useCustomLink } from 'src/hooks/useCustomLink'
import { HOME_ROUTE } from 'src/constants/routes'
import { ADMIN_THREADS_CONTAINER_HEIGHT } from 'src/constants/vars'
import { AdminThreadsSection } from 'src/charts/AdminThreadsSection'
import { getEndOfToday } from 'src/utils/date'
import { BackNavigationButton } from 'src/components/BackNavigationButton'
import { AnalyticsApi } from 'src/services/analyticsApi'
import Button from 'src/components/Button'
import { useDownloadFileByUrl } from 'src/hooks/useDownloadFIleByUrl'
import classes from './threads.module.scss'

const ThreadsPage: FC = () => {
  const backURL = useCustomLink(HOME_ROUTE)

  const sessionId = getSessionIdFromLocalStorage()

  const { isLoading: isCsvLoading, handleFileDownload } = useDownloadFileByUrl()

  const [filters, setFilters] = useState<AdminThreadsFilterChangeParams>({
    lastDate: getEndOfToday(),
    sentiment: undefined,
    source: undefined,
  })

  const [threads, setThreads] = useState<AdminFetchThreadsResponseDataItem[]>()

  const [isScrolledUp, setIsScrolledUp] = useState<boolean>(false)

  const [isScrolledDown, setIsScrolledDown] = useState<boolean>(false)

  const { data, refetch, isLoading, isFetching } = useGetAdminThreadsQuery({
    lastDate: isScrolledDown
      ? threads?.[0]?.published_at?.toString() ?? filters.lastDate
      : filters.lastDate,
    limit: isScrolledDown
      ? (threads?.length || 20) + 10
      : threads?.length || 20,
    sentiment: filters.sentiment,
    source: filters.source?.toLowerCase(),
  })

  const handleThreadsScroll = (event: UIEvent) => {
    if (!event.currentTarget) {
      return
    }

    const { scrollTop, scrollHeight } = event.currentTarget

    const isScrolledCloseToTop = scrollTop < 40
    const isScrolledCloseToBottom =
      scrollTop > scrollHeight - ADMIN_THREADS_CONTAINER_HEIGHT - 40

    if (isScrolledCloseToTop !== isScrolledUp) {
      setIsScrolledUp(isScrolledCloseToTop)
    }

    if (isScrolledCloseToBottom !== isScrolledDown) {
      setIsScrolledDown(isScrolledCloseToBottom)
    }
  }

  const handleDowloadCallback = async () => {
    const response = await AnalyticsApi.v1Private.analyticsFetchThreadsCsvList({
      lastDate: isScrolledDown
        ? threads?.[0]?.published_at?.toString() ?? filters.lastDate
        : filters.lastDate,
      limit: isScrolledDown
        ? (threads?.length || 20) + 10
        : threads?.length || 20,
      ...(filters.sentiment ? [{ sentiment: filters.sentiment }] : []),
      source: filters.source?.toLowerCase(),
      vector: FetchThreadsCSVVectorType.Desc,
    })

    const url = window.URL.createObjectURL(await response.blob())

    const contentDisposition = response.headers.get('content-disposition')

    let fileName = 'downloaded.csv'

    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/)

      if (fileNameMatch?.length === 2) {
        const [, ...rest] = fileNameMatch[1].split('.')

        fileName = `${rest.join('.')}.csv`
      }
    }

    return { fileName, url }
  }

  const HandleCSVDownload = async () => {
    try {
      handleFileDownload(handleDowloadCallback)
    } catch (error) {
      toast.error(en.ERROR_CSV)
    }
  }

  useEffect(() => {
    if (data) {
      setThreads(data)
    }
  }, [data])

  useEffect(() => {
    refetch()
  }, [sessionId, filters])

  useEffect(() => {
    if ((isScrolledUp || isScrolledDown) && !isLoading && !!data) {
      refetch()
    }
  }, [isScrolledUp, isScrolledDown])

  return (
    <div className={classes.threadsPage}>
      <BackNavigationButton title={en.threads} url={backURL} />

      <AdminThreadsSection
        handleThreadsScroll={handleThreadsScroll}
        isLoading={isFetching}
        onFilterChange={(filters) => {
          setThreads(undefined)

          setFilters(filters)
        }}
        threads={threads}
      />

      <div>
        <Button isDisabled={isCsvLoading} onClick={HandleCSVDownload}>
          {en.downoadCSV}
        </Button>
      </div>
    </div>
  )
}

export default ThreadsPage
