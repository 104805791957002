import { useRef, useEffect, ReactElement } from 'react'
import { EChartOption, ECharts, init } from 'echarts'
import { ClickEventValue } from './interfaces/clickEventValue.interface'
import { MouseOverEventValue } from './interfaces/mouseOverEventValue.interface'
import { GeoRoamEventValue } from './interfaces/clickEventValue.interface copy'
import styles from './eChart.module.scss'

interface EChartProps<T> {
  height?: number
  onChart?: (chart: ECharts) => void
  onClick?: (event: ClickEventValue) => void
  onDblClick?: (event: ClickEventValue) => void
  onGeoRoam?: (event: GeoRoamEventValue) => void
  onMouseOut?: () => void
  onMouseOver?: (event: MouseOverEventValue<T>) => void
  options: EChartOption
  width?: number
}

export const EChart = <T,>({
  options,
  width,
  height,
  onClick,
  onMouseOver,
  onGeoRoam,
  onDblClick,
  onMouseOut,
  onChart,
}: EChartProps<T>): ReactElement => {
  const myChart = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    let chart: ECharts | null = null

    if (myChart.current && !chart) {
      chart = init(myChart.current)

      if (options) {
        chart.setOption(options)
      }

      if (width) {
        chart.resize({
          width,
        })
      }

      if (height) {
        chart.resize({
          height,
        })
      }

      chart.on('georoam', { dataType: 'node' }, onGeoRoam ?? (() => {}))

      chart.on('dblclick', { dataType: 'node' }, onDblClick ?? (() => {}))

      chart.on('click', { dataType: 'node' }, onClick ?? (() => {}))

      chart.on('mouseover', {}, onMouseOver ?? (() => {}))

      chart.on('mouseout', {}, onMouseOut ?? (() => {}))

      if (onChart && chart) {
        onChart(chart)
      }
    }

    return () => {
      chart?.dispose()
    }
  }, [options, width, height])

  return <div className={styles.eChart} ref={myChart} />
}
