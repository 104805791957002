import { FC, useEffect, useMemo, useState } from 'react'
import { EChartOption } from 'echarts'
import en from 'src/constants/en'
import { COLORS } from 'src/constants/colors'
import { ReactComponent as LoudspeakerSvg } from 'src/assets/svg/loudspeaker.svg'
import { numberWithCommas } from 'src/utils/numberFormat'
import { useGetInformationTooltipsQuery } from 'src/reactQuery/useGetInformationTooltips'
import { SectionTitle } from 'src/components/SectionTitle'
import styles from './positivityChartSection.module.scss'
import { ChartInfo } from './interfaces/chartInfo.interface'
import { EChart } from '../EChart'

interface PositivityChartProps {
  isLoading?: boolean
  mentions: number
  negativePerc: number
  neutralPerc: number
  positivePerc: number
}

export const PositivityChartSection: FC<PositivityChartProps> = ({
  isLoading,
  negativePerc = 0,
  neutralPerc = 0,
  positivePerc = 0,
  mentions = 0,
}) => {
  const { data: informationTooltips } = useGetInformationTooltipsQuery()

  const [chartInfo, setChartInfo] = useState<ChartInfo>({
    name: '',
    value: 0,
  })

  const [mentionsInfo, setMentionsInfo] = useState<ChartInfo>({
    name: en.totalMentions,
    value: 0,
  })

  const [isHoveringChart, setIsHoveringChart] = useState<boolean>(false)

  const total = negativePerc + neutralPerc + positivePerc

  const options: EChartOption<EChartOption.SeriesPie> | undefined =
    useMemo(() => {
      if (!negativePerc && !neutralPerc && !positivePerc) {
        return undefined
      }

      const data: EChartOption.SeriesPie.DataObject[] = [
        {
          name: en.positive,
          value: positivePerc,
        },
        {
          name: en.neutral,
          value: neutralPerc,
        },
        {
          name: en.negative,
          value: negativePerc,
        },
        {
          itemStyle: { opacity: 0 },
          name: '',
          value: total,
        },
      ]

      const options: EChartOption<EChartOption.SeriesPie> = {
        color: [COLORS.hpttSp1, COLORS.hpttSp1, COLORS.hpttSp1],
        series: [
          {
            animationDuration: 0,
            avoidLabelOverlap: true,
            data,
            emphasis: {
              // @ts-ignore-next-line
              scale: false,
            },
            height: 274,
            itemStyle: {
              borderColor: COLORS.periwinkle,
              borderWidth: 7,
              color: ({ dataIndex }: { dataIndex: number }) => {
                if (dataIndex === 3) return undefined

                return !isHoveringChart && dataIndex === 0
                  ? COLORS.white
                  : COLORS.hpttSp1
              },
              emphasis: {
                borderColor: COLORS.periwinkle,
                color: COLORS.white,
                show: true,
              },
            },
            label: {
              show: false,
            },
            labelLayout: {
              verticalAlign: 'bottom',
            },
            labelLine: {
              show: false,
            },
            pointer: { show: false },
            radius: ['60%', '100%'],
            startAngle: 180,
            type: 'pie',
            with: 274,
          },
        ],
      }

      return options
    }, [negativePerc, neutralPerc, positivePerc, isHoveringChart])

  useEffect(() => {
    if (positivePerc) {
      setChartInfo({
        name: en.positive,
        value: positivePerc,
      })
    }

    if (mentions) {
      setMentionsInfo({
        name: en.totalMentions,
        value: mentions,
      })
    }
  }, [positivePerc, mentions])

  const getMentionInfoTitleFromChartName = (name: string) => {
    const nameToTitleMap = {
      [en.positive]: en.positiveMentions,
      [en.neutral]: en.neutralMentions,
      [en.negative]: en.negativeMentions,
    }

    return nameToTitleMap[name] ?? en.totalMentions
  }

  return (
    <div className={styles.positivityChartSection}>
      <SectionTitle
        isLoading={isLoading}
        title={en.positivityChart}
        tooltipContent={informationTooltips?.positivityChart}
      />

      <div className={styles.chart}>
        {options ? (
          <EChart
            onMouseOut={() => {
              setIsHoveringChart(false)

              setChartInfo({
                name: en.positive,
                value: positivePerc,
              })

              setMentionsInfo({
                name: en.totalMentions,
                value: mentions,
              })
            }}
            onMouseOver={(event) => {
              if (!isHoveringChart) {
                setIsHoveringChart(true)
              }

              if (event?.name) {
                const name = event.name as string
                const value = event.value as number

                if (chartInfo.name !== name) {
                  setChartInfo({
                    name,
                    value,
                  })
                }

                if (
                  mentionsInfo.name !== getMentionInfoTitleFromChartName(name)
                ) {
                  setMentionsInfo({
                    name: getMentionInfoTitleFromChartName(name),
                    value: Math.round(mentions * (value / 100)),
                  })
                }
              }
            }}
            options={options}
          />
        ) : null}

        <div className={styles.chartInfo}>
          <div className={styles.chartTitle}>{chartInfo.value}%</div>

          <div className={styles.chartSubTitle}>{chartInfo.name}</div>
        </div>
      </div>

      <div className={styles.textWrapper}>
        <div className={styles.textWrapperLabel}>{mentionsInfo.name}</div>

        <div className={styles.text}>
          <span>{numberWithCommas(mentionsInfo.value)}</span>

          <LoudspeakerSvg />
        </div>
      </div>
    </div>
  )
}
