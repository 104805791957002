import variables from 'src/styles/colorVariables.scss'

export const COLORS = {
  alto: variables.alto,
  apricot: variables.arpicot,
  azureRadiance: variables.azureRadiance,
  black: variables.black,
  blizzardBlue: variables.blizzardBlue,
  burningOrange: variables.burningOrange,
  darkBlue: variables.darkBlue,
  darkTitanWhite: variables.darkTitanWhite,
  fog: variables.fog,
  ghostWhite: variables.ghostWhite,
  grey100: variables.grey100,
  grey200: variables.grey200,
  hawkesBlue: variables.hawkesBlue,
  hpttSp1: variables.hpttSp1,
  hpttSp3: variables.hpttSp3,
  hpttSp4: variables.hpttSp4,
  iron: variables.iron,
  malibu: variables.malibu,
  midGray: variables.midGray,
  onahau: variables.onahau,
  periwinkle: variables.periwinkle,
  pinkLace: variables.pinkLace,
  redOrange: variables.redOrange,
  regentGray: variables.regentGray,
  shark: variables.shark,
  tide: variables.tide,
  turbo: variables.turbo,
  white: variables.white,
}
