import { FC, useState, useEffect, useMemo, useRef } from 'react'
import { EChartOption } from 'echarts'
import { getNumberOrZero } from 'src/utils/getNumberOrZero'
import { COLORS } from 'src/constants/colors'
import en from 'src/constants/en'
import { numberWithCommas } from 'src/utils/numberFormat'
import { useGetInformationTooltipsQuery } from 'src/reactQuery/useGetInformationTooltips'
import { SectionTitle } from 'src/components/SectionTitle'
import Button from 'src/components/Button'
import { getRandomColor } from 'src/utils/getRandomColor'
import { ChartData } from './interfaces/chartData.interface'
import styles from './mostMentionsSection.module.scss'
import { Legend } from './interfaces/legend.interface'
import { MostMentionsLegend } from './MostMentionsLegend'
import { EChart } from '../EChart'

const COLOR_PALETTE = [
  COLORS.malibu,
  COLORS.burningOrange,
  COLORS.periwinkle,
  COLORS.darkBlue,
  COLORS.pinkLace,
  COLORS.redOrange,
  COLORS.hpttSp4,
  COLORS.fog,
  COLORS.iron,
  '#1a1b21',
  '#6363ff',
  '#b8bec6',
  '#ffa07a',
  '#0911c2',
  '#e5ccff',
  '#ffa3a3',
  '#ff99ff',
  '#b1b3d5',
  '#6d9ae9',
  '#ff8a62',
  '#0d0fc0',
  '#d5b6ff',
  '#ff7777',
  '#d572ff',
  '#a1a3c5',
  '#5d8ee9',
  '#ff6b6b',
  '#2b2c38',
  '#414252',
  '#7273ff',
  '#8c93a3',
  '#d3f955',
  '#3acfff',
  '#ff8c6e',
  '#dffab3',
  '#b56eff',
  '#ffa3f0',
  '#b59eff',
  '#5a8de9',
  '#3f56d9',
  '#8a9bcf',
  '#d4e0ff',
  '#9c83ff',
  '#cc8a8a',
  '#8d86e1',
  '#5a5c6e',
  '#9799c3',
  '#7c7eb2',
  '#e6e7ff',
  '#f9b3c1',
  '#4f5272',
  '#ff7f9b',
  '#f0bff7',
  '#ff7ca1',
]

const CHART_SIZE = 148

interface MostMentionsSectionProps {
  activeItemIndex?: number
  chartData?: ChartData
  isLoading?: boolean
  updateActiveItemIndex: (index?: number) => void
}

export const MostMentionsSection: FC<MostMentionsSectionProps> = ({
  activeItemIndex,
  chartData,
  isLoading,
  updateActiveItemIndex,
}) => {
  const { data: informationTooltips } = useGetInformationTooltipsQuery()

  const [chartTitle, setChartTitle] = useState<string>()

  const [legendData, setLegendData] = useState<Legend[] | undefined>()

  const [isExpanded, setIsExpanded] = useState(false)
  const [isResized, setIsResized] = useState(false) // Track if the chart has been resized
  const scrollAreaRef = useRef<HTMLDivElement>(null)

  const allMentionsCount = chartData?.reduce((total, mentionItem) => {
    return total + getNumberOrZero(mentionItem.mentions)
  }, 0)

  useEffect(() => {
    updateActiveItemIndex(undefined)

    if (!chartData) {
      setChartTitle('')
      setLegendData(undefined)
      return
    }

    if (allMentionsCount) {
      setChartTitle(allMentionsCount.toFixed(0))
    }
    const mappedData: Legend[] = chartData?.map((item) => {
      return {
        name: item.name || '',
        value: getNumberOrZero(item.mentions),
      }
    })

    setLegendData(mappedData)
  }, [chartData])

  const mappedColors = COLOR_PALETTE.map((activeColor, index) => {
    return activeItemIndex === index ? activeColor : COLORS.grey200
  })

  const extendedColorPallete = [
    ...COLOR_PALETTE,
    Array(allMentionsCount)
      .fill(null)
      .map(() => getRandomColor()),
  ]

  const chartOptions: EChartOption | undefined = useMemo(() => {
    const options: EChartOption<EChartOption.SeriesPie> = {
      color:
        activeItemIndex === undefined
          ? COLOR_PALETTE.length < Math.max(0, allMentionsCount ?? 0)
            ? extendedColorPallete
            : COLOR_PALETTE
          : mappedColors,
      legend: {
        show: false,
      },
      series: [
        {
          avoidLabelOverlap: true,
          data: legendData,
          emphasis: {
            // @ts-ignore-next-line
            scale: false,
          },
          height: CHART_SIZE,
          itemStyle: {
            borderColor: COLORS.ghostWhite,
            borderWidth: 5,
          },
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          left: 'center',
          radius: ['76%', '100%'],
          type: 'pie',
          width: CHART_SIZE,
        },
      ],
      tooltip: {},
    }

    return options
  }, [legendData, activeItemIndex, isExpanded, isResized])

  const onClickLegendItem = (itemIndex: number) => {
    if (activeItemIndex === itemIndex) {
      updateActiveItemIndex(undefined)
      setChartTitle(allMentionsCount?.toFixed(0))
    } else {
      updateActiveItemIndex(itemIndex)
      setChartTitle(legendData?.[itemIndex]?.value?.toFixed(0))
    }
  }

  useEffect(() => {
    const handleScroll = (e: Event) => {
      const target = e.target as HTMLDivElement
      const threshold = 1
      if (target.scrollTop > threshold && !isResized) {
        setIsExpanded(true)
        setIsResized(true)
      }
    }

    const scrollArea = scrollAreaRef.current
    if (scrollArea) {
      scrollArea.addEventListener('scroll', handleScroll, { passive: true })
    }

    return () => {
      if (scrollArea) {
        scrollArea.removeEventListener('scroll', handleScroll)
      }
    }
  }, [isResized])

  const resetToInitialState = () => {
    setIsExpanded(false)
    setIsResized(false)
    if (scrollAreaRef.current) {
      scrollAreaRef.current.scrollTop = 0
    }
  }

  return (
    <div className={styles.mostMentionsSection}>
      <div className="flex justify-between pb-6">
        <SectionTitle
          isLoading={isLoading}
          title={en.mostMentions}
          tooltipContent={informationTooltips?.mostMentions}
        />

        {isResized && <Button onClick={resetToInitialState}>Show Chart</Button>}
      </div>

      <div
        className={(styles.chartContainer, 'transition-all duration-300')}
        style={{ height: isExpanded ? '0' : '172px' }}
      >
        <div
          className={styles.chartInfo}
          style={{ visibility: !isExpanded ? 'visible' : 'hidden' }}
        >
          <div className={styles.chartTitle}>
            {chartTitle &&
              (chartTitle.includes('%')
                ? chartTitle
                : numberWithCommas(parseFloat(chartTitle)))}
          </div>

          <div className={styles.chartSubTitle}>{en.mentions}</div>
        </div>

        {!isExpanded && !isResized && chartOptions ? (
          <div style={{ height: '172px' }}>
            <EChart
              onMouseOut={() =>
                setChartTitle(
                  activeItemIndex !== undefined
                    ? legendData?.[activeItemIndex]?.value?.toFixed(0)
                    : allMentionsCount?.toFixed(0)
                )
              }
              onMouseOver={(event) =>
                setChartTitle(`${event.percent?.toFixed(0)}%`)
              }
              options={chartOptions}
            />
          </div>
        ) : null}
      </div>

      <MostMentionsLegend
        activeLegendItemIndex={activeItemIndex}
        legendData={legendData}
        onClickLegendItem={onClickLegendItem}
        ref={scrollAreaRef}
        resetToInitialState={resetToInitialState}
      />
    </div>
  )
}
