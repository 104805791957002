import { CampaignType } from 'src/services/__generated__/api'

export const sortCategoryObjects = (data: CampaignType[]): CampaignType[] => {
  data.sort((a, b) => {
    const [key1] = Object.keys(a)
    const [key2] = Object.keys(b)

    return key1 < key2 ? -1 : 1
  })

  return data
}
