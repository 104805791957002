import { FC, useMemo, useState } from 'react'
import { EChartOption } from 'echarts'
import { TotalMentionPercentage } from 'src/components/TotalMentionPercentage'
import { AnalyticsFetchTopSourcesResponseItem } from 'src/services/__generated__/api'
import { ReactComponent as LoudspeakerSvg } from 'src/assets/svg/loudspeaker.svg'
import { getNumberOrZero } from 'src/utils/getNumberOrZero'
import { COLORS } from 'src/constants/colors'
import en from 'src/constants/en'
import { capitalizeFirstLetter } from 'src/utils/capitalizeFirstLetter'
import { numberWithCommas } from 'src/utils/numberFormat'
import styles from './topSourcesChart.module.scss'
import { ChartInfo } from './interfaces/chartInfo.interface'
import { ChartDataItem } from './interfaces/chartData.interface'
import { EChart } from '../../EChart'
import { calculateToTalMentions } from '../utils/calculateToTalMentions'

interface TopSourcesChartProps {
  mention: AnalyticsFetchTopSourcesResponseItem
  overallMentions: number
}

const CHART_SIZE = 164

export const TopSourcesChart: FC<TopSourcesChartProps> = ({
  mention,
  overallMentions,
}) => {
  const chartData: ChartDataItem[] = [
    {
      name: en.positive,
      percentage: getNumberOrZero(mention?.positivePercentage),
      value: getNumberOrZero(mention?.positives),
    },
    {
      name: en.negative,
      percentage: getNumberOrZero(mention?.negativePercentage),
      value: getNumberOrZero(mention?.negatives),
    },
    {
      name: en.neutral,
      percentage: getNumberOrZero(mention?.neutralPercentage),
      value: getNumberOrZero(mention?.neutrals),
    },
  ]

  const maxDataItem = chartData?.reduce((prev, current) => {
    return prev.value > current.value ? prev : current
  })

  const totalMentions = calculateToTalMentions(mention)

  const [chartInfo, setChartInfo] = useState<ChartInfo>({
    percentage: maxDataItem.percentage,
    value: maxDataItem.value,
  })

  const options = useMemo(() => {
    if (!mention) {
      return undefined
    }

    const options: EChartOption<EChartOption.SeriesPie> = {
      color: [COLORS.malibu, COLORS.burningOrange, COLORS.grey200],
      series: [
        {
          data: chartData,
          emphasis: {
            // @ts-ignore-next-line
            scale: false,
          },
          height: CHART_SIZE,
          hoverAnimation: false,
          itemStyle: {
            borderColor: COLORS.white,
            borderWidth: 7,
          },
          label: {
            show: false,
          },
          labelLine: {
            normal: {
              show: false,
            },
          },
          left: 'center',
          radius: [61, 80],
          type: 'pie',
          width: CHART_SIZE,
        },
      ],
    }

    return options
  }, [mention])

  return (
    <div className={styles.card}>
      <div className={styles.chart}>
        <div className={styles.chartInfo}>
          <div className={styles.chartTitle}>
            {chartInfo.percentage.toFixed(0)}%
          </div>

          <div className={styles.chartSubTitle}>
            <span>{numberWithCommas(chartInfo.value)}</span>

            <LoudspeakerSvg className={styles.loudspeakerIcon} />
          </div>
        </div>

        {options && (
          <EChart<ChartDataItem>
            onMouseOut={() => {
              setChartInfo({
                percentage: (maxDataItem.value / totalMentions) * 100,
                value: maxDataItem.value,
              })
            }}
            onMouseOver={(event) => {
              setChartInfo({
                percentage: event?.data.percentage,
                value: event?.data.value,
              })
            }}
            options={options}
          />
        )}
      </div>

      <div className={styles.name}>
        {mention?.name && capitalizeFirstLetter(mention?.name)}
      </div>

      <TotalMentionPercentage
        overallMentions={overallMentions}
        totalMentions={totalMentions}
      />
    </div>
  )
}
