import React, { FC } from 'react'
import { ReactComponent as QuestionMarkCircleSvg } from 'src/assets/svg/question-mark-circle.svg'
import styles from './questionMarkTooltip.module.scss'
import { Tooltip } from '../Tooltip'

interface QuestionMarkTooltipProps {
  overlay: string
  placement?: string
}

export const QuestionMarkTooltip: FC<QuestionMarkTooltipProps> = ({
  overlay,
  placement,
}) => {
  return (
    <Tooltip overlay={overlay} placement={placement}>
      <QuestionMarkCircleSvg className={styles.questionMarkTooltip} />
    </Tooltip>
  )
}
