const LATIN_LETTER_REGEX = /^[a-z]+$/i

export const capitalizeFirstLetter = (string: string): string => {
  const firstChar = string.charAt(0)

  if (LATIN_LETTER_REGEX.test(firstChar)) {
    return firstChar.toLocaleUpperCase() + string.slice(1)
  }

  return string
}
