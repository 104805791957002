import React, { FC, useEffect, useState } from 'react'
import classNames from 'classnames'
import { ReactComponent as FilterSelectArrow } from 'src/assets/svg/filter-select-arrow.svg'
import en from 'src/constants/en'
import styles from './filterSearchDropdownSection.module.scss'
import { FilterSearchDropdown } from '../FilterSearchDropdown'
import { FilterDropdownSelectOption } from '../FilterSearchDropdown/interfaces/filterDropdownSelectOption.interface'

export interface FilterSearchDropdownSectionProps {
  countLabel?: string
  isOpen?: boolean
  onSelect?: (options: FilterDropdownSelectOption[]) => void
  options?: FilterDropdownSelectOption[]
  placeholder?: string
  selectedOptions?: FilterDropdownSelectOption[]
}

export const FilterSearchDropdownSection: FC<
  FilterSearchDropdownSectionProps
> = ({
  countLabel,
  placeholder,
  options,
  onSelect,
  selectedOptions,
  isOpen,
}) => {
  const [searchPattern, setSearchPattern] = useState('')

  const [isOpened, setIsOpened] = useState(!!isOpen)

  const isAllSelected =
    !!selectedOptions?.length && selectedOptions?.length === options?.length

  const filteredOptions = options?.filter(
    (option) =>
      option.label.toLowerCase().includes(searchPattern.toLowerCase()) ||
      option.value.toLowerCase().includes(searchPattern.toLowerCase())
  )

  const handleChange = (val: string) => {
    setIsOpened(true)

    setSearchPattern(val)
  }

  useEffect(() => {
    if (isOpen !== undefined && isOpen !== isOpened) {
      setIsOpened(isOpen)
    }
  }, [isOpen])

  return (
    <div className={styles.filterSearchDropdownSection}>
      <div
        className={styles.inputWrapper}
        onClick={() => setIsOpened((prevState) => !prevState)}
      >
        <input
          onChange={(e) => handleChange(e.target.value)}
          onClick={(e) => e.stopPropagation()}
          placeholder={placeholder}
          type="text"
          value={searchPattern}
        />

        <div className={styles.selectedLabel}>
          <span className={styles.selectedLabelCount}>
            {isAllSelected ? en.all : selectedOptions?.length || 0}
          </span>
          {!!countLabel && (
            <>
              &nbsp;
              <span>{countLabel}</span>
            </>
          )}
          &nbsp;
          <span>{en.selected}</span>
        </div>

        <FilterSelectArrow
          className={classNames(styles.arrow, isOpened && styles.arrowOpen)}
        />
      </div>

      {isOpened && (
        <div className={styles.dropdown}>
          <div className={styles.divider} />

          <FilterSearchDropdown
            isAllOptionHidden={!!searchPattern.length}
            onSelect={onSelect}
            options={filteredOptions}
            selectedOptions={selectedOptions}
          />
        </div>
      )}
    </div>
  )
}
