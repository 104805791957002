import qs from 'qs'
import { CreateLinkParams } from './interfaces/createLink.interface'

export const createLink = (
  pathName: string,
  { params, queryParams }: CreateLinkParams = {}
): string => {
  const stringifiedQueryParams = qs.stringify(queryParams, {
    addQueryPrefix: true,
  })

  let constructedLink = Object.entries(params || {})?.reduce(
    (result, [key, value]) => {
      return result.replace(new RegExp(`:${key}[?]?`), value || '')
    },
    pathName
  )

  // Remove optional params
  constructedLink = constructedLink.replace(/:.+[?]/, '')

  // Remove trailing slash
  constructedLink = constructedLink.replace(/\/$/, '')

  constructedLink = `${constructedLink || '/'}${stringifiedQueryParams}`

  return constructedLink
}
