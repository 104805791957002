import { useQuery } from '@tanstack/react-query'
import { QueryKey } from 'src/reactQuery/enums/queryKey.enum'
import { AnalyticsKeywordsAnalysisResponse } from 'src/services/__generated__/api'
import { AnalyticsApi } from 'src/services/analyticsApi'
import { UseGetKeywordsAnalysisParams } from './interfaces/useGetKeywordsAnalysis.interface'

const getKeywordsAnalysis = async (
  fromDate?: string,
  toDate?: string,
  campaign?: string,
  selectedKeywords?: string,
  filterCount?: string,
  filterChoice?: string,
  sentimentType?: string
): Promise<AnalyticsKeywordsAnalysisResponse | null> => {
  if (!campaign) {
    return null
  }

  const { data } =
    await AnalyticsApi.v1Private.analyticsFetchKeywordsAnalysisList({
      campaign,
      filterChoice,
      filterCount,
      fromDate,
      selectedKeywords,
      sentimentType,
      toDate,
    })

  return data ?? null
}

export const useGetKeywordsAnalysis = (
  params: UseGetKeywordsAnalysisParams
) => {
  return useQuery({
    enabled: !!params.campaign,
    queryFn: () =>
      getKeywordsAnalysis(
        params.fromDate,
        params.toDate,
        params.campaign,
        params.selectedKeywords,
        params.filterCount,
        params.filterChoice,
        params.sentimentType
      ),
    queryKey: [QueryKey.GET_KEYWORDS_ANALYSIS, ...Object.values(params)],
    refetchInterval: Infinity,
  })
}
