import React, { useEffect, useState } from 'react'
import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api'

interface PlacesMapProps {
  placeId: string | null
}

const PlacesMap: React.FC<PlacesMapProps> = ({ placeId }) => {
  const [mapCenter, setMapCenter] = useState<google.maps.LatLngLiteral>({
    lat: -3.745,
    lng: -38.523,
  })

  const [marker, setMarker] = useState<{
    formatted_address: string
    name: string
    position: google.maps.LatLngLiteral
    url: string
  } | null>(null)

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (placeId && window.google) {
      const fetchPlaceDetails = async () => {
        const service = new window.google.maps.places.PlacesService(
          document.createElement('div')
        )

        service.getDetails({ placeId }, (place, status) => {
          if (
            status === window.google.maps.places.PlacesServiceStatus.OK &&
            place
          ) {
            const location = place.geometry?.location
            if (location) {
              const latLng = { lat: location.lat(), lng: location.lng() }
              setMapCenter(latLng)
              setMarker({
                formatted_address: place.formatted_address || '',
                name: place.name || '',
                position: latLng,
                url: `https://www.google.com/maps/place/?q=place_id:${placeId}`,
              })
              setIsOpen(true)
            }
          }
        })
      }

      fetchPlaceDetails()
    }
  }, [placeId])

  return (
    <GoogleMap
      center={mapCenter}
      mapContainerStyle={{
        borderRadius: '18px',
        height: '232px',
        marginBottom: '16px',
        width: '100%',
      }}
      zoom={15}
    >
      {marker && (
        <>
          <Marker
            onClick={() => setIsOpen(true)}
            position={marker.position}
            title={marker.name}
          />
          {isOpen && (
            <InfoWindow
              onCloseClick={() => setIsOpen(false)}
              position={marker.position}
            >
              <div>
                <h4>
                  <strong>{marker.name}</strong>
                </h4>
                <p>{marker.formatted_address}</p>
                <a
                  href={marker.url}
                  rel="noopener noreferrer"
                  style={{ color: 'blue' }}
                  target="_blank"
                >
                  View on Google Maps
                </a>
              </div>
            </InfoWindow>
          )}
        </>
      )}
    </GoogleMap>
  )
}

export default PlacesMap
