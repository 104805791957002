import { useQuery } from '@tanstack/react-query'
import { QueryKey } from 'src/reactQuery/enums/queryKey.enum'
import { AnalyticsApi } from 'src/services/analyticsApi'
import { AnalyticsFetchDailyTrendsResponse } from 'src/services/__generated__/api'
import { UseGetDailyTrendsByCampaignQueryParams } from './interfaces/useGetDailyTrendsByCampaignQuery.interface'

const getDailyTrendsByCampaign = async (
  start?: string,
  end?: string,
  campaign?: string
): Promise<AnalyticsFetchDailyTrendsResponse | null> => {
  if (!campaign) {
    return null
  }

  const { data } = await AnalyticsApi.v1Private.analyticsFetchDailyTrendsList({
    campaign,
    fromDate: start,
    toDate: end,
  })

  return data
}

export const useGetDailyTrendsByCampaignQuery = (
  params: UseGetDailyTrendsByCampaignQueryParams
) => {
  return useQuery({
    enabled: !!params.campaign,
    queryFn: () =>
      getDailyTrendsByCampaign(params.start, params.end, params.campaign),
    queryKey: [QueryKey.GET_DAILY_TRENDS_BY_CAMPAIGN, ...Object.values(params)],
  })
}
