import React, { FC } from 'react'
import en from 'src/constants/en'
import styles from './confirmationModalContent.module.scss'
import { Card } from '../Card'
import Button from '../Button'

export interface ConfirmationModalContentProps {
  cancelLabel?: string
  confirmLabel?: string
  onCancel: () => void
  onConfirm: () => void
  title?: string
}

export const ConfirmationModalContent: FC<ConfirmationModalContentProps> = ({
  title = `${en.areYouSure}?`,
  confirmLabel = en.confirm,
  cancelLabel = en.cancel,
  onConfirm,
  onCancel,
}) => {
  return (
    <Card className={styles.confirmationModalContent}>
      <div className={styles.title}>{title}</div>

      <div className={styles.buttons}>
        <button className={styles.cancel} onClick={() => onCancel()}>
          {cancelLabel}
        </button>

        <Button className={styles.confirm} onClick={() => onConfirm()}>
          {confirmLabel}
        </Button>
      </div>
    </Card>
  )
}
