import React, { FC } from 'react'
import ReactSelect from 'react-select'
import { colourStyles } from './styles'
import { Option } from '../interfaces/option.interface'

export interface SingleSelectProps {
  isSearchable?: boolean
  onChange: (option: Option) => void
  options?: Option[]
  value: Option
}

export const SingleSelect: FC<SingleSelectProps> = ({
  onChange,
  options,
  value,
  isSearchable = false,
}) => {
  return (
    <ReactSelect
      isSearchable={isSearchable}
      onChange={(newValue) => onChange(newValue as Option)}
      options={options}
      styles={colourStyles}
      value={value}
    />
  )
}
