import {
  FetchInformationTooltipsResponse,
  InformationTooltipsData,
} from './interfaces/fetchInformationTooltips.interface'
import { nApi } from '../index'

export const fetchInformationTooltips =
  async (): Promise<InformationTooltipsData> => {
    const result = await nApi().get<FetchInformationTooltipsResponse>(
      `/hapttic-admin-information-tooltips`
    )

    return result.data[0]
  }
