import React, { FC } from 'react'
import { numberWithCommas } from 'src/utils/numberFormat'
import { ReactComponent as Loudspeaker } from 'src/assets/svg/loudspeaker.svg'
import styles from './mentionsCell.module.scss'

interface MentionCellProps {
  percentage?: number
  value?: number
}

export const MentionCell: FC<MentionCellProps> = ({ percentage, value }) => {
  return (
    <span className={styles.mentionsCell}>
      {!!percentage && <span className={styles.percentage}>{percentage}%</span>}

      {!!percentage && !!value && <span className={styles.separator} />}

      {!!value && numberWithCommas(value)}

      {!!value && <Loudspeaker />}
    </span>
  )
}
