import {
  FC,
  KeyboardEvent,
  useEffect,
  useState,
  Fragment,
  useMemo,
} from 'react'
import { toast } from 'react-toastify'
import classNames from 'classnames'
import en from 'src/constants/en'
import { getSessionIdFromLocalStorage } from 'src/localStorage/sessionId/getSessionIdFromLocalStorage/getSessionIdFromLocalStorage'
import { useGetTopKeywordsQuery } from 'src/reactQuery/useGetTopKeywordsQuery'
import { useGetKeywordsWithStatsQuery } from 'src/reactQuery/useGetKeywordsWithStatsQuery'
import { useGetCampaignsQuery } from 'src/reactQuery/useGetCampaignsQuery'
import {
  CampaignPayload,
  CampaignPayloadKeyword,
  CampaignType,
  KeywordFetchKeywordsWithStats,
} from 'src/services/__generated__/api'
import Loudspeaker from 'src/assets/svg/loudspeaker.svg'
import PlusIcon from 'src/assets/svg/plusicon.svg'
import FilterClosed from 'src/assets/svg/filter-closed.svg'
import FilterOpen from 'src/assets/svg/filter-opened.svg'
import { ReactComponent as CampaignsChartBanner } from 'src/assets/svg/campaigns-chart-banner.svg'
import { BackNavigationButton } from 'src/components/BackNavigationButton'
import { SearchInput } from 'src/components/SearchInput'
import { SmallRoundTag } from 'src/components/SmallRoundTag'
import { AddNewCard } from 'src/components/AddNewCard'
import { BaseModal } from 'src/containers/BaseModal'
import { AnalyticsApi } from 'src/services/analyticsApi'
import { getDateRangeByQueryParams } from 'src/utils/getDateRangeByQueryParams'
import { useQueryParams } from 'src/hooks/useQueryParams'
import { GlobalQueryParams } from 'src/interfaces/globalQueryParams.interface'
import { KeywordsFilterSection } from './KeywordsFilterSection'
import { sortFilteredDataByOrder } from './utils/sortFilteredDataByOrder'
import { FilteredDataItem } from './interfaces/filteredData.interface'
import { KeywordCardItem } from './KeywordCardItem'
import { createCards } from './utils/createCards'
import { ORDER_BY } from './constants/orderBy'
import styles from './keywords.module.scss'
import { ChooseCampaignModalContent } from './ChooseCampaignModalContent'
import { AddNewKeywordModalContent } from './AddNewKeywordModalContent'
import { MentionCard } from '../Campaigns/MentionCard'
import { numberWithCommas } from '../../utils/numberFormat'
import { removeFromArray } from '../../utils/removeFromArray'

export const KeywordsPage: FC = () => {
  const sessionId = getSessionIdFromLocalStorage()

  const { queryParams } = useQueryParams<GlobalQueryParams>()

  const dates = getDateRangeByQueryParams(queryParams)

  const startDate = dates?.start
  const endDate = dates?.end

  const [filteredData, setFilteredData] = useState<FilteredDataItem[]>([])

  const [activeNum, setActiveNum] = useState<number>(0)

  const [completedNum, setCompletedNum] = useState<number>(0)

  const [allCardsNum, setAllCardsNum] = useState<number>(0)

  const [selectedCampaignsForNewKeyword, setSelectedCampaignForNewKeyword] =
    useState<string[]>([])

  const [showModal1, setShowModal1] = useState(false)

  const [showModal2, setShowModal2] = useState(false)

  const [selectedCampaigns, setSelectedCampaigns] = useState<
    CampaignType[] | null
  >(null)

  const [currentKeywordInput, setCurrentKeywordInput] = useState<string>('')

  const [addedKeywordsArr, setAddedKeywordsArr] = useState<string[]>([])

  const [registeredKeywordsArr, setRegisteredKeywordsArr] = useState<
    CampaignPayloadKeyword[]
  >([])

  const [filterOpen, setFilterOpen] = useState(false)

  const [orderFilter, setOrderFilter] = useState<string>(ORDER_BY[2])

  const [themesFilterArr, setThemesFilterArr] = useState<Array<any>>([])

  const [searchInput, setSearchInput] = useState<string>('')

  const filteredAndSearchedItems = useMemo(() => {
    return filteredData.filter((item) => {
      const searchPattern = searchInput.toLowerCase()
      return (
        item?.name?.toLowerCase().includes(searchPattern) ||
        item.campaign?.toLowerCase().includes(searchPattern)
      )
    })
  }, [filteredData, searchInput])

  const { data: topKeywordsData, refetch: refetchTopKeywords } =
    useGetTopKeywordsQuery({
      end: endDate,
      start: startDate,
    })

  const { data: keywordsWithStatsData, refetch: refetchKeywordsWithStats } =
    useGetKeywordsWithStatsQuery({
      end: endDate,
      start: startDate,
    })

  const { data: campaignsData, refetch: refetchCampaigns } =
    useGetCampaignsQuery({
      end: endDate,
      start: startDate,
    })

  const {
    avgMentionPerKeyword,
    avgDailyPositiveMentionCount,
    avgDailyPositiveMentionCountChange,
    avgDailyNegativeMentionCount,
    avgDailyNegativeMentionCountChange,
  } = topKeywordsData?.stats ?? {}

  const filterItems = [
    { count: activeNum, name: 'Active' },
    { count: completedNum, name: 'Completed' },
    { count: allCardsNum, name: 'All' },
  ]

  const [filter, setFilter] = useState<string>(filterItems[2].name)

  // First, we see which themes are going to be displayed from themes filter:
  const handleChangeThemesFilter = (val: string[]): void => {
    setThemesFilterArr(val)
  }

  // we will be using this method to get exact num of available keywords
  // from themesFilterArr (array of selected theme ids) from scratch,
  // without depending on filteredData and without changing it.
  // This way we do not intervene in single-direction stream of state and
  // never let it go backwards
  const filterDataByIdsFromThemeSelect = ():
    | KeywordFetchKeywordsWithStats[]
    | undefined => {
    return keywordsWithStatsData?.filter((keyword) => {
      return themesFilterArr.includes(keyword.campaignID)
    })
  }

  const statusFilterSwitch = () => {
    // finding which of the selected themes has relevant status as true and
    // setting filteredData on tab change
    const filterCampaignsByStatus = (selectedStatus: string): void => {
      const filteredKeywordsWithStatsData = filterDataByIdsFromThemeSelect()

      let temp = filteredKeywordsWithStatsData?.filter((item) => {
        return (
          item?.activeStatus !== undefined &&
          item?.activeStatus?.toLowerCase() === selectedStatus.toLowerCase()
        )
      })

      temp = temp ? createCards(temp) : []

      const reorderedTemp = sortFilteredDataByOrder(temp, orderFilter)

      if (reorderedTemp) {
        setFilteredData(reorderedTemp)
      }
    }

    const data = filterDataByIdsFromThemeSelect()

    switch (filter) {
      case 'All': {
        if (data) {
          const allSortedCards = sortFilteredDataByOrder(
            createCards(data),
            orderFilter
          )

          if (allSortedCards) {
            setFilteredData(allSortedCards)
          }
        }

        break
      }

      case 'Active':
        filterCampaignsByStatus('active')
        break

      case 'Completed':
        filterCampaignsByStatus('completed')
        break

      default:
        break
    }
  }

  const handleChangeOrderFilter = (value: string): void => {
    setOrderFilter(value)
  }

  const handleCloseModal = () => {
    setShowModal1(false)
  }

  const clearModalForm = () => {
    setCurrentKeywordInput('')

    setSelectedCampaigns(null)

    setRegisteredKeywordsArr([])

    setAddedKeywordsArr([])
  }

  const closeAndClear = () => {
    setSelectedCampaignForNewKeyword([])

    setShowModal1(false)

    setShowModal2(false)

    clearModalForm()
  }

  const handleNextModal = () => {
    if (selectedCampaignsForNewKeyword?.length) {
      const temp = campaignsData?.campaigns?.filter(
        (item) =>
          !!item._id && !!selectedCampaignsForNewKeyword.includes(item._id)
      )

      if (temp) {
        setSelectedCampaigns(temp)
      }

      handleCloseModal()

      setShowModal2(true)

      setSelectedCampaignForNewKeyword([])
    }
  }

  function addNewKeyword() {
    if (currentKeywordInput.length > 0) {
      const keyword = currentKeywordInput

      selectedCampaigns?.forEach((campaign) => {
        const index = campaign.keywords?.findIndex((k) => {
          return k.name === keyword
        })

        if (index === -1 && !addedKeywordsArr.includes(keyword)) {
          setAddedKeywordsArr([...addedKeywordsArr, keyword])

          setTimeout(() => setCurrentKeywordInput(''), 1000)
        }
      })
    }
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      event.stopPropagation()
      event.preventDefault()

      addNewKeyword()
    }
  }

  function handleChangeKeywordSentimentsValue(
    keyword: CampaignPayloadKeyword
  ): void {
    let tempArr = [...registeredKeywordsArr]
    const index = tempArr.findIndex((item) => item.name === keyword.name)

    if (index === -1) {
      tempArr = [...tempArr, keyword]
    } else {
      tempArr = tempArr.filter((item, i) => i !== index)
      tempArr.push(keyword)
    }
    setRegisteredKeywordsArr(tempArr)
  }

  const updateCampaignAndRefetchData = async (
    id: string,
    data: CampaignPayload
  ): Promise<void> => {
    try {
      await AnalyticsApi.v1Private.campaignUpdate(id, data)

      refetchCampaigns()

      refetchKeywordsWithStats()
    } catch (error) {
      toast.error(en.somethingWentWrong)
    }
  }

  const handleFinalizeKeywordForm = async () => {
    if (registeredKeywordsArr.length > 0) {
      await Promise.all(
        selectedCampaigns?.map((campaign) => {
          const payload: CampaignPayload = {
            from: campaign?.from,
            keywords: [...(campaign?.keywords || []), ...registeredKeywordsArr],
            name: campaign?.name,
            status: campaign?.status,
            targets: campaign?.targets,
            to: campaign?.to,
          }

          if (campaign?._id) {
            return updateCampaignAndRefetchData(campaign._id, payload)
          }

          return null
        }) || []
      )

      closeAndClear()
    }
  }

  const handleDeleteKeyword = (keyword: string): void => {
    const temp = removeFromArray(addedKeywordsArr, keyword)
    setAddedKeywordsArr(temp)
    const index = registeredKeywordsArr.findIndex(
      (item) => item.name === keyword
    )
    const tempRegistered = registeredKeywordsArr.slice()
    if (index !== -1) {
      tempRegistered.splice(index, 1)
    }
    setRegisteredKeywordsArr(tempRegistered)
  }

  const handleSearchChange = (val: string): void => {
    setSearchInput(val)
  }

  // after that, we filter themes from campaignData by ids and setFilteredData:
  useEffect(() => {
    const filteredDataToSet = filterDataByIdsFromThemeSelect()

    if (filteredDataToSet) {
      setFilteredData(filteredDataToSet)
    }

    // counting numbers for tag buttons in status filter
    const imaginaryCardsForCounting = filteredDataToSet
      ? createCards(filteredDataToSet)
      : undefined

    const activeArr = imaginaryCardsForCounting?.filter((item) => {
      return item.active
    })

    const completedArr = imaginaryCardsForCounting?.filter((item) => {
      return item?.completed
    })

    setActiveNum(activeArr?.length || 0)

    setCompletedNum(completedArr?.length || 0)

    setAllCardsNum(imaginaryCardsForCounting?.length || 0)
  }, [themesFilterArr, keywordsWithStatsData])

  useEffect(() => {
    statusFilterSwitch()
  }, [filter, themesFilterArr, keywordsWithStatsData, orderFilter])

  useEffect(() => {
    if (sessionId) {
      refetchCampaigns()

      if (startDate && endDate) {
        refetchTopKeywords()
      }

      refetchKeywordsWithStats()
    }
  }, [sessionId, startDate, endDate])

  return (
    <>
      <div className={styles.keywords}>
        <BackNavigationButton title={en.keywords} />

        <div className={styles.mentionCards}>
          <MentionCard
            blockText={
              avgMentionPerKeyword !== undefined
                ? numberWithCommas(avgMentionPerKeyword ?? 0)
                : undefined
            }
            infoType="total"
            title={en.totalMentions}
          />

          <MentionCard
            blockText={
              avgDailyPositiveMentionCount !== undefined
                ? numberWithCommas(avgDailyPositiveMentionCount ?? 0)
                : undefined
            }
            infoType="positive"
            percent={
              avgDailyPositiveMentionCountChange
                ? parseFloat(
                    numberWithCommas(avgDailyPositiveMentionCountChange) || '0'
                  )
                : undefined
            }
            title={en.dailyPositiveMentions}
          />

          <MentionCard
            blockText={
              avgDailyNegativeMentionCount !== undefined
                ? numberWithCommas(avgDailyNegativeMentionCount ?? 0)
                : undefined
            }
            infoType="negative"
            percent={
              avgDailyNegativeMentionCountChange
                ? parseFloat(
                    numberWithCommas(avgDailyNegativeMentionCountChange) || '0'
                  )
                : undefined
            }
            title={en.dailyNegativeMentions}
          />
        </div>

        {!!topKeywordsData?.topKeywords?.length && (
          <div className={styles.topKeywords}>
            <div className={styles.topKeywordsTitle}>{en.topKeywords}</div>

            <div className={styles.topKeywordsList}>
              {topKeywordsData.topKeywords.map((item) => (
                <Fragment key={item.name}>
                  <div className={styles.topKeywordsDivider} />

                  <div className={styles.topKeyword}>
                    <div className={styles.topKeywordsName}>{item.name}</div>

                    <div className={styles.topKeywordInfo}>
                      <span>
                        <img alt="Loudspeaker" src={Loudspeaker} />
                      </span>

                      {!!item.mentionsCount && (
                        <span className={styles.topKeywordsCount}>
                          {numberWithCommas(item.mentionsCount)}
                        </span>
                      )}
                    </div>
                  </div>
                </Fragment>
              ))}
            </div>
          </div>
        )}

        <div className={styles.mainCard}>
          <div className={styles.mainCardInnerLeft}>{en.createNewKeyword}</div>

          <div
            className={styles.mainCardButton}
            onClick={() => setShowModal1(true)}
          >
            <img alt="plus icon" src={PlusIcon} />

            {en.addNewKeyword}
          </div>

          <CampaignsChartBanner
            className={styles.mainCardLeftCampaignChartImage}
          />
        </div>

        <div className={styles.statusFilterItemsWrapper}>
          <ul className={styles.statusFilterList}>
            {filterItems.map((item) => (
              <li
                className={classNames(
                  styles.statusFilterListItem,
                  filter === item.name && styles.statusFilterListItemActive
                )}
                key={item.name}
              >
                <div
                  className={styles.statusFilterListItemButton}
                  onClick={() => setFilter(item.name)}
                >
                  {item.name}

                  <span className={styles.statusFilterListItemButtonTag}>
                    <SmallRoundTag content={item.count} />
                  </span>
                </div>
              </li>
            ))}
          </ul>

          <div className={styles.searchWrapper}>
            <SearchInput debouncedChange={handleSearchChange} />

            <button
              className={styles.filterButton}
              onClick={() => setFilterOpen(!filterOpen)}
            >
              {en.filters}

              <img alt="filter" src={filterOpen ? FilterOpen : FilterClosed} />
            </button>
          </div>
        </div>

        <KeywordsFilterSection
          campaignsData={campaignsData?.campaigns}
          open={filterOpen}
          orderCallback={(value) => {
            if (value) {
              handleChangeOrderFilter(value)
            }
          }}
          orderOptions={ORDER_BY}
          themesCallback={(value) => {
            if (value) {
              handleChangeThemesFilter(value)
            }
          }}
        />

        <div className={styles.cards}>
          {filteredAndSearchedItems.length ? (
            filteredAndSearchedItems.map((item) => (
              <KeywordCardItem
                data={item}
                key={`${item.name} - ${item.campaign}`}
              />
            ))
          ) : (
            <div className={styles.empty}>{en.noKeywordsFound}</div>
          )}

          <AddNewCard
            handleClick={() => setShowModal1(true)}
            text={en.addNewKeyword}
          />
        </div>
      </div>

      <BaseModal isOpen={!!showModal1}>
        <ChooseCampaignModalContent
          campaignsData={campaignsData}
          closeAndClear={closeAndClear}
          handleNextModal={handleNextModal}
          selectedCampaignForNewKeyword={selectedCampaignsForNewKeyword}
          setSelectedCampaignForNewKeyword={(ids) => {
            setSelectedCampaignForNewKeyword(ids)
          }}
        />
      </BaseModal>

      <BaseModal isOpen={!!showModal2}>
        <AddNewKeywordModalContent
          addNewKeyword={() => addNewKeyword()}
          addedKeywordsArr={addedKeywordsArr}
          closeAndClear={closeAndClear}
          currentKeywordInput={currentKeywordInput}
          handleChangeKeywordSentimentsValue={(keywordData) =>
            handleChangeKeywordSentimentsValue(keywordData)
          }
          handleDeleteKeyword={handleDeleteKeyword}
          handleFinalizeKeywordForm={handleFinalizeKeywordForm}
          handleKeyDown={handleKeyDown}
          setCurrentKeywordInput={setCurrentKeywordInput}
        />
      </BaseModal>
    </>
  )
}
