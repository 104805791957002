import React, { FC, ReactNode } from 'react'
import styles from './nameCell.module.scss'

interface NameCellProps {
  children?: ReactNode
}

export const NameCell: FC<NameCellProps> = ({ children }) => {
  return <span className={styles.nameCell}>{children}</span>
}
