import { useQuery } from '@tanstack/react-query'
import { QueryKey } from 'src/reactQuery/enums/queryKey.enum'
import { AnalyticsApi } from 'src/services/analyticsApi'
import { AnalyticsFetchCalendarDetailsResponse } from 'src/services/__generated__/api'
import { useGetHomeCalendarDetailsQueryParams } from './interfaces/useGetHomeCalendarDetailsQuery.interface'

const getHomeCalendarDetails = async (
  date: string | null
): Promise<AnalyticsFetchCalendarDetailsResponse | undefined> => {
  if (!date) {
    return undefined
  }

  const { data } =
    await AnalyticsApi.v1Private.analyticsFetchCalendarDetailsList({ date })

  return data
}

export const useGetHomeCalendarDetailsQuery = (
  params: useGetHomeCalendarDetailsQueryParams
) => {
  return useQuery({
    queryFn: () => getHomeCalendarDetails(params.date),
    queryKey: [QueryKey.GET_HOME_CALENDAR_DETAILS],
  })
}
