import { KeywordFetchKeywordsWithStats } from 'src/services/__generated__/api'
import { FilteredDataItem } from '../../interfaces/filteredData.interface'

// when filteredData changes, we rearrange cards by selected order
export const createCards = (
  data: KeywordFetchKeywordsWithStats[]
): FilteredDataItem[] => {
  const extractedKeywordsArr: FilteredDataItem[] = []

  data?.forEach((item) => {
    const card = {
      active: item.activeStatus === 'active',
      campaign: item.campaign,
      campaignId: item.campaignID,
      completed: item.activeStatus === 'completed',
      createdAt: item.createdAt,
      from: item.from,
      mentions: item.mentions,
      name: item.name,
      negative: item.negativeCount,
      positive: item.positiveCount,
      stats: {
        negativeMentionsCount: item.negativeCount,
        neutralMentionsCount: item.neutralCount,
        positiveMentionsCount: item.positiveCount,
      },
      targets: item?.topSources?.map((source) => ({
        name: source._id,
        number: source.count,
      })),
      to: item.to,
      updatedAt: item.updatedAt,
    }

    extractedKeywordsArr.push(card)
  })

  return extractedKeywordsArr
}
