import React, { FC, useState, ReactNode } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import styles from './filter.module.scss'

interface FilterProps {
  children: ReactNode
  name: string
}

export const Filter: FC<FilterProps> = ({ name, children }) => {
  const [className, setClassName] = useState(styles.hidden)
  const [iconActive, setIconActive] = useState('')

  function toggleState() {
    setClassName(className === styles.hidden ? styles.shown : styles.hidden)
    setIconActive(iconActive === '' ? styles.filterActive : '')
  }

  return (
    <div className={styles.filterWrapper}>
      <div className={styles.navMenuHeader} onClick={toggleState}>
        <span>{name}</span>

        <span>
          <FontAwesomeIcon className={iconActive} icon={faChevronDown} />
        </span>
      </div>

      <div className={`${styles.submenu} ${className}`}>{children}</div>
    </div>
  )
}
