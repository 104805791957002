import { useMutation } from '@tanstack/react-query'
import { SuggestionsApi } from 'src/services/suggestionsApi'
import {
  RelevancyParams,
  RelevancyResult,
} from 'src/services/__generated__/api'

const updateRelevancy = async (
  params: RelevancyParams
): Promise<RelevancyResult> => {
  const { data } = await SuggestionsApi.v1.relevancyCreate(params)

  return data
}

export const useUpdateRelevancy = () => {
  return useMutation({
    mutationFn: (params: RelevancyParams) => {
      return updateRelevancy(params)
    },
  })
}
