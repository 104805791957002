import React, { FC, ReactNode } from 'react'
import Modal from 'react-modal'
import styles from './baseModal.module.scss'

export interface BaseModalProps {
  children?: ReactNode
  isOpen: boolean
}

export const BaseModal: FC<BaseModalProps> = ({ isOpen, children }) => {
  return (
    <Modal
      className={styles.content}
      isOpen={isOpen}
      overlayClassName={styles.overlay}
    >
      <div>{children}</div>
    </Modal>
  )
}
