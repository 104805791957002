import React, { useState, useEffect } from 'react'
import en from 'src/constants/en'
import { ReactComponent as ArrowUp } from 'src/assets/svg/arrow-up-double-fill.svg'
import styles from './backToTopButton.module.scss'

const BackToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      setIsVisible(window.scrollY > 200)
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    })
  }

  return (
    isVisible && (
      <div className={styles.btnWrapper} onClick={scrollToTop}>
        <ArrowUp />
        <span className={styles.text}>{en.backToTop}</span>
      </div>
    )
  )
}

export default BackToTopButton
