import { useQuery } from '@tanstack/react-query'
import { QueryKey } from 'src/reactQuery/enums/queryKey.enum'
import { AnalyticsApi } from 'src/services/analyticsApi'
import {
  AnalyticsFetchThreadsIsLostType,
  AnalyticsFetchThreadsResponse,
  AnalyticsFetchThreadsVectorType,
} from 'src/services/__generated__/api'
import { UseGetAllSentimentAnalysisThreadsQueryParams } from './interfaces/useGetAllSentimentAnalysisThreadsQuery.interface'

const getAllSentimentAnalysisThreads = async (
  campaign?: string,
  keyword?: string,
  lastDate?: string,
  limit?: number,
  selectedKeywords?: string,
  selectedSources?: string,
  selectedLanguages?: string,
  fromDate?: string,
  toDate?: string,
  mentionFormat?: string,
  isLost?: AnalyticsFetchThreadsIsLostType
): Promise<AnalyticsFetchThreadsResponse | null> => {
  if (!lastDate || !limit) {
    return null
  }
  const { data } = await AnalyticsApi.v1Private.analyticsFetchThreadsList({
    campaign,
    fromDate,
    isLost,
    keyword,
    languages: selectedLanguages,
    lastDate,
    limit,
    mentionFormat,
    selectedKeywords,
    selectedSources,
    toDate,
    vector: AnalyticsFetchThreadsVectorType.Desc,
  })

  return data ?? null
}

export const useGetAllSentimentAnalysisThreadsQuery = (
  params: UseGetAllSentimentAnalysisThreadsQueryParams
) => {
  return useQuery({
    queryFn: () =>
      getAllSentimentAnalysisThreads(
        params.campaign,
        params.keyword,
        params.lastDate,
        params.limit,
        params.selectedKeywords,
        params.selectedSources,
        params.selectedLanguages,
        params.fromDate,
        params.toDate,
        params.selectedMentionFormats,
        params.isLost
      ),
    queryKey: [
      QueryKey.GET_ALL_SENTIMENT_ANALYSIS_THREADS,
      ...Object.values(params),
    ],
    refetchInterval: Infinity,
  })
}
