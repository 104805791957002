export const addThreeDotsToString = (string: string, limit: number): string => {
  const dots = '...'
  return string.length > limit ? string.substring(0, limit) + dots : string
}

export const addThreeDotsToStringStart = (
  string: string,
  limit: number
): string => {
  const dots = '...'
  return string.length > limit ? dots + string.substring(-limit) : string
}
