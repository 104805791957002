import React from 'react'
import ReactDOM from 'react-dom/client'
import { QueryClientProvider } from '@tanstack/react-query'
import Modal from 'react-modal'
import { App } from './App'
import reportWebVitals from './reportWebVitals'
import { queryClient } from './reactQuery'
import 'rc-tooltip/assets/bootstrap_white.css'
import 'react-tagsinput/react-tagsinput.css'
import 'react-daterange-picker/dist/css/react-calendar.css'
import './styles/globals.css'
import './styles/global.scss'
import './styles/fonts.scss'
import './styles/normalize.scss'

Modal.setAppElement('#root')

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
