import { useQuery } from '@tanstack/react-query'
import { QueryKey } from 'src/reactQuery/enums/queryKey.enum'
import { AnalyticsApi } from 'src/services/analyticsApi'
import { AnalyticsFetchPositivityPercentResponse } from 'src/services/__generated__/api'
import { UseGetPositivityPercentParams } from './interfaces/useGetPositivityPercentQuery.interface'

const getPositivityPercent = async (
  start?: string,
  end?: string
): Promise<AnalyticsFetchPositivityPercentResponse | null> => {
  const { data } =
    await AnalyticsApi.v1Private.analyticsFetchPositivityPercentList({
      fromDate: start,
      toDate: end,
    })

  return data
}

export const useGetPositivityPercentQuery = (
  params: UseGetPositivityPercentParams
) => {
  return useQuery({
    queryFn: () => getPositivityPercent(params.start, params.end),
    queryKey: [QueryKey.GET_POSITIVITY_PERCENT],
  })
}
