import { CONST_PERIOD_OPTIONS } from 'src/constants/filterPeriods'
import { GlobalQueryParams } from 'src/interfaces/globalQueryParams.interface'

export interface GetDateRangeByQueryParamsParamsValue {
  end?: string
  start?: string
}

export const getDateRangeByQueryParams = (
  queryParams?: GlobalQueryParams
): GetDateRangeByQueryParamsParamsValue | undefined => {
  const period = queryParams?.period
    ? CONST_PERIOD_OPTIONS[queryParams.period]
    : undefined

  if (period) {
    const from = period?.start.toISOString(true)
    const to = period?.end.toISOString(true)

    if (from || to) {
      return {
        end: to,
        start: from,
      }
    }
  }

  const from = queryParams?.from
  const to = queryParams?.to

  if (from || to) {
    return {
      end: to,
      start: from,
    }
  }

  return undefined
}
