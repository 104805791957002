import { max } from 'lodash'
import { GetThreadPreviewTextParams } from './interfaces/getThreadPreviewTextParams.interface'
import {
  addThreeDotsToString,
  addThreeDotsToStringStart,
} from '../addThreeDotsToString'

const MAX_CHARACTERS_IN_PREVIEW = 51

const DOTS_LENGTH = 3

export const getThreadPreviewText = ({
  text,
  keyword,
}: GetThreadPreviewTextParams): string => {
  if (!text) {
    return ''
  }

  // Check if whole text fits in preview.
  if (text.length <= MAX_CHARACTERS_IN_PREVIEW || !keyword) {
    return addThreeDotsToString(text, MAX_CHARACTERS_IN_PREVIEW)
  }

  // Split text into two parts, before and after keyword.

  const indexOfFirstKeyword = text
    .toLowerCase()
    .split(keyword.toLowerCase())[0].length
  const fullLeftText = text.slice(0, indexOfFirstKeyword)
  const keywordInText = text.slice(
    indexOfFirstKeyword,
    indexOfFirstKeyword + keyword.length
  )
  const fullRightText = text.slice(indexOfFirstKeyword + keyword.length)

  // Check if (fullLeftText + keyword + dots) fits in preview.
  // Dots need to be added at the end, because whole text doesn't fit.

  const paddingCharactersCount =
    MAX_CHARACTERS_IN_PREVIEW - DOTS_LENGTH - keyword.length

  if (fullLeftText.length < paddingCharactersCount) {
    return addThreeDotsToString(text, MAX_CHARACTERS_IN_PREVIEW)
  }

  const fullLeftTextParts = fullLeftText.match(/\b(\w+\W+)/g) || []
  let leftText = ''

  const leftPaddingCharactersCountMin = paddingCharactersCount / 2
  const leftPaddingCharactersCount =
    max([
      leftPaddingCharactersCountMin,
      MAX_CHARACTERS_IN_PREVIEW -
        fullRightText.length -
        keywordInText.length -
        DOTS_LENGTH,
    ]) || leftPaddingCharactersCountMin

  if (fullLeftTextParts.length < 2) {
    leftText = addThreeDotsToStringStart(
      fullLeftTextParts[0] || '',
      leftPaddingCharactersCount
    )
  } else {
    let leftTextWithoutDots = ''
    let index = fullLeftTextParts.length - 1

    while (
      index >= 0 &&
      (fullLeftTextParts[index] + leftTextWithoutDots).length <
        leftPaddingCharactersCount
    ) {
      leftTextWithoutDots = fullLeftTextParts[index] + leftTextWithoutDots
      index--
    }

    // If full left text doesn't fit, add dots at the start.
    leftText = `${index !== -1 && '...'}${leftTextWithoutDots}`
  }

  const previewText =
    leftPaddingCharactersCountMin === leftPaddingCharactersCount
      ? leftText + keywordInText + fullRightText
      : addThreeDotsToString(
          leftText + keywordInText + fullRightText,
          MAX_CHARACTERS_IN_PREVIEW
        )

  return previewText
}
