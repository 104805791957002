import { FC, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { toast } from 'react-toastify'
import { HOME_ROUTE, NEW_CAMPAIGN_ROUTE } from 'src/constants/routes'
import en from 'src/constants/en'
import { useGetCampaignsQuery } from 'src/reactQuery/useGetCampaignsQuery'
import { useGetDailyPositivityCampaignQuery } from 'src/reactQuery/useGetDailyPositivityCampaignQuery'
import { useGetCampaignTopLocationQuery } from 'src/reactQuery/useGetCampaignTopLocationDataQuery'
import { useGetCampaignTopSourceQuery } from 'src/reactQuery/useGetCampaignTopSourceQuery'
import { capitalizeFirstLetter } from 'src/utils/capitalizeFirstLetter'
import { CampaignPayload, CampaignType } from 'src/services/__generated__/api'
import { useCustomLink } from 'src/hooks/useCustomLink'
import { ReactComponent as CampaignsChartBanner } from 'src/assets/svg/campaigns-chart-banner.svg'
import { BackNavigationButton } from 'src/components/BackNavigationButton'
import { SearchInput } from 'src/components/SearchInput'
import { SmallRoundTag } from 'src/components/SmallRoundTag'
import { AddNewCard } from 'src/components/AddNewCard'
import { BaseModal } from 'src/containers/BaseModal'
import { ConfirmationModalContent } from 'src/components/ConfirmationModalContent'
import { AnalyticsApi } from 'src/services/analyticsApi'
import { useQueryParams } from 'src/hooks/useQueryParams'
import { FilterSelectOption } from 'src/components/FilterSelect/interfaces/filterSelectOption.interface'
import { numberWithCommas } from 'src/utils/numberFormat'
import { useUpdateCampaignMutation } from 'src/reactQuery/useUpdateCampaignMutation'
import { getDateRangeByQueryParams } from 'src/utils/getDateRangeByQueryParams'
import { QueryParams } from 'src/pages/Campaigns/interfaces/queryParams.interface'
import { MentionCard } from './MentionCard'
import CampaignsFilter from './CampaignsFilter'
import { MARGIN_LEFT } from './constants/marginLeft'
import { FilteredCampaignsByCategory } from './interfaces/filteredCampaignsByCategory.interface'
import { sortFilteredDataByOrder } from './utils/sortFilteredDataByOrder'
import { ORDER_BY } from './constants/orderBy'
import { sortCategoryObjects } from './utils/sortCategoryObjects'
import { getCategoriesOptions } from './utils/getCategoriesOptions'
import { groupCampaignsByCategories } from './utils/groupCampaignsByCategories'
import CampaignCard from './CampaignCard'
import styles from './campaigns.module.scss'
import { RenameCampaignModalContent } from './RenameCampaignModalContent'
import PlusIcon from '../../assets/svg/plusicon.svg'
import LiveIcon from '../../assets/svg/live.svg'
import FilterClosed from '../../assets/svg/filter-closed.svg'
import FilterOpen from '../../assets/svg/filter-opened.svg'

export const Campaigns: FC = () => {
  const navigate = useNavigate()

  const { queryParams, updateQueryParams } = useQueryParams<QueryParams>({
    qsStringifyOptions: {
      arrayFormat: 'comma',
    },
  })

  const dates = getDateRangeByQueryParams(queryParams)

  const startDate = dates?.start

  const endDate = dates?.end

  const selectedCampaigns = queryParams.campaigns || []

  const [campaignToDelete, setCampaignToDelete] = useState<CampaignType | null>(
    null
  )

  const [campaignToRename, setCampaignToRename] = useState<CampaignType | null>(
    null
  )

  const { data: campaignsData, refetch: campaignsRefetch } =
    useGetCampaignsQuery({
      end: endDate,
      start: startDate,
    })

  const { data: dailyPositivityCampaignData, refetch: positiveRefetch } =
    useGetDailyPositivityCampaignQuery({
      end: endDate,
      start: startDate,
    })

  const { data: campaignTopLocationData, refetch: locationRefetch } =
    useGetCampaignTopLocationQuery({
      end: endDate,
      start: startDate,
    })

  const { data: campaignTopSourceData, refetch: sourcesRefetch } =
    useGetCampaignTopSourceQuery({
      end: endDate,
      start: startDate,
    })

  const mutation = useUpdateCampaignMutation({
    onSuccess() {
      campaignsRefetch()
    },
    updateType: 'update',
  })

  const campaignOptions = useMemo<FilterSelectOption[]>(() => {
    return (
      campaignsData?.campaigns?.map((item, index) => ({
        label: item.name || '',
        value: item._id || index.toString(),
      })) || []
    )
  }, [campaignsData?.campaigns])

  const selectedCampaignOptions = useMemo<FilterSelectOption[]>(() => {
    return campaignOptions.filter((item) =>
      selectedCampaigns.includes(item.value)
    )
  }, [campaignOptions, selectedCampaigns])

  const [filteredData, setFilteredData] = useState<
    FilteredCampaignsByCategory[] | null
  >(null)

  const [activeNum, setActiveNum] = useState<number>(0)

  const [draftNum, setDraftNum] = useState<number>(0)

  const [completedNum, setCompletedNum] = useState<number>(0)

  const [extractedCategories, setExtractedCategories] =
    useState<Array<string> | null>(null)

  const [filterOpen, setFilterOpen] = useState(false)

  const [orderFilter, setOrderFilter] = useState<string>(ORDER_BY[2])

  const [categoriesFilterArr, setCategoriesFilterArr] = useState<Array<any>>([])

  const [searchInput, setSearchInput] = useState<string>('')

  const [footNote1, setFootNote1] = useState<string>('')

  const [footNote2, setFootNote2] = useState<string>('')

  const [footNote3, setFootNote3] = useState<string>('')

  const backURL = useCustomLink(HOME_ROUTE)

  const statusFilterItems = [
    { count: activeNum, name: 'Active' },
    { count: draftNum, name: 'Draft' },
    { count: completedNum, name: 'Completed' },
  ]

  const [statusFilter, setStatusFilter] = useState<string>('Active')

  const createCategoryFilterOptions = () => {
    if (campaignsData?.campaigns) {
      setExtractedCategories(getCategoriesOptions(campaignsData.campaigns))
    }
  }

  const handleDelete = async (id: string): Promise<void> => {
    try {
      await AnalyticsApi.v1Private.campaignDelete(id)

      campaignsRefetch()
    } catch (error) {
      toast.error(en.somethingWentWrong)
    }
  }

  const handleCampaignUpdate = async (
    campaign: CampaignPayload,
    id: string
  ) => {
    const payload: CampaignPayload = {
      from: campaign?.from,
      keywords: campaign?.keywords,
      name: campaign?.name,
      status: campaign?.status,
      targets: campaign?.targets,
      to: campaign?.to,
    }

    mutation.mutate({ data: payload, id })
  }

  useEffect(() => {
    createCategoryFilterOptions()

    const activeArr = campaignsData?.campaigns?.filter((item) => {
      return item.status?.toLowerCase() === 'active'
    })
    const draftArr = campaignsData?.campaigns?.filter((item) => {
      return item.status?.toLowerCase() === 'draft'
    })
    const completedArr = campaignsData?.campaigns?.filter((item) => {
      return item.status?.toLowerCase() === 'completed'
    })

    setActiveNum(activeArr?.length ?? 0)
    setDraftNum(draftArr?.length ?? 0)
    setCompletedNum(completedArr?.length ?? 0)
  }, [campaignsData])

  const filterThemes = () => {
    const filtered = campaignsData?.campaigns?.filter((item) => {
      return (
        item?.status?.toLowerCase() === statusFilter.toLowerCase() &&
        item?.name?.toLowerCase()?.includes(searchInput.toLowerCase())
      )
    })

    const grouped = groupCampaignsByCategories(filtered || [])

    const temp: [string, CampaignType[]][][] = []
    categoriesFilterArr.slice().forEach((selectedCategory) => {
      const selectedByKey = Object.entries(grouped).filter(
        ([key]) => key === selectedCategory
      )
      if (selectedByKey.length) {
        temp.push(selectedByKey)
      }
    })
    const withoutCategory = Object.entries(grouped).filter(
      ([key]) => key === 'Themes With No Categories'
    )
    if (withoutCategory.length) {
      temp.push(withoutCategory)
    }

    const finalData = temp.map((item) =>
      item.reduce<{ [key: string]: CampaignType[] }>((accum, [k, v]) => {
        // eslint-disable-next-line no-param-reassign
        accum[k] = v

        return accum
      }, {})
    )

    sortCategoryObjects(finalData)

    sortFilteredDataByOrder(finalData, orderFilter)

    setFilteredData(finalData)
  }

  const handleChangeOrderFilter = (val: string) => {
    setOrderFilter(val)
  }

  const handleChangeCategoriesFilter = (val: string[]) => {
    setCategoriesFilterArr(val)
  }

  const handleSearchChange = (val: string) => {
    setSearchInput(val)
  }

  useEffect(() => {
    if (campaignsData) {
      filterThemes()
    }
  }, [
    campaignsData,
    categoriesFilterArr,
    orderFilter,
    statusFilter,
    searchInput,
  ])

  useEffect(() => {
    if (dailyPositivityCampaignData) {
      setFootNote1(
        dailyPositivityCampaignData?.mentions
          ? `${numberWithCommas(dailyPositivityCampaignData.mentions)} Mentions`
          : ''
      )
    }
  }, [dailyPositivityCampaignData])

  useEffect(() => {
    if (campaignTopLocationData)
      setFootNote2(
        campaignTopLocationData?.mentions
          ? `${numberWithCommas(campaignTopLocationData.mentions)} Mentions`
          : ''
      )
  }, [campaignTopLocationData])

  useEffect(() => {
    if (campaignTopSourceData) {
      setFootNote3(
        campaignTopSourceData?.mentions
          ? `${numberWithCommas(campaignTopSourceData.mentions)} Mentions`
          : ''
      )
    }
  }, [campaignTopSourceData])

  useEffect(() => {
    campaignsRefetch()
    positiveRefetch()
    locationRefetch()
    sourcesRefetch()
  }, [startDate, endDate])

  useEffect(() => {
    if (campaignOptions.length) {
      updateQueryParams({
        campaigns: selectedCampaigns.length
          ? selectedCampaigns
          : campaignOptions.map((item) => item.value),
      })
    }
  }, [campaignOptions, selectedCampaigns])

  return (
    <div className={styles.campaigns}>
      <BackNavigationButton title={en.campaigns} url={backURL} />

      <div className={styles.sectionRow}>
        <MentionCard
          blockText={dailyPositivityCampaignData?.name ?? ''}
          footNote={footNote1}
          footNotePercent={dailyPositivityCampaignData?.changePercentage}
          infoType="positiveTheme"
          title={en.dailyPositiveCampaign}
        />

        <MentionCard
          blockText={capitalizeFirstLetter(
            campaignTopLocationData?.country ?? ''
          )}
          footNote={footNote2}
          infoType="topLocationTheme"
          locationChartData={campaignTopLocationData}
          title={en.topLocation}
        />

        <MentionCard
          blockText={capitalizeFirstLetter(campaignTopSourceData?.source ?? '')}
          footNote={footNote3}
          infoType="topSourceTheme"
          title={en.topSource}
          topSourceChartData={campaignTopSourceData}
        />
      </div>

      <div className={styles.mainCard}>
        <div className={styles.mainCardInnerLeft}>{en.createNewCampaign}</div>

        <Link
          className={styles.mainCardLink}
          to={useCustomLink(NEW_CAMPAIGN_ROUTE)}
        >
          <img alt="plus icon" src={PlusIcon} />

          {en.addNewCampaign}
        </Link>

        <CampaignsChartBanner
          className={styles.mainCardLeftCampaignChartImage}
        />
      </div>

      <div className={styles.statusFilterItemsWrapper}>
        <ul className={styles.statusFilterList}>
          {statusFilterItems.map((item) => (
            <li
              className={classNames(
                styles.statusFilterListItem,
                statusFilter === item.name && styles.statusFilterListItemActive
              )}
              key={item.name}
            >
              <button
                className={styles.statusFilterListItemButton}
                onClick={() => setStatusFilter(item.name)}
              >
                {item.name === 'Draft' && (
                  <img
                    alt="Live"
                    className={styles.statusFilterListItemButtonDraft}
                    src={LiveIcon}
                  />
                )}

                {item.name}

                <span className={styles.statusFilterListItemButtonTag}>
                  <SmallRoundTag content={item.count} />
                </span>
              </button>
            </li>
          ))}

          <hr
            className={styles.line}
            style={{
              marginLeft:
                MARGIN_LEFT[
                  statusFilterItems.findIndex(
                    (item) => item.name === statusFilter
                  )
                ],
            }}
          />
        </ul>

        <div className={styles.searchWrapper}>
          <SearchInput debouncedChange={handleSearchChange} />

          <button
            className={styles.filterButton}
            onClick={() => setFilterOpen(!filterOpen)}
          >
            {en.filters}

            <img alt="filter" src={filterOpen ? FilterOpen : FilterClosed} />
          </button>
        </div>
      </div>

      <CampaignsFilter
        campaignOptions={campaignOptions}
        categoriesData={extractedCategories || undefined}
        open={filterOpen}
        orderCallback={handleChangeOrderFilter}
        orderOptions={ORDER_BY}
        selectedCampaignOptions={selectedCampaignOptions}
        themesCallback={handleChangeCategoriesFilter}
      />

      <div className={styles.cards}>
        {filteredData?.length ? (
          filteredData.map((categoryObj, index) => {
            const [categoryName, categoryCampaigns] =
              Object.entries(categoryObj).at(index) ?? []

            return (
              <div className={styles.cardRow} key={categoryName ?? index}>
                {categoryCampaigns?.map((campaign) => {
                  if (
                    campaign?.status?.toLowerCase() !==
                      statusFilter?.toLowerCase() ||
                    (campaign?._id &&
                      !selectedCampaigns.includes(campaign?._id))
                  ) {
                    return null
                  }

                  return (
                    <CampaignCard
                      campaignRefetch={campaignsRefetch}
                      data={campaign}
                      key={campaign._id}
                      locationRefetch={locationRefetch}
                      onDelete={() => setCampaignToDelete(campaign)}
                      onRename={() => setCampaignToRename(campaign)}
                      positiveRefetch={positiveRefetch}
                      sourcesRefetch={sourcesRefetch}
                    />
                  )
                })}

                <AddNewCard
                  handleClick={() => navigate(NEW_CAMPAIGN_ROUTE)}
                  text={en.addNewCampaign}
                />
              </div>
            )
          })
        ) : (
          <div className={styles.empty}>{en.noCampaignsFound}</div>
        )}
      </div>

      <BaseModal isOpen={!!campaignToDelete}>
        <ConfirmationModalContent
          onCancel={() => setCampaignToDelete(null)}
          onConfirm={() => {
            if (campaignToDelete?._id) {
              handleDelete(campaignToDelete?._id)

              setCampaignToDelete(null)
            }
          }}
          title={`${en.areYouSureYouWantToDelete} ${campaignToDelete?.name}?`}
        />
      </BaseModal>

      <BaseModal isOpen={!!campaignToRename}>
        <RenameCampaignModalContent
          name={campaignToRename?.name}
          onCancel={() => setCampaignToRename(null)}
          onConfirm={(name) => {
            if (campaignToRename?._id) {
              handleCampaignUpdate(
                { ...campaignToRename, name },
                campaignToRename?._id
              )

              setCampaignToRename(null)
            }
          }}
        />
      </BaseModal>
    </div>
  )
}
