import { FC, useState, ReactNode } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'
import classes from './adminThreadsFilter.module.scss'

interface AdminThreadsFilterProps {
  children: ReactNode
  name: string
}

export const AdminThreadsFilter: FC<AdminThreadsFilterProps> = ({
  name,
  children,
}) => {
  const [submenuClassName, setSubmenuClassName] = useState(classes.hidden)

  const [iconClassName, setIconClassName] = useState('')

  const toggleState = () => {
    setSubmenuClassName(
      submenuClassName === classes.hidden ? classes.shown : classes.hidden
    )

    setIconClassName(iconClassName === '' ? classes.filterActive : '')
  }

  return (
    <div className={classes.filterWrapper}>
      <div className={classes.navMenuHeader} onClick={toggleState}>
        <span>{name}</span>

        <span>
          <FontAwesomeIcon
            className={classNames(classes.icon, iconClassName)}
            icon={faChevronDown}
          />
        </span>
      </div>

      <div className={classNames(classes.submenu, submenuClassName)}>
        {children}
      </div>
    </div>
  )
}
