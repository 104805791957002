import axios from 'axios'
import { HAPTTIC_X_API_KEY } from 'src/constants/env'
import { getSessionIdFromLocalStorage } from 'src/localStorage/sessionId/getSessionIdFromLocalStorage/getSessionIdFromLocalStorage'
import {
  ANALYTICS_API_URL,
  PRODUCTION_SUGGESTIONS_API_URL,
} from '../constants/urls'
import { Api } from '../__generated__/api'

export const getSuggestionsApiHeaders = () => {
  return {
    'Access-Control-Allow-Origin': ANALYTICS_API_URL,
    Vary: 'Origin',
    'X-Api-Key': HAPTTIC_X_API_KEY,
    'x-session-id': getSessionIdFromLocalStorage() || '',
  }
}

export const suggestionsApi = () => {
  return axios.create({
    baseURL: PRODUCTION_SUGGESTIONS_API_URL,
    headers: getSuggestionsApiHeaders(),
  })
}

export class SuggestionsApi {
  private static _instance: SuggestionsApi

  private api: Api<unknown>

  private constructor() {
    this.api = new Api({
      baseApiParams: {
        credentials: 'same-origin',
        headers: getSuggestionsApiHeaders(),
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
      },
      baseUrl: PRODUCTION_SUGGESTIONS_API_URL,
    })
  }

  private static get instance(): SuggestionsApi {
    if (!SuggestionsApi._instance) {
      SuggestionsApi._instance = new SuggestionsApi()
    }

    return SuggestionsApi._instance
  }

  public static get v1(): Api<unknown>['v1'] {
    return SuggestionsApi.instance.api.v1
  }

  public static refresh(): void {
    SuggestionsApi._instance = new SuggestionsApi()
  }
}
