import React, { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { removeDataFromLocalStorage } from 'src/localStorage/removeDataFromLocalStorage'
import { LocalStorageKey } from 'src/localStorage/enums/localStorageKey.enum'
import en from 'src/constants/en'
import { LOGIN_ROUTE } from 'src/constants/routes'
import { useGetSelfQuery } from 'src/reactQuery/useGetSelfQuery'
import { queryClient } from 'src/reactQuery'
import { SuggestionsApi } from 'src/services/suggestionsApi'
import { AnalyticsApi } from 'src/services/analyticsApi'
import { AdminApi } from 'src/services/adminApi'
import CaretIcon from 'src/assets/svg/caret.svg'
import styles from './userDropDown.module.scss'

export const UserDropDown: FC = () => {
  const navigate = useNavigate()

  const { data: userData } = useGetSelfQuery()

  const [isDropDownOpen, setIsDropDownOpen] = useState(false)

  const user = userData?.user

  const onLogOut = () => {
    removeDataFromLocalStorage(LocalStorageKey.SESSION_ID)

    AdminApi.refresh()

    AnalyticsApi.refresh()

    SuggestionsApi.refresh()

    queryClient.clear()

    navigate(LOGIN_ROUTE)
  }

  return (
    <div className={styles.userDropDown}>
      <span className={styles.accountImage}>
        <span>{!!user?.firstName && `${user?.firstName.charAt(0)}`}</span>
      </span>

      <button
        className={styles.dropDownButton}
        onClick={() => setIsDropDownOpen((prev) => !prev)}
      >
        {!!user?.firstName && user?.lastName && (
          <span>
            {user?.firstName}
            &nbsp;
            {user?.lastName}
          </span>
        )}

        <span
          className={classNames(
            styles.dropDownIcon,
            isDropDownOpen && styles.dropDownIconReverse
          )}
        >
          <img alt="caret" src={CaretIcon} />
        </span>
      </button>

      {isDropDownOpen && (
        <div className={styles.dropDownMenu}>
          <div className={styles.dropDownItem}>
            <button onClick={onLogOut}>{en.logOut}</button>
          </div>
        </div>
      )}
    </div>
  )
}
