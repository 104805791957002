import { useQueryParams } from 'src/hooks/useQueryParams'
import { GlobalQueryParams } from 'src/interfaces/globalQueryParams.interface'
import { createLink } from 'src/utils/createLink'

export const useCustomLink = (link: string): string => {
  const { queryParams } = useQueryParams<GlobalQueryParams>()

  return createLink(link, {
    queryParams: {
      from: queryParams.from,
      period: queryParams.period,
      to: queryParams.to,
    },
  })
}
