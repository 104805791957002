import React, { FC, useMemo, useState } from 'react'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import {
  AnalyticsKeywordsAnalysisResponse,
  AnalyticsKeywordsAnalysisResponseItem,
} from 'src/services/__generated__/api'
import { Card } from 'src/components/Card'
import en from 'src/constants/en'
import { useGetInformationTooltipsQuery } from 'src/reactQuery/useGetInformationTooltips'
import { SectionTitle } from 'src/components/SectionTitle'
import { ScrollBarContainer } from 'src/components/ScrollBarContainer'
import { capitalizeFirstLetter } from 'src/utils/capitalizeFirstLetter'
import { HeaderCell } from 'src/components/table/HeaderCell'
import { NameCell } from 'src/components/table/NameCell'
import { BorderedRow } from 'src/components/table/BorderedRow'
import { SentimentByMostRated } from 'src/components/table/SentimentByMostRated'
import { MentionCell } from 'src/components/table/MentionCell'
import { numberWithCommas } from 'src/utils/numberFormat'
import { StickyHead } from 'src/components/table/StickyHead'
import CardFilterButton from 'src/components/CardFilterButton'
import { BaseModal } from 'src/containers/BaseModal'
import { UseGetKeywordsAnalysisParams } from 'src/reactQuery/useGetKeywordsAnalysis/interfaces/useGetKeywordsAnalysis.interface'
import styles from './keywordAnalyses.module.scss'
import { FilterModalContent } from './FilterModalContent'

interface KeywordAnalysesProps {
  applyFilters?: (params: {
    amount?: string
    selectedKeywords?: string[]
    type?: string
  }) => void
  isLoading?: boolean
  keywordAnalysesFilters?: UseGetKeywordsAnalysisParams
  keywordsAnalysisData?: AnalyticsKeywordsAnalysisResponse
}

export const KeywordAnalyses: FC<KeywordAnalysesProps> = ({
  keywordsAnalysisData,
  isLoading,
  keywordAnalysesFilters,
  applyFilters,
}) => {
  const allKeywords = keywordsAnalysisData?.allKeywords
  const selectedKeywords = keywordsAnalysisData?.selectedKeywords

  const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false)

  const { data: informationTooltips } = useGetInformationTooltipsQuery()

  const columnHelper =
    createColumnHelper<AnalyticsKeywordsAnalysisResponseItem>()

  const columns = useMemo(
    () => [
      columnHelper.accessor('keyword', {
        cell: (info) => (
          <NameCell>{capitalizeFirstLetter(info.getValue() || '')}</NameCell>
        ),
        header: () => <HeaderCell>{en.keyword}</HeaderCell>,
        id: 'name',
      }),
      columnHelper.accessor('mostlyRated', {
        cell: (info) => (
          <SentimentByMostRated sentiment={info.getValue() || ''} />
        ),
        header: () => <HeaderCell>{en.mostlyRatedAs}</HeaderCell>,
        id: 'mostlyRated',
      }),
      columnHelper.accessor('positives', {
        cell: (info) => {
          const { row } = info

          return (
            <MentionCell
              percentage={row.original.positivePercentage}
              value={info.getValue()}
            />
          )
        },
        header: () => <HeaderCell>{en.positiveMentions}</HeaderCell>,
        id: 'positiveMentions',
      }),
      columnHelper.accessor('neutrals', {
        cell: (info) => {
          const { row } = info

          return (
            <MentionCell
              percentage={row.original.neutralPercentage}
              value={info.getValue()}
            />
          )
        },
        header: () => <HeaderCell>{en.neutralMentions}</HeaderCell>,
        id: 'neutralMentions',
      }),
      columnHelper.accessor('negatives', {
        cell: (info) => {
          const { row } = info

          return (
            <MentionCell
              percentage={row.original.negativePercentage}
              value={info.getValue()}
            />
          )
        },
        header: () => <HeaderCell>{en.negativeMentions}</HeaderCell>,
        id: 'negativeMentions',
      }),
      columnHelper.accessor('total', {
        cell: (info) => (
          <NameCell>{numberWithCommas(info.getValue() || 0)}</NameCell>
        ),
        header: () => <HeaderCell>{en.totalMentions}</HeaderCell>,
        id: 'totalMentions',
      }),
    ],
    []
  )

  const { getHeaderGroups, getRowModel } = useReactTable({
    columns,
    data: allKeywords || [],
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <Card className={styles.keywordAnalyses}>
      <SectionTitle
        endItem={
          <CardFilterButton
            className={styles.filterButton}
            onClick={() => setIsFilterModalOpen(true)}
          />
        }
        isLoading={isLoading}
        title={en.keywordAnalyses}
        tooltipContent={informationTooltips?.keywordAnalyses}
      />

      <div className={styles.tableContent}>
        {allKeywords?.length ? (
          <ScrollBarContainer>
            <table width="100%">
              <StickyHead>
                {getHeaderGroups().map((headerGroup) => (
                  <BorderedRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th align="left" key={header.id}>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </th>
                    ))}
                  </BorderedRow>
                ))}
              </StickyHead>

              <tbody>
                {getRowModel().rows.map((row) => {
                  return (
                    <BorderedRow key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td align="left" key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </BorderedRow>
                  )
                })}
              </tbody>
            </table>
          </ScrollBarContainer>
        ) : (
          <div>
            <div>{isLoading ? en.loading : en.notEnoughDataToDisplay}</div>
          </div>
        )}
      </div>

      <BaseModal isOpen={!!isFilterModalOpen}>
        <FilterModalContent
          allKeywords={allKeywords}
          keywordAnalysesFilters={keywordAnalysesFilters}
          onClose={() => setIsFilterModalOpen(false)}
          onSave={(params) => {
            applyFilters?.(params)

            setIsFilterModalOpen(false)
          }}
          selectedKeywords={selectedKeywords}
        />
      </BaseModal>
    </Card>
  )
}
