import classNames from 'classnames'
import { ChangeEvent, ClipboardEvent, forwardRef, KeyboardEvent } from 'react'
import styles from './autofillInput.module.scss'

interface AutofillInputProps {
  addTag: () => void
  autoFillClassName?: string
  autofilldWord: string
  className?: string
  fillKey: () => void
  inputClassName?: string
  isFullWidth?: boolean
  isInputKeyword?: boolean
  negatives?: string[]
  onChange: (value: string) => void
  onPaste?: (value: string) => void
  placeHolder?: string
  positives?: string[]
  value: string
}

export const AutofillInput = forwardRef<HTMLInputElement, AutofillInputProps>(
  (
    {
      autofilldWord,
      value,
      placeHolder,
      onChange,
      onPaste,
      fillKey,
      addTag,
      className,
      isFullWidth,
      inputClassName,
      autoFillClassName,
      positives,
      negatives,
      isInputKeyword,
    },
    ref
  ) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value)
    }

    const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
      if (!onPaste) {
        return
      }

      onPaste(event.clipboardData.getData('Text'))

      event.stopPropagation()
      event.preventDefault()
    }

    const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      // copy values on ctrl+c and command+c
      const charCode = String.fromCharCode(event.which).toLowerCase()
      if (
        (event.ctrlKey || event.metaKey) &&
        charCode === 'c' &&
        (positives?.length || negatives?.length)
      ) {
        navigator.clipboard.writeText(
          [...(positives || []), ...(negatives || [])].toString()
        )
      }

      if (event.key === 'Enter' || (event.key === ' ' && !isInputKeyword)) {
        addTag()

        event.stopPropagation()
        event.preventDefault()
      }

      if (event.key === 'Tab') {
        fillKey()

        event.stopPropagation()
        event.preventDefault()
      }
    }

    const shouldShowAutofill =
      autofilldWord &&
      value &&
      autofilldWord.length > value.length &&
      autofilldWord.includes(value)

    return (
      <div
        className={classNames(
          styles.autofillInput,
          className,
          isFullWidth && styles.fullWidth
        )}
      >
        {shouldShowAutofill && (
          <div className={classNames(styles.autofill, autoFillClassName)}>
            {autofilldWord}
          </div>
        )}

        <input
          className={classNames(styles.input, inputClassName)}
          onChange={handleChange}
          onKeyDown={onKeyDown}
          onPaste={handlePaste}
          placeholder={placeHolder}
          ref={ref}
          value={value}
        />
      </div>
    )
  }
)

AutofillInput.displayName = 'AutofillInput'
