import { FC, useEffect, useMemo } from 'react'
import classNames from 'classnames'
import en from 'src/constants/en'
import { CampaignType } from 'src/services/__generated__/api'
import { SingleSelect } from 'src/components/Select/SingleSelect'
import { FilterSelect } from 'src/components/FilterSelect'
import { useQueryParams } from 'src/hooks/useQueryParams'
import { FilterSelectOption } from 'src/components/FilterSelect/interfaces/filterSelectOption.interface'
import styles from './keywordsFilter.module.scss'
import { QueryParams } from '../interfaces/queryParams.interface'

interface KeywordsFilterSectionProps {
  campaignsData?: CampaignType[]
  open: boolean
  orderCallback: (val?: string) => void
  orderOptions?: string[]
  themesCallback: (val?: string[]) => void
}

export const KeywordsFilterSection: FC<KeywordsFilterSectionProps> = ({
  open,
  campaignsData,
  orderOptions,
  orderCallback,
  themesCallback,
}) => {
  const { queryParams, updateQueryParams, removeQueryParams } =
    useQueryParams<QueryParams>({
      qsStringifyOptions: {
        arrayFormat: 'comma',
      },
    })

  const campaigns = queryParams.themes || []
  const orderBy = queryParams.orderBy || 'A-Z'

  const options = useMemo<FilterSelectOption[]>(() => {
    return (
      campaignsData?.map((item, index) => ({
        label: item.name || '',
        value: item._id || index.toString(),
      })) || []
    )
  }, [campaignsData])

  const selected = useMemo<FilterSelectOption[]>(() => {
    return options?.filter((item) => campaigns.includes(item.value))
  }, [options, campaigns])

  const onOrderByChange = (newValue: string) => {
    updateQueryParams({
      orderBy: newValue,
    })
  }

  const onCampaignsChange = (selected: string[]) => {
    themesCallback(selected)

    if (!selected.length) {
      removeQueryParams(['themes'])

      return
    }

    updateQueryParams({
      themes: selected,
    })
  }

  useEffect(() => {
    orderCallback(orderBy)
  }, [orderBy])

  useEffect(() => {
    if (!selected.length && !!campaignsData?.length) {
      onCampaignsChange(options.map((item) => item.value))
    }
  }, [selected])

  return (
    <div
      className={classNames(
        styles.keywordsFilter,
        open && styles.keywordsFilterOpen
      )}
    >
      <div className={styles.inner}>
        <div className={styles.orderBy}>
          <div className={styles.sectionTitle}>{en.orderBy}</div>

          <SingleSelect
            onChange={(newValue) => {
              if (newValue) {
                onOrderByChange(newValue.value)
              }
            }}
            options={orderOptions?.map((item) => ({
              label: item,
              value: item,
            }))}
            value={{ label: orderBy, value: orderBy }}
          />
        </div>

        {!!options.length && (
          <div className={styles.right}>
            <div className={styles.sectionTitle}>{en.campaigns}</div>

            <div className={styles.selectWrapper}>
              <FilterSelect
                countLabel={en.campaigns}
                onSelect={(selectedOptions) =>
                  onCampaignsChange(selectedOptions.map((item) => item.value))
                }
                options={options}
                placeholder={en.campaigns}
                selectedOptions={selected}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
