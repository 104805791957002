import {
  faHome,
  faFileAlt,
  faEdit,
  faCog,
  faList,
} from '@fortawesome/free-solid-svg-icons'
import en from 'src/constants/en'
import {
  CAMPAIGNS_ROUTE,
  HOME_ROUTE,
  KEYWORDS_ROUTE,
  SETTINGS_ROUTE,
  THREADS_ROUTE,
} from 'src/constants/routes'

export const SIDEBAR_ITEMS = [
  {
    icon: faHome,
    name: 'Home',
    route: HOME_ROUTE,
  },
  {
    icon: faFileAlt,
    name: en.campaigns,
    route: CAMPAIGNS_ROUTE,
  },
  {
    icon: faEdit,
    name: en.keywords,
    route: KEYWORDS_ROUTE,
  },
  {
    icon: faCog,
    name: en.settings,
    route: SETTINGS_ROUTE,
  },
  {
    icon: faList,
    name: en.threads,
    requiresAdminAccess: true,
    route: THREADS_ROUTE,
  },
]
