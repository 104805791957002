export const CAMPAIGNS_ROUTE = '/campaigns'
export const CAMPAIGN_DETAILS_ROUTE = `${CAMPAIGNS_ROUTE}/:themeId`
export const LOGIN_ROUTE = '/login'
export const SIGNUP_ROUTE = '/signup'
export const KEYWORDS_ROUTE = '/keywords'
export const KEYWORD_DETAILS_ROUTE = `${KEYWORDS_ROUTE}/:themeId/:keywordName`
export const HOME_ROUTE = '/home'
export const SETTINGS_ROUTE = '/settings'
export const NEW_CAMPAIGN_ROUTE = '/new-campaign'
export const UPDATE_CAMPAIGN_BASE_ROUTE = '/update-campaign'
export const UPDATE_CAMPAIGN_ROUTE = `${UPDATE_CAMPAIGN_BASE_ROUTE}/:id`
export const THREADS_ROUTE = '/threads'
