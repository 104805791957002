import { FC } from 'react'
import classNames from 'classnames'
import { ReactComponent as CheckLineSvg } from 'src/assets/svg/check-line.svg'
import { ReactComponent as CheckDoubleLineSvg } from 'src/assets/svg/check-double-line.svg'
import styles from './checkBox.module.scss'

interface CheckBoxProps {
  isChecked: boolean
  isDouble?: boolean
  isPassive?: boolean
  onChange?: (isChecked: boolean) => void
}

export const CheckBox: FC<CheckBoxProps> = ({
  isChecked,
  onChange,
  isPassive,
  isDouble,
}) => {
  return (
    <div
      className={classNames(
        styles.checkBoxWrapper,
        isChecked && styles.checked,
        isPassive && styles.passive,
        isDouble && styles.double
      )}
      onClick={() => {
        if (onChange) {
          onChange(!isChecked)
        }
      }}
    >
      {isChecked &&
        (isDouble ? (
          <CheckDoubleLineSvg className={styles.checkBox} />
        ) : (
          <CheckLineSvg className={styles.checkBox} />
        ))}
    </div>
  )
}
