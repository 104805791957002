import React, { FC, ReactNode } from 'react'
import styles from './borderedRow.module.scss'

interface BorderedRowProps {
  children?: ReactNode
}

export const BorderedRow: FC<BorderedRowProps> = ({ children }) => {
  return <tr className={styles.borderedRow}>{children}</tr>
}
