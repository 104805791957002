import {
  UpdateSelfPasswordResponse,
  UpdateSelfPasswordParams,
} from './interfaces/updateSelfPassword.interface'
import { nApi } from '../index'

export const updateSelfPassword = async (
  params: UpdateSelfPasswordParams
): Promise<UpdateSelfPasswordResponse> => {
  const result = await nApi().post<UpdateSelfPasswordResponse>(
    `/v2_private/auth/self/updatePassword`,
    params
  )

  return result.data
}
