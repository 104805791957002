import React, { FC, ReactNode } from 'react'
import styles from './stickyHead.module.scss'

interface StickyHeadProps {
  children?: ReactNode
}

export const StickyHead: FC<StickyHeadProps> = ({ children }) => {
  return <thead className={styles.stickyHead}>{children}</thead>
}
