import { FC, MouseEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import en from 'src/constants/en'
import { KEYWORDS_ROUTE } from 'src/constants/routes'
import { Tag } from 'src/components/Tag'
import hexToRgbA from 'src/utils/hexToRgba'
import { ReactComponent as Loudspeaker } from 'src/assets/svg/loudspeaker.svg'
import { numberWithCommas } from 'src/utils/numberFormat'
import FireIcon from 'src/assets/svg/fire.svg'
import { getDate } from 'src/utils/date'
import { capitalizeFirstLetter } from 'src/utils/capitalizeFirstLetter'
import { useCustomLink } from 'src/hooks/useCustomLink'
import { CampaignsChart } from 'src/pages/Campaigns/CampaignsChart'
import { COLORS } from 'src/constants/colors'
import { Card } from 'src/components/Card'
import styles from './keywordCardItem.module.scss'
import { FilteredDataItem } from '../interfaces/filteredData.interface'

interface KeywordCardItemProps {
  data: FilteredDataItem
}

const activeTag = {
  backgroundColor: hexToRgbA(COLORS.malibu, 0.2),
  color: COLORS.malibu,
  title: 'Active',
}

const activeMentionsTag = {
  backgroundColor: COLORS.burningOrange,
  color: COLORS.white,
  icon: FireIcon,
  title: 'Active Mentions',
}

const notificationsTag = {
  backgroundColor: hexToRgbA(COLORS.burningOrange, 0.2),
  color: COLORS.burningOrange,
  iconBackgroundColor: COLORS.burningOrange,
  number: 1,
  numberColor: COLORS.white,
  title: 'Notification',
}

export const KeywordCardItem: FC<KeywordCardItemProps> = ({ data }) => {
  const navigate = useNavigate()

  const url = useCustomLink(
    `${KEYWORDS_ROUTE}/${data?.campaignId}/${data?.name}`
  )

  const navigateToCampaign = (event: MouseEvent<HTMLDivElement>) => {
    if (
      (event.target as HTMLDivElement).id !== 'dropdownMenuButton' &&
      (event.target as HTMLDivElement).id !== 'deleteButtonId'
    ) {
      navigate(url)
    }
  }

  return (
    <Card className={styles.keywordCardItem} onClick={navigateToCampaign}>
      <div className={styles.cardInner}>
        <div className={styles.titleWrapper}>
          <div className={styles.title}>{data?.name}</div>

          <div className={styles.subtitleWrapper}>
            <span className={styles.ball}>&#x25cf;</span>

            {data?.campaign}
          </div>
        </div>
      </div>

      <div className={styles.activeMentions}>
        {data?.activeMentions ? (
          <Tag
            backgroundColor={activeMentionsTag.backgroundColor}
            color={activeMentionsTag.color}
            icon={activeMentionsTag.icon}
            key={activeMentionsTag.title}
            title={activeMentionsTag.title}
          />
        ) : null}

        {data?.active ? (
          <Tag
            backgroundColor={activeTag.backgroundColor}
            color={activeTag.color}
            key={activeTag.title}
            title={activeTag.title}
          />
        ) : null}

        {data?.notification ? (
          <Tag
            backgroundColor={notificationsTag.backgroundColor}
            color={notificationsTag.color}
            iconBackgroundColor={notificationsTag.iconBackgroundColor}
            key={notificationsTag.title}
            number={data.notification}
            numberColor={notificationsTag.numberColor}
            title={notificationsTag.title}
          />
        ) : null}
      </div>

      <div className={styles.info}>
        <div className={styles.infoCol}>
          <div className={styles.detailsWrapper}>
            <div className={styles.infoLinesWrapper}>
              <div className={styles.infoTitle}>{en.mentions}</div>

              <div className={styles.infoLine}>
                <div className={styles.themecardKey}>{en.positive}</div>

                <div className={styles.themecardValue}>
                  {numberWithCommas(data?.positive || 0)}
                </div>
              </div>

              <div className={styles.infoLine}>
                <div className={styles.themecardKey}>{en.negative}</div>

                <div className={styles.themecardValue}>
                  {numberWithCommas(data?.negative || 0)}
                </div>
              </div>

              <div className={styles.infoLine}>
                <div className={styles.themecardKey}>{en.total}</div>

                <div className={styles.themecardValue}>
                  {numberWithCommas(data?.mentions || 0)}
                </div>
              </div>
            </div>

            <div className={styles.targetsWrapper}>
              {data?.targets?.map((target) => (
                <div
                  className={styles.target}
                  key={`${target.name}-${target.number}`}
                >
                  <div className={styles.targetLeft}>
                    <Loudspeaker className={styles.targetLeftIcon} />

                    {numberWithCommas(target?.number || 0)}
                  </div>

                  <div className={styles.targetRight}>
                    {capitalizeFirstLetter(target?.name ?? '')}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className={styles.chartWrapper}>
          <CampaignsChart
            negativeMentionsCount={data?.stats?.negativeMentionsCount || 0}
            neutralMentionsCount={data?.stats?.neutralMentionsCount || 0}
            positiveMentionsCount={data?.stats?.positiveMentionsCount || 0}
          />
        </div>
      </div>

      <div className={styles.footer}>
        {data?.createdAt && `${en.createdAt} ${getDate(data?.createdAt)}`}
        &nbsp; | &nbsp;
        {data?.updatedAt && `${en.updatedAt} ${getDate(data?.updatedAt)}`}
      </div>
    </Card>
  )
}
