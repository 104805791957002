import React, { FC } from 'react'
import classNames from 'classnames'
import en from 'src/constants/en'
import { ScrollBarContainer } from 'src/components/ScrollBarContainer'
import { CheckBox } from 'src/components/CheckBox'
import styles from './filterSearchDropdown.module.scss'
import { FilterDropdownSelectOption } from './interfaces/filterDropdownSelectOption.interface'

export interface FilterSearchDropdownProps {
  countLabel?: string
  isAllOptionHidden?: boolean
  onSelect?: (options: FilterDropdownSelectOption[]) => void
  options?: FilterDropdownSelectOption[]
  selectedOptions?: FilterDropdownSelectOption[]
}

export const FilterSearchDropdown: FC<FilterSearchDropdownProps> = ({
  countLabel,
  isAllOptionHidden,
  onSelect,
  options,
  selectedOptions,
}) => {
  const isAllSelected =
    !!selectedOptions?.length && selectedOptions?.length === options?.length

  const handleSelect = (
    option: FilterDropdownSelectOption,
    isActive: boolean
  ) => {
    if (isActive) {
      onSelect?.(
        selectedOptions?.filter(
          (selectedOption) => selectedOption.value !== option.value
        ) || []
      )

      return
    }

    onSelect?.([...(selectedOptions || []), option])
  }

  const handleToggleAll = () => {
    if (isAllSelected) {
      onSelect?.([])

      return
    }

    onSelect?.(options || [])
  }

  return (
    <ScrollBarContainer className={styles.options}>
      {!isAllOptionHidden && !!options?.length && (
        <div
          className={classNames(
            styles.option,
            isAllSelected && styles.optionActive
          )}
          onClick={() => handleToggleAll()}
        >
          <CheckBox isChecked={isAllSelected} isDouble />

          <span className={styles.label}>
            {en.all}&nbsp;{countLabel}
          </span>
        </div>
      )}

      {options?.map((option) => {
        const isActive = !!selectedOptions?.some(
          (selectedOption) => selectedOption.value === option.value
        )

        return (
          <div
            className={classNames(
              styles.option,
              isActive && styles.optionActive
            )}
            key={option.value}
            onClick={() => handleSelect(option, isActive)}
          >
            <CheckBox isChecked={isActive} isPassive={isAllSelected} />

            <span className={styles.label}>{option.label}</span>

            <span>{option.labelAddition}</span>
          </div>
        )
      })}
    </ScrollBarContainer>
  )
}
