import { useState } from 'react'

interface HandleDownloadCallBackResult {
  fileName: string
  url: string
}

interface DownloadFileByUrlValue {
  handleFileDownload: (
    callBack: () => Promise<HandleDownloadCallBackResult>
  ) => Promise<void>
  isLoading?: boolean
}

export const useDownloadFileByUrl = (): DownloadFileByUrlValue => {
  const [isLoading, setIsLoading] = useState(false)

  const handleFileDownload = async (
    callBack: () => Promise<HandleDownloadCallBackResult>
  ) => {
    setIsLoading(true)

    try {
      const { fileName, url } = await callBack()

      const link = document.createElement('a')

      link.href = url

      link.setAttribute('download', fileName)

      document.body.appendChild(link)

      link.click()

      setIsLoading(false)
    } catch (error) {
      throw new Error('Error downloading file')
    }
  }

  return {
    handleFileDownload,
    isLoading,
  }
}
