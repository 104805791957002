import { useQuery } from '@tanstack/react-query'
import { QueryKey } from 'src/reactQuery/enums/queryKey.enum'
import { SuggestionsApi } from 'src/services/suggestionsApi'
import { UseGetSuggestCategoriesQueryParams } from './interfaces/useGetSuggestCategoriesQuery.interface'

const getSuggestionsCategories = async (
  word?: string
): Promise<string[] | undefined> => {
  if (!word) {
    return undefined
  }

  const { data } = await SuggestionsApi.v1.suggestionsCategoriesList({
    word,
  })

  return data.result
}

export const useGetSuggestionsCategoriesQuery = (
  params?: UseGetSuggestCategoriesQueryParams
) => {
  return useQuery({
    queryFn: () => getSuggestionsCategories(params?.word),
    queryKey: [params?.queryKey || QueryKey.SUGGESTIONS_CATEGORIES],
    staleTime: params?.staleTime,
  })
}
