import moment from 'moment'

const nth = (d: number): string => {
  if (d > 3 && d < 21) return 'th'
  switch (d % 10) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}

export function convertToMDY(isoFormat: string) {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const date = new Date(isoFormat)

  return `${monthNames[date.getMonth()]} ${date.getDate()}${nth(
    date.getDate()
  )} ${date.getFullYear()}`
}

export function getDate(ISOstring: string): string | null {
  if (!ISOstring.length) {
    return null
  }

  const date = new Date(ISOstring as any)

  return Intl.DateTimeFormat('en-GB', {
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    month: 'short',
  }).format(date)
}

export function getTimezoneOffsetInHours(): number {
  return moment().utcOffset() / 60
}

export function getEndOfToday(): string {
  return moment().endOf('day').toISOString(true)
}
