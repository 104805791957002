import { FC, useMemo } from 'react'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import 'react-datepicker/dist/react-datepicker.css'
import en from 'src/constants/en'

import { AnalyticsFetchLocationsResponseItemValue } from 'src/services/__generated__/api'
import { ScrollBarContainer } from 'src/components/ScrollBarContainer'
import { capitalizeFirstLetter } from 'src/utils/capitalizeFirstLetter'
import { numberWithCommas } from 'src/utils/numberFormat'
import { NameCell } from 'src/components/table/NameCell'
import { HeaderCell } from 'src/components/table/HeaderCell'
import { BorderedRow } from 'src/components/table/BorderedRow'
import { SentimentByMostRated } from 'src/components/table/SentimentByMostRated'
import { MentionCell } from 'src/components/table/MentionCell'
import { StickyHead } from 'src/components/table/StickyHead'
import styles from './tableContent.module.scss'

interface TableContentProps {
  countries?: AnalyticsFetchLocationsResponseItemValue[]
  isLoading?: boolean
}

export const TableContent: FC<TableContentProps> = ({
  countries,
  isLoading,
}) => {
  const columnHelper =
    createColumnHelper<AnalyticsFetchLocationsResponseItemValue>()

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => (
          <NameCell>{capitalizeFirstLetter(info.getValue() || '')}</NameCell>
        ),
        header: () => <HeaderCell>{en.keyword}</HeaderCell>,
        id: 'name',
      }),
      columnHelper.accessor('mostlyRatedAs', {
        cell: (info) => (
          <SentimentByMostRated sentiment={info.getValue() || ''} />
        ),
        header: () => <HeaderCell>{en.mostlyRatedAs}</HeaderCell>,
        id: 'mostlyRatedAs',
      }),
      columnHelper.accessor('positives', {
        cell: (info) => <MentionCell value={info.getValue()} />,
        header: () => <HeaderCell>{en.positiveMentions}</HeaderCell>,
        id: 'positiveMentions',
      }),
      columnHelper.accessor('neutrals', {
        cell: (info) => <MentionCell value={info.getValue()} />,
        header: () => <HeaderCell>{en.neutralMentions}</HeaderCell>,
        id: 'positiveMentions',
      }),
      columnHelper.accessor('negatives', {
        cell: (info) => <MentionCell value={info.getValue()} />,
        header: () => <HeaderCell>{en.negativeMentions}</HeaderCell>,
        id: 'negativeMentions',
      }),
      columnHelper.accessor('total', {
        cell: (info) => (
          <NameCell>{numberWithCommas(info.getValue() || 0)}</NameCell>
        ),
        header: () => <HeaderCell>{en.totalMentions}</HeaderCell>,
        id: 'totalMentions',
      }),
    ],
    []
  )

  const { getHeaderGroups, getRowModel } = useReactTable({
    columns,
    data: countries || [],
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <div className={styles.tableContent}>
      {countries?.length ? (
        <ScrollBarContainer>
          <table width="100%">
            <StickyHead>
              {getHeaderGroups().map((headerGroup) => (
                <BorderedRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th align="left" key={header.id}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </th>
                  ))}
                </BorderedRow>
              ))}
            </StickyHead>

            <tbody>
              {getRowModel().rows.map((row) => {
                return (
                  <BorderedRow key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td align="left" key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </BorderedRow>
                )
              })}
            </tbody>
          </table>
        </ScrollBarContainer>
      ) : (
        <div>
          <div>{isLoading ? en.loading : en.notEnoughDataToDisplay}</div>
        </div>
      )}
    </div>
  )
}
