import { ORDER_BY } from '../../constants/orderBy'
import { FilteredCampaignsByCategory } from '../../interfaces/filteredCampaignsByCategory.interface'

export const sortFilteredDataByOrder = (
  data: FilteredCampaignsByCategory[],
  orderFilter: string
) => {
  const filterDataCopy: FilteredCampaignsByCategory[] = [...data]

  switch (orderFilter) {
    case ORDER_BY[0]:
      Object.values(filterDataCopy).map((object) => {
        const [val] = Object.values(object)
        const [key] = Object.keys(object)

        const sortedThemes = val.sort((a, b) => {
          const countA = a?.stats?.mentions
          const countB = b?.stats?.mentions

          if (!countA || !countB) {
            return 0
          }

          if (countA > countB) {
            return -1
          }

          if (countB > countA) {
            return 1
          }

          return 0
        })

        return {
          [key]: sortedThemes,
        }
      })

      break

    case ORDER_BY[1]:
      Object.values(filterDataCopy).map((object) => {
        const [val] = Object.values(object)
        const [key] = Object.keys(object)

        const sortedThemes = val.sort((a, b) => {
          const countA = a?.stats?.positiveMentionsCount
          const countB = b?.stats?.positiveMentionsCount

          if (!countA || !countB) {
            return 0
          }

          if (countA > countB) {
            return -1
          }

          if (countB > countA) {
            return 1
          }

          return 0
        })

        return {
          [key]: sortedThemes,
        }
      })

      break

    case ORDER_BY[2]:
      Object.values(filterDataCopy).map((object) => {
        const [val] = Object.values(object)
        const [key] = Object.keys(object)

        const sortedThemes = val.sort((a, b) => {
          const countA = a?.stats?.negativeMentionsCount
          const countB = b?.stats?.negativeMentionsCount

          if (!countA || !countB) {
            return 0
          }

          if (countA > countB) {
            return -1
          }

          if (countB > countA) {
            return 1
          }

          return 0
        })

        return {
          [key]: sortedThemes,
        }
      })

      break

    case ORDER_BY[3]:
      Object.values(filterDataCopy).map((object) => {
        const [val] = Object.values(object)
        const [key] = Object.keys(object)

        const sortedThemes = val.sort((a, b) => {
          if (a.createdAt && b.createdAt) {
            const dateA = a.createdAt
            const dateB = b.createdAt

            if (dateA < dateB) {
              return -1
            }

            if (dateB < dateA) {
              return 1
            }
          }

          return 0
        })

        return {
          [key]: sortedThemes,
        }
      })

      break

    case ORDER_BY[4]:
      break

    case ORDER_BY[5]:
      Object.values(filterDataCopy).map((object) => {
        const [val] = Object.values(object)
        const [key] = Object.keys(object)

        const sortedThemes = val.sort((a, b) => {
          if (a.name && b.name) {
            const nameA = a.name.toLowerCase()
            const nameB = b.name.toLowerCase()

            if (nameA < nameB) {
              return -1
            }

            if (nameA > nameB) {
              return 1
            }
          }

          return 0
        })

        return {
          [key]: sortedThemes,
        }
      })

      break

    case ORDER_BY[6]:
      Object.values(filterDataCopy).map((object) => {
        const [val] = Object.values(object)
        const [key] = Object.keys(object)

        const sortedThemes = val.sort((a, b) => {
          if (a.from && a.to && b.from && b.to) {
            const aFrom = +new Date(a.from)
            const aTo = +new Date(a.to)
            const bFrom = +new Date(b.from)
            const bTo = +new Date(b.to)
            const aDiff = aTo - aFrom
            const bDiff = bTo - bFrom

            if (aDiff < bDiff) {
              return 1
            }

            if (aDiff > bDiff) {
              return -1
            }
          }

          return 0
        })

        return {
          [key]: sortedThemes,
        }
      })

      break

    default:
  }
}
