import { EChartOption } from 'echarts'
import { FC, useEffect, useMemo, useState } from 'react'
import { COLORS } from 'src/constants/colors'
import en from 'src/constants/en'
import { EChart } from 'src/charts/EChart'
import { CampaignChartDataItem } from './interfaces/campaignChartDataItem.interface'
import styles from './campaignsChart.module.scss'

interface CampaignsChartProps {
  negativeMentionsCount: number
  neutralMentionsCount: number
  positiveMentionsCount: number
}

export const CampaignsChart: FC<CampaignsChartProps> = ({
  negativeMentionsCount,
  neutralMentionsCount,
  positiveMentionsCount,
}) => {
  const [chartData, setChartData] = useState<CampaignChartDataItem[]>([])
  const [label, setLabel] = useState<number>()

  const total =
    negativeMentionsCount + neutralMentionsCount + positiveMentionsCount

  const maxRatioPercent = total
    ? Math.max(
        (positiveMentionsCount / total) * 100,
        (negativeMentionsCount / total) * 100,
        (neutralMentionsCount / total) * 100
      )
    : 0

  const options: EChartOption<EChartOption.SeriesPie> = useMemo(() => {
    const options: EChartOption<EChartOption.SeriesPie> = {
      color: [COLORS.malibu, COLORS.burningOrange, COLORS.grey200],
      grid: {
        left: 'center',
      },
      series: [
        {
          avoidLabelOverlap: true,
          data: chartData,
          emphasis: {
            // @ts-ignore-next-line
            scale: false,
          },
          height: 122,
          itemStyle: {
            borderColor: COLORS.white,
            borderWidth: 3,
          },
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          left: 'center',
          radius: ['75%', '100%'],
          top: 'middle',

          type: 'pie',
          width: 122,
        },
      ],
      tooltip: {
        formatter: '{b}: {c}',
        trigger: 'item',
      },
    }

    return options
  }, [chartData])

  useEffect(() => {
    setChartData([
      { name: en.positive, value: positiveMentionsCount },
      { name: en.negative, value: negativeMentionsCount },
      { name: en.neutral, value: neutralMentionsCount },
    ])

    setLabel(maxRatioPercent)
  }, [negativeMentionsCount, neutralMentionsCount, positiveMentionsCount])

  return options ? (
    <div className={styles.campaignsChart}>
      <div className={styles.label}>{label?.toFixed(0)}%</div>

      <EChart
        onMouseOut={() => setLabel(maxRatioPercent)}
        onMouseOver={(event) => setLabel(event.percent)}
        options={options}
      />
    </div>
  ) : null
}
