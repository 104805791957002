import { FC, useEffect, useState } from 'react'
import { EChartOption } from 'echarts'
import { COLORS } from 'src/constants/colors'
import { numberWithCommas } from 'src/utils/numberFormat'
import en from 'src/constants/en'
import { useGetInformationTooltipsQuery } from 'src/reactQuery/useGetInformationTooltips'
import { AnalyticsFetchCampaignsCalendarResponse } from 'src/services/__generated__/api'
import { SectionTitle } from 'src/components/SectionTitle'
import styles from './mentionsByMonth.module.scss'
import { EchartLabelItem } from './interfaces/echartLabelItem.interface'
import { EChart } from '../EChart'

interface MentionsByMonthProps {
  chartData?: AnalyticsFetchCampaignsCalendarResponse
  isLoading?: boolean
}

const visualMapPieces = [
  {
    color: COLORS.malibu,
    max: 10,
    min: 8,
  },
  {
    color: 'rgba(10, 18, 214, 0.6)',
    max: 7,
    min: 3,
  },
  {
    color: 'rgba(10, 18, 214, 0.3)',
    max: 3,
    min: 1,
  },
  {
    color: '#F3F5F5',
    max: 0.99,
    min: -0.99,
  },
  {
    color: 'rgba(255, 104, 51, 0.3)',
    max: -1,
    min: -3,
  },
  {
    color: 'rgba(255, 104, 51, 0.6)',
    max: -3,
    min: -7,
  },
  {
    color: 'rgba(255, 104, 51, 1)',
    max: -8,
    min: -10,
  },
]

export const MentionsByMonth: FC<MentionsByMonthProps> = ({
  chartData,
  isLoading,
}) => {
  const { data: informationTooltips } = useGetInformationTooltipsQuery()

  const [options, setOptions] = useState<any>(null)

  useEffect(() => {
    function getOptions() {
      const data = chartData?.sentiments?.map((item) => {
        return [item[0], item[1], item[2], item[3] || '-']
      })

      const options: EChartOption<EChartOption.SeriesGraph> = {
        series: [
          {
            // @ts-ignore-next-line
            data,
            emphasis: {
              itemStyle: {
                borderWidth: 0,
              },
            },
            itemStyle: {
              borderColor: COLORS.white,
              borderWidth: 4,
            },
            label: {
              color: COLORS.black,
              fontSize: 14,
              fontWeight: 500,
              formatter: (data: EchartLabelItem) => {
                return data.value[2] ? numberWithCommas(data.value[2]) : ''
              },
              lineHeight: 16,
              show: true,
            },
            name: 'Punch Card',
            type: 'heatmap',
          },
        ],
        visualMap: [
          {
            bottom: 16,
            calculable: true,
            color: visualMapPieces.map((item) => item.color),
            hoverLink: false,
            itemHeight: 212,
            left: 'center',
            max: 10,
            min: -10,
            orient: 'horizontal',
            textStyle: {
              // hide text
              color: 'rgba(255, 255, 255, 0)',
            },
          },
        ],
        xAxis: {
          axisLabel: {
            color: '#000',
            fontSize: 14,
            fontWeight: 500,
            interval: 0,
            lineHeight: 16.41,
            margin: 10,
            show: true,
          },
          axisLine: {
            show: false,
          },
          axisTick: { show: false },
          data: chartData?.xAxis,
          position: 'top',
          splitArea: {
            show: true,
          },
          type: 'category',
        },
        yAxis: {
          axisLabel: {
            color: COLORS.shark,
            fontSize: 14,
            fontWeight: 400,
            interval: 0,
            lineHeight: 16.41,
            margin: 15,
            show: true,
          },
          axisLine: {
            show: false,
          },
          axisTick: { show: false },
          data: chartData?.yAxis,
          splitArea: {
            show: true,
          },
          type: 'category',
        },
      }

      setOptions(options)
    }

    if (chartData) {
      getOptions()
    }
  }, [chartData])

  return (
    <div className={styles.mentionsByMonth}>
      <SectionTitle
        isLoading={isLoading}
        title={en.mentionsByMonth}
        tooltipContent={informationTooltips?.mentionsByMonth}
      />

      <div className={styles.chartContainer}>
        {!!options && <EChart options={options} />}
      </div>

      <div className={styles.labels}>
        <span>{en.negative}</span>

        <span>{en.positive}</span>
      </div>
    </div>
  )
}
