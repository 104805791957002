import { useState } from 'react'
import { toast } from 'react-toastify'
import { MAX_CAMPAIGN_NAME_LENGTH } from 'src/constants/campaigns'
import en from 'src/constants/en'

const useCampaignNameInput = (
  initialValue: string
): [string, (value: string) => void] => {
  const [campaignName, setCampaignName] = useState<string>(initialValue || '')

  const onCampaignNameChange = (value: string): void => {
    if (value.length > MAX_CAMPAIGN_NAME_LENGTH) {
      toast.error(en.CAMPAIGN_ERROR_NAME_TOO_LONG)
      return
    }

    setCampaignName(value)
  }

  return [campaignName, onCampaignNameChange]
}

export default useCampaignNameInput
