import { FC, MouseEvent, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  CAMPAIGNS_ROUTE,
  UPDATE_CAMPAIGN_BASE_ROUTE,
} from 'src/constants/routes'
import { getDate } from 'src/utils/date'
import { Tag } from 'src/components/Tag'
import en from 'src/constants/en'
import { useUpdateCampaignMutation } from 'src/reactQuery/useUpdateCampaignMutation'
import { ReactComponent as Loudspeaker } from 'src/assets/svg/loudspeaker.svg'
import { CampaignsChart } from 'src/pages/Campaigns/CampaignsChart'
import hexToRgbA from 'src/utils/hexToRgba'
import MenuThreeDotsIcon from 'src/assets/svg/menuThreeDots.svg'
import { useCustomLink } from 'src/hooks/useCustomLink'
import { capitalizeFirstLetter } from 'src/utils/capitalizeFirstLetter'
import { CampaignType } from 'src/services/__generated__/api'
import { COLORS } from 'src/constants/colors'
import useOutsideClick from 'src/hooks/useOutsideClick'
import { SOCIAL_ICONS } from 'src/constants/socialIcons'
import { numberWithCommas } from 'src/utils/numberFormat'
import { StatusPill } from './interfaces/statusPill.interface'
import styles from './campaignCard.module.scss'

const STATUS_PILLS: StatusPill[] = [
  {
    backgroundColor: hexToRgbA(COLORS.malibu, 0.2),
    color: COLORS.malibu,
    title: 'Active',
  },
  {
    backgroundColor: hexToRgbA(COLORS.malibu, 0.2),
    color: COLORS.malibu,
    title: 'Draft',
  },
  {
    backgroundColor: hexToRgbA(COLORS.malibu, 0.2),
    color: COLORS.malibu,
    title: 'Completed',
  },
]

interface CampaignCardProps {
  campaignRefetch: () => void
  data: CampaignType
  locationRefetch: () => void
  onDelete: () => void
  onRename: () => void
  positiveRefetch: () => void
  sourcesRefetch: () => void
}

const CampaignCard: FC<CampaignCardProps> = ({
  data,
  campaignRefetch,
  positiveRefetch,
  locationRefetch,
  sourcesRefetch,
  onDelete,
  onRename,
}) => {
  const dropDownRef = useRef<HTMLDivElement>(null)

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  useOutsideClick({
    onOutsideClick: () => {
      setIsDropdownOpen(false)
    },
    ref: dropDownRef,
  })

  const navigate = useNavigate()

  const themeDetailLink = useCustomLink(`${CAMPAIGNS_ROUTE}/${data?._id}`)

  const updatePageLink = useCustomLink(
    `${UPDATE_CAMPAIGN_BASE_ROUTE}/${data?._id}`
  )

  const campaignMutation = useUpdateCampaignMutation({
    onSuccess() {
      campaignRefetch()
      positiveRefetch()
      locationRefetch()
      sourcesRefetch()
    },
    updateType: 'complete',
  })

  const {
    keywordsCount,
    negativeMentionsCount,
    neutralMentionsCount,
    positiveMentionsCount,
    topSources,
    topKeywords,
  } = data?.stats ?? {}

  const navigateToDetailPage = (event: MouseEvent<HTMLDivElement>) => {
    if (
      (event.target as HTMLDivElement).id !== 'dropdownMenuButton' &&
      (event.target as HTMLDivElement).id !== 'deleteButtonId' &&
      (event.target as HTMLDivElement).id !== 'updateButtonId'
    ) {
      navigate(themeDetailLink)
    }
  }

  const completeCampaign = () => {
    const campaignData = { ...data }
    delete campaignData._id

    campaignMutation.mutate({
      data: {
        ...campaignData,
        status: 'completed',
        to: new Date().toISOString(),
      },
      id: data._id,
    })
  }

  const statusItem: StatusPill | undefined = data?.status
    ? STATUS_PILLS.find((item) => item.title?.toLowerCase() === data.status)
    : undefined

  return (
    <div className={styles.campaignCard} onClick={navigateToDetailPage}>
      <div className={styles.cardTitleRow}>
        <div className={styles.campaignNameAndCategories}>
          <span className={styles.campaignName}>{data?.name}</span>

          <div className={styles.categories}>
            {data?.categories?.join(' • ')}
          </div>
        </div>

        {data?.status?.toLowerCase() !== 'completed' && (
          <div className={styles.dropdown} ref={dropDownRef}>
            <button
              className={styles.dropdownButton}
              onClick={(e) => {
                e.stopPropagation()

                setIsDropdownOpen((isDropdownOpen) => !isDropdownOpen)
              }}
            >
              <img alt="menu icon" src={MenuThreeDotsIcon} />
            </button>

            {isDropdownOpen && (
              <div
                className={styles.dropdownMenu}
                onClick={(e) => {
                  e.stopPropagation()
                }}
              >
                <div className={styles.dropdownItem} onClick={() => onRename()}>
                  {en.rename}
                </div>

                {data?.status?.toLowerCase() === 'active' && (
                  <div
                    className={styles.dropdownItem}
                    onClick={completeCampaign}
                  >
                    {en.complete}
                  </div>
                )}

                <Link className={styles.dropdownItem} to={updatePageLink}>
                  {en.edit}
                </Link>

                <div className={styles.dropdownItem} onClick={() => onDelete()}>
                  {en.delete}
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <div className={styles.statusTag}>
        {statusItem && (
          <Tag
            backgroundColor={statusItem.backgroundColor}
            color={statusItem.color}
            icon={statusItem.icon ?? undefined}
            iconBackgroundColor={statusItem.iconBackgroundColor ?? undefined}
            key={statusItem.title}
            number={statusItem.number ?? undefined}
            numberColor={statusItem.numberColor ?? undefined}
            title={statusItem.title}
          />
        )}
      </div>

      <div className={styles.content}>
        <div className={styles.contentWrapper}>
          <div className={styles.keywordsSection}>
            <span className={styles.keywordsTitle}>{en.keywords}</span>
            &nbsp;
            <span className={styles.keywordsCount}>{keywordsCount}</span>
          </div>

          <div className={styles.mentionsAndTopSources}>
            {!!topSources?.length && (
              <div className={styles.section}>
                <div className={styles.title}>{en.topSources}</div>

                <div className={styles.sectionList}>
                  {topSources?.map(({ source, mentions }) => (
                    <div
                      className={styles.sectionListItem}
                      key={`${source}-${mentions}`}
                    >
                      <span className={styles.sourceIcon}>
                        {source && SOCIAL_ICONS[source.toLowerCase()]}
                      </span>

                      <span className={styles.label}>
                        {capitalizeFirstLetter(source ?? '')}{' '}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {!!topKeywords?.length && (
              <div className={styles.section}>
                <div className={styles.title}>{en.topKeywords}</div>

                <div className={styles.sectionList}>
                  {topKeywords?.map((keywordItem) => (
                    <div
                      className={styles.sectionListItem}
                      key={`${keywordItem.keyword}-${keywordItem.mentions}`}
                    >
                      <span className={styles.label}>
                        {keywordItem.keyword}
                        &nbsp;•&nbsp;
                      </span>
                      <span className={styles.labelCount}>
                        {numberWithCommas(keywordItem.mentions || 0)}
                      </span>
                      &nbsp;
                      <Loudspeaker className={styles.loudSpeakerIcon} />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={styles.chartWrapper}>
          <CampaignsChart
            negativeMentionsCount={negativeMentionsCount || 0}
            neutralMentionsCount={neutralMentionsCount || 0}
            positiveMentionsCount={positiveMentionsCount || 0}
          />
        </div>
      </div>

      <div className={styles.dates}>
        {data?.createdAt && `Created at ${getDate(data?.createdAt)}`}

        <span>|</span>

        {data?.updatedAt && `Updated at ${getDate(data?.updatedAt)}`}
      </div>
    </div>
  )
}

export default CampaignCard
