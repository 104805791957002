import { useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import en from 'src/constants/en'
import { FetchCampaignByIdResponse } from 'src/services/__generated__/api'
import { AnalyticsApi } from 'src/services/analyticsApi'
import {
  UpdateCampaignParams,
  UpdateCampaignType,
  UseUpdateCampaignMutationParams,
} from './interfaces/useUpdateCampaign.interfaces'

const updateCampaignData = async (
  params: UpdateCampaignParams
): Promise<FetchCampaignByIdResponse | undefined> => {
  if (!params.id) return undefined

  const { data } = await AnalyticsApi.v1Private.campaignUpdate(
    params.id,
    params.data
  )

  return data
}

const UPDATE_TYPES_TO_SUCCESS_TOAST_MESSAGES_MAP: {
  [key in UpdateCampaignType]: string
} = {
  complete: en.COMPLETE_CAMPAIGN_SUCCESS_MESSAGE,
  saveAsDraft: en.SAVE_CAMPAIGN_AS_DRAFT_SUCCESS_MESSAGE,
  update: en.UPDATE_CAMPAIGN_SUCCESS_MESSAGE,
}

export const useUpdateCampaignMutation = ({
  onError,
  onSuccess,
  updateType,
}: UseUpdateCampaignMutationParams) => {
  const notifySuccess = () =>
    toast.success(UPDATE_TYPES_TO_SUCCESS_TOAST_MESSAGES_MAP[updateType])

  const notifyError = () => toast.error(en.somethingWentWrong)

  return useMutation({
    mutationFn: updateCampaignData,
    onError: () => {
      if (onError) {
        onError()
        notifyError()
      }
    },
    onSuccess: () => {
      if (onSuccess) {
        onSuccess()
        notifySuccess()
      }
    },
  })
}
