import { ORDER_BY } from '../../constants/orderBy'
import { FilteredDataItem } from '../../interfaces/filteredData.interface'

export const sortFilteredDataByOrder = (
  data: FilteredDataItem[],
  orderFilter: string
): FilteredDataItem[] | undefined => {
  const filterDataCopy = data.slice()

  switch (orderFilter) {
    case ORDER_BY[0]:
      return filterDataCopy.sort((a, b) => {
        const countA = a.mentions
        const countB = b.mentions

        if (!countA || !countB) {
          return 0
        }

        if (countA > countB) {
          return -1
        }

        if (countB > countA) {
          return 1
        }

        return 0
      })

    case ORDER_BY[1]:
      return filterDataCopy.sort((a, b) => {
        const countA = a.stats?.positiveMentionsCount
        const countB = b.stats?.positiveMentionsCount

        if (!countA || !countB) {
          return 0
        }

        if (countA > countB) {
          return -1
        }

        if (countB > countA) {
          return 1
        }

        return 0
      })

    case ORDER_BY[2]:
      return filterDataCopy.sort((a, b) => {
        const countA = a.stats?.negativeMentionsCount
        const countB = b.stats?.negativeMentionsCount

        if (!countA || !countB) {
          return 0
        }

        if (countA > countB) {
          return -1
        }

        if (countB > countA) {
          return 1
        }

        return 0
      })

    case ORDER_BY[3]:
      return filterDataCopy.sort((a, b) => {
        const dateA = a.createdAt
        const dateB = b.createdAt

        if (!dateA || !dateB) {
          return 0
        }

        if (dateA < dateB) {
          return -1
        }

        if (dateB < dateA) {
          return 1
        }

        return 0
      })

    case ORDER_BY[4]:
      // to be added later
      break

    case ORDER_BY[5]:
      return filterDataCopy.sort((a, b) => {
        const nameA = a.name?.toLowerCase()
        const nameB = b.name?.toLowerCase()

        if (!nameA || !nameB) {
          return 0
        }

        if (nameA < nameB) {
          return -1
        }

        if (nameA > nameB) {
          return 1
        }

        return 0
      })

    case ORDER_BY[6]:
      return filterDataCopy.sort((a, b) => {
        if (!a.from || !a.to || !b.from || !b.to) {
          return 0
        }

        const aFrom = +new Date(a.from)
        const aTo = +new Date(a.to)
        const bFrom = +new Date(b.from)
        const bTo = +new Date(b.to)
        const aDiff = aTo - aFrom
        const bDiff = bTo - bFrom

        if (aDiff < bDiff) {
          return 1
        }

        if (aDiff > bDiff) {
          return -1
        }

        return 0
      })

    default:
      return undefined
  }

  return undefined
}
