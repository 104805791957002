import { FC, ReactNode } from 'react'
import classnames from 'classnames'
import { ReactComponent as ArrowDownSvg } from 'src/assets/svg/arrow-down.svg'
import styles from './clampedParagraph.module.scss'

interface ClampedParagraphProps {
  children: ReactNode
  isExpanded: boolean
  switchExpandedState: () => void
}

export const ClampedParagraph: FC<ClampedParagraphProps> = ({
  children,
  isExpanded,
  switchExpandedState,
}) => {
  return (
    <div
      className={classnames(
        styles.clampedParagraph,
        !isExpanded && styles.clamp
      )}
      onClick={switchExpandedState}
    >
      <div
        className={classnames(styles.paragraph, !isExpanded && styles.clamp)}
      >
        {children}
      </div>

      <ArrowDownSvg
        className={classnames(styles.arrow, isExpanded && styles.rotate)}
      />
    </div>
  )
}
