import { useQuery } from '@tanstack/react-query'
import { QueryKey } from 'src/reactQuery/enums/queryKey.enum'
import { AnalyticsApi } from 'src/services/analyticsApi'
import { AnalyticsFetchLocationsResponse } from 'src/services/__generated__/api'
import { UseGetLocationsQueryParams } from './interfaces/useGetLocationsQuery.interface'

const getLocations = async (
  params?: UseGetLocationsQueryParams
): Promise<AnalyticsFetchLocationsResponse | undefined> => {
  const { data } =
    await AnalyticsApi.v1Private.analyticsFetchLocationsList(params)

  return data
}

export const useGetLocationsQuery = (params?: UseGetLocationsQueryParams) => {
  return useQuery({
    queryFn: () => getLocations(params),
    queryKey: [
      QueryKey.GET_LOCATIONS,
      ...(params ? Object.values(params) : []),
    ],
  })
}
