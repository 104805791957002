import { FC } from 'react'
import { AppRouter } from './AppRouter'
import styles from './app.module.scss'
import NotificationMessages from '../components/NotificationMessages'
import { useGetSelfQuery } from '../reactQuery/useGetSelfQuery'

export const App: FC = () => {
  const { isLoading } = useGetSelfQuery()

  return (
    <div className={styles.app}>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          <AppRouter />

          <NotificationMessages />
        </>
      )}
    </div>
  )
}
