import { ReactNode } from 'react'
import { ReactComponent as FacebookSvg } from 'src/assets/svg/facebook.svg'
import { ReactComponent as InstagramSvg } from 'src/assets/svg/instagram.svg'
import { ReactComponent as TwitterSvg } from 'src/assets/svg/twitter.svg'
import { ReactComponent as YoutubeSvg } from 'src/assets/svg/youtube.svg'
import { ReactComponent as LinkedinSvg } from 'src/assets/svg/linkedin.svg'
import { ReactComponent as RedditSvg } from 'src/assets/svg/reddit.svg'
import { ReactComponent as EarthSvg } from 'src/assets/svg/earth.svg'
import { ReactComponent as GoogleSvg } from 'src/assets/svg/google.svg'
import { ReactComponent as TripadvisorSvg } from 'src/assets/svg/tripadvisor.svg'
import { ReactComponent as TiktokSvg } from 'src/assets/svg/tiktok.svg'
import { ReactComponent as BookingSvg } from 'src/assets/svg/booking.svg'
import { ReactComponent as YandexSvg } from 'src/assets/svg/yandex.svg'

export const SOCIAL_ICONS: Record<string, ReactNode> = {
  'News & Blogs': <EarthSvg />,
  booking: <BookingSvg />,
  earth: <EarthSvg />,
  facebook: <FacebookSvg />,
  google: <GoogleSvg />,
  'google reviews': <GoogleSvg />,
  instagram: <InstagramSvg />,
  linkedin: <LinkedinSvg />,
  reddit: <RedditSvg />,
  tiktok: <TiktokSvg />,
  tripadvisor: <TripadvisorSvg />,
  twitter: <TwitterSvg />,
  yandex: <YandexSvg />,
  youtube: <YoutubeSvg />,
}
