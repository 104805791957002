import { useQuery } from '@tanstack/react-query'
import { QueryKey } from 'src/reactQuery/enums/queryKey.enum'
import { AnalyticsApi } from 'src/services/analyticsApi'
import { KeywordFetchKeywordsWithStats } from 'src/services/__generated__/api'
import { UseGetKeywordsWithStatsQueryParams } from './interfaces/useGetKeywordsWithStatsQuery.interface'

const getKeywordsWithStats = async (
  start?: string,
  end?: string
): Promise<KeywordFetchKeywordsWithStats[] | null> => {
  const { data } =
    await AnalyticsApi.v1Private.keywordFetchKeywordsWithStatsList({
      fromDate: start,
      toDate: end,
    })

  return data
}

export const useGetKeywordsWithStatsQuery = (
  params: UseGetKeywordsWithStatsQueryParams
) => {
  return useQuery({
    queryFn: () => getKeywordsWithStats(params.start, params.end),
    queryKey: [QueryKey.GET_KEYWORDS_WITH_STATS, params.start, params.end],
  })
}
