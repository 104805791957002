import { useQuery } from '@tanstack/react-query'
import { QueryKey } from 'src/reactQuery/enums/queryKey.enum'
import { AnalyticsApi } from 'src/services/analyticsApi'
import { AnalyticsFetchMostMentionsResponse } from 'src/services/__generated__/api'
import { UseGetMostMentionsParams } from './interfaces/useGetMostMentionsQuery.interface'

const getMostMentions = async (
  start?: string,
  end?: string
): Promise<AnalyticsFetchMostMentionsResponse | null> => {
  const { data } = await AnalyticsApi.v1Private.analyticsFetchMostMentionsList({
    fromDate: start,
    toDate: end,
  })

  return data
}

export const useGetMostMentionsQuery = (params: UseGetMostMentionsParams) => {
  return useQuery({
    queryFn: () => getMostMentions(params.start, params.end),
    queryKey: [QueryKey.GET_MOST_MENTIONS, params.start, params.end],
  })
}
