import React, { FC, ReactNode } from 'react'
import en from 'src/constants/en'
import { Card } from 'src/components/Card'
import Button from 'src/components/Button'
import { ReactComponent as CloseSvg } from 'src/assets/svg/close.svg'
import { ReactComponent as RotateLeftSvg } from 'src/assets/svg/rotate-left.svg'
import styles from './filterModalContentWrapper.module.scss'

export interface FilterModalContentWrapperProps {
  children?: ReactNode
  onClose: () => void
  onReset?: () => void
  onSave: () => void
}

export const FilterModalContentWrapper: FC<FilterModalContentWrapperProps> = ({
  onSave,
  onClose,
  onReset,
  children,
}) => {
  return (
    <Card className={styles.filterModalContentWrapper}>
      <div className={styles.header}>
        <div className={styles.title}>{en.filter}</div>

        <CloseSvg className={styles.closeButton} onClick={() => onClose()} />
      </div>

      <div className={styles.content}>{children}</div>

      <div className={styles.buttons}>
        <button className={styles.resetButton} onClick={() => onReset?.()}>
          <RotateLeftSvg className={styles.resetIcon} />

          {en.resetFilter}
        </button>

        <button className={styles.cancel} onClick={() => onClose()}>
          {en.cancel}
        </button>

        <Button className={styles.confirm} onClick={() => onSave()}>
          {en.save}
        </Button>
      </div>
    </Card>
  )
}
