import { FC } from 'react'
import Tooltip from 'rc-tooltip'
import styles from './tag.module.scss'

interface TagProps {
  backgroundColor?: string
  color?: string
  cursor?: string
  fontSize?: string
  fontWeight?: number
  handleClick?: (value?: string) => void
  height?: string
  icon?: string
  iconBackgroundColor?: string
  lineHeight?: string
  marginRight?: string
  marginTop?: string
  number?: number
  numberColor?: string
  padding?: string
  quantity?: string
  title?: string
  tooltipContent?: string
}

export const Tag: FC<TagProps> = ({
  color,
  backgroundColor,
  title,
  icon,
  iconBackgroundColor,
  number,
  numberColor,
  padding,
  height,
  fontWeight,
  fontSize,
  lineHeight,
  marginRight,
  marginTop,
  cursor,
  handleClick,
  tooltipContent,
  quantity,
}) => {
  function handleClickButton(): void {
    const value = title ?? undefined

    if (handleClick) {
      handleClick(value)
    }
  }

  return (
    <Tooltip
      mouseLeaveDelay={0}
      overlay={<div className={styles.tooltip}>{tooltipContent}</div>}
      placement="top"
      trigger={tooltipContent ? ['hover'] : []}
    >
      <div
        className={styles.tag}
        onClick={handleClickButton}
        style={{
          backgroundColor,
          color,
          cursor: cursor ?? 'auto',
          fontSize: fontSize ?? 'inherit',
          fontWeight: fontWeight ?? 400,
          height: height ?? '26px',
          lineHeight: lineHeight ?? '14.06px',
          marginRight: marginRight ?? '8px',
          marginTop: marginTop ?? '0px',
          padding: padding || '6px 8px',
          paddingLeft: icon || number ? '3px' : 'auto',
        }}
      >
        <span
          className={styles.tagIcon}
          style={{
            backgroundColor: iconBackgroundColor ?? 'transparent',
            color: numberColor ?? '',
            display: icon || number ? 'inline-flex' : 'none',
          }}
        >
          {icon && <img alt="icon" src={icon} />}

          {number || null}
        </span>
        {title}{' '}
        {quantity && quantity !== '0' && (
          <span className={styles.quantity}>
            <p>{quantity}</p>
          </span>
        )}
      </div>
    </Tooltip>
  )
}
