import { PasswordErrors } from '../interfaces/error'

export const validatePassword = (
  password?: string | null
): {
  errors: PasswordErrors[]
} => {
  const _password = password || ''

  const errors = []

  if (!password) {
    errors.push(PasswordErrors.EMPTY)
  }
  if (_password.length < 8) {
    errors.push(PasswordErrors.SHORT)
  }

  return {
    errors,
  }
}

export const validateEmail = (email?: string | null): boolean =>
  !!email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
