import {
  LogInData,
  LogInResponse,
  LogInVariables,
} from './interfaces/logIn.interface'
import { nApi } from '../index'

export const logIn = async (
  email: string,
  password: string
): Promise<LogInData> => {
  const { data } = await nApi().post<LogInVariables, LogInResponse>(
    `/v2/auth/login`,
    {
      email,
      password,
    }
  )

  return data
}
