import { useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import en from 'src/constants/en'
import {
  CampaignPayload,
  FetchCampaignByIdResponse,
} from 'src/services/__generated__/api'
import { AnalyticsApi } from 'src/services/analyticsApi'
import { UseCreateCampaignMutationParams } from './interfaces/useCreateCampaign.interfaces'

const createCampaign = async (
  payload: CampaignPayload
): Promise<FetchCampaignByIdResponse | undefined> => {
  const { data } = await AnalyticsApi.v1Private.campaignCreate(payload)

  return data
}

export const useCreateCampaignMutation = ({
  onError,
  onSuccess,
}: UseCreateCampaignMutationParams = {}) => {
  const notifySuccess = (status: CampaignPayload['status']) => {
    toast.success(
      status === 'active'
        ? en.CREATE_CAMPAIGN_SUCCESS_MESSAGE
        : en.SAVE_CAMPAIGN_AS_DRAFT_SUCCESS_MESSAGE
    )
  }

  const notifyError = () => {
    toast.error(en.CREATE_CAMPAIGN_ERROR_MESSAGE)
  }

  return useMutation({
    mutationFn: createCampaign,
    onError: () => {
      if (onError) {
        onError()
        notifyError()
      }
    },
    onSuccess: (data) => {
      if (onSuccess) {
        onSuccess()

        if (data?.campaign?.status) {
          notifySuccess(data.campaign.status)
        }
      }
    },
  })
}
