import { FC, MouseEvent } from 'react'
import classNames from 'classnames'
import EyeIcon from 'src/assets/svg/eye.svg'
import EyeClosedIcon from 'src/assets/svg/eyeclosed.svg'
import Button from 'src/components/Button'
import styles from './passwordVisibilityButton.module.scss'

interface PasswordVisibilityButtonProps {
  className?: string
  toggleVisibility: (newVisibility: boolean) => void
  visibility: boolean
}

const PasswordVisibilityButton: FC<PasswordVisibilityButtonProps> = ({
  className,
  toggleVisibility,
  visibility,
}) => {
  const clickHandler = (e: MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()

    toggleVisibility(!visibility)
  }

  return (
    <Button
      background="transparent"
      className={classNames(styles.visibilityButton, className)}
      onClick={(e) => clickHandler(e)}
      shape="square"
    >
      <img
        alt="password visibility"
        src={visibility ? EyeIcon : EyeClosedIcon}
      />
    </Button>
  )
}

export default PasswordVisibilityButton
