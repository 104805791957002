import { useQuery } from '@tanstack/react-query'
import { QueryKey } from 'src/reactQuery/enums/queryKey.enum'
import { AnalyticsApi } from 'src/services/analyticsApi'
import {
  AnalyticsFetchThreadsIsLostType,
  AnalyticsFetchThreadsResponse,
  AnalyticsFetchThreadsSentimentType,
  AnalyticsFetchThreadsVectorType,
} from 'src/services/__generated__/api'
import { UseGetPositiveSentimentAnalysisThreadsQueryParams } from './interfaces/useGetPositiveSentimentAnalysisThreadsQuery.interface'

const getPositiveSentimentAnalysisThreads = async (
  campaign?: string,
  keyword?: string,
  lastDate?: string,
  limit?: number,
  selectedKeywords?: string,
  selectedSources?: string,
  fromDate?: string,
  toDate?: string,
  isLost?: AnalyticsFetchThreadsIsLostType,
  languages?: string,
  mentionFormat?: string
): Promise<AnalyticsFetchThreadsResponse | null> => {
  if (!lastDate || !limit) {
    return null
  }

  const { data } = await AnalyticsApi.v1Private.analyticsFetchThreadsList({
    campaign,
    fromDate,
    isLost,
    keyword,
    languages,
    lastDate,
    limit,
    mentionFormat,
    selectedKeywords,
    selectedSources,
    sentiment: AnalyticsFetchThreadsSentimentType.POS,
    toDate,
    vector: AnalyticsFetchThreadsVectorType.Desc,
  })

  return data ?? null
}

export const useGetPositiveSentimentAnalysisThreadsQuery = (
  params: UseGetPositiveSentimentAnalysisThreadsQueryParams
) => {
  return useQuery({
    queryFn: () =>
      getPositiveSentimentAnalysisThreads(
        params.campaign,
        params.keyword,
        params.lastDate,
        params.limit,
        params.selectedKeywords,
        params.selectedSources,
        params.fromDate,
        params.toDate,
        params.isLost,
        params.selectedLanguages,
        params.selectedMentionFormats
      ),
    queryKey: [
      QueryKey.GET_POS_SENTIMENT_ANALYSIS_THREADS,
      ...Object.values(params),
    ],
    refetchInterval: Infinity,
  })
}
