export const removeFromArray = <T>(array: T[], val: T): T[] => {
  const duplicateArray = array.slice()

  for (let i = 0; i < duplicateArray.length; i++) {
    if (duplicateArray[i] === val) {
      duplicateArray.splice(i, 1)
      i--
    }
  }

  return duplicateArray
}
