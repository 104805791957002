import { FC } from 'react'
import classNames from 'classnames'
import { getNumberOrZero } from 'src/utils/getNumberOrZero'
import { numberWithCommas } from 'src/utils/numberFormat'
import { ReactComponent as Loudspeaker } from 'src/assets/svg/loudspeaker.svg'
import styles from './mentionItem.module.scss'

interface MentionItemProps {
  className?: string
  totalMentions: number
}

export const MentionItem: FC<MentionItemProps> = ({
  totalMentions,
  className,
}) => {
  return (
    <div className={classNames(styles.mentionItem, className)}>
      &nbsp;&#x2022;&nbsp;
      <span>{numberWithCommas(getNumberOrZero(totalMentions))}</span>
      <Loudspeaker />
    </div>
  )
}
