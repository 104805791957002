import React, { FC } from 'react'
import classNames from 'classnames'
import { labelByMentionType } from 'src/utils/labelByMentionType'
import styles from './sentimentByMostRated.module.scss'

interface SentimentByMostRatedProps {
  sentiment: string
}

export const SentimentByMostRated: FC<SentimentByMostRatedProps> = ({
  sentiment,
}) => {
  return (
    <span
      className={classNames(
        styles.sentimentByMostRated,
        styles[sentiment.slice(0, 3)?.toLowerCase()]
      )}
    >
      {labelByMentionType(sentiment)}
    </span>
  )
}
