export const insertIntoArrayAtGivenIndex = <T>(
  array: T[],
  index: number,
  ...items: T[]
): T[] => {
  array.splice(index, 0, ...items)

  return array
}

export const groupElementsThatMatchPredicateInArray = <T>(
  array: T[],
  predicate: (element: T) => boolean
): T[] => {
  const firstMatchIndex = array.findIndex(predicate)

  const arrayBeforeMatch = array.slice(0, firstMatchIndex)
  const arrayAfterMatch = array.slice(firstMatchIndex)

  arrayAfterMatch.sort((a, b) => {
    const aMatches = predicate(a)
    const bMatches = predicate(b)

    if (aMatches === bMatches) {
      return 0
    }

    if (aMatches && !bMatches) {
      return -1
    }

    return 1
  })

  return [...arrayBeforeMatch, ...arrayAfterMatch]
}
