import { useQuery } from '@tanstack/react-query'
import { QueryKey } from 'src/reactQuery/enums/queryKey.enum'
import { AnalyticsApi } from 'src/services/analyticsApi'
import { KeywordFetchDailyTrends } from 'src/services/__generated__/api'
import { UseGetDailyTrendsByKeywordQueryParams } from './interfaces/useGetDailyTrendsByKeywordQuery.interface'

const getDailyTrendsByKeyword = async (
  campaign?: string,
  keyword?: string,
  start?: string,
  end?: string
): Promise<KeywordFetchDailyTrends | undefined> => {
  const { data } = await AnalyticsApi.v1Private.keywordFetchDailyTrendsList({
    campaign,
    fromDate: start,
    keyword,
    toDate: end,
  })

  return data
}

export const useGetDailyTrendsByKeywordQuery = (
  params: UseGetDailyTrendsByKeywordQueryParams
) => {
  return useQuery({
    queryFn: () =>
      getDailyTrendsByKeyword(
        params.campaign,
        params.keyword,
        params.start,
        params.end
      ),
    queryKey: [QueryKey.GET_DAILY_TRENDS_BY_KEYWORD, ...Object.values(params)],
  })
}
