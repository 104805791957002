import { LocalStorageKey } from '../enums/localStorageKey.enum'

export const getDataFromLocalStorage = <T>(
  localStorageKey: LocalStorageKey
): T | string | undefined => {
  const data = localStorage.getItem(localStorageKey)

  if (!data) {
    return undefined
  }

  try {
    return JSON.parse(data)
  } catch (error) {
    return data
  }
}
