import { useQuery } from '@tanstack/react-query'
import { QueryKey } from 'src/reactQuery/enums/queryKey.enum'
import { AnalyticsApi } from 'src/services/analyticsApi'
import { CampaignFetchTopSourceData } from 'src/services/__generated__/api'
import { UseGetCampaignTopSourceDataQuery } from './interfaces/useGetCampaignTopSourceParams.interface'

const getCampaignTopLocation = async (
  start?: string,
  end?: string
): Promise<CampaignFetchTopSourceData | undefined> => {
  const { data } = await AnalyticsApi.v1Private.campaignFetchTopSourceList({
    fromDate: start,
    toDate: end,
  })

  return data
}

export const useGetCampaignTopSourceQuery = (
  params: UseGetCampaignTopSourceDataQuery
) => {
  return useQuery({
    queryFn: () => getCampaignTopLocation(params.start, params.end),
    queryKey: [QueryKey.GET_CAMPAIGN_TOP_SOURCE],
  })
}
