import { useQuery } from '@tanstack/react-query'
import { QueryKey } from 'src/reactQuery/enums/queryKey.enum'
import { AnalyticsApi } from 'src/services/analyticsApi'
import { FetchCampaignByIdResponse } from 'src/services/__generated__/api'
import { UseGetCampaignByIdQueryParams } from './interfaces/useGetCampaignByIdQuery.interface'

const getCampaignById = async (
  id?: string
): Promise<FetchCampaignByIdResponse | undefined> => {
  if (!id) return undefined

  const { data } = await AnalyticsApi.v1Private.campaignDetail(id)

  return data
}

export const useGetCampaignByIdQuery = (
  params: UseGetCampaignByIdQueryParams
) => {
  return useQuery({
    queryFn: () => getCampaignById(params.id),
    queryKey: [QueryKey.GET_CAMPAIGN_BY_ID, params.id],
  })
}
