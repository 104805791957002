import { useMutation } from '@tanstack/react-query'
import { AnalyticsApi } from 'src/services/analyticsApi'

export interface MakeThreadIrrelevantResponse {
  success?: boolean
}

export interface useMakeThreadIrrelevantMutationParams {
  onError?: () => void
  onSuccess?: () => void
}

const makeThreadsIrrelevant = async (
  ids: string[]
): Promise<MakeThreadIrrelevantResponse | undefined> => {
  const { data } =
    await AnalyticsApi.v1Private.campaignMakeThreadIrrelevantCreate({
      ids,
    })
  return data
}

export const useMakeIrrelevantMutation = ({
  onError,
  onSuccess,
}: useMakeThreadIrrelevantMutationParams = {}) => {
  return useMutation({
    mutationFn: makeThreadsIrrelevant,
    onError: () => {
      if (onError) {
        onError()
      }
    },
    onSuccess: () => {
      if (onSuccess) {
        onSuccess()
      }
    },
  })
}
