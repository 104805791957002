import { FC } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import { useQueryParams } from 'src/hooks/useQueryParams'
import { GlobalQueryParams } from 'src/interfaces/globalQueryParams.interface'
import { createLink } from 'src/utils/createLink'

interface CustomLinkProps {
  children: LinkProps['children']
  className?: string
  inlineStyle?: any
  link: string
}

const CustomLink: FC<CustomLinkProps> = ({
  className,
  inlineStyle,
  link,
  children,
}) => {
  const { queryParams } = useQueryParams<GlobalQueryParams>()

  return (
    <Link
      className={className}
      style={inlineStyle}
      to={createLink(link, {
        queryParams: {
          from: queryParams.from,
          period: queryParams.period,
          to: queryParams.to,
        },
      })}
    >
      {children}
    </Link>
  )
}

export default CustomLink
