export const FB_SUGGESTIONS = ['https://www.facebook.com/pagename/']

export const IG_SUGGESTIONS = ['@pagename']

export const TWITTER_SUGGESTIONS = ['https://x.com/username']

export const REDDIT_SUGGESTIONS = ['https://reddit.com/r/subreddit']

export const YOUTUBE_SUGGESTIONS = ['https://youtube.com/@channel']

export const NEWS_SUGGESTIONS = ['https://www.nytimes.com/']

export const GOOGLE_SUGGESTIONS = ['https://www.google.com/search?q=topic']

export const HACKERNEWS_SUGGESTIONS = [
  'https://news.ycombinator.com/item?id=story_id',
]

export const WEBSITES_SUGGESTIONS = ['https://www.example.com']

export const TRIPADVISOR_SUGGESTIONS = [
  'https://www.tripadvisor.com/Attraction_Review-g12345',
]

export const TIKTOK_SUGGESTIONS = ['https://www.tiktok.com/@username']

export const BOOKING_SUGGESTIONS = [
  'https://www.booking.com/hotel/location.html',
]

export const YANDEX_SUGGESTIONS = ['https://yandex.com/search/?text=query']

export const ENTER_KEY_CODE = 13

export const SPACE_KEY_CODE = 32

export const MAX_CAMPAIGN_NAME_LENGTH = 50

export const TARGETS = [
  'Facebook',
  'Instagram',
  'Twitter',
  'Reddit',
  'Youtube',
  'News & Blogs',
  'Google',
  'Hackernews',
  'Websites',
  'Google Reviews',
  'Tripadvisor',
  'TikTok',
  'Booking',
  'Yandex',
]

export const INPUT_PROPS = {
  className: 'tags-input tags-input-positive',
  placeholder: '',
}

export const INPUT_PROPS_POSITIVE = {
  className: 'tags-input tags-input-positive',
  placeholder: '',
}

export const INPUT_PROPS_NEGATIVE = {
  className: 'tags-input tags-input-negative',
  placeholder: '',
}

export const LETTERS_NUMBERS_AND_SPACES_REGEX =
  /^(?!.*?(\d)\1)[a-zA-Z0-9 Ⴀ-ჯ]+(?:-[a-zA-Z0-9 Ⴀ-ჯ]+){0,2}$/

export const MAX_TAGS = 35

export const MAX_KEYWORD_SENTIMENTS = 20

export const FB_TARGET_ENDPOINT_VALIDATION_REGEX =
  // eslint-disable-next-line no-useless-escape
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
