import { useQuery } from '@tanstack/react-query'
import { QueryKey } from 'src/reactQuery/enums/queryKey.enum'
import { AnalyticsApi } from 'src/services/analyticsApi'
import { AnalyticsFetchCampaignsCalendarResponse } from 'src/services/__generated__/api'
import { UseGetCalendarByCampaignQueryParams } from './interfaces/useGetCalendarByCampaignQuery.interface'

const getCalendarByCampaign = async (
  campaignId?: string,
  fromDate?: string,
  toDate?: string
): Promise<AnalyticsFetchCampaignsCalendarResponse | null> => {
  if (!campaignId) {
    return null
  }

  const { data } =
    await AnalyticsApi.v1Private.analyticsFetchCampaignsCalendarList({
      campaign: campaignId,
      fromDate,
      toDate,
    })

  return data
}

export const useGetCalendarByCampaignQuery = (
  params: UseGetCalendarByCampaignQueryParams
) => {
  return useQuery({
    enabled: !!params.campaign,
    queryFn: () =>
      getCalendarByCampaign(params.campaign, params.fromDate, params.toDate),
    queryKey: [
      QueryKey.GET_CALENDAR_BY_CAMPAIGN,
      params.campaign,
      params.fromDate,
      params.toDate,
    ],
  })
}
