import { useMutation } from '@tanstack/react-query'
import { AnalyticsApi } from 'src/services/analyticsApi'

export interface reportThreadResponse {
  success?: boolean
}

export interface useReportThreadMutationParams {
  onError?: () => void
  onSuccess?: () => void
}

const reportThread = async ({
  ids,
  message,
}: {
  ids: string[]
  message: string
}): Promise<reportThreadResponse | undefined> => {
  const { data } = await AnalyticsApi.v1Private.campaignReportThreadCreate({
    ids,
    message,
  })
  return data
}

export const useReportThreadMutation = ({
  onError,
  onSuccess,
}: useReportThreadMutationParams = {}) => {
  return useMutation({
    mutationFn: reportThread,
    onError: () => {
      if (onError) {
        onError()
      }
    },
    onSuccess: () => {
      if (onSuccess) {
        onSuccess()
      }
    },
  })
}
