import { FC, useEffect, useState } from 'react'
import { EChartOption } from 'echarts'
import en from 'src/constants/en'
import { AnalyticsFetchRadarChartResponse } from 'src/services/__generated__/api'
import { addThreeDotsToString } from 'src/utils/addThreeDotsToString'
import { numberWithCommas } from 'src/utils/numberFormat'
import { capitalizeFirstLetter } from 'src/utils/capitalizeFirstLetter'
import { COLORS } from 'src/constants/colors'
import { useGetInformationTooltipsQuery } from 'src/reactQuery/useGetInformationTooltips'
import { SectionTitle } from 'src/components/SectionTitle'
import { tooltipFormatterParams } from './interfaces/tooltipFormatterParams.interface'
import styles from './radarChartComponent.module.scss'
import { EChart } from '../EChart'

export interface RadarChartProps {
  chartData?: AnalyticsFetchRadarChartResponse
  isLoading?: boolean
}

const COLOR_SCHEME = [
  'rgba(37,38, 45, 0.15)',
  'rgba(37,38, 45, 0.085)',
  'rgba(37,38, 45, 0.06)',
  'rgba(37,38, 45, 0.04)',
  'rgba(37,38, 45, 0.03)',
]

const TOOLTIP_COLOR_PALETTE = [
  COLORS.malibu,
  COLORS.burningOrange,
  COLORS.periwinkle,
  COLORS.darkBlue,
  COLORS.pinkLace,
  COLORS.redOrange,
]

const ADDED_AMOUNT = 1

export const RadarChartComponent: FC<RadarChartProps> = ({
  chartData,
  isLoading,
}) => {
  const { data: informationTooltips } = useGetInformationTooltipsQuery()

  const [options, setOptions] = useState<EChartOption | null>(null)

  const [isInsufficientData, setIsInsufficientData] = useState<boolean>(false)

  useEffect(() => {
    if (!Array.isArray(chartData?.result)) {
      return
    }

    if (chartData?.result && chartData.result.length < 3) {
      setIsInsufficientData(true)
      setOptions(null)
      return
    }

    setIsInsufficientData(false)

    const positiveValues =
      chartData?.result?.map((item) => (item.positives ?? 0) + ADDED_AMOUNT) ||
      []

    const negativeValues =
      chartData?.result?.map((item) => (item.negatives ?? 0) + ADDED_AMOUNT) ||
      []

    const biggestNumber = Math.max(...positiveValues, ...negativeValues)

    setOptions({
      animation: true,
      legend: {
        bottom: 20,
        data: [
          {
            // @ts-ignore-next-line
            itemStyle: {
              color: COLORS.malibu,
            },
            name: 'Positive',
          },
          {
            // @ts-ignore-next-line
            itemStyle: {
              color: COLORS.burningOrange,
            },
            name: 'Negative',
          },
        ],
        icon: 'circle',
        itemGap: 24,
        itemHeight: 8,
        itemWidth: 22,
        selectedMode: false,
        textStyle: {
          color: COLORS.shark,
          fontFamily: 'Roboto',
          fontSize: 14,
          fontWeight: 500,
          lineHeight: 16,
          verticalAlign: 'middle',
        },
      },
      radar: {
        axisLine: {
          lineStyle: {
            color: COLORS.shark,
            dashOffset: 10,
            opacity: '0.3',
            type: [10, 10],
          },
        },
        axisNameGap: 8,
        center: ['50%', '45%'],
        indicator: chartData?.result.map((item) => ({
          max: biggestNumber,
          name: item.campaign,
        })),
        name: {
          formatter: (value: string) => {
            const LINE_CHARACTER_LIMIT = 15
            const LINE_LIMIT = 2

            const name = capitalizeFirstLetter(
              addThreeDotsToString(value, LINE_CHARACTER_LIMIT * LINE_LIMIT - 3)
            )

            if (name.length <= LINE_CHARACTER_LIMIT) {
              return name
            }

            const lineCount = name.length / LINE_CHARACTER_LIMIT
            const textLines = []

            for (let i = 0; i < lineCount; i++) {
              textLines.push(
                name.slice(
                  i * LINE_CHARACTER_LIMIT,
                  i === lineCount - 1
                    ? undefined
                    : (i + 1) * LINE_CHARACTER_LIMIT
                )
              )
            }

            return textLines.join('\n')
          },
          rich: {},
          textStyle: {
            color: COLORS.shark,
            fontFamily: 'Roboto',
            fontSize: 9,
            fontWeight: 400,
            lineHeight: '10.5',
          },
        },
        radius: 95,
        shape: 'polygon',
        splitArea: {
          areaStyle: {
            color: COLOR_SCHEME.slice(0, chartData?.result.length),
          },
        },
        splitLine: {
          show: false,
        },
        splitNumber: 4,
        startAngle: 270,
      },
      series: [
        {
          data: [
            {
              areaStyle: {
                color: 'rgba(128, 128, 255, 0.4)',
              },
              lineStyle: {
                color: COLORS.malibu,
                width: 1,
              },
              name: 'Positive',
              tooltip: {
                borderColor: COLORS.malibu,
              },
              value: positiveValues,
            },
            {
              areaStyle: {
                color: 'rgba(255, 104, 51, 0.4)',
              },
              lineStyle: {
                color: COLORS.burningOrange,
                width: 1,
              },
              name: 'Negative',
              tooltip: {
                borderColor: COLORS.burningOrange,
              },
              value: negativeValues,
            },
          ],
          name: 'Radar Chart',
          symbolSize: 0,
          type: 'radar',
        },
      ],
      tooltip: {
        formatter: (
          params: tooltipFormatterParams | tooltipFormatterParams[]
        ) => {
          if (Array.isArray(params)) {
            return ''
          }

          let result: string = `<div class=${styles.tooltip}>`

          params?.data?.value.forEach((item, index, arr) => {
            const selectedCampaignName = capitalizeFirstLetter(
              chartData?.result?.[index].campaign ?? ''
            )
            const amount = numberWithCommas(item - ADDED_AMOUNT)
            const color =
              TOOLTIP_COLOR_PALETTE[index % TOOLTIP_COLOR_PALETTE.length]

            result += `
                <div class="${styles.ball}" style="background: ${color}"></div>
                ${selectedCampaignName}: ${amount}
                ${index !== arr.length - 1 ? '<br />' : ''}`
          })

          result += '</div>'

          return result
        },
        show: true,
        transitionDuration: 0.2,
        trigger: 'item',
        triggerOn: 'mousemove',
      },
    })
  }, [chartData])

  return (
    <div className={styles.radarChartComponent}>
      <SectionTitle
        isLoading={isLoading}
        title={en.radarChart}
        tooltipContent={informationTooltips?.radarChart}
      />

      {isInsufficientData ? (
        <div className={styles.insufficientData}>
          {en.notEnoughDataToDisplay}
        </div>
      ) : (
        options && <EChart options={options} />
      )}
    </div>
  )
}
