import moment from 'moment'
import { FilterPeriod } from './enums/filterPeriod.enum'
import { PeriodOptions } from './interfaces/periodOptions.interface'
import en from '../en'

export const CONST_PERIOD_OPTIONS: PeriodOptions = {
  [FilterPeriod.LAST_30_DAYS]: {
    end: moment(),
    start: moment().subtract(29, 'days'),
  },
  [FilterPeriod.LAST_7_DAYS]: {
    end: moment(),
    start: moment().subtract(6, 'days'),
  },
  [FilterPeriod.LAST_90_DAYS]: {
    end: moment(),
    start: moment().subtract(89, 'days'),
  },
  [FilterPeriod.TODAY]: {
    end: moment().endOf('day'),
    start: moment().startOf('day'),
  },
  [FilterPeriod.YESTERDAY]: {
    end: moment().subtract(1, 'day').endOf('day'),
    start: moment().subtract(1, 'day').startOf('day'),
  },
  [FilterPeriod.LIFETIME]: undefined,
  [FilterPeriod.CUSTOM]: undefined,
}

export const PERIOD_OPTIONS_TO_LABELS: Record<FilterPeriod, string> = {
  [FilterPeriod.LAST_30_DAYS]: en.last30Days,
  [FilterPeriod.LAST_7_DAYS]: en.last7Days,
  [FilterPeriod.LAST_90_DAYS]: en.last90Days,
  [FilterPeriod.TODAY]: en.today,
  [FilterPeriod.YESTERDAY]: en.yesterday,
  [FilterPeriod.LIFETIME]: en.lifetime,
  [FilterPeriod.CUSTOM]: en.custom,
}
