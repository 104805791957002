import { EChartOption } from 'echarts'
import { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { EChart } from 'src/charts/EChart'
import { COLORS } from 'src/constants/colors'
import en from 'src/constants/en'
import { getNumberOrZero } from 'src/utils/getNumberOrZero'
import {
  KeywordFetchSentimentAnalysis,
  AnalyticsFetchSentimentAnalysisResponseData,
} from 'src/services/__generated__/api'
import styles from './sentimentAnalysesChart.module.scss'

interface SentimentAnalysesChartProps {
  chartData?:
    | AnalyticsFetchSentimentAnalysisResponseData
    | KeywordFetchSentimentAnalysis
  children?: ReactNode
}

export const SentimentAnalysesChart: FC<SentimentAnalysesChartProps> = ({
  chartData,
  children,
}) => {
  const [label, setLabel] = useState<number>()

  const maxRatioPercentage = Math.max(
    getNumberOrZero(chartData?.positivePercentage),
    getNumberOrZero(chartData?.negativePercentage),
    getNumberOrZero(chartData?.neutralPercentage)
  )

  const options: EChartOption<EChartOption.SeriesPie> | undefined =
    useMemo(() => {
      if (!chartData) {
        return undefined
      }

      const pieData = [
        {
          name: en.positive,
          percentage: chartData.positivePercentage,
          // @ts-ignore-next-line
          value: chartData.positives ?? chartData.positiveMentionsCount,
        },
        {
          name: en.negative,
          percentage: chartData.negativePercentage,
          // @ts-ignore-next-line
          value: chartData.negatives ?? chartData.negativeMentionsCount,
        },
        {
          name: en.neutral,
          percentage: chartData.neutralPercentage,
          // @ts-ignore-next-line
          value: chartData.neutrals ?? chartData.neutralMentionsCount,
        },
      ]

      const options: EChartOption<EChartOption.SeriesPie> = {
        color: [COLORS.malibu, COLORS.burningOrange, COLORS.grey200],
        grid: {
          height: '436',
          left: 'center',
          width: '100%',
        },
        legend: {
          align: 'left',
          animation: true,
          bottom: 0,
          data: [en.positive, en.negative, en.neutral],
          icon: 'circle',
          itemGap: 20,
          orient: 'horizontal',
          selectedMode: false,
          show: true,
          width: '400',
        },
        series: [
          {
            avoidLabelOverlap: true,
            data: pieData,
            emphasis: {
              // @ts-ignore-next-line
              scale: false,
            },
            itemStyle: {
              borderColor: COLORS.white,
              borderWidth: 5,
            },
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            left: 90,
            radius: ['70%', '100%'],
            type: 'pie',
            width: 274,
          },
        ],
        tooltip: {
          formatter: '{b} : {c}',
          trigger: 'item',
        },
      }

      return options
    }, [chartData])

  useEffect(() => {
    setLabel(maxRatioPercentage)
  }, [maxRatioPercentage])

  return (
    <div className={styles.sentimentAnalysesChart}>
      <div className={styles.inner}>
        {options ? (
          <EChart
            onMouseOut={() => setLabel(maxRatioPercentage)}
            onMouseOver={(event) => setLabel(event.percent)}
            options={options}
          />
        ) : null}

        <div className={styles.label}>{label?.toFixed(1)}%</div>
      </div>

      <div className={styles.rightWrapper}>{children}</div>
    </div>
  )
}
