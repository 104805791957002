import { FC, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import en from 'src/constants/en'
import { useGetInformationTooltipsQuery } from 'src/reactQuery/useGetInformationTooltips'
import { AnalyticsFetchLocationsResponse } from 'src/services/__generated__/api'
import { SectionTabs } from 'src/components/SectionTabs'
import { SectionTab } from 'src/components/SectionTabs/interfaces/sectionTab.interface'
import { SectionTitle } from 'src/components/SectionTitle'
import CardFilterButton from 'src/components/CardFilterButton'
import { BaseModal } from 'src/containers/BaseModal'
import styles from './locationChartSection.module.scss'
import { LocationSectionTab } from './enums/locationSectionTab.enum'
import { MapContent } from './MapContent'
import { TableContent } from './TableContent'
import { MAX_ZOOM_IN } from './constants/zoom'
import { FilterModalContent } from './FilterModalContent'

interface LocationChartSectionProps {
  chartData?: AnalyticsFetchLocationsResponse
  isFullWidth?: boolean
  isLoading?: boolean
  onZoomChange?: (zoom: number) => void
  themeName?: string
  visibleTabs?: LocationSectionTab[]
}

export const LocationChartSection: FC<LocationChartSectionProps> = ({
  chartData,
  themeName,
  isFullWidth,
  onZoomChange,
  isLoading,
  visibleTabs,
}) => {
  const { data: informationTooltips } = useGetInformationTooltipsQuery()

  const [activeTab, setActiveTab] = useState<LocationSectionTab>(
    LocationSectionTab.CHART
  )

  const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false)

  const allLocations = useMemo(() => {
    return Object.values(chartData?.locations || {})
  }, [chartData])

  const initialSelectedLocations = useMemo(() => {
    return allLocations?.map((location) => location.name || '') || []
  }, [allLocations])

  const [selectedLocations, setSelectedLocations] = useState<string[]>(
    initialSelectedLocations
  )

  const filteredLocations = useMemo(() => {
    return allLocations?.filter((location) =>
      selectedLocations?.includes(location.name || '')
    )
  }, [allLocations, selectedLocations])

  const tabs: SectionTab[] = [
    {
      label: en.locationChartUsingMap,
      tooltip: informationTooltips?.locationChart,
      value: LocationSectionTab.CHART,
    },
    {
      label: en.locationChartUsingList,
      tooltip: informationTooltips?.locationTable,
      value: LocationSectionTab.TABLE,
    },
  ]

  useEffect(() => {
    if (initialSelectedLocations.length) {
      setSelectedLocations(initialSelectedLocations)
    }
  }, [initialSelectedLocations.toString()])

  return (
    <>
      <div
        className={classNames(
          styles.locationChartSection,
          isFullWidth && styles.fullWidth
        )}
      >
        {visibleTabs?.length === 2 ? (
          <SectionTabs
            activeTab={activeTab}
            endItem={
              <CardFilterButton
                className={styles.filterButton}
                onClick={() => setIsFilterModalOpen(true)}
              />
            }
            isLoading={isLoading}
            onTabClick={(tab) => {
              if (tab.value === LocationSectionTab.TABLE) {
                onZoomChange?.(MAX_ZOOM_IN)
              }

              setActiveTab(tab.value as LocationSectionTab)
            }}
            tabs={tabs}
          />
        ) : (
          <SectionTitle
            isLoading={isLoading}
            title={en.locationChart}
            tooltipContent={informationTooltips?.locationChart}
          />
        )}

        <div className={styles.content}>
          {activeTab === LocationSectionTab.CHART ? (
            <MapContent
              campaignName={themeName}
              locations={filteredLocations}
              onZoomChange={onZoomChange}
              totalMentions={chartData?.totalMentions}
            />
          ) : (
            <TableContent countries={filteredLocations} isLoading={isLoading} />
          )}
        </div>
      </div>

      <BaseModal isOpen={!!isFilterModalOpen}>
        <FilterModalContent
          allLocations={allLocations}
          onClose={() => setIsFilterModalOpen(false)}
          onSave={(selectedLocations) => {
            setSelectedLocations(selectedLocations || [])

            setIsFilterModalOpen(false)
          }}
          selectedLocations={selectedLocations}
        />
      </BaseModal>
    </>
  )
}
