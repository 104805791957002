import { useQuery } from '@tanstack/react-query'
import { QueryKey } from 'src/reactQuery/enums/queryKey.enum'
import { AnalyticsApi } from 'src/services/analyticsApi'
import { CampaignFetchTopLocationData } from 'src/services/__generated__/api'
import { UseGetCampaignTopLocationParams } from './interfaces/useGetCampaignTopLocationParams.interface'

const getCampaignTopLocation = async (
  start?: string,
  end?: string
): Promise<CampaignFetchTopLocationData | undefined> => {
  const { data } = await AnalyticsApi.v1Private.campaignFetchTopLocationList({
    fromDate: start,
    toDate: end,
  })

  return data
}

export const useGetCampaignTopLocationQuery = (
  params: UseGetCampaignTopLocationParams
) => {
  return useQuery({
    queryFn: () => getCampaignTopLocation(params.start, params.end),
    queryKey: [QueryKey.GET_CAMPAIGN_TOP_LOCATION],
  })
}
