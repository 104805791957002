import { useMutation } from '@tanstack/react-query'
import { logIn } from 'src/services/napi/logIn'
import { LogInData } from 'src/services/napi/logIn/interfaces/logIn.interface'
import {
  HandleLogInParams,
  UseLoginMutationParams,
} from './interfaces/useLogInMutation.interfaces'

const handleLogIn = async ({
  username,
  password,
}: HandleLogInParams): Promise<LogInData> => {
  const data = await logIn(username, password)

  return data
}

export const useLoginMutation = ({
  onError,
  onSuccess,
}: UseLoginMutationParams) => {
  return useMutation({
    mutationFn: handleLogIn,
    onError: (error: { response: Response }) => {
      onError?.(error)
    },
    onSuccess: (data) => {
      onSuccess?.(data)
    },
  })
}
