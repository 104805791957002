import { FC } from 'react'
import classNames from 'classnames'
import { mostMentionLabelByMentionType } from 'src/utils/mostMentionLabelByMentionType'
import styles from './mostMentionItem.module.scss'

interface MostMentionItemProps {
  sentiment: string
}

export const MostMentionItem: FC<MostMentionItemProps> = ({ sentiment }) => {
  return (
    <span
      className={classNames(
        styles.mostMentionItem,
        styles[sentiment.slice(0, 3)?.toLowerCase()]
      )}
    >
      &nbsp;&#x2022;&nbsp;
      {mostMentionLabelByMentionType(sentiment)}
    </span>
  )
}
