import { FC } from 'react'
import { getNumberOrZero } from 'src/utils/getNumberOrZero'
import { getPercentage } from 'src/utils/getPercentage'
import en from 'src/constants/en'
import { numberWithCommas } from 'src/utils/numberFormat'
import { ReactComponent as Loudspeaker } from 'src/assets/svg/loudspeaker.svg'
import styles from './totalMentionPercentage.module.scss'

interface TotalMentionPercentageProps {
  overallMentions: number
  totalMentions: number
}

export const TotalMentionPercentage: FC<TotalMentionPercentageProps> = ({
  overallMentions,
  totalMentions,
}) => {
  return (
    <div className={styles.totalMentionPercentage}>
      <div className={styles.details}>
        <span>
          {getPercentage(
            getNumberOrZero(totalMentions),
            getNumberOrZero(overallMentions)
          )}{' '}
          | {numberWithCommas(getNumberOrZero(totalMentions))}
        </span>

        <Loudspeaker />
      </div>

      <div className={styles.label}>{en.ofTotalMentions}</div>
    </div>
  )
}
