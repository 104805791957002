import { HAPTTIC_X_API_KEY } from 'src/constants/env'
import { getSessionIdFromLocalStorage } from 'src/localStorage/sessionId/getSessionIdFromLocalStorage/getSessionIdFromLocalStorage'
import { ANALYTICS_API_URL } from 'src/services/constants/urls'
import { Api } from 'src/services/__generated__/api'

export const getAdminApiHeaders = () => {
  return {
    'Access-Control-Allow-Origin': ANALYTICS_API_URL,
    Vary: 'Origin',
    'X-Api-Key': HAPTTIC_X_API_KEY,
    'x-session-id': getSessionIdFromLocalStorage() || '',
  }
}

export class AdminApi {
  private static _instance: AdminApi

  private api: Api<unknown>

  private constructor() {
    this.api = new Api({
      baseApiParams: {
        credentials: 'same-origin',
        headers: getAdminApiHeaders(),
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
      },
      baseUrl: ANALYTICS_API_URL,
    })
  }

  private static get instance(): AdminApi {
    if (!AdminApi._instance) {
      AdminApi._instance = new AdminApi()
    }

    return AdminApi._instance
  }

  public static get v1Api(): Api<unknown>['v1Api'] {
    return AdminApi.instance.api.v1Api
  }

  public static refresh(): void {
    AdminApi._instance = new AdminApi()
  }
}
