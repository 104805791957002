import { CampaignType } from 'src/services/__generated__/api'

export const getCategoriesOptions = (campaigns: CampaignType[]): string[] => {
  const obj: Record<string, CampaignType[]> = {}

  campaigns.forEach((campaign) => {
    campaign.categories?.forEach((category) => {
      if (!obj[category]) {
        obj[category] = []
      }

      obj[category].push(campaign)
    })
  })

  return Array.from(new Set(Object.keys(obj))).sort()
}
