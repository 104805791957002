import { FC } from 'react'
import TimezoneSelect, { ITimezoneOption } from 'react-timezone-select'
import styles from './timezoneSelectInput.module.scss'

interface TimezoneSelectInputProps {
  onChange: (offset: number, name: string) => void
  timezoneName: string
}

const TimezoneSelectInput: FC<TimezoneSelectInputProps> = ({
  onChange,
  timezoneName,
}) => {
  const handleChange = (timezone: ITimezoneOption): void => {
    if (timezone.offset !== undefined) {
      onChange(timezone.offset, timezone.value)
    }
  }

  return (
    <TimezoneSelect
      className={styles.timezone}
      labelStyle="abbrev"
      onChange={handleChange}
      value={timezoneName}
    />
  )
}

export default TimezoneSelectInput
