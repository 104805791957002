import React, { FC, useEffect, useMemo, useState } from 'react'
import en from 'src/constants/en'
import { FilterModalContentWrapper } from 'src/containers/FilterModalContentWrapper'
import { AnalyticsFetchLocationsResponseItemValue } from 'src/services/__generated__/api'
import { FilterDropdownSelectOption } from 'src/containers/FilterSearchDropdown/interfaces/filterDropdownSelectOption.interface'
import { FilterSearchDropdownWithTabs } from 'src/containers/FilterSearchDropdownWithTabs'
import { MostMentionItem } from 'src/components/MostMentionItem'
import styles from './filterModalContent.module.scss'

export interface FilterModalContentProps {
  allLocations?: AnalyticsFetchLocationsResponseItemValue[]
  onClose: () => void
  onSave: (selectedLocations?: string[]) => void
  selectedLocations?: string[]
}

export const FilterModalContent: FC<FilterModalContentProps> = ({
  onSave,
  onClose,
  allLocations,
  selectedLocations,
}) => {
  const [selectedTab, setSelectedTab] = useState<string>(en.all)

  const filteredLocations = useMemo(() => {
    if (selectedTab === en.all) {
      return allLocations
    }

    if (selectedTab === en.positive) {
      return allLocations?.filter(
        (location) => location.mostlyRatedAs === 'POS'
      )
    }

    if (selectedTab === en.negative) {
      return allLocations?.filter(
        (location) => location.mostlyRatedAs === 'NEG'
      )
    }

    if (selectedTab === en.neutral) {
      return allLocations?.filter(
        (location) => location.mostlyRatedAs === 'NEU'
      )
    }

    return allLocations
  }, [selectedTab])

  const locationsToOption = (
    locations?: AnalyticsFetchLocationsResponseItemValue[]
  ) => {
    return (
      locations?.map((location) => ({
        label: location.name || '',
        labelAddition: location.mostlyRatedAs && (
          <MostMentionItem sentiment={location.mostlyRatedAs || ''} />
        ),
        value: location.name || '',
      })) || []
    )
  }

  const optionToValue = (options?: FilterDropdownSelectOption[]) => {
    return options?.map((option) => option.value) || []
  }

  const mappedSelectedLocations = locationsToOption(
    filteredLocations?.filter(
      (locationItem) =>
        locationItem.name && selectedLocations?.includes(locationItem.name)
    ) || []
  )

  const [selectedLocationOptions, setSelectedLocationOptions] = useState<
    FilterDropdownSelectOption[]
  >(mappedSelectedLocations)

  const locationOptions = locationsToOption(filteredLocations)

  const handleReset = () => {
    setSelectedLocationOptions(locationOptions)
  }

  useEffect(() => {
    setSelectedLocationOptions(mappedSelectedLocations)
  }, [selectedLocations?.toString()])

  return (
    <FilterModalContentWrapper
      onClose={() => onClose()}
      onReset={() => handleReset()}
      onSave={() => onSave(optionToValue(selectedLocationOptions))}
    >
      <div className={styles.filterModalContent}>
        <FilterSearchDropdownWithTabs
          countLabel={en.countries}
          onSelect={setSelectedLocationOptions}
          onTabSelect={setSelectedTab}
          options={locationOptions}
          placeholder={en.country}
          selectedOptions={selectedLocationOptions}
          selectedTab={selectedTab}
          tabs={[en.all, en.positive, en.negative, en.neutral]}
        />
      </div>
    </FilterModalContentWrapper>
  )
}
