import { FC, useMemo } from 'react'
import { EChartOption } from 'echarts'
import moment from 'moment'
import classNames from 'classnames'
import { COLORS } from 'src/constants/colors'
import {
  AnalyticsFetchHomeCalendarDateItem,
  AnalyticsFetchHomeCalendarResponse,
} from 'src/services/__generated__/api'
import en from 'src/constants/en'
import { useGetInformationTooltipsQuery } from 'src/reactQuery/useGetInformationTooltips'
import { SectionTitle } from 'src/components/SectionTitle'
import { CalendarChartData } from './interfaces/chartData.interface'
import styles from './calendar.module.scss'
import { EChart } from '../EChart'
import { ChartNavigation } from '../ChartNavigation'

interface CalendarProps {
  activeDate: string
  chartData?: AnalyticsFetchHomeCalendarResponse
  isLoading?: boolean
  navigateMonths: (range: string) => void
  onDateClick: (date: string) => void
}

export const CalendarSection: FC<CalendarProps> = ({
  chartData,
  isLoading,
  onDateClick,
  navigateMonths,
  activeDate,
}) => {
  const { data: informationTooltips } = useGetInformationTooltipsQuery()

  const getPositiveColor = (opacity: number): string => {
    return `rgba(128, 128, 255, ${opacity})`
  }

  const getNegativeColor = (opacity: number): string => {
    return `rgba(255, 104, 51, ${opacity})`
  }

  const getSelectedDateColor = (selectedDate: string): string => {
    const negative = chartData?.negativeData?.find(
      (day) => day?.value?.[0] === moment(selectedDate).format('YYYY-MM-DD')
    )

    if (negative) {
      return getNegativeColor(negative?.value?.[2]?.opacity || 0)
    }

    const positive = chartData?.positiveData?.find(
      (day) => day?.value?.[0] === moment(selectedDate).format('YYYY-MM-DD')
    )

    if (positive) {
      return getPositiveColor(positive?.value?.[2]?.opacity || 0)
    }

    return COLORS.white
  }

  const getDaysSeries = (
    dates?: AnalyticsFetchHomeCalendarDateItem[],
    color: string = COLORS.shark
  ): EChartOption.SeriesGraph => {
    return {
      coordinateSystem: 'calendar',
      data: dates || [],
      itemStyle: {
        color: COLORS.white,
      },
      label: {
        color,
        formatter: (data: CalendarChartData) => {
          return moment(data.value?.[0]).toDate().getDate()
        },
        show: true,
      },
      name: '',
      symbol: 'roundRect',
      symbolSize: 36,
      type: 'graph',
      z: 2,
    }
  }

  const options: EChartOption<EChartOption.SeriesGraph> | undefined =
    useMemo(() => {
      if (!chartData) {
        return undefined
      }

      const isSameMonth = moment(chartData.range).isSame(activeDate, 'month')

      const options: EChartOption<EChartOption.SeriesGraph> = {
        calendar: [
          {
            cellSize: [44, 44],
            dayLabel: {
              firstDay: 1,
              nameMap: 'en',
            },
            itemStyle: {
              borderWidth: 0,
            },
            left: 'center',
            monthLabel: {
              show: false,
            },
            orient: 'vertical',
            range: chartData.range,
            splitLine: {
              show: false,
            },
            top: 'middle',
            yearLabel: {
              show: false,
            },
          },
        ],
        legend: {
          show: false,
        },
        series: [
          {
            // @ts-ignore-next-line
            color: COLORS.malibu,
            coordinateSystem: 'calendar',
            data: chartData.positiveData,
            itemStyle: {
              color: (data: CalendarChartData) => {
                return getPositiveColor(data.value?.[2].opacity || 0)
              },
            },
            label: {
              color: COLORS.black,
              formatter: (data: CalendarChartData) => {
                return moment(data.value?.[0]).toDate().getDate()
              },
              show: true,
            },
            name: en.positive,
            symbol: 'roundRect',
            symbolSize: 36,
            type: 'graph',
            z: 3,
          },
          {
            // @ts-ignore-next-line
            color: COLORS.burningOrange,
            coordinateSystem: 'calendar',
            data: chartData.negativeData,
            itemStyle: {
              color: (data: CalendarChartData) => {
                return getNegativeColor(data.value?.[2].opacity || 0)
              },
            },
            label: {
              color: COLORS.black,
              formatter: (data: CalendarChartData) => {
                return moment(data.value?.[0]).toDate().getDate()
              },
              show: true,
            },
            name: en.negative,
            symbol: 'roundRect',
            symbolSize: 36,
            type: 'graph',
            z: 3,
          },
          {
            coordinateSystem: 'calendar',
            data: isSameMonth
              ? [
                  {
                    value: [activeDate, 0],
                  },
                ]
              : [],
            itemStyle: {
              borderColor: COLORS.darkBlue,
              borderWidth: 2,
              color: getSelectedDateColor(activeDate),
            },
            label: {
              color: COLORS.shark,
              formatter: (data?: CalendarChartData) => {
                if (!data) {
                  return ''
                }

                return moment(data.value?.[0]).toDate().getDate()
              },
              show: true,
            },
            name: '',
            symbol: 'roundRect',
            symbolSize: 36,
            type: 'graph',
            z: 3,
          },
          getDaysSeries(chartData.datesBefore, COLORS.iron),
          getDaysSeries(chartData.campaignDates),
          getDaysSeries(chartData.datesAfter, COLORS.iron),
        ],
      }

      return options
    }, [chartData, activeDate])

  return (
    <div className={styles.calendar}>
      <SectionTitle
        isLoading={isLoading}
        title={en.calendar}
        tooltipContent={informationTooltips?.calendar}
      />

      <div className={styles.navigation}>
        {!!chartData && (
          <ChartNavigation
            onLeftClick={() =>
              navigateMonths(
                moment(chartData.range).subtract(1, 'month').format('YYYY-MM')
              )
            }
            onRightClick={() =>
              navigateMonths(
                moment(chartData.range).add(1, 'month').format('YYYY-MM')
              )
            }
            title={chartData?.title}
          />
        )}
      </div>

      <div>
        {options && chartData ? (
          <EChart
            height={336}
            onClick={(event) => {
              if (
                chartData.campaignDates?.some(
                  (date) => date?.value?.[0] === event.value[0]
                )
              ) {
                onDateClick(event.value[0])
              }
            }}
            options={options}
            width={322}
          />
        ) : null}
      </div>

      <div className={styles.legend}>
        <div className={styles.legendItem}>
          <div className={classNames(styles.rect, styles.rectBlue)} />

          <span className={styles.label}>{en.positive}</span>
        </div>

        <div className={styles.legendItem}>
          <div className={classNames(styles.rect, styles.rectRed)} />

          <span className={styles.label}>{en.negative}</span>
        </div>
      </div>
    </div>
  )
}
