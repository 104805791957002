import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import * as echarts from 'echarts'
import { EChartOption, ECharts } from 'echarts'
import classNames from 'classnames'
import { debounce } from 'lodash'
import { ReactComponent as PlusSvg } from 'src/assets/svg/plus.svg'
import { ReactComponent as MinusSvg } from 'src/assets/svg/minus.svg'
import en from 'src/constants/en'
import { COLORS } from 'src/constants/colors'
import { numberWithCommas } from 'src/utils/numberFormat'
import hexToRgbA from 'src/utils/hexToRgba'
import { PositivityLegend } from 'src/components/PositivityLegend'
import { PositivityTypes } from 'src/components/PositivityLegend/enums/positivityTypes.enum'
import { capitalizeFirstLetter } from 'src/utils/capitalizeFirstLetter'
import {
  AnalyticsFetchLocationsResponseItemValue,
  AnalyticsFetchLocationsResponseRegionCountry,
} from 'src/services/__generated__/api'
import geoJSON from 'src/assets/json/worldmapgeojson.json'
import styles from './mapContent.module.scss'
import {
  INITIAL_ZOOM,
  MAX_ZOOM_IN,
  MAX_ZOOM_OUT,
  ZOOM_STEP,
} from '../constants/zoom'
import { EChart } from '../../EChart'
import { GeoRoamEventValue } from '../../EChart/interfaces/clickEventValue.interface copy'

echarts.registerMap('world', { geoJSON } as any)

interface MapContentProps {
  campaignName?: string
  locations?: AnalyticsFetchLocationsResponseItemValue[]
  onZoomChange?: (zoom: number) => void
  totalMentions?: number
}

const colorPalette = [
  COLORS.malibu,
  COLORS.burningOrange,
  COLORS.darkBlue,
  COLORS.redOrange,
  COLORS.pinkLace,
]

export const MapContent: FC<MapContentProps> = ({
  totalMentions,
  locations,
  campaignName,
  onZoomChange,
}) => {
  const [zoomRatio, setZoomRatio] = useState<GeoRoamEventValue>()

  const [chart, setChart] = useState<ECharts>()

  const getCurrentZoom = (chart: ECharts): number => {
    // @ts-ignore-next-line
    return chart?.getOption()?.geo?.[0]?.zoom || INITIAL_ZOOM
  }

  const zoom = chart ? getCurrentZoom(chart) : INITIAL_ZOOM

  const block = (name: string, title: string): ReactElement => {
    return (
      <div className={styles.block}>
        <div className={styles.blockName}>{name}</div>

        <div className={styles.blockTitle}>{title}</div>
      </div>
    )
  }

  const getZoomToLimits = (zoom: number): number => {
    if (zoom > MAX_ZOOM_IN) {
      return MAX_ZOOM_IN
    }

    if (zoom < MAX_ZOOM_OUT) {
      return MAX_ZOOM_IN
    }

    return zoom
  }

  const onZoomIn = () => {
    if (!chart) {
      return
    }

    const zoom = getZoomToLimits(getCurrentZoom(chart) + ZOOM_STEP)

    chart?.setOption({
      geo: {
        zoom,
      },
    })

    onZoomChange?.(zoom)
  }

  const onZoomOut = () => {
    if (!chart) {
      return
    }

    const zoom = getZoomToLimits(getCurrentZoom(chart) - ZOOM_STEP)

    chart?.setOption({
      geo: {
        zoom,
      },
    })

    onZoomChange?.(zoom)
  }

  const onMapZoom = useCallback(
    debounce((zoomRatio: GeoRoamEventValue) => {
      setZoomRatio(zoomRatio)
    }, 350),
    []
  )

  const totalNumber = totalMentions || 0

  const renderRegionTooltip = (params: EChartOption.Tooltip.Format) => {
    if (Array.isArray(params?.data?.countries)) {
      const countries = (
        params?.data
          ?.countries as AnalyticsFetchLocationsResponseRegionCountry[]
      ).reduce<string>((countriesTemplate, country) => {
        return `${countriesTemplate} <div>${capitalizeFirstLetter(
          country?.name || ''
        )}: ${numberWithCommas(country?.total || 0)}</div>`
      }, '')

      return `<div class=${styles.tooltip}>
      ${capitalizeFirstLetter(params?.data?.country)} <br />
      ${countries}</div>`
    }

    return ''
  }

  const renderCountryTooltip = (params: EChartOption.Tooltip.Format) => {
    if (params?.data) {
      return `<div class=${styles.tooltip}>
      ${capitalizeFirstLetter(params?.data?.country)} <br />
      <div class="${styles.ball} ${styles.blue}"></div>
      ${en.positive}: ${numberWithCommas(params?.data?.positives || 0)} <br />
        <div class="${styles.ball} ${styles.red}"></div>
        ${en.negative}: ${numberWithCommas(
          params?.data?.negatives || 0
        )} <br /> <div class="${styles.ball} ${styles.grey}"></div> ${
          en.neutral
        }: ${numberWithCommas(params?.data?.neutrals || 0)}</div>`
    }

    return ''
  }

  const getSortedLocations = (
    locations: AnalyticsFetchLocationsResponseItemValue[]
  ): EChartOption.SeriesScatter[] | undefined => {
    const sortedLocations = Object.entries(locations || {}).sort(
      ([, a], [, b]) => (b?.total || 0) - (a?.total || 0)
    )

    // @ts-ignore-next-line
    const locationList: EChartOption<EChartOption.SeriesScatter>['series'] =
      sortedLocations.map(([key, value], index) => {
        const lat = value?.lat
        const lng = value?.lng
        const mentions = value?.total

        return {
          coordinateSystem: 'geo',
          data: [
            {
              countries: value.countries,
              country: key,
              mentions: value.total,
              name: key,
              negatives: value.negatives,
              neutrals: value.neutrals,
              positives: value.positives,
              value: [lng, lat, mentions],
            },
          ],
          itemStyle: {
            normal: {
              borderColor: hexToRgbA(
                colorPalette[index] || COLORS.periwinkle,
                1
              ),
              borderWidth: '1',
              color: hexToRgbA(colorPalette[index] || COLORS.periwinkle, 0.4),
            },
          },
          name: key,
          type: 'scatter',
        }
      })

    return locationList
  }

  const options: EChartOption<EChartOption.SeriesScatter> | undefined =
    useMemo(() => {
      const options: EChartOption<EChartOption.SeriesScatter> = {
        geo: {
          doubleTapToZoomEnabled: true,
          itemStyle: {
            emphasis: {
              areaColor: COLORS.hpttSp3,
              borderColor: COLORS.hpttSp4,
              borderWidth: 1,
              label: {
                show: false,
              },
            },
            normal: {
              areaColor: COLORS.hpttSp3,
              borderColor: COLORS.hpttSp4,
              borderWidth: 0,
              label: {
                show: false,
              },
            },
          },
          label: {
            emphasis: {
              show: false,
            },
          },
          map: 'world',
          roam: 'move',
          scaleLimit: {
            max: MAX_ZOOM_IN,
            min: MAX_ZOOM_OUT,
          },
          type: 'map',
          zoom: INITIAL_ZOOM,
        },
        tooltip: {
          enterable: true,
          formatter: (params) => {
            if (Array.isArray(params)) {
              return ''
            }

            if (params?.data?.countries?.length) {
              return renderRegionTooltip(params)
            }

            return renderCountryTooltip(params)
          },
          show: true,
          showDelay: 350,
          trigger: 'item',
        },
      }

      return options
    }, [])

  useEffect(() => {
    if (!locations?.length) {
      return
    }

    const locationList = getSortedLocations(locations || [])

    const mentions = Object.values(locations || {}).map(
      (item) => item?.total || 0
    )

    const max = Math.max(...mentions)

    // @ts-ignore-next-line
    // replaceMerge is not typed correctly in echarts
    chart?.setOption(
      {
        series: locationList,
        visualMap: [
          {
            inRange: {
              symbolSize: [6, 60],
            },
            max,
            min: 0,
            show: false,
          },
        ],
      },
      {
        replaceMerge: ['series'],
      }
    )
  }, [locations])

  useEffect(() => {
    if (!chart) {
      return
    }

    const zoom = getZoomToLimits(getCurrentZoom(chart) + ZOOM_STEP)

    onZoomChange?.(zoom)
  }, [zoomRatio])

  const totalNUmberWithCommas = numberWithCommas(totalNumber)

  return (
    <>
      <div className={styles.mapContent}>
        <div className={styles.wrapper}>
          {campaignName && block(en.campaign, campaignName)}

          {block(en.region, en.worldwide)}

          {totalNUmberWithCommas && block(en.mentions, totalNUmberWithCommas)}

          <div className={styles.additionalText}>
            {en.locationChartAdditionalText}
          </div>
        </div>

        <div className={styles.map}>
          {options ? (
            <EChart
              onChart={setChart}
              onGeoRoam={(event) => {
                if (event.zoom) {
                  onMapZoom(event)
                }
              }}
              options={options}
            />
          ) : null}
        </div>

        <div className={styles.mapButtons}>
          <div
            className={classNames(
              styles.mapButton,
              zoom === MAX_ZOOM_IN && styles.mapButtonDisabled
            )}
            onClick={onZoomIn}
          >
            <PlusSvg className={styles.mapButtonSvg} />
          </div>

          <div
            className={classNames(
              styles.mapButton,
              zoom === MAX_ZOOM_OUT && styles.mapButtonDisabled
            )}
            onClick={onZoomOut}
          >
            <MinusSvg className={styles.mapButtonSvg} />
          </div>
        </div>
      </div>

      <div className={styles.footer}>
        <PositivityLegend
          types={[
            PositivityTypes.POSITIVE,
            PositivityTypes.NEUTRAL,
            PositivityTypes.NEGATIVE,
          ]}
        />
      </div>
    </>
  )
}
