import React, { FC, ReactNode } from 'react'
import classNames from 'classnames'
import styles from './card.module.scss'

export interface CardProps {
  children?: ReactNode
  className?: string
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
}

export const Card: FC<CardProps> = ({ children, className, onClick }) => {
  return (
    <div className={classNames(styles.card, className)} onClick={onClick}>
      {children}
    </div>
  )
}
