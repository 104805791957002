import { FC, useRef } from 'react'
import useOutsideClick from 'src/hooks/useOutsideClick'
import { useDisableScroll } from 'src/hooks/useDisableScroll'
import { ReactComponent as ModalClose } from 'src/assets/svg/modal-close.svg'

interface SmallRoundTagProps {
  isOpen: boolean
  onDelete: () => void
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const DeletePopUpModal: FC<SmallRoundTagProps> = ({
  isOpen,
  setIsOpen,
  onDelete,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useOutsideClick({
    onOutsideClick: () => setIsOpen(false),
    ref,
  })

  useDisableScroll(isOpen || false)

  return (
    <div className="fixed inset-0 z-[9999] flex items-center justify-center bg-black bg-opacity-50">
      <div
        className="flex h-[233px] w-[617px] flex-col rounded-[18px] bg-white p-6 shadow-[0px_56px_64px_-48px_rgba(109,121,158,0.14)]"
        ref={ref}
      >
        <div className="flex items-center justify-between">
          <h2 className="font-roboto text-[24px] font-medium tracking-[0.24px] text-[#25262d]">
            Are you sure?
          </h2>

          <div className="cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
            <ModalClose />
          </div>
        </div>

        <h4 className="mt-[30px] w-[569px] font-roboto text-[16px] font-normal leading-[24px] tracking-[0.16px] text-[#25262d]">
          By deleting this sentiment, it will be permanently removed from your
          records. This action cannot be undone.
        </h4>

        <div className="mt-[40px] flex items-center gap-[10px] self-end">
          <button
            className="font-roboto text-[16px] font-normal tracking-[0.16px] text-[#25262d]"
            onClick={() => setIsOpen(false)}
          >
            Discard
          </button>

          <button
            className="rounded-[10px] bg-malibu px-4 py-2 font-roboto text-[16px] font-medium tracking-[0.16px] text-white"
            onClick={onDelete}
          >
            Delete Mention
          </button>
        </div>
      </div>
    </div>
  )
}
