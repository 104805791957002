import { SignUpResponse, User } from './interfaces/signUp.interface'
import { nApi } from '../index'

export const signUp = async (user: User): Promise<SignUpResponse> => {
  const { data } = await nApi().post<User, SignUpResponse>(`/v2/auth/signup`, {
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    metadata: {
      companyName: user.company,
    },
    password: user.password,
  })

  return { data }
}
