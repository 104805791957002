import { FC } from 'react'
import en from 'src/constants/en'
import { PositivityTypes } from './enums/positivityTypes.enum'
import { PositivityMapValue } from './interfaces/positivityMap.interface'
import styles from './positivityLegend.module.scss'

interface PositivityLegendProps {
  types?: PositivityTypes[]
}

export const PositivityLegend: FC<PositivityLegendProps> = ({
  types = [
    PositivityTypes.POSITIVE,
    PositivityTypes.NEGATIVE,
    PositivityTypes.NEUTRAL,
  ],
}) => {
  const positivityMap: Record<PositivityTypes, PositivityMapValue> = {
    [PositivityTypes.POSITIVE]: {
      className: styles.positive,
      text: en.positive,
    },
    [PositivityTypes.NEGATIVE]: {
      className: styles.negative,
      text: en.negative,
    },
    [PositivityTypes.NEUTRAL]: {
      className: styles.neutral,
      text: en.neutral,
    },
  }

  return (
    <div className={styles.positivityLegend}>
      {types?.map((type) => {
        return (
          <div className={styles.positivityLegendItem} key={type}>
            <div
              className={`${styles.ball} ${positivityMap[type].className}`}
            />

            <span className={styles.text}>{positivityMap[type].text}</span>
          </div>
        )
      })}
    </div>
  )
}
