import { FC, ReactNode } from 'react'
import classNames from 'classnames'
import styles from './calendarNavigation.module.scss'
import { ReactComponent as CalendarChevron } from '../../assets/svg/calendarChevron.svg'

interface ChartNavigationProps {
  isLeftDisabled?: boolean
  isRightDisabled?: boolean
  onLeftClick?: () => void
  onRightClick?: () => void
  title?: ReactNode
}

export const ChartNavigation: FC<ChartNavigationProps> = ({
  title,
  onLeftClick,
  onRightClick,
  isLeftDisabled,
  isRightDisabled,
}) => {
  return (
    <div className={styles.calendarSectionNavigation}>
      <span
        className={classNames(
          styles.arrow,
          styles.arrowLeft,
          isLeftDisabled && 'pointer-events-none opacity-50'
        )}
        onClick={() => !isLeftDisabled && onLeftClick?.()}
      >
        <CalendarChevron />
      </span>

      <span>{title}</span>

      <span
        className={classNames(
          styles.arrow,
          isRightDisabled && 'pointer-events-none opacity-50'
        )}
        onClick={() => !isRightDisabled && onRightClick?.()}
      >
        <CalendarChevron />
      </span>
    </div>
  )
}
