import { useQuery } from '@tanstack/react-query'
import { QueryKey } from 'src/reactQuery/enums/queryKey.enum'
import { AnalyticsApi } from 'src/services/analyticsApi'
import { AnalyticsFetchTopSourcesResponse } from 'src/services/__generated__/api'
import { UseGetTopSourcesByCampaignQueryParams } from './interfaces/useGetTopSourcesByCampaignQuery.interface'

const getTopSourcesByCampaign = async (
  start?: string,
  end?: string,
  campaign?: string
): Promise<AnalyticsFetchTopSourcesResponse | null> => {
  if (!campaign) {
    return null
  }

  const { data } = await AnalyticsApi.v1Private.analyticsFetchTopSourcesList({
    campaign,
    fromDate: start,
    toDate: end,
  })

  return data
}

export const useGetTopSourcesByCampaignQuery = (
  params: UseGetTopSourcesByCampaignQueryParams
) => {
  return useQuery({
    enabled: !!params.campaign,
    queryFn: () =>
      getTopSourcesByCampaign(params.start, params.end, params.campaign),
    queryKey: [QueryKey.GET_TOP_SOURCES_BY_CAMPAIGN, ...Object.values(params)],
  })
}
