import { GetThreadExpandedTextParams } from './interfaces/getThreadExpandedTextParams.interface'
import { VisibleTextSectionIndexes } from './interfaces/visibleTextSectionIndexes.interface'

const MAX_PADDED_WORDS_IN_EXPANDED_TEXT = 10

export const getThreadExpandedText = ({
  text,
  keyword,
  sentiments,
}: GetThreadExpandedTextParams): string => {
  if (!text) {
    return ''
  }

  // Split text into words and white spaces

  const wordsAndWhiteSpaces = text.trim().split(/(\s+)/)

  const words: string[] = []
  const whiteSpaces: string[] = []

  wordsAndWhiteSpaces.forEach((wordOrWhiteSpace, index) => {
    const isWord = index % 2 === 0

    if (isWord) {
      words.push(wordOrWhiteSpace)
    } else {
      whiteSpaces.push(wordOrWhiteSpace)
    }
  })

  // Calculate word count in keyword and sentiments

  const keywordWordCount = keyword?.split(/(\s+)/)?.length ?? 0

  const sentimentToWordCountMap: { [key: string]: number } = {}
  sentiments.forEach((sentiment) => {
    const wordCount = sentiment?.split(/(\s+)/)?.length ?? 0
    sentimentToWordCountMap[sentiment] = wordCount
  })

  // Find word indexes of keywords and sentiments

  const keywordOrSentimentInstanceIndexes: number[] = []

  for (let index = 0; index < words.length; index++) {
    const uncheckedWordCount = words.length - index

    const canBeKeyword = uncheckedWordCount >= keywordWordCount

    const keywordMatchTestString = !canBeKeyword
      ? ''
      : words
          .slice(index, index + keywordWordCount)
          .reduce((result, value, wordIndex, { length }) => {
            const whiteSpace =
              wordIndex === length - 1 ? '' : whiteSpaces[index + wordIndex]

            return `${result}${value}${whiteSpace}`
          }, '')

    const isKeyword =
      keyword &&
      keywordMatchTestString.toLowerCase().includes(keyword.toLowerCase())

    const isSentiment = sentiments.some((sentiment) => {
      const sentimentWordCount = sentimentToWordCountMap[sentiment]

      const canBeSentiment = uncheckedWordCount >= sentimentWordCount

      if (!canBeSentiment) {
        return false
      }

      const sentimentMatchTestString = words
        .slice(index, index + sentimentWordCount)
        .reduce((result, value, wordIndex, { length }) => {
          const whiteSpace =
            wordIndex === length - 1 ? '' : whiteSpaces[index + wordIndex]

          return `${result}${value}${whiteSpace}`
        }, '')

      return sentimentMatchTestString
        .toLowerCase()
        .includes(sentiment.toLowerCase())
    })

    if (isKeyword || isSentiment) {
      keywordOrSentimentInstanceIndexes.push(index)
    }
  }

  // Calculate visible text sections start/end word indexes

  const visibleTextSectionsIndexes: VisibleTextSectionIndexes[] = []

  keywordOrSentimentInstanceIndexes.forEach((instanceIndex) => {
    let start = instanceIndex - MAX_PADDED_WORDS_IN_EXPANDED_TEXT

    if (start < 0) start = 0

    let end = instanceIndex + MAX_PADDED_WORDS_IN_EXPANDED_TEXT

    if (end >= words.length) end = words.length - 1

    visibleTextSectionsIndexes.push({ end, start })
  })

  // Merge text sections into word indexes array

  const visibleTextWordIndexes: number[] = []

  visibleTextSectionsIndexes.forEach(({ start, end }) => {
    for (let i = start; i <= end; i++) {
      visibleTextWordIndexes.push(i)
    }
  })

  // Remove duplicate word indexes and sort them in ascending order

  const visibleTextUniqueWordIndexes: number[] = Array.from(
    new Set(visibleTextWordIndexes)
  ).sort((a, b) => a - b)

  // Join words with white spaces in between
  // Join different word sections with dots

  let expandedText = ''

  visibleTextUniqueWordIndexes.forEach((wordIndex, index) => {
    const word = words[wordIndex]

    const isLastWord = index === visibleTextUniqueWordIndexes.length - 1
    const isLastWordInSection =
      wordIndex + 1 !== visibleTextUniqueWordIndexes[index + 1]

    expandedText += word

    if (isLastWord) {
      return
    }

    if (isLastWordInSection) {
      expandedText += ' ... '
    } else {
      const whiteSpace = whiteSpaces[wordIndex] || ''

      expandedText += whiteSpace
    }
  })

  // Add dots at the start, if first visible word isn't first word in text

  if (visibleTextUniqueWordIndexes[0] !== 0) {
    expandedText = `... ${expandedText}`
  }

  // Add dots at the end, if last visible word isn't last word in text

  if (
    visibleTextUniqueWordIndexes[visibleTextUniqueWordIndexes.length - 1] !==
    words.length - 1
  ) {
    expandedText = `${expandedText} ...`
  }

  return expandedText
}
