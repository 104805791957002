import { FC } from 'react'
import styles from './smallRoundTag.module.scss'

interface SmallRoundTagProps {
  content?: number | string
}

export const SmallRoundTag: FC<SmallRoundTagProps> = ({ content }) => {
  return <span className={styles.smallRoundTag}>{content}</span>
}
