import qs from 'qs'
import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { UseQueryParamsParams } from './interfaces/useQueryParams.interface'

export const useQueryParams = <T>({
  qsStringifyOptions,
}: UseQueryParamsParams = {}): {
  queryParams: T
  removeQueryParams: (queryParams: (keyof T)[]) => void
  setQueryParams: (queryParams: T) => void
  updateQueryParams: (queryParams: T) => void
} => {
  const location = useLocation()

  const navigate = useNavigate()

  const queryParams = useMemo<T>(
    () =>
      qs.parse(location.search, { ignoreQueryPrefix: true }) as unknown as T,
    [location.search]
  )

  const updateQueryParams = (appliedQueryParams: T) => {
    navigate(
      {
        pathname: location.pathname,
        search: qs.stringify(
          {
            ...queryParams,
            ...appliedQueryParams,
          },
          { addQueryPrefix: false, ...qsStringifyOptions }
        ),
      },
      { replace: true }
    )
  }

  const setQueryParams = (appliedQueryParams: T) => {
    navigate(
      {
        pathname: location.pathname,
        search: qs.stringify(
          {
            ...appliedQueryParams,
          },
          { addQueryPrefix: false, ...qsStringifyOptions }
        ),
      },
      { replace: true }
    )
  }

  const removeQueryParams = (keys: (keyof T)[]) => {
    const newQueryParams = { ...queryParams }

    keys.forEach((key) => {
      delete newQueryParams[key]
    })

    navigate(
      {
        pathname: location.pathname,
        search: qs.stringify(newQueryParams, { addQueryPrefix: false }),
      },
      { replace: true }
    )
  }

  return {
    queryParams,
    removeQueryParams,
    setQueryParams,
    updateQueryParams,
  }
}
