import { FC } from 'react'
import { ScrollBarContainer } from 'src/components/ScrollBarContainer'
import en from 'src/constants/en'
import { AnalyticsFetchTopSourcesResponse } from 'src/services/__generated__/api'
import { PositivityLegend } from 'src/components/PositivityLegend'
import { useGetInformationTooltipsQuery } from 'src/reactQuery/useGetInformationTooltips'
import { SectionTitle } from 'src/components/SectionTitle'
import styles from './topSourcesSection.module.scss'
import { TopSourcesChart } from './TopSourcesChart'
import { calculateToTalMentions } from './utils/calculateToTalMentions'

interface TopSourcesSectionProps {
  chartData?: AnalyticsFetchTopSourcesResponse
  isLoading?: boolean
}

export const TopSourcesSection: FC<TopSourcesSectionProps> = ({
  chartData,
  isLoading,
}) => {
  const { data: informationTooltips } = useGetInformationTooltipsQuery()

  const overallMentions =
    chartData?.reduce((mentionCount, mention) => {
      return calculateToTalMentions(mention) + mentionCount
    }, 0) || 0

  return (
    <div className={styles.topSourcesSection}>
      <SectionTitle
        isLoading={isLoading}
        title={en.topSources}
        tooltipContent={informationTooltips?.topSources}
      />

      <ScrollBarContainer isHorizontal>
        <div className={styles.chartWrapper}>
          {chartData?.map((chartDataItem) => {
            const { name, positives, negatives, neutrals } = chartDataItem

            return (
              <TopSourcesChart
                key={`${name} - ${positives} - ${negatives} - ${neutrals}`}
                mention={chartDataItem}
                overallMentions={overallMentions}
              />
            )
          })}
        </div>
      </ScrollBarContainer>

      <div className={styles.footer}>
        <PositivityLegend />
      </div>
    </div>
  )
}
