import axios from 'axios'
import { HAPTTIC_X_API_KEY } from 'src/constants/env'
import { getSessionIdFromLocalStorage } from 'src/localStorage/sessionId/getSessionIdFromLocalStorage/getSessionIdFromLocalStorage'
import { ANALYTICS_API_URL } from 'src/services/constants/urls'
import { Api } from 'src/services/__generated__/api'

export const getAnalyticsApiHeaders = () => {
  return {
    'Access-Control-Allow-Origin': ANALYTICS_API_URL,
    Vary: 'Origin',
    'X-Api-Key': HAPTTIC_X_API_KEY,
    'x-session-id': getSessionIdFromLocalStorage() || '',
  }
}

export const analyticsApi = () =>
  axios.create({
    baseURL: ANALYTICS_API_URL,
    headers: getAnalyticsApiHeaders(),
  })

export class AnalyticsApi {
  private static _instance: AnalyticsApi

  private api: Api<unknown>

  private constructor() {
    this.api = new Api({
      baseApiParams: {
        credentials: 'same-origin',
        headers: getAnalyticsApiHeaders(),
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
      },
      baseUrl: ANALYTICS_API_URL,
    })
  }

  private static get instance(): AnalyticsApi {
    if (!AnalyticsApi._instance) {
      AnalyticsApi._instance = new AnalyticsApi()
    }

    return AnalyticsApi._instance
  }

  public static get v1Private(): Api<unknown>['v1Private'] {
    return AnalyticsApi.instance.api.v1Private
  }

  public static refresh(): void {
    AnalyticsApi._instance = new AnalyticsApi()
  }
}
