import { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { useGetTripAdvisorPlacesQuery } from 'src/reactQuery/useGetTripAdvisorPlacesQuery'
import styles from './tripAdvisorDebounce.module.scss'

interface TripAdvisorDebounceProps {
  handleSuggestionClick: () => void
  inputRef: HTMLInputElement
  onSelect: (placeId: string | undefined) => void
  searchQuery: string
}

const TripAdvisorDebounce: React.FC<TripAdvisorDebounceProps> = ({
  searchQuery,
  onSelect,
  handleSuggestionClick,
  inputRef,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery)

  const { data: tripAdvisorPlaces } = useGetTripAdvisorPlacesQuery({
    language: 'en',
    searchQuery: debouncedSearchQuery,
  })

  const debouncedSetSearchQuery = useCallback(
    debounce((query: string) => {
      setDebouncedSearchQuery(query)
    }, 300),
    []
  )

  useEffect(() => {
    if (inputRef === document.activeElement) {
      debouncedSetSearchQuery(searchQuery)
    }

    return () => {
      debouncedSetSearchQuery.cancel()
    }
  }, [searchQuery, debouncedSetSearchQuery, inputRef])

  useEffect(() => {
    setIsOpen(!!tripAdvisorPlaces && tripAdvisorPlaces.length > 0)
  }, [tripAdvisorPlaces])

  return (
    <div
      className={styles.debounceContainer}
      style={{ display: `${isOpen ? 'flex' : 'none'}` }}
    >
      {tripAdvisorPlaces &&
        tripAdvisorPlaces.map((place) => (
          <div
            className={styles.placeContainer}
            key={place.location_id}
            onClick={() => {
              onSelect(place?.location_id)
              handleSuggestionClick()
              setIsOpen(false)
            }}
          >
            <div className={styles.placeInfoContainer}>
              <h3 className={styles.placeName}>{place.name}</h3>
              <p
                className={styles.placeAddress}
              >{`${place?.address_obj?.street1},${place?.address_obj?.country}`}</p>
            </div>
          </div>
        ))}
    </div>
  )
}

export default TripAdvisorDebounce
