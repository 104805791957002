import { useQuery } from '@tanstack/react-query'
import { QueryKey } from 'src/reactQuery/enums/queryKey.enum'
import { getSelf } from 'src/services/napi/getSelf'
import { GetSelfResponse } from 'src/services/napi/getSelf/interfaces/getSelf.interface'
import { getSessionIdFromLocalStorage } from 'src/localStorage/sessionId/getSessionIdFromLocalStorage/getSessionIdFromLocalStorage'

const fetchSelf = async (): Promise<GetSelfResponse | null> => {
  const sessionId = getSessionIdFromLocalStorage()

  if (!sessionId) {
    return null
  }

  const data = await getSelf()

  return data
}

export const useGetSelfQuery = () => {
  return useQuery({
    queryFn: fetchSelf,
    queryKey: [QueryKey.GET_SELF],
  })
}
