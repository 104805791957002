import { FC, useRef, useState } from 'react'
import { ReactComponent as ModalClose } from 'src/assets/svg/modal-close.svg'
import useOutsideClick from 'src/hooks/useOutsideClick'
import { useDisableScroll } from 'src/hooks/useDisableScroll'
import styles from './reportPop.module.scss'

interface SmallRoundTagProps {
  isOpen: boolean
  onReport: (message: string) => void
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const ReportPopUpModal: FC<SmallRoundTagProps> = ({
  isOpen,
  setIsOpen,
  onReport,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  const [message, setMessage] = useState('')

  useOutsideClick({
    onOutsideClick: () => setIsOpen(false),
    ref,
  })

  useDisableScroll(isOpen || false)

  return (
    <div className="fixed inset-0 z-[9999] flex items-center justify-center bg-black bg-opacity-50">
      <div
        className="flex h-[293px] w-[617px] flex-col rounded-[18px] bg-white p-6 shadow-[0px_56px_64px_-48px_rgba(109,121,158,0.14)]"
        ref={ref}
      >
        <div className="flex items-center justify-between">
          <h2 className="font-roboto text-[24px] font-medium tracking-[0.24px] text-[#25262d]">
            Reporting Problem
          </h2>

          <div className="cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
            <ModalClose />
          </div>
        </div>

        <h4 className="mt-8 font-roboto text-[14px] font-normal tracking-[0.14px] text-[#25262d]">
          Briefly describe your reason of report:
        </h4>

        <input
          className={styles.input}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Reason of Report"
          type="text"
          value={message}
        />

        <div className="mt-[36px] flex items-center gap-[10px] self-end">
          <button
            className="font-roboto text-[16px] font-normal tracking-[0.16px] text-[#25262d]"
            onClick={() => setIsOpen(false)}
          >
            Discard
          </button>

          <button
            className="rounded-[10px] bg-malibu px-4 py-2 font-roboto text-[16px] font-medium tracking-[0.16px] text-white"
            onClick={() => onReport(message)}
          >
            Report Problem
          </button>
        </div>
      </div>
    </div>
  )
}
