import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import ChariotIcon from 'src/assets/svg/chariot.svg'
import { cn } from 'src/utils/cn'
import styles from './backNavigationButton.module.scss'

interface BackNavigationButtonProps {
  className?: string
  handleClick?: () => void
  title?: string
  url?: string
}

export const BackNavigationButton: FC<BackNavigationButtonProps> = ({
  title,
  url,
  handleClick,
  className,
}) => {
  const navigate = useNavigate()

  const onClick = () => {
    if (url) {
      navigate(url)

      return
    }

    if (handleClick) {
      handleClick()

      return
    }

    navigate(-1)
  }

  return (
    <div
      className={cn(styles.backNavigationButton, className)}
      onClick={onClick}
    >
      <img src={ChariotIcon} />

      <span className={styles.linkText}>{title}</span>
    </div>
  )
}
