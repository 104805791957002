import {
  useEffect,
  FC,
  useState,
  Dispatch,
  SetStateAction,
  UIEvent,
} from 'react'
import { useParams } from 'react-router-dom'
import { UseQueryResult } from '@tanstack/react-query'
import moment from 'moment'
import { toast } from 'react-toastify'
import { INITIAL_ZOOM } from 'src/charts/LocationChartSection/constants/zoom'
import { SentimentChartSection } from 'src/charts/SentimentChartSection'
import { MentionsByMonth } from 'src/charts/MentionsByMonth'
import { TopSourcesSection } from 'src/charts/TopSourcesSection'
import { SentimentAnalysesSection } from 'src/charts/SentimentAnalysesSection'
import { LocationChartSection } from 'src/charts/LocationChartSection'
import { CAMPAIGNS_ROUTE } from 'src/constants/routes'
import { getSessionIdFromLocalStorage } from 'src/localStorage/sessionId/getSessionIdFromLocalStorage/getSessionIdFromLocalStorage'
import { DailyTrendsSection } from 'src/charts/DailyTrendsSection'
import { useGetLocationsQuery } from 'src/reactQuery/useGetLocationsQuery'
import { adjustZoomToAPI } from 'src/reactQuery/useGetLocationsQuery/utils/adjustZoomToAPI'
import { SENTIMENT_ANALYSIS_CONTAINER_HEIGHT } from 'src/constants/vars'
import { useGetAllSentimentAnalysisThreadsQuery } from 'src/reactQuery/useGetAllSentimentAnalysisThreadsQuery'
import { useGetNegativeSentimentAnalysisThreadsQuery } from 'src/reactQuery/useGetNegativeSentimentAnalysisThreadsQuery'
import { useGetNeutralSentimentAnalysisThreadsQuery } from 'src/reactQuery/useGetNeutralSentimentAnalysisThreadsQuery'
import { useGetPositiveSentimentAnalysisThreadsQuery } from 'src/reactQuery/useGetPositiveSentimentAnalysisThreadsQuery'
import {
  AnalyticsFetchThreadsResponse,
  AnalyticsFetchThreadsSentimentType,
  FetchThreadsCSVIsLostType,
} from 'src/services/__generated__/api'
import { useGetDailyTrendsByCampaignQuery } from 'src/reactQuery/useGetDailyTrendsByCampaignQuery'
import { useGetSentimentChartByCampaignQuery } from 'src/reactQuery/useGetSentimentChartByCampaignQuery'
import { useGetSentimentAnalysisByCampaignQuery } from 'src/reactQuery/useGetSentimentAnalysisByCampaignQuery'
import { useGetTopSourcesByCampaignQuery } from 'src/reactQuery/useGetTopSourcesByCampaignQuery'
import { useGetCalendarByCampaignQuery } from 'src/reactQuery/useGetCalendarByCampaignQuery'
import { OnFilterSelectParams } from 'src/charts/SentimentChartSection/interfaces/onFilterSelect.interface'
import { getEndOfToday } from 'src/utils/date'
import { useCustomLink } from 'src/hooks/useCustomLink'
import { BackNavigationButton } from 'src/components/BackNavigationButton'
import { UseGetSentimentAnalysisByCampaignQueryParams } from 'src/reactQuery/useGetSentimentAnalysisByCampaignQuery/interfaces/useGetSentimentAnalysisByCampaignQuery.interface'
import { useQueryParams } from 'src/hooks/useQueryParams'
import { LocationSectionTab } from 'src/charts/LocationChartSection/enums/locationSectionTab.enum'
import { useGetKeywordsAnalysis } from 'src/reactQuery/useGetKeywordsAnalysis'
import { KeywordAnalyses } from 'src/charts/KeywordAnalyses'
import { UseGetKeywordsAnalysisParams } from 'src/reactQuery/useGetKeywordsAnalysis/interfaces/useGetKeywordsAnalysis.interface'
import { getDateRangeByQueryParams } from 'src/utils/getDateRangeByQueryParams'
import { useGetCampaignByIdQuery } from 'src/reactQuery/useGetCampaignById'
import { useGetCustomCalendarRangeQuery } from 'src/reactQuery/useGetCustomCalendarRangeQuery'
import { ReactComponent as FileDownloadLineSvg } from 'src/assets/svg/file-download-line.svg'
import en from 'src/constants/en'
import { AnalyticsApi } from 'src/services/analyticsApi'
import Button from 'src/components/Button'
import { useDownloadFileByUrl } from 'src/hooks/useDownloadFIleByUrl'
import { ThreadState } from './interfaces/threadState.interface'
import styles from './themeDetail.module.scss'
import { QueryParams } from '../Campaigns/interfaces/queryParams.interface'

const INIT_THREAD_STATE: ThreadState = {
  data: null,
  isScrolledDown: false,
  isScrolledUp: false,
}

const ThemeDetail: FC = () => {
  const sessionId = getSessionIdFromLocalStorage()

  const [locationZoom, setLocationZoom] = useState(INITIAL_ZOOM)

  const [filters, setFilters] = useState<OnFilterSelectParams | null>(null)

  const [selectedSentimentChartYear, setSelectedSentimentChartYear] = useState<
    number | null
  >(null)

  const [keywordAnalysesFilters, setKeywordAnalysesFilters] =
    useState<UseGetKeywordsAnalysisParams>()

  const [sentimentAnalysesFilters, setSentimentAnalysesFilters] =
    useState<
      Pick<
        UseGetSentimentAnalysisByCampaignQueryParams,
        | 'selectedKeywords'
        | 'selectedSources'
        | 'selectedLanguages'
        | 'isLost'
        | 'selectedMentionFormats'
      >
    >()

  const backURL = useCustomLink(CAMPAIGNS_ROUTE)

  const { themeId } = useParams<{
    themeId: string
  }>()

  const { queryParams } = useQueryParams<QueryParams>()

  const dates = getDateRangeByQueryParams(queryParams)

  const startDate = dates?.start
  const endDate = dates?.end

  const { data: campaignData } = useGetCampaignByIdQuery({
    id: themeId,
  })

  const themeName = campaignData?.campaign?.name

  const { data: customCalendarRange } = useGetCustomCalendarRangeQuery()

  const sentimentChartRangeStartYear = moment(
    startDate || customCalendarRange?.firstDate
  ).year()

  const sentimentChartRangeEndYear = moment(
    endDate || customCalendarRange?.lastDate
  ).year()

  const { isLoading: isCsvLoading, handleFileDownload } = useDownloadFileByUrl()

  const {
    data: locationsData,
    refetch: refetchLocations,
    isFetching: isLocationsFetching,
  } = useGetLocationsQuery({
    campaign: themeId,
    fromDate: startDate || undefined,
    toDate: endDate || undefined,
    zoom: adjustZoomToAPI(locationZoom),
  })

  const {
    data: dailyTrendsData,
    refetch: refetchDailyTrends,
    isFetching: isDailyTrendsFetching,
  } = useGetDailyTrendsByCampaignQuery({
    campaign: themeId,
    end: endDate,
    start: startDate,
  })

  const {
    data: topSourcesData,
    refetch: refetchTopSources,
    isFetching: isTopSourcesFetching,
  } = useGetTopSourcesByCampaignQuery({
    campaign: themeId,
    end: endDate,
    start: startDate,
  })

  const {
    data: sentimentChartData,
    refetch: refetchSentimentChart,
    isLoading: isLoadingSentimentChart,
  } = useGetSentimentChartByCampaignQuery({
    campaign: themeId,
    end: selectedSentimentChartYear
      ? moment(selectedSentimentChartYear.toString())
          .endOf('year')
          .toISOString(true)
      : endDate,
    keywords: filters?.keywords,
    sources: filters?.sources,
    start: selectedSentimentChartYear
      ? moment(selectedSentimentChartYear.toString())
          .startOf('year')
          .toISOString(true)
      : startDate,
  })

  const {
    data: sentimentAnalysisData,
    refetch: refetchSentimentAnalysis,
    isFetching: isSentimentAnalysisFetching,
  } = useGetSentimentAnalysisByCampaignQuery({
    campaign: themeId,
    end: endDate,
    isLost: sentimentAnalysesFilters?.isLost,
    selectedKeywords: sentimentAnalysesFilters?.selectedKeywords,
    selectedLanguages: sentimentAnalysesFilters?.selectedLanguages,
    selectedMentionFormats: sentimentAnalysesFilters?.selectedMentionFormats,
    selectedSources: sentimentAnalysesFilters?.selectedSources,
    start: startDate,
  })

  const {
    data: calendarData,
    refetch: refetchCalendar,
    isFetching: isCalendarFetching,
  } = useGetCalendarByCampaignQuery({
    campaign: themeId,
    fromDate: startDate,
    toDate: endDate,
  })

  const { data: keywordsAnalysisData, isFetching: isKeywordsAnalysisFetching } =
    useGetKeywordsAnalysis({
      campaign: themeId,
      filterChoice: keywordAnalysesFilters?.filterChoice,
      filterCount: keywordAnalysesFilters?.filterCount,
      fromDate: startDate,
      selectedKeywords: keywordAnalysesFilters?.selectedKeywords,
      sentimentType: keywordAnalysesFilters?.sentimentType,
      toDate: endDate,
    })

  const [allSentimentAnalysisThreads, setAllSentimentAnalysisThreads] =
    useState<ThreadState>({ ...INIT_THREAD_STATE })

  const [
    positiveSentimentAnalysisThreads,
    setPositiveSentimentAnalysisThreads,
  ] = useState<ThreadState>({ ...INIT_THREAD_STATE })

  const [
    negativeSentimentAnalysisThreads,
    setNegativeSentimentAnalysisThreads,
  ] = useState<ThreadState>({ ...INIT_THREAD_STATE })

  const [neutralSentimentAnalysisThreads, setNeutralSentimentAnalysisThreads] =
    useState<ThreadState>({ ...INIT_THREAD_STATE })

  const getThreadsStateFromSentiment = (
    sentiment?: AnalyticsFetchThreadsSentimentType
  ): {
    setState: Dispatch<SetStateAction<ThreadState>>
    state: ThreadState
  } => {
    const sentimentsToThreadsStateMap = {
      ALL: {
        setState: setAllSentimentAnalysisThreads,
        state: allSentimentAnalysisThreads,
      },
      NEG: {
        setState: setNegativeSentimentAnalysisThreads,
        state: negativeSentimentAnalysisThreads,
      },
      NEU: {
        setState: setNeutralSentimentAnalysisThreads,
        state: neutralSentimentAnalysisThreads,
      },
      POS: {
        setState: setPositiveSentimentAnalysisThreads,
        state: positiveSentimentAnalysisThreads,
      },
    }

    return sentiment
      ? sentimentsToThreadsStateMap[sentiment]
      : sentimentsToThreadsStateMap.ALL
  }

  const allSentimentAnalysisThreadsQuery =
    useGetAllSentimentAnalysisThreadsQuery({
      campaign: themeId,
      fromDate: startDate || undefined,
      isLost: sentimentAnalysesFilters?.isLost,
      lastDate:
        allSentimentAnalysisThreads.isScrolledDown &&
        allSentimentAnalysisThreads.data
          ? allSentimentAnalysisThreads.data[0]?.published_at?.toString()
          : getEndOfToday(),
      limit: allSentimentAnalysisThreads.isScrolledDown
        ? (allSentimentAnalysisThreads.data?.length || 20) + 10
        : allSentimentAnalysisThreads.data?.length || 20,
      selectedKeywords: sentimentAnalysesFilters?.selectedKeywords,
      selectedLanguages: sentimentAnalysesFilters?.selectedLanguages,
      selectedMentionFormats: sentimentAnalysesFilters?.selectedMentionFormats,
      selectedSources: sentimentAnalysesFilters?.selectedSources,
      toDate: endDate || undefined,
    })

  const positiveSentimentAnalysisThreadsQuery =
    useGetPositiveSentimentAnalysisThreadsQuery({
      campaign: themeId,
      fromDate: startDate || undefined,
      isLost: sentimentAnalysesFilters?.isLost,
      lastDate:
        positiveSentimentAnalysisThreads.isScrolledDown &&
        positiveSentimentAnalysisThreads.data
          ? positiveSentimentAnalysisThreads.data[0]?.published_at?.toString()
          : getEndOfToday(),
      limit: positiveSentimentAnalysisThreads.isScrolledDown
        ? (positiveSentimentAnalysisThreads.data?.length || 20) + 10
        : positiveSentimentAnalysisThreads.data?.length || 20,
      selectedKeywords: sentimentAnalysesFilters?.selectedKeywords,
      selectedLanguages: sentimentAnalysesFilters?.selectedLanguages,
      selectedMentionFormats: sentimentAnalysesFilters?.selectedMentionFormats,
      selectedSources: sentimentAnalysesFilters?.selectedSources,
      toDate: endDate || undefined,
    })

  const negativeSentimentAnalysisThreadsQuery =
    useGetNegativeSentimentAnalysisThreadsQuery({
      campaign: themeId,
      fromDate: startDate || undefined,
      isLost: sentimentAnalysesFilters?.isLost,
      lastDate:
        negativeSentimentAnalysisThreads.isScrolledDown &&
        negativeSentimentAnalysisThreads.data
          ? negativeSentimentAnalysisThreads.data[0]?.published_at?.toString()
          : getEndOfToday(),
      limit: negativeSentimentAnalysisThreads.isScrolledDown
        ? (negativeSentimentAnalysisThreads.data?.length || 20) + 10
        : negativeSentimentAnalysisThreads.data?.length || 20,
      selectedKeywords: sentimentAnalysesFilters?.selectedKeywords,
      selectedLanguages: sentimentAnalysesFilters?.selectedLanguages,
      selectedMentionFormats: sentimentAnalysesFilters?.selectedMentionFormats,
      selectedSources: sentimentAnalysesFilters?.selectedSources,
      toDate: endDate || undefined,
    })

  const neutralSentimentAnalysisThreadsQuery =
    useGetNeutralSentimentAnalysisThreadsQuery({
      campaign: themeId,
      fromDate: startDate || undefined,
      lastDate:
        neutralSentimentAnalysisThreads.isScrolledDown &&
        neutralSentimentAnalysisThreads.data
          ? neutralSentimentAnalysisThreads.data[0]?.published_at?.toString()
          : getEndOfToday(),
      limit: neutralSentimentAnalysisThreads.isScrolledDown
        ? (neutralSentimentAnalysisThreads.data?.length || 20) + 10
        : neutralSentimentAnalysisThreads.data?.length || 20,
      selectedKeywords: sentimentAnalysesFilters?.selectedKeywords,
      selectedLanguages: sentimentAnalysesFilters?.selectedLanguages,
      selectedMentionFormats: sentimentAnalysesFilters?.selectedMentionFormats,
      selectedSources: sentimentAnalysesFilters?.selectedSources,
      toDate: endDate || undefined,
    })

  const getThreadsQueryFromSentiment = (
    sentiment?: AnalyticsFetchThreadsSentimentType
  ): UseQueryResult<AnalyticsFetchThreadsResponse | null, unknown> => {
    const sentimentsToThreadsQueryMap = {
      ALL: allSentimentAnalysisThreadsQuery,
      NEG: negativeSentimentAnalysisThreadsQuery,
      NEU: neutralSentimentAnalysisThreadsQuery,
      POS: positiveSentimentAnalysisThreadsQuery,
    }

    return sentiment
      ? sentimentsToThreadsQueryMap[sentiment]
      : sentimentsToThreadsQueryMap.ALL
  }

  const setThreadsDataBySentiment = (
    sentiment?: AnalyticsFetchThreadsSentimentType
  ) => {
    const { setState } = getThreadsStateFromSentiment(sentiment)

    const { data } = getThreadsQueryFromSentiment(sentiment)

    if (data) {
      setState((prev) => ({ ...prev, data: data.data || null }))
    }
  }

  const refetchThreadsBySentiment = (
    sentiment?: AnalyticsFetchThreadsSentimentType
  ) => {
    const { isScrolledUp, isScrolledDown } =
      getThreadsStateFromSentiment(sentiment).state
    const { refetch, isLoading } = getThreadsQueryFromSentiment(sentiment)

    const shouldRefetch = isScrolledUp || isScrolledDown

    if (shouldRefetch && !isLoading) {
      refetch()
    }
  }

  const handleThreadsScroll = (
    event: UIEvent,
    sentiment?: AnalyticsFetchThreadsSentimentType
  ) => {
    if (!event.currentTarget) {
      return
    }

    const { scrollTop, scrollHeight } = event.currentTarget

    const {
      state: { isScrolledUp, isScrolledDown },
      setState,
    } = getThreadsStateFromSentiment(sentiment)

    const isScrolledCloseToTop = scrollTop < 40
    const isScrolledCloseToBottom =
      scrollTop > scrollHeight - SENTIMENT_ANALYSIS_CONTAINER_HEIGHT - 40

    if (isScrolledCloseToTop !== isScrolledUp) {
      setState((prev) => ({ ...prev, isScrolledUp: isScrolledCloseToTop }))
    }

    if (isScrolledCloseToBottom !== isScrolledDown) {
      setState((prev) => ({ ...prev, isScrolledDown: isScrolledCloseToBottom }))
    }
  }

  const handleDownloadCallback = async () => {
    const response =
      await AnalyticsApi.v1Private.analyticsFetchCampaignExcelList({
        campaign: themeId,
        fromDate: startDate,
        isLost: sentimentAnalysesFilters?.isLost as
          | FetchThreadsCSVIsLostType
          | undefined,
        languages: sentimentAnalysesFilters?.selectedLanguages,
        mentionFormat: sentimentAnalysesFilters?.selectedMentionFormats,
        selectedKeywords: sentimentAnalysesFilters?.selectedKeywords,
        selectedSources: sentimentAnalysesFilters?.selectedSources,
        toDate: endDate,
      })

    const url = window.URL.createObjectURL(await response.blob())

    const contentDisposition = response.headers.get('content-disposition')

    let fileName = 'downloaded.csv'

    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/)

      if (fileNameMatch?.length === 2) {
        const [, ...rest] = fileNameMatch[1].split('.')

        fileName = `${rest.join('.')}.csv`
      }
    }

    return {
      fileName,
      url,
    }
  }

  const handleCSVDownload = async () => {
    try {
      await handleFileDownload(handleDownloadCallback)
    } catch (error) {
      toast.error(en.ERROR_CSV)
    }
  }

  useEffect(() => {
    setThreadsDataBySentiment()
  }, [allSentimentAnalysisThreadsQuery.data])

  useEffect(() => {
    setThreadsDataBySentiment(AnalyticsFetchThreadsSentimentType.POS)
  }, [positiveSentimentAnalysisThreadsQuery.data])

  useEffect(() => {
    setThreadsDataBySentiment(AnalyticsFetchThreadsSentimentType.NEG)
  }, [negativeSentimentAnalysisThreadsQuery.data])

  useEffect(() => {
    setThreadsDataBySentiment(AnalyticsFetchThreadsSentimentType.NEU)
  }, [neutralSentimentAnalysisThreadsQuery.data])

  useEffect(() => {
    refetchThreadsBySentiment()
  }, [
    allSentimentAnalysisThreads.isScrolledUp,
    allSentimentAnalysisThreads.isScrolledDown,
  ])

  useEffect(() => {
    refetchThreadsBySentiment(AnalyticsFetchThreadsSentimentType.POS)
  }, [
    positiveSentimentAnalysisThreads.isScrolledUp,
    positiveSentimentAnalysisThreads.isScrolledDown,
  ])

  useEffect(() => {
    refetchThreadsBySentiment(AnalyticsFetchThreadsSentimentType.NEG)
  }, [
    negativeSentimentAnalysisThreads.isScrolledUp,
    negativeSentimentAnalysisThreads.isScrolledDown,
  ])

  useEffect(() => {
    refetchThreadsBySentiment(AnalyticsFetchThreadsSentimentType.NEU)
  }, [
    neutralSentimentAnalysisThreads.isScrolledUp,
    neutralSentimentAnalysisThreads.isScrolledDown,
  ])

  useEffect(() => {
    if (themeId) {
      refetchCalendar()
    }

    if (sessionId && startDate && endDate) {
      refetchDailyTrends()

      refetchTopSources()

      refetchSentimentChart()

      refetchSentimentAnalysis()

      refetchLocations()

      allSentimentAnalysisThreadsQuery.refetch()
      positiveSentimentAnalysisThreadsQuery.refetch()
      negativeSentimentAnalysisThreadsQuery.refetch()
      neutralSentimentAnalysisThreadsQuery.refetch()
    }
  }, [sessionId, themeId, startDate, endDate, sentimentAnalysesFilters])

  useEffect(() => {
    if (startDate && endDate) {
      refetchSentimentChart()
    }
  }, [filters, startDate, endDate])

  useEffect(() => {
    setSelectedSentimentChartYear(
      sentimentChartRangeStartYear !== sentimentChartRangeEndYear
        ? moment(endDate || customCalendarRange?.lastDate).year()
        : null
    )
  }, [sentimentChartRangeStartYear, sentimentChartRangeEndYear])

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 })
  }, [])

  return (
    <div className={styles.themeDetail}>
      <div className="flex items-center justify-between">
        <BackNavigationButton
          className="mb-0"
          title={themeName}
          url={backURL}
        />

        <Button
          background="white"
          isDisabled={isCsvLoading}
          onClick={() => handleCSVDownload()}
        >
          <FileDownloadLineSvg className="mr-2" />

          {en.downloadCsvReport}
        </Button>
      </div>

      <DailyTrendsSection
        chartData={dailyTrendsData || undefined}
        isLoading={isDailyTrendsFetching}
      />

      <SentimentChartSection
        chartData={sentimentChartData || undefined}
        isLoading={isLoadingSentimentChart}
        isNextYearDisabled={
          !!selectedSentimentChartYear &&
          selectedSentimentChartYear >= sentimentChartRangeEndYear
        }
        isPrevYearDisabled={
          !!selectedSentimentChartYear &&
          selectedSentimentChartYear <= sentimentChartRangeStartYear
        }
        onFilterSelect={(filters) => setFilters(filters)}
        onNextYearClick={() => {
          setSelectedSentimentChartYear((prev) => (prev || 0) + 1)
        }}
        onPrevYearClick={() => {
          setSelectedSentimentChartYear((prev) => (prev || 0) - 1)
        }}
        selectedYear={selectedSentimentChartYear || undefined}
      />

      <SentimentAnalysesSection
        allThreadsData={allSentimentAnalysisThreads.data || undefined}
        applyFilters={(params) => {
          setSentimentAnalysesFilters((prev) => {
            return {
              ...prev,
              isLost: params.isLost,
              selectedKeywords: params.selectedKeywords.join(','),
              selectedLanguages: params.selectedLanguages.join(','),
              selectedMentionFormats: params.selectedMentionFormats.join(','),
              selectedSources: params.selectedSources.join(','),
            }
          })
        }}
        chartData={sentimentAnalysisData || undefined}
        handleThreadsScroll={handleThreadsScroll}
        hasFilters
        isLoading={
          isSentimentAnalysisFetching ||
          allSentimentAnalysisThreadsQuery.isFetching ||
          negativeSentimentAnalysisThreadsQuery.isFetching ||
          neutralSentimentAnalysisThreadsQuery.isFetching ||
          positiveSentimentAnalysisThreadsQuery.isFetching
        }
        negativeThreadsData={negativeSentimentAnalysisThreads.data || undefined}
        neutralThreadsData={neutralSentimentAnalysisThreads.data || undefined}
        positiveThreadsData={positiveSentimentAnalysisThreads.data || undefined}
      />

      <TopSourcesSection
        chartData={topSourcesData || undefined}
        isLoading={isTopSourcesFetching}
      />

      <KeywordAnalyses
        applyFilters={(params) => {
          setKeywordAnalysesFilters({
            filterChoice: params.type,
            filterCount: params.amount,
            selectedKeywords: params.selectedKeywords?.join(','),
          })
        }}
        isLoading={isKeywordsAnalysisFetching}
        keywordAnalysesFilters={keywordAnalysesFilters}
        keywordsAnalysisData={keywordsAnalysisData || undefined}
      />

      <div className={styles.horizontalContainer}>
        <LocationChartSection
          chartData={locationsData}
          isFullWidth
          isLoading={isLocationsFetching}
          onZoomChange={(zoom) => setLocationZoom(zoom)}
          themeName={themeName}
          visibleTabs={[LocationSectionTab.CHART, LocationSectionTab.TABLE]}
        />
      </div>

      <MentionsByMonth
        chartData={calendarData || undefined}
        isLoading={isCalendarFetching}
      />
    </div>
  )
}

export default ThemeDetail
