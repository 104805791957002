import { useEffect } from 'react'

export const useDisableScroll = (shouldDisable: boolean) => {
  useEffect(() => {
    const originalStyle = document.body.style.overflowY

    if (shouldDisable) {
      document.body.style.overflowY = 'hidden'
    } else {
      document.body.style.overflowY = 'auto'
    }

    return () => {
      document.body.style.overflowY = originalStyle
    }
  }, [shouldDisable])
}
