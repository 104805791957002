export enum QueryKey {
  CUSTOM_CALENDAR_RANGE = 'customCalendarRange',
  GET_ADMIN_THREADS = 'getAdminThreads',
  GET_ALL_SENTIMENT_ANALYSIS_THREADS = 'getAllSentimentAnalysisThreads',
  GET_CALENDAR_BY_CAMPAIGN = 'getCalendarByCampaign',
  GET_CALENDAR_BY_CATEGORY = 'getCalendarByCategory',
  GET_CAMPAIGNS = 'getCampaigns',
  GET_CAMPAIGN_BY_ID = 'getCampaignById',
  GET_CAMPAIGN_CATEGORIES = 'getCampaignCategories',
  GET_CAMPAIGN_TARGETS = 'getCampaignTargets',
  GET_CAMPAIGN_TOP_LOCATION = 'getCampaignTopLocation',
  GET_CAMPAIGN_TOP_SOURCE = 'getCampaignTopSource',
  GET_DAILY_POSITIVITY_CAMPAIGN = 'getDailyPositivityCampaign',
  GET_DAILY_TRENDS = 'getDailyTrends',
  GET_DAILY_TRENDS_BY_CAMPAIGN = 'getDailyTrendsByCampaign',
  GET_DAILY_TRENDS_BY_CATEGORY = 'getDailyTrendsByCategory',
  GET_DAILY_TRENDS_BY_KEYWORD = 'getDailyTrendsByKeyword',
  GET_FACEBOOK_LOGIN = 'getFacebookLogin',
  GET_HOME_CALENDAR = 'getHomeCalendar',
  GET_HOME_CALENDAR_DETAILS = 'getHomeCalendarDetails',
  GET_HOT_LINKS = 'getHotLinks',
  GET_KEYWORDS_ANALYSIS = 'getKeywordsAnalysis',
  GET_KEYWORDS_WITH_STATS = 'getKeywordsWithStats',
  GET_LOCATIONS = 'getLocations',
  GET_MOST_MENTIONS = 'getMostMentions',
  GET_NEGATIVE_LIVE_NOTIFICATIONS = 'getNegativeLiveNotifications',
  GET_NEG_SENTIMENT_ANALYSIS_THREADS = 'getNegSentimentAnalysisThreads',
  GET_NEU_SENTIMENT_ANALYSIS_THREADS = 'getNeuSentimentAnalysisThreads',
  GET_POSITIVE_LIVE_NOTIFICATIONS = 'getPositiveLiveNotifications',
  GET_POSITIVITY_PERCENT = 'getPositivityPercent',
  GET_POS_SENTIMENT_ANALYSIS_THREADS = 'getPosSentimentAnalysisThreads',
  GET_QUICK_DETAILS = 'getQuickDetails',
  GET_RADAR_CHART = 'getRadarChart',
  GET_SELF = 'getSelf',
  GET_SENTIMENT_ANALYSIS_BY_CAMPAIGN = 'getSentimentAnalysisByCampaign',
  GET_SENTIMENT_ANALYSIS_BY_CATEGORY = 'getSentimentAnalysisByCategory',
  GET_SENTIMENT_ANALYSIS_BY_KEYWORD = 'getSentimentAnalysisByKeyword',
  GET_SENTIMENT_CHART_BY_CAMPAIGN = 'getSentimentChartByCampaign',
  GET_SENTIMENT_CHART_BY_CATEGORY = 'getSentimentChartByCategory',
  GET_SENTIMENT_CHART_BY_KEYWORD = 'getSentimentChartByKeyword',
  GET_TOP_KEYWORDS = 'getTopKeywords',
  GET_TOP_SOURCES_BY_CAMPAIGN = 'getTopSourcesByCampaign',
  GET_TOP_SOURCES_BY_CATEGORY = 'getTopSourcesByCategory',
  GET_TOP_SOURCES_BY_KEYWORD = 'getTopSourcesByKeyword',
  GET_TRIPADVISOR_PLACES = 'getTripAdvisorPlaces',
  INFORMATION_TOOLTIPS = 'informationTooltips',
  SUGGESTIONS_CATEGORIES = 'suggestionsCategories',
  SUGGEST_WORD = 'suggestWord',
  SUGGEST_WORD_CREATE_CAMPAIGN_KEYWORD = 'suggestWordCreateCampaignKeyword',
}
