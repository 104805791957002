import { FC, MouseEventHandler } from 'react'
import classNames from 'classnames'
import { ReactComponent as FilterSvg } from 'src/assets/svg/filter.svg'
import en from 'src/constants/en'
import styles from './cardFilterButton.module.scss'

interface CardFilterButtonProps {
  className?: string
  label?: string
  onClick: MouseEventHandler<HTMLButtonElement>
}

const CardFilterButton: FC<CardFilterButtonProps> = ({
  label = en.filter,
  onClick,
  className,
}) => {
  return (
    <button
      className={classNames(styles.cardFilterButton, className)}
      onClick={onClick}
    >
      <FilterSvg className={styles.filterIcon} />

      {label}
    </button>
  )
}

export default CardFilterButton
