import { ReactElement, FC, useState, useEffect, MouseEvent } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import 'react-tagsinput/react-tagsinput.css'
import TagsInput from 'react-tagsinput'
import Tooltip from 'rc-tooltip'
import { toast } from 'react-toastify'
import classNames from 'classnames'
import { CAMPAIGNS_ROUTE } from 'src/constants/routes'
import en from 'src/constants/en'
import { getSessionIdFromLocalStorage } from 'src/localStorage/sessionId/getSessionIdFromLocalStorage/getSessionIdFromLocalStorage'
import { useGetCampaignByIdQuery } from 'src/reactQuery/useGetCampaignById'
import { useUpdateCampaignMutation } from 'src/reactQuery/useUpdateCampaignMutation'
import { Tag } from 'src/components/Tag'
import { useUpdateRelevancy } from 'src/reactQuery/useUpdateRelevancy'
import {
  ENTER_KEY_CODE,
  SPACE_KEY_CODE,
  FB_SUGGESTIONS,
  FB_TARGET_ENDPOINT_VALIDATION_REGEX,
  IG_SUGGESTIONS,
  INPUT_PROPS,
  TARGETS,
  MAX_TAGS,
} from 'src/constants/campaigns'
import { CampaignInput } from 'src/components/CampaignInput'
import useCampaignNameInput from 'src/hooks/useCampaignNameInput'
import { convertArrayToLowerCase } from 'src/utils/convertArrayToLowerCase'
import { useCustomLink } from 'src/hooks/useCustomLink'
import {
  CampaignPayloadKeyword,
  CampaignPayload,
} from 'src/services/__generated__/api'
import { COLORS } from 'src/constants/colors'
import { BackNavigationButton } from 'src/components/BackNavigationButton'
import Button from 'src/components/Button'
import { AddKeywordInput } from 'src/components/AddKeywordInput'
import { cn } from 'src/utils/cn'
import styles from './editCampaign.module.scss'
import KeywordFormCard from '../AddNewCampaign/KeywordFormCard'
import hexToRgbA from '../../utils/hexToRgba'
import { removeFromArray } from '../../utils/removeFromArray'
import { insertIntoArrayAtGivenIndex } from '../../utils/arrayMethods'
import TimezoneSelectInput from '../AddNewCampaign/TimezoneSelectInput'

const EditCampaign: FC = (): ReactElement => {
  const { id } = useParams<{ id?: string }>()

  const navigate = useNavigate()

  const [campaignName, setCampaignName] = useCampaignNameInput('')

  const [timezoneOffset, setTimezoneOffset] = useState<number>(0)

  const [timezoneName, setTimezoneName] = useState<string>('GMT')

  // const [savedCategories, setSavedCategories] = useState<string[]>([])

  // const [selectedCategories, setSelectedCategories] = useState<string[]>([])

  const [deletedKeyWords, setDeletedKeyWords] = useState<string[]>([])

  const [addedTargetsArr, setAddedTargetsArr] = useState<string[]>([])

  const [currentKeywordInput, setCurrentKeywordInput] = useState<string>('')

  const [addedKeywordsArr, setAddedKeywordsArr] = useState<
    (string | undefined)[]
  >([])

  const [registeredKeywordsArr, setRegisteredKeywordsArr] = useState<
    CampaignPayloadKeyword[]
  >([])

  // TODO: uncomment after relevancy functionality is ready
  // const [currentRelevancyKeyword, setCurrentRelevancyKeyword] =
  //   useState<KeywordInterface | null>(null)

  const [fbTargetEndpoints, setFbTargetEndpoints] = useState<string[]>([])

  const [igTargetEndpoints, setIgTargetEndpoints] = useState<string[]>([])

  const [tags, setTags] = useState<string[]>([])

  const {
    data: campaignData,
    isLoading: isLoadingCampaign,
    refetch: refetchCampaign,
  } = useGetCampaignByIdQuery({ id })

  const backURL = useCustomLink(CAMPAIGNS_ROUTE)

  // const {
  //   data: categoriesData,
  //   isLoading: isLoadingCategory,
  //   refetch: refetchCategories,
  // } = useGetCampaignCategoriesQuery()

  // const {
  //   data: targetsData,
  //   isLoading: isLoadingTargets,
  //   refetch: refetchTargets,
  // } = useGetCampaignTargetsQuery()

  const [draft, setDraft] = useState(false)

  const handleAddTags = (updatedTags: string[]) => {
    const isRemoving = updatedTags.length < tags.length

    if (isRemoving) {
      setTags(updatedTags)
      return
    }

    const latestTag = updatedTags.at(-1)?.trim() ?? ''
    const lowerCaseLatestTag = latestTag.toLowerCase()

    updatedTags.splice(-1, 1, latestTag)

    if (convertArrayToLowerCase(tags).includes(lowerCaseLatestTag)) {
      toast.error(en.TAG_ERROR_ALREADY_IN_USE)
      return
    }

    if (updatedTags.length > MAX_TAGS) {
      toast.error(en.TAG_ERROR_LIMIT)
      return
    }

    if (lowerCaseLatestTag && lowerCaseLatestTag.split(' ').length > 3) {
      toast.error(en.TAG_ERROR_TOO_MANY_WORDS)
      return
    }

    let isTagUsedAsKeyword = false
    let isTagUsedAsKeywordCategory = false
    let isTagUsedAsPositiveSentiment = false
    let isTagUsedAsNegativeSentiment = false

    registeredKeywordsArr.forEach(
      ({ categories, name, negative, positive }) => {
        if (name?.toLowerCase() === lowerCaseLatestTag) {
          isTagUsedAsKeyword = true
        }

        if (
          convertArrayToLowerCase(categories || []).includes(lowerCaseLatestTag)
        ) {
          isTagUsedAsKeywordCategory = true
        }

        if (
          convertArrayToLowerCase(negative || []).includes(lowerCaseLatestTag)
        ) {
          isTagUsedAsNegativeSentiment = true
        }

        if (
          convertArrayToLowerCase(positive || []).includes(lowerCaseLatestTag)
        ) {
          isTagUsedAsPositiveSentiment = true
        }
      }
    )

    if (isTagUsedAsKeyword) {
      toast.error(en.TAG_ERROR_ALREADY_USED_AS_KEYWORD)
      return
    }

    if (isTagUsedAsKeywordCategory) {
      toast.error(en.TAG_ERROR_ALREADY_USED_AS_KEYWORD_CATEGORY)
      return
    }

    if (isTagUsedAsNegativeSentiment) {
      toast.error(en.TAG_ERROR_ALREADY_USED_AS_NEGATIVE_SENTIMENT)
      return
    }

    if (isTagUsedAsPositiveSentiment) {
      toast.error(en.TAG_ERROR_ALREADY_USED_AS_POSITIVE_SENTIMENT)
      return
    }

    toast.success(en.TAG_SUCCESS_ADDED)
    setTags(updatedTags)
  }

  const emptyState = () => {
    setDraft(false)
    setCampaignName('')
    setTimezoneOffset(0)
    setTimezoneName('GMT')
    // setSavedCategories([])
    setAddedTargetsArr([])
    setRegisteredKeywordsArr([])
    setAddedKeywordsArr([])
    setFbTargetEndpoints([])
    setIgTargetEndpoints([])
    setTags([])
  }

  const mutation = useUpdateCampaignMutation({
    onError() {
      console.error('error')
    },
    onSuccess() {
      refetchCampaign()
      // refetchCategories()
      // refetchTargets()

      navigate(backURL)

      emptyState()
    },
    updateType: draft ? 'saveAsDraft' : 'update',
  })

  const mutationRelevancy = useUpdateRelevancy()

  const sessionId = getSessionIdFromLocalStorage()

  useEffect(() => {
    emptyState()
  }, [])

  useEffect(() => {
    if (campaignData && sessionId) {
      const val = campaignData.campaign
      setCampaignName(val?.name || '')
      setTimezoneOffset(val?.timezone || 0)
      setTimezoneName(val?.timezoneName || 'GMT')
      // setSavedCategories(val?.categories || [])
      setAddedTargetsArr(val?.targets || [])
      setRegisteredKeywordsArr(val?.keywords || [])
      setTags(val?.tags || [])
      setFbTargetEndpoints(val?.facebook_target_endpoints || [])
      setIgTargetEndpoints(val?.instagram_target_endpoints || [])

      const keywordsNames = val?.keywords?.map((item) => {
        return item.name
      })

      setAddedKeywordsArr(keywordsNames || [])
    }
  }, [campaignData, sessionId])

  const addNewKeyword = () => {
    const keyword = currentKeywordInput
    const keywordArray = keyword.trim().split(' ')
    if (keywordArray.length > 10) {
      toast.error(en.KEYWORD_ERROR_OVER_10_WORDS)

      setTimeout(() => setCurrentKeywordInput(''), 1000)
      return
    }

    if (addedKeywordsArr.includes(keyword)) {
      toast.error('Keyword you entered is already in use')

      setTimeout(() => setCurrentKeywordInput(''), 1000)
      return
    }

    if (keyword.length === 0) {
      toast.error('Keyword can not be empty')

      setTimeout(() => setCurrentKeywordInput(''), 1000)
      return
    }

    setAddedKeywordsArr([...addedKeywordsArr, keyword])

    setCurrentKeywordInput('')

    toast.success('Keyword is added successfully')
  }

  // const addTargets = (val: string | undefined) => {
  //   if (val !== undefined) {
  //     if (!addedTargetsArr.includes(val)) {
  //       setAddedTargetsArr([...addedTargetsArr, val])
  //     } else {
  //       const arr = removeFromArray(addedTargetsArr.slice(), val)
  //       setAddedTargetsArr([...arr])
  //     }
  //   }
  // }

  // const handleCategoryChange = (val: string[] | undefined) => {
  //   if (val) {
  //     setSelectedCategories(val)
  //   }
  // }

  function handleChangeIgTargetEndpoints(tags: string[]): void {
    const tagsWithoutAtSymbols = tags.map((tag) => tag.replaceAll('@', ''))

    setIgTargetEndpoints(tagsWithoutAtSymbols)
  }

  async function updateCampaign() {
    if (addedTargetsArr.length === 0) {
      toast.error(en.EMPTY_TARGETS_ERROR)

      return
    }

    const payload: CampaignPayload = {
      // categories: selectedCategories,
      deletedKeyWords,
      from: campaignData?.campaign?.from ?? '',
      keywords: registeredKeywordsArr,
      name: campaignName,
      status: !draft ? 'active' : 'draft',
      tags,
      targets: addedTargetsArr,
      timezone: timezoneOffset,
      timezoneName,
      to: campaignData?.campaign?.to ?? '',
    }

    if (addedTargetsArr.includes('Facebook')) {
      payload.facebook_target_endpoints = fbTargetEndpoints
    }
    if (addedTargetsArr.includes('Instagram')) {
      payload.instagram_target_endpoints = igTargetEndpoints
    }

    mutation.mutate({ data: payload, id })
  }

  const removeIndexFromArray = (
    val: CampaignPayloadKeyword,
    index: number,
    array: CampaignPayloadKeyword[]
  ) => {
    const duplicateArray = array.slice()
    if (duplicateArray[index].name === val.name) {
      duplicateArray.splice(index, 1)
    }
    return duplicateArray
  }

  const replaceKeywordWithUpdatedOne = (
    val: CampaignPayloadKeyword,
    index: number,
    array: CampaignPayloadKeyword[]
  ): CampaignPayloadKeyword[] => {
    const reducedArr = removeIndexFromArray(val, index, array)
    const result = insertIntoArrayAtGivenIndex(reducedArr, index, val)
    return result
  }

  const handleChangeKeywordSentimentsValue = (val: CampaignPayloadKeyword) => {
    const index = registeredKeywordsArr.findIndex((item) => {
      return item.name === val.name
    })
    let registeredTempArr = registeredKeywordsArr.slice()
    if (index === -1) {
      registeredTempArr = insertIntoArrayAtGivenIndex(registeredTempArr, 0, val)
    } else {
      registeredTempArr = replaceKeywordWithUpdatedOne(
        val,
        index,
        registeredTempArr
      )
    }
    setRegisteredKeywordsArr(registeredTempArr)

    // TODO: uncomment after relevancy functionality is ready
    // setCurrentRelevancyKeyword(val)

    if (val.name && val.negative && val.positive) {
      mutationRelevancy.mutate({
        keyword: val.name,
        negatives: val.negative,
        positives: val.positive,
      })
    }
  }

  // TODO: uncomment after relevancy functionality is ready
  /*
  const handleRelevancySuggestionClick = (
    item: FetchRelevancyDataSuggestionItem,
    positivity: boolean
  ) => {
    if (!currentRelevancyKeyword) {
      return
    }

    const { from, to } = item
    const { negative, positive } = currentRelevancyKeyword

    if (positivity && positive) {
      const repeatsIndex = positive.findIndex((sentiment) => sentiment === to)
      const updatedIndex = positive.findIndex((sentiment) => sentiment === from)

      if (repeatsIndex === -1) {
        positive[updatedIndex] = to
      } else {
        positive.splice(updatedIndex, 1)
      }
    } else if (negative) {
      const repeatsIndex = negative.findIndex((sentiment) => sentiment === to)
      const updatedIndex = negative.findIndex((sentiment) => sentiment === from)

      if (repeatsIndex === -1) {
        negative[updatedIndex] = to
      } else {
        negative.splice(updatedIndex, 1)
      }
    }

    handleChangeKeywordSentimentsValue({
      ...currentRelevancyKeyword,
      negative,
      positive,
    })
  }
  */

  function savedPositiveSentimentsByKeyword(name?: string, index?: number) {
    return registeredKeywordsArr.find((keyword, jindex) => {
      return keyword.name === name && index === jindex
    })?.positive
  }

  function savedNegativeSentimentsByKeyword(name?: string, index?: number) {
    return registeredKeywordsArr.find((keyword, jindex) => {
      return keyword.name === name && index === jindex
    })?.negative
  }

  const handleUpdateCampaign = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    updateCampaign()
  }

  const handleSaveAsDraft = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setDraft(true)
  }

  const handleDeleteKeyword = (keyword: string) => {
    const temp = removeFromArray(addedKeywordsArr, keyword)

    setAddedKeywordsArr(temp)

    const index = registeredKeywordsArr.findIndex(
      (item) => item.name === keyword
    )

    const tempRegistered = registeredKeywordsArr.slice()

    if (index !== -1) {
      tempRegistered.splice(index, 1)
    }

    setRegisteredKeywordsArr(tempRegistered)

    setDeletedKeyWords((prevState) => [...prevState, keyword])
  }

  const handleKeywordUpdate = (keyword: string, index: number): void => {
    setAddedKeywordsArr((prevKeywords) => {
      const temp = [...prevKeywords]

      temp[index] = keyword

      return temp
    })

    setRegisteredKeywordsArr((prevKeywords) => {
      const temp = [...prevKeywords]

      temp[index].name = keyword

      return temp
    })
  }

  const onSelectAllTargets = () => {
    setAddedTargetsArr(addedTargetsArr.length === TARGETS.length ? [] : TARGETS)
  }

  useEffect(() => {
    if (draft !== false) updateCampaign()
  }, [draft])

  if (isLoadingCampaign) {
    return <div>{en.loading}...</div>
  }

  return (
    <div className={styles.editCampaignBody}>
      <BackNavigationButton
        handleClick={() => emptyState()}
        title={en.updateCampaign}
        url={backURL}
      />

      <div className={styles.body}>
        <form className={styles.editCampaignForm}>
          <div className={styles.formSection}>
            <div>
              <label className={styles.sectionLabel}>{en.campaignName}</label>

              <CampaignInput
                onChange={setCampaignName}
                placeholder={en.enterNameOfCampaign}
                themeName={campaignName}
              />
            </div>

            {/*           
            <div className={styles.formSection}>
              <div
                className={styles.sectionLabel}
              >
                {en.category}
              </div>

              <DropdownCheckboxes
                borderRadius="10px"
                handleChange={handleCategoryChange}
                height="48px"
                keepTitle={false}
                options={categoriesData?.categories}
                position="static"
                savedCategorySelection={savedCategories}
                title="Choose category"
                width="569px"
              />
            </div> */}
          </div>

          <div
            className={classNames(styles.formSection, styles.timezoneSection)}
          >
            <h3 className={styles.sectionLabel}>{en.timezone}</h3>

            <TimezoneSelectInput
              onChange={(offset, name) => {
                setTimezoneOffset(offset)
                setTimezoneName(name)
              }}
              timezoneName={timezoneName}
            />
          </div>

          <div className={styles.formSection}>
            <div
              className={cn(
                styles.sectionLabel,
                'flex items-center justify-between'
              )}
            >
              <span>{en.target}</span>

              <button
                className={cn('text-malibu', 'text-[14px]')}
                onClick={() => onSelectAllTargets()}
                type="button"
              >
                <span>
                  {addedTargetsArr.length === TARGETS.length
                    ? en.unSelectAll
                    : en.selectAll}
                </span>
              </button>
            </div>

            <div className={styles.editCampaignTargets}>
              {TARGETS.map((item) => (
                <Tooltip
                  key={item}
                  overlay={
                    <div className={styles.tooltip}>{en.tagTooltip}</div>
                  }
                  placement="top"
                  showArrow={false}
                  trigger={['hover']}
                >
                  <Tag
                    backgroundColor={
                      addedTargetsArr.includes(item)
                        ? COLORS.malibu
                        : hexToRgbA(COLORS.periwinkle, 0.5)
                    }
                    color={
                      addedTargetsArr.includes(item)
                        ? COLORS.white
                        : COLORS.shark
                    }
                    cursor="not-allowed"
                    fontSize="14px"
                    fontWeight={500}
                    // handleClick={addTargets}
                    height="36px"
                    lineHeight="16.41px"
                    marginRight="8px"
                    marginTop="8px"
                    padding="10px 16px"
                    title={item}
                    tooltipContent={en.tagTooltip}
                  />
                </Tooltip>
              ))}
            </div>
          </div>

          <div
            className={classNames(
              styles.formSection,
              !addedTargetsArr.includes('Facebook') && styles.hidden
            )}
          >
            <label className={styles.sectionLabel}>
              {en.facebookTargetEndpoints}
            </label>

            <TagsInput
              addKeys={[ENTER_KEY_CODE, SPACE_KEY_CODE]}
              inputProps={INPUT_PROPS}
              onChange={(tags) => setFbTargetEndpoints(tags)}
              validationRegex={FB_TARGET_ENDPOINT_VALIDATION_REGEX}
              value={fbTargetEndpoints}
            />

            <div className={styles.suggestionWrapper}>
              <span className={styles.suggestionLabel}>{en.suggested}:</span>
              &nbsp;
              {FB_SUGGESTIONS.map((item) => (
                <span className={styles.suggestionValue} key={item}>
                  {item}
                </span>
              ))}
            </div>
          </div>

          <div
            className={classNames(
              styles.formSection,
              !addedTargetsArr.includes('Instagram') && styles.hidden
            )}
          >
            <div className={styles.sectionLabel}>
              {en.instagramTargetEndpoints}
            </div>

            <TagsInput
              addKeys={[ENTER_KEY_CODE, SPACE_KEY_CODE]}
              inputProps={INPUT_PROPS}
              onChange={(tags) => handleChangeIgTargetEndpoints(tags)}
              value={igTargetEndpoints}
            />

            <div className={styles.suggestionWrapper}>
              <span className={styles.suggestionLabel}>{en.suggested}:</span>
              &nbsp;
              {IG_SUGGESTIONS.map((item) => (
                <span className={styles.suggestionValue} key={item}>
                  {item}
                </span>
              ))}
            </div>
          </div>

          <div className={styles.tagsWrapper}>
            <div className={styles.sectionLabel}>{en.tags}</div>

            <TagsInput
              addKeys={[ENTER_KEY_CODE]}
              inputProps={INPUT_PROPS}
              onChange={(tags) => handleAddTags(tags)}
              value={tags}
            />
          </div>

          <div className={styles.formSection}>
            <AddKeywordInput
              addNewKeyword={addNewKeyword}
              currentKeywordInput={currentKeywordInput}
              keywordCount={addedKeywordsArr.length}
              setCurrentKeywordInput={setCurrentKeywordInput}
            />
          </div>

          <div className={styles.formSection}>
            {addedKeywordsArr.map((item, index) => (
              <KeywordFormCard
                handleDelete={handleDeleteKeyword}
                key={item}
                keyword={item}
                negatives={savedNegativeSentimentsByKeyword(item, index)}
                onChangeValue={handleChangeKeywordSentimentsValue}
                onKeywordUpdate={(newKeyword) =>
                  handleKeywordUpdate(newKeyword, index)
                }
                positives={savedPositiveSentimentsByKeyword(item, index)}
                reservedKeywords={tags}
              />
            ))}
          </div>

          <div className={styles.actionsWrapper}>
            <div className={styles.actionsWrapperInner}>
              <span>
                <button
                  className={styles.saveAsDraftButton}
                  onClick={(e) => handleSaveAsDraft(e)}
                  type="button"
                >
                  {en.saveAsDraft}
                </button>

                <Button
                  className={styles.addButton}
                  onClick={(e) => handleUpdateCampaign(e)}
                  type="submit"
                >
                  {en.updateCampaign}
                </Button>
              </span>
            </div>
          </div>
        </form>

        <div className={styles.relevancy}>
          {/* TODO: uncomment after relevancy functionality is ready */}
          {/* 
          {!!mutationRelevancy.data && (
            <Relevancy
              isLoading={mutationRelevancy.isLoading}
              onClickSuggestionItem={handleRelevancySuggestionClick}
              relevancyData={mutationRelevancy.data}
            />
          )}
          */}
        </div>
      </div>
    </div>
  )
}

export default EditCampaign
