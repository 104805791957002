import {
  FC,
  useEffect,
  useState,
  UIEvent,
  Dispatch,
  SetStateAction,
} from 'react'
import { useParams } from 'react-router-dom'
import { UseQueryResult } from '@tanstack/react-query'
import { SentimentChartSection } from 'src/charts/SentimentChartSection'
import { TopSourcesSection } from 'src/charts/TopSourcesSection'
import { SentimentAnalysesSection } from 'src/charts/SentimentAnalysesSection'
import { getSessionIdFromLocalStorage } from 'src/localStorage/sessionId/getSessionIdFromLocalStorage/getSessionIdFromLocalStorage'
import { DailyTrendsSection } from 'src/charts/DailyTrendsSection'
import { SENTIMENT_ANALYSIS_CONTAINER_HEIGHT } from 'src/constants/vars'
import { useGetAllSentimentAnalysisThreadsQuery } from 'src/reactQuery/useGetAllSentimentAnalysisThreadsQuery'
import { useGetPositiveSentimentAnalysisThreadsQuery } from 'src/reactQuery/useGetPositiveSentimentAnalysisThreadsQuery'
import { useGetNeutralSentimentAnalysisThreadsQuery } from 'src/reactQuery/useGetNeutralSentimentAnalysisThreadsQuery'
import { useGetNegativeSentimentAnalysisThreadsQuery } from 'src/reactQuery/useGetNegativeSentimentAnalysisThreadsQuery'
import {
  AnalyticsFetchThreadsResponse,
  AnalyticsFetchThreadsSentimentType,
} from 'src/services/__generated__/api'
import { OnFilterSelectParams } from 'src/charts/SentimentChartSection/interfaces/onFilterSelect.interface'
import { useGetDailyTrendsByKeywordQuery } from 'src/reactQuery/useGetDailyTrendsByKeywordQuery'
import { useGetSentimentAnalysisByKeywordQuery } from 'src/reactQuery/useGetSentimentAnalysisByKeywordQuery'
import { useGetSentimentChartByKeywordQuery } from 'src/reactQuery/useGetSentimentChartByKeywordQuery'
import { useGetTopSourcesByKeywordQuery } from 'src/reactQuery/useGetTopSourcesByKeywordQuery'
import { getEndOfToday } from 'src/utils/date'
import { useCustomLink } from 'src/hooks/useCustomLink'
import { BackNavigationButton } from 'src/components/BackNavigationButton'
import { useQueryParams } from 'src/hooks/useQueryParams'
import { GlobalQueryParams } from 'src/interfaces/globalQueryParams.interface'
import { getDateRangeByQueryParams } from 'src/utils/getDateRangeByQueryParams'
import styles from './keywordDetail.module.scss'
import { ThreadState } from '../KeywordsPage/interfaces/threadState.interface'

const INIT_THREAD_STATE: ThreadState = {
  data: null,
  isScrolledDown: false,
  isScrolledUp: false,
}

export const KeywordDetail: FC = () => {
  const sessionId = getSessionIdFromLocalStorage()

  const { queryParams } = useQueryParams<GlobalQueryParams>()

  const dates = getDateRangeByQueryParams(queryParams)

  const startDate = dates?.start
  const endDate = dates?.end

  const { themeId, keywordName } = useParams<{
    keywordName: string
    themeId: string
  }>()

  const [filters, setFilters] = useState<OnFilterSelectParams | null>(null)

  const {
    data: dailyTrendsData,
    refetch: refetchDailyTrends,
    isFetching: isDailyTrendsFetching,
  } = useGetDailyTrendsByKeywordQuery({
    campaign: themeId,
    end: endDate || undefined,
    keyword: keywordName,
    start: startDate || undefined,
  })

  const {
    data: sentimentAnalysisData,
    refetch: refetchSentimentAnalysis,
    isFetching: isSentimentAnalysesFetching,
  } = useGetSentimentAnalysisByKeywordQuery({
    campaign: themeId,
    end: endDate || undefined,
    keyword: keywordName,
    start: startDate || undefined,
  })

  const {
    data: sentimentChartData,
    refetch: refetchSentimentChart,
    isFetching: isSentimentChartFetching,
  } = useGetSentimentChartByKeywordQuery({
    campaign: themeId,
    campaigns: filters?.campaigns,
    end: endDate || undefined,
    keyword: keywordName,
    sources: filters?.sources,
    start: startDate || undefined,
  })

  const {
    data: topSourcesData,
    refetch: refetchTopSources,
    isFetching: isTopSourcesFetching,
  } = useGetTopSourcesByKeywordQuery({
    campaign: themeId,
    end: endDate || undefined,
    keyword: keywordName,
    start: startDate || undefined,
  })

  const [allSentimentAnalysisThreads, setAllSentimentAnalysisThreads] =
    useState<ThreadState>({ ...INIT_THREAD_STATE })

  const [
    positiveSentimentAnalysisThreads,
    setPositiveSentimentAnalysisThreads,
  ] = useState<ThreadState>({ ...INIT_THREAD_STATE })

  const [
    negativeSentimentAnalysisThreads,
    setNegativeSentimentAnalysisThreads,
  ] = useState<ThreadState>({ ...INIT_THREAD_STATE })

  const [neutralSentimentAnalysisThreads, setNeutralSentimentAnalysisThreads] =
    useState<ThreadState>({ ...INIT_THREAD_STATE })

  const getThreadsStateFromSentiment = (
    sentiment?: AnalyticsFetchThreadsSentimentType
  ): {
    setState: Dispatch<SetStateAction<ThreadState>>
    state: ThreadState
  } => {
    const sentimentsToThreadsStateMap = {
      ALL: {
        setState: setAllSentimentAnalysisThreads,
        state: allSentimentAnalysisThreads,
      },
      NEG: {
        setState: setNegativeSentimentAnalysisThreads,
        state: negativeSentimentAnalysisThreads,
      },
      NEU: {
        setState: setNeutralSentimentAnalysisThreads,
        state: neutralSentimentAnalysisThreads,
      },
      POS: {
        setState: setPositiveSentimentAnalysisThreads,
        state: positiveSentimentAnalysisThreads,
      },
    }

    return sentiment
      ? sentimentsToThreadsStateMap[sentiment]
      : sentimentsToThreadsStateMap.ALL
  }

  const allSentimentAnalysisThreadsQuery =
    useGetAllSentimentAnalysisThreadsQuery({
      campaign: themeId,
      keyword: keywordName,
      lastDate:
        allSentimentAnalysisThreads.isScrolledDown &&
        allSentimentAnalysisThreads.data
          ? allSentimentAnalysisThreads.data[0]?.published_at?.toString()
          : getEndOfToday(),
      limit: allSentimentAnalysisThreads.isScrolledDown
        ? (allSentimentAnalysisThreads.data?.length || 20) + 10
        : allSentimentAnalysisThreads.data?.length || 20,
    })

  const positiveSentimentAnalysisThreadsQuery =
    useGetPositiveSentimentAnalysisThreadsQuery({
      campaign: themeId,
      keyword: keywordName,
      lastDate:
        positiveSentimentAnalysisThreads.isScrolledDown &&
        positiveSentimentAnalysisThreads.data
          ? positiveSentimentAnalysisThreads.data[0]?.published_at?.toString()
          : getEndOfToday(),
      limit: positiveSentimentAnalysisThreads.isScrolledDown
        ? (positiveSentimentAnalysisThreads.data?.length || 20) + 10
        : positiveSentimentAnalysisThreads.data?.length || 20,
    })

  const negativeSentimentAnalysisThreadsQuery =
    useGetNegativeSentimentAnalysisThreadsQuery({
      campaign: themeId,
      keyword: keywordName,
      lastDate:
        negativeSentimentAnalysisThreads.isScrolledDown &&
        negativeSentimentAnalysisThreads.data
          ? negativeSentimentAnalysisThreads.data[0]?.published_at?.toString()
          : getEndOfToday(),
      limit: negativeSentimentAnalysisThreads.isScrolledDown
        ? (negativeSentimentAnalysisThreads.data?.length || 20) + 10
        : negativeSentimentAnalysisThreads.data?.length || 20,
    })

  const neutralSentimentAnalysisThreadsQuery =
    useGetNeutralSentimentAnalysisThreadsQuery({
      campaign: themeId,
      keyword: keywordName,
      lastDate:
        neutralSentimentAnalysisThreads.isScrolledDown &&
        neutralSentimentAnalysisThreads.data
          ? neutralSentimentAnalysisThreads.data[0]?.published_at?.toString()
          : getEndOfToday(),
      limit: neutralSentimentAnalysisThreads.isScrolledDown
        ? (neutralSentimentAnalysisThreads.data?.length || 20) + 10
        : neutralSentimentAnalysisThreads.data?.length || 20,
    })

  const getThreadsQueryFromSentiment = (
    sentiment?: AnalyticsFetchThreadsSentimentType
  ): UseQueryResult<AnalyticsFetchThreadsResponse | null, unknown> => {
    const sentimentsToThreadsQueryMap = {
      ALL: allSentimentAnalysisThreadsQuery,
      NEG: negativeSentimentAnalysisThreadsQuery,
      NEU: neutralSentimentAnalysisThreadsQuery,
      POS: positiveSentimentAnalysisThreadsQuery,
    }

    return sentiment
      ? sentimentsToThreadsQueryMap[sentiment]
      : sentimentsToThreadsQueryMap.ALL
  }

  const setThreadsDataBySentiment = (
    sentiment?: AnalyticsFetchThreadsSentimentType
  ) => {
    const { setState } = getThreadsStateFromSentiment(sentiment)
    const { data } = getThreadsQueryFromSentiment(sentiment)

    if (data) {
      setState((prev) => ({ ...prev, data: data.data || null }))
    }
  }

  useEffect(() => {
    setThreadsDataBySentiment()
  }, [allSentimentAnalysisThreadsQuery.data])
  useEffect(() => {
    setThreadsDataBySentiment(AnalyticsFetchThreadsSentimentType.POS)
  }, [positiveSentimentAnalysisThreadsQuery.data])
  useEffect(() => {
    setThreadsDataBySentiment(AnalyticsFetchThreadsSentimentType.NEG)
  }, [negativeSentimentAnalysisThreadsQuery.data])
  useEffect(() => {
    setThreadsDataBySentiment(AnalyticsFetchThreadsSentimentType.NEU)
  }, [neutralSentimentAnalysisThreadsQuery.data])

  const refetchThreadsBySentiment = (
    sentiment?: AnalyticsFetchThreadsSentimentType
  ) => {
    const { isScrolledUp, isScrolledDown } =
      getThreadsStateFromSentiment(sentiment).state
    const { refetch, isLoading } = getThreadsQueryFromSentiment(sentiment)

    const shouldRefetch = isScrolledUp || isScrolledDown

    if (shouldRefetch && !isLoading) {
      refetch()
    }
  }

  useEffect(() => {
    refetchThreadsBySentiment()
  }, [
    allSentimentAnalysisThreads.isScrolledUp,
    allSentimentAnalysisThreads.isScrolledDown,
  ])
  useEffect(() => {
    refetchThreadsBySentiment(AnalyticsFetchThreadsSentimentType.POS)
  }, [
    positiveSentimentAnalysisThreads.isScrolledUp,
    positiveSentimentAnalysisThreads.isScrolledDown,
  ])
  useEffect(() => {
    refetchThreadsBySentiment(AnalyticsFetchThreadsSentimentType.NEG)
  }, [
    negativeSentimentAnalysisThreads.isScrolledUp,
    negativeSentimentAnalysisThreads.isScrolledDown,
  ])
  useEffect(() => {
    refetchThreadsBySentiment(AnalyticsFetchThreadsSentimentType.NEU)
  }, [
    neutralSentimentAnalysisThreads.isScrolledUp,
    neutralSentimentAnalysisThreads.isScrolledDown,
  ])

  const handleThreadsScroll = (
    event: UIEvent,
    sentiment?: AnalyticsFetchThreadsSentimentType
  ) => {
    if (!event.currentTarget) {
      return
    }

    const { scrollTop, scrollHeight } = event.currentTarget

    const {
      state: { isScrolledUp, isScrolledDown },
      setState,
    } = getThreadsStateFromSentiment(sentiment)

    const isScrolledCloseToTop = scrollTop < 40
    const isScrolledCloseToBottom =
      scrollTop > scrollHeight - SENTIMENT_ANALYSIS_CONTAINER_HEIGHT - 40

    if (isScrolledCloseToTop !== isScrolledUp) {
      setState((prev) => ({ ...prev, isScrolledUp: isScrolledCloseToTop }))
    }

    if (isScrolledCloseToBottom !== isScrolledDown) {
      setState((prev) => ({ ...prev, isScrolledDown: isScrolledCloseToBottom }))
    }
  }

  useEffect(() => {
    if (sessionId) {
      refetchDailyTrends()

      refetchSentimentAnalysis()

      refetchSentimentChart()

      allSentimentAnalysisThreadsQuery.refetch()
      positiveSentimentAnalysisThreadsQuery.refetch()
      negativeSentimentAnalysisThreadsQuery.refetch()
      neutralSentimentAnalysisThreadsQuery.refetch()

      if (startDate && endDate) {
        refetchTopSources()
      }
    }
  }, [sessionId, themeId, keywordName, startDate, endDate])

  useEffect(() => {
    if (startDate && endDate) {
      refetchSentimentChart()
    }
  }, [filters, startDate, endDate])

  const url = useCustomLink('/keywords')

  return (
    <div className={styles.container}>
      <BackNavigationButton title={keywordName} url={url} />

      <DailyTrendsSection
        chartData={dailyTrendsData}
        isLoading={isDailyTrendsFetching}
      />

      <SentimentChartSection
        chartData={sentimentChartData}
        isLoading={isSentimentChartFetching}
        onFilterSelect={(filters) => setFilters(filters)}
      />

      <SentimentAnalysesSection
        allThreadsData={allSentimentAnalysisThreads.data || undefined}
        chartData={sentimentAnalysisData}
        handleThreadsScroll={handleThreadsScroll}
        isLoading={
          isSentimentAnalysesFetching ||
          allSentimentAnalysisThreadsQuery.isFetching ||
          negativeSentimentAnalysisThreadsQuery.isFetching ||
          neutralSentimentAnalysisThreadsQuery.isFetching ||
          positiveSentimentAnalysisThreadsQuery.isFetching
        }
        negativeThreadsData={negativeSentimentAnalysisThreads.data || undefined}
        neutralThreadsData={neutralSentimentAnalysisThreads.data || undefined}
        positiveThreadsData={positiveSentimentAnalysisThreads.data || undefined}
      />

      <TopSourcesSection
        chartData={topSourcesData}
        isLoading={isTopSourcesFetching}
      />
    </div>
  )
}
