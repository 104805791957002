import axios from 'axios'
import { MDELLO_X_API_KEY } from 'src/constants/env'
import { getSessionIdFromLocalStorage } from 'src/localStorage/sessionId/getSessionIdFromLocalStorage/getSessionIdFromLocalStorage'
import { ANALYTICS_API_URL, AUTH_API_URL } from '../constants/urls'

export const getNApiHeaders = () => {
  return {
    'Access-Control-Allow-Origin': ANALYTICS_API_URL,
    Vary: 'Origin',
    'X-Api-Key': MDELLO_X_API_KEY,
    'x-session-id': getSessionIdFromLocalStorage() || '',
  }
}

export const nApi = () =>
  axios.create({
    baseURL: AUTH_API_URL,
    headers: getNApiHeaders(),
  })
