import classNames from 'classnames'
import React, { FC, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Button from 'src/components/Button'
import PasswordVisibilityButton from 'src/components/PasswordVisibilityButton'
import en from 'src/constants/en'
import { MINIMUM_PASSWORD_LENGTH } from 'src/constants/password'
import { GetSelfUser } from 'src/services/napi/getSelf/interfaces/getSelf.interface'
import { UpdateSelfParams } from 'src/services/napi/updateSelf/interfaces/updateSelf.interface'
import { UpdateSelfPasswordParams } from 'src/services/napi/updateSelfPassword/interfaces/updateSelfPassword.interface'
import styles from './yourAccount.module.scss'

interface YourAccountProps {
  handleUpdatePassword: (params: UpdateSelfPasswordParams) => Promise<boolean>
  handleUpdateUser: (params: UpdateSelfParams) => Promise<boolean>
  userData?: GetSelfUser
}

const YourAccount: FC<YourAccountProps> = ({
  userData,
  handleUpdateUser,
  handleUpdatePassword,
}) => {
  const [firstName, setFirstName] = useState<string>('')

  const [lastName, setLastName] = useState<string>('')

  const [email, setEmail] = useState<string>('')

  const [company, setCompany] = useState<string>('')

  const [profileImageUrl, setProfileImageUrl] = useState<string>('')

  const [oldPassword, setOldPassword] = useState<string>('')

  const [newPassword, setNewPassword] = useState<string>('')

  const [confirmPassword, setConfirmPassword] = useState<string>('')

  const [oldPasswordError, setOldPasswordError] = useState<boolean>(false)

  const [newPasswordError, setNewPasswordError] = useState<boolean>(false)

  const [confirmPasswordError, setConfirmPasswordError] =
    useState<boolean>(false)

  const [oldPasswordVisibility, setOldPasswordVisibility] =
    useState<boolean>(false)

  const [newPasswordVisibility, setNewPasswordVisibility] =
    useState<boolean>(false)

  const [confirmPasswordVisibility, setConfirmPasswordVisibility] =
    useState<boolean>(false)

  const [editModes, setEditModes] = useState({
    company: false,
    email: false,
    firstName: false,
    lastName: false,
    password: false,
  })

  const [profileImageName, setProfileImageName] = useState<string>('')

  const resetPasswordFields = () => {
    setOldPassword('')
    setNewPassword('')
    setConfirmPassword('')
  }

  const resetPasswordErrors = () => {
    setOldPasswordError(false)
    setNewPasswordError(false)
    setConfirmPasswordError(false)
  }

  const resetPasswordVisibilities = () => {
    setOldPasswordVisibility(false)
    setNewPasswordVisibility(false)
    setConfirmPasswordVisibility(false)
  }

  const setDataFromUser = () => {
    setFirstName(userData?.firstName || '')
    setLastName(userData?.lastName || '')
    setEmail(userData?.email || '')
    setCompany(userData?.metadata?.companyName || '')

    resetPasswordFields()
    resetPasswordErrors()
    resetPasswordVisibilities()
  }

  const hasUserDataChanged = (field: string): boolean => {
    switch (field) {
      case 'firstName':
        return firstName !== userData?.firstName
      case 'lastName':
        return lastName !== userData?.lastName
      case 'email':
        return email !== userData?.email
      case 'company':
        return company !== userData?.metadata?.companyName
      default:
        return false
    }
  }

  const arePasswordsValid = (): boolean => {
    resetPasswordErrors()

    if (!newPassword && !confirmPassword) {
      return false
    }

    if (!oldPassword || !newPassword || !confirmPassword) {
      toast.error(en.PASSWORD_ERROR_NOT_FILLED_ALL_FIELDS)

      if (!oldPassword) {
        setOldPasswordError(true)
      }

      if (!newPassword) {
        setNewPasswordError(true)
      }

      if (!confirmPassword) {
        setConfirmPasswordError(true)
      }

      return false
    }

    if (newPassword !== confirmPassword) {
      toast.error(en.PASSWORD_ERROR_NOT_MATCHING)

      setNewPasswordError(true)
      setConfirmPasswordError(true)

      return false
    }

    if (newPassword.length < MINIMUM_PASSWORD_LENGTH) {
      toast.error(en.PASSWORD_ERROR_TOO_SHORT)

      setNewPasswordError(true)
      setConfirmPasswordError(true)

      return false
    }

    return true
  }

  async function handleSubmitField(field: string): Promise<void> {
    if (field === 'password') {
      if (!arePasswordsValid()) {
        return
      }

      const success = await handleUpdatePassword({ newPassword, oldPassword })

      if (!success) {
        setOldPasswordError(true)
        setNewPasswordError(true)
        setConfirmPasswordError(true)
        return
      }

      resetPasswordFields()
      resetPasswordErrors()
      resetPasswordVisibilities()
      setEditModes((prev) => ({ ...prev, password: false }))
    } else {
      if (!hasUserDataChanged(field)) {
        setEditModes((prev) => ({ ...prev, [field]: false }))
        return
      }

      const updateParams: UpdateSelfParams = {
        email: userData?.email,
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        metadata: {
          companyName: userData?.metadata?.companyName,
        },
      }

      switch (field) {
        case 'firstName':
          updateParams.firstName = firstName
          break
        case 'lastName':
          updateParams.lastName = lastName
          break
        case 'email':
          updateParams.email = email
          break
        case 'company':
          updateParams.metadata = {
            companyName: company,
          }
          break
        default:
          break
      }

      const success = await handleUpdateUser(updateParams)

      if (!success) {
        return
      }

      setEditModes((prev) => ({ ...prev, [field]: false }))
    }
  }

  function handleCancelEditing(field: string) {
    setDataFromUser()

    setEditModes((prev) => ({ ...prev, [field]: false }))
  }

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const selectedImage = e.target.files[0]

      try {
        // TODO: when backend is ready
        const imageUrl = URL.createObjectURL(selectedImage)

        setProfileImageUrl(imageUrl)

        setProfileImageName(selectedImage.name)
        toast.success('Profile image updated successfully.')
      } catch (error) {
        toast.error('Failed to upload image. Please try again.')
      }
    }
  }

  // Handle image removal
  const handleRemoveImage = () => {
    setProfileImageUrl('')

    setProfileImageName('')
    // TODO:  when backend is ready
    toast.success('Profile image removed.')
  }

  // Open file input when "Change Image" is clicked
  const fileInputRef = React.useRef<HTMLInputElement>(null)

  const handleChangeImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  useEffect(() => {
    setDataFromUser()
  }, [userData])

  return (
    <form className={styles.form}>
      <div className={styles.card}>
        <div className={styles.imageRow}>
          <div className={styles.leftSide}>
            {profileImageUrl ? (
              <img
                alt="Profile"
                className={styles.profileImage}
                src={profileImageUrl}
              />
            ) : (
              <div className={styles.accountImage}>
                <span>{firstName[0]}</span>
              </div>
            )}

            <div className={styles.imageText}>
              {profileImageUrl ? (
                <span className={styles.upperText}>{profileImageName}</span>
              ) : (
                <span className={styles.upperText}>No Profile Picture Yet</span>
              )}
              <span className={styles.lowerText}>
                Recommended: 180x180px in PNG or JPG format
              </span>
            </div>
          </div>

          <div className={styles.imageButtons}>
            {profileImageUrl ? (
              <div className={styles.buttonGroup}>
                <Button
                  background="transparent"
                  onClick={handleChangeImageClick}
                  type="button"
                >
                  Change Image
                </Button>
                <Button
                  background="malibu"
                  onClick={handleRemoveImage}
                  type="button"
                >
                  Remove
                </Button>
                <input
                  accept="image/*"
                  onChange={handleImageChange}
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  type="file"
                />
              </div>
            ) : (
              <>
                <Button
                  background="malibu"
                  onClick={handleChangeImageClick}
                  type="button"
                >
                  Upload Image
                </Button>

                <input
                  accept="image/*"
                  onChange={handleImageChange}
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  type="file"
                />
              </>
            )}
          </div>
        </div>

        <hr />

        <div className={styles.formRow}>
          <label htmlFor="firstName">{en.firstName}</label>

          <div className={styles.inputWrapper}>
            <input
              className={editModes.firstName ? styles.enabled : styles.disabled}
              id="firstName"
              onChange={(e) => setFirstName(e.target.value)}
              readOnly={!editModes.firstName}
              type="text"
              value={firstName}
            />
          </div>
          <div className={styles.buttonGroup}>
            {editModes.firstName ? (
              <>
                <Button
                  background="transparent"
                  onClick={() => handleCancelEditing('firstName')}
                  type="button"
                >
                  {en.cancel}
                </Button>
                <Button
                  background="malibu"
                  onClick={() => handleSubmitField('firstName')}
                  type="button"
                >
                  {en.save}
                </Button>
              </>
            ) : (
              <Button
                background="malibu"
                onClick={() =>
                  setEditModes((prev) => ({ ...prev, firstName: true }))
                }
                type="button"
              >
                {en.edit}
              </Button>
            )}
          </div>
        </div>

        <hr />

        <div className={styles.formRow}>
          <label htmlFor="lastName">{en.lastName}</label>

          <div className={styles.inputWrapper}>
            <input
              className={editModes.lastName ? styles.enabled : styles.disabled}
              id="lastName"
              onChange={(e) => setLastName(e.target.value)}
              readOnly={!editModes.lastName}
              type="text"
              value={lastName}
            />
          </div>
          <div className={styles.buttonGroup}>
            {editModes.lastName ? (
              <>
                <Button
                  background="transparent"
                  onClick={() => handleCancelEditing('lastName')}
                  type="button"
                >
                  {en.cancel}
                </Button>
                <Button
                  background="malibu"
                  onClick={() => handleSubmitField('lastName')}
                  type="button"
                >
                  {en.save}
                </Button>
              </>
            ) : (
              <Button
                background="malibu"
                onClick={() =>
                  setEditModes((prev) => ({ ...prev, lastName: true }))
                }
                type="button"
              >
                {en.edit}
              </Button>
            )}
          </div>
        </div>

        <hr />

        <div className={styles.formRow}>
          <label htmlFor="staticEmail">{en.email}</label>

          <div className={styles.inputWrapper}>
            <input
              className={editModes.email ? styles.enabled : styles.disabled}
              id="staticEmail"
              onChange={(e) => setEmail(e.target.value)}
              readOnly={!editModes.email}
              type="text"
              value={email}
            />
          </div>
          <div className={styles.buttonGroup}>
            {editModes.email ? (
              <>
                <Button
                  background="transparent"
                  onClick={() => handleCancelEditing('email')}
                  type="button"
                >
                  {en.cancel}
                </Button>
                <Button
                  background="malibu"
                  onClick={() => handleSubmitField('email')}
                  type="button"
                >
                  {en.save}
                </Button>
              </>
            ) : (
              <Button
                background="malibu"
                onClick={() =>
                  setEditModes((prev) => ({ ...prev, email: true }))
                }
                type="button"
              >
                {en.edit}
              </Button>
            )}
          </div>
        </div>

        <hr />

        <div className={styles.formRow}>
          <label htmlFor="company">{en.company}</label>

          <div className={styles.inputWrapper}>
            <input
              className={editModes.company ? styles.enabled : styles.disabled}
              id="company"
              onChange={(e) => setCompany(e.target.value)}
              readOnly={!editModes.company}
              type="text"
              value={company}
            />
          </div>
          <div className={styles.buttonGroup}>
            {editModes.company ? (
              <>
                <Button
                  background="transparent"
                  onClick={() => handleCancelEditing('company')}
                  type="button"
                >
                  {en.cancel}
                </Button>
                <Button
                  background="malibu"
                  onClick={() => handleSubmitField('company')}
                  type="button"
                >
                  {en.save}
                </Button>
              </>
            ) : (
              <Button
                background="malibu"
                onClick={() =>
                  setEditModes((prev) => ({ ...prev, company: true }))
                }
                type="button"
              >
                {en.edit}
              </Button>
            )}
          </div>
        </div>

        <hr />

        {editModes.password ? (
          <>
            <div className={styles.formRow}>
              <label htmlFor="oldPassword">{en.oldPassword}</label>

              <div className={styles.inputWrapper}>
                <input
                  className={classNames(
                    styles.enabled,
                    oldPasswordError && styles.error
                  )}
                  id="oldPassword"
                  onChange={(e) => setOldPassword(e.target.value)}
                  placeholder="Old Password"
                  type={oldPasswordVisibility ? 'text' : 'password'}
                  value={oldPassword}
                />

                <PasswordVisibilityButton
                  className={styles.visibilityButton}
                  toggleVisibility={(val) => setOldPasswordVisibility(val)}
                  visibility={oldPasswordVisibility}
                />
              </div>
            </div>

            <hr />

            <div className={styles.formRow}>
              <label htmlFor="newPassword">{en.newPassword}</label>

              <div className={styles.inputWrapper}>
                <input
                  className={classNames(
                    styles.enabled,
                    newPasswordError && styles.error
                  )}
                  id="newPassword"
                  onChange={(e) => setNewPassword(e.target.value)}
                  placeholder="New Password"
                  type={newPasswordVisibility ? 'text' : 'password'}
                  value={newPassword}
                />

                <PasswordVisibilityButton
                  className={styles.visibilityButton}
                  toggleVisibility={(val) => setNewPasswordVisibility(val)}
                  visibility={newPasswordVisibility}
                />
              </div>
            </div>

            <hr />

            <div className={styles.formRow}>
              <label htmlFor="confirmPassword">{en.confirmPassword}</label>

              <div className={styles.inputWrapper}>
                <input
                  className={classNames(
                    styles.enabled,
                    confirmPasswordError && styles.error
                  )}
                  id="confirmPassword"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm Password"
                  type={confirmPasswordVisibility ? 'text' : 'password'}
                  value={confirmPassword}
                />

                <PasswordVisibilityButton
                  className={styles.visibilityButton}
                  toggleVisibility={(val) => setConfirmPasswordVisibility(val)}
                  visibility={confirmPasswordVisibility}
                />
              </div>
            </div>

            <hr />

            <div className={styles.buttonsRow}>
              <Button
                background="transparent"
                onClick={() => handleCancelEditing('password')}
                type="button"
              >
                {en.cancel}
              </Button>

              <Button
                background="malibu"
                onClick={() => handleSubmitField('password')}
                type="button"
              >
                {en.save}
              </Button>
            </div>
          </>
        ) : (
          <div className={styles.formRow}>
            <label htmlFor="passwordId">{en.password}</label>

            <div className={styles.inputWrapper}>
              <input
                className={styles.disabled}
                id="passwordId"
                readOnly
                type="password"
                value="********"
              />
            </div>
            <div>
              <Button
                background="malibu"
                onClick={() =>
                  setEditModes((prev) => ({ ...prev, password: true }))
                }
                type="button"
              >
                {en.edit}
              </Button>
            </div>
          </div>
        )}
      </div>
    </form>
  )
}

export default YourAccount
