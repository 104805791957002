import { useQuery } from '@tanstack/react-query'
import { debounce } from 'lodash'
import { useCallback, useState } from 'react'
import { QueryKey } from 'src/reactQuery/enums/queryKey.enum'
import { SuggestionsApi } from 'src/services/suggestionsApi'
import { LATINS_NUMBERS } from 'src/constants/regex'
import {
  UseGetSuggestWordQueryParams,
  UseGetSuggestWordQueryValue,
} from './interfaces/useGetSuggestWordQuery.interface'

const fetchSuggestWord = async (word: string) => {
  if (!word.length || !LATINS_NUMBERS.test(word)) {
    return null
  }

  const { data } = await SuggestionsApi.v1.suggestionsSuggestWordList({
    word,
  })

  return data
}

export const useGetSuggestWordQuery = (
  params?: UseGetSuggestWordQueryParams
): UseGetSuggestWordQueryValue => {
  const [word, setWord] = useState(params?.word || '')

  const queryKey = params?.queryKey || QueryKey.SUGGEST_WORD

  const { data, refetch, isLoading } = useQuery({
    queryFn: ({ queryKey }) => fetchSuggestWord(queryKey[1] || word),
    queryKey: [queryKey, word],
  })

  const handleSearch = useCallback(
    debounce(async (word: string) => {
      setWord(word)
      refetch()
    }, 350),
    []
  )

  return {
    data,
    handleSearch,
    isLoading,
  }
}
