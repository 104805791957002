import { useRef, useEffect, useState, FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { setDataToLocalStorage } from 'src/localStorage/setDataToLocalStorage'
import { LocalStorageKey } from 'src/localStorage/enums/localStorageKey.enum'
import { getDataFromLocalStorage } from 'src/localStorage/getDataFromLocalStorage'
import CustomLink from 'src/components/CustomLink'
import { useGetSelfQuery } from 'src/reactQuery/useGetSelfQuery'
import { HOME_ROUTE } from 'src/constants/routes'
import classes from './sidebar.module.scss'
import { SIDEBAR_ITEMS } from './constants'

export const Sidebar: FC = () => {
  const container = useRef(null)

  const location = useLocation()

  const { data: userData } = useGetSelfQuery()

  const hasAdminAccess = userData?.user?.role === 'admin'

  const lastActiveIndexString = getDataFromLocalStorage(
    LocalStorageKey.LAST_ACTIVE_INDEX
  )
  const lastActiveIndex = Number(lastActiveIndexString)

  const [activeIndex, setActiveIndex] = useState(lastActiveIndex)

  function changeActiveIndex(newIndex: number): void {
    setDataToLocalStorage(
      LocalStorageKey.LAST_ACTIVE_INDEX,
      newIndex.toString()
    )

    setActiveIndex(newIndex)
  }

  function getPath(path: string): string {
    if (path.charAt(0) !== '/') {
      return `/${path}`
    }
    return path
  }

  useEffect(() => {
    const activeItem = SIDEBAR_ITEMS.findIndex(
      (item) => getPath(item.route) === getPath(location.pathname)
    )
    changeActiveIndex(activeItem)
  }, [location])

  return (
    <div className={classes.sidebarContainer} ref={container}>
      <Link className={classes.sidebarHeader} to={HOME_ROUTE}>
        <img alt="logo" className={classes.sidebarLogo} src="/logo.png" />
      </Link>

      <div className={classes.sidebarMenu}>
        {SIDEBAR_ITEMS.map(
          ({ name, icon, route, requiresAdminAccess }, index) =>
            requiresAdminAccess && !hasAdminAccess ? null : (
              <CustomLink
                className={classNames(
                  classes.sidebarMenuItem,
                  activeIndex === index && classes.active,
                  requiresAdminAccess && classes.bottom
                )}
                key={name}
                link={route}
              >
                <span className={classes.sidebarMenuItemIconWrapper}>
                  <FontAwesomeIcon
                    className={classes.sidebarMenuItemIcon}
                    icon={icon}
                  />
                </span>

                <span className={classes.sidebarMenuItemName}>{name}</span>
              </CustomLink>
            )
        )}
      </div>
    </div>
  )
}
