import { useQuery } from '@tanstack/react-query'
import { QueryKey } from 'src/reactQuery/enums/queryKey.enum'
import { AnalyticsApi } from 'src/services/analyticsApi'
import { AnalyticsFetchSentimentChartResponse } from 'src/services/__generated__/api'
import { UseGetSentimentChartByCampaignQueryParams } from './interfaces/useGetSentimentChartByCampaignQuery.interface'

const getSentimentChartByCampaign = async (
  start?: string,
  end?: string,
  campaign?: string,
  keywords?: string[],
  sources?: string[]
): Promise<AnalyticsFetchSentimentChartResponse | null> => {
  if (!campaign) {
    return null
  }

  const keywordsString: string = keywords?.join(',') || ''
  const sourcesString: string = sources?.join(',') || ''

  const { data } =
    await AnalyticsApi.v1Private.analyticsFetchSentimentChartList({
      campaign,
      fromDate: start,
      keywords: keywordsString,
      sources: sourcesString,
      toDate: end,
    })

  return data
}

export const useGetSentimentChartByCampaignQuery = (
  params: UseGetSentimentChartByCampaignQueryParams
) => {
  return useQuery({
    enabled: !!params.campaign,
    queryFn: () =>
      getSentimentChartByCampaign(
        params.start,
        params.end,
        params.campaign,
        params.keywords,
        params.sources
      ),
    queryKey: [
      QueryKey.GET_SENTIMENT_CHART_BY_CAMPAIGN,
      params.start,
      params.end,
      params.campaign,
      params.keywords,
      params.sources,
    ],
  })
}
