import { FC, useEffect, useState, UIEvent } from 'react'
import classNames from 'classnames'
import en from 'src/constants/en'
import { LiveNotificationItem } from 'src/components/LiveNotificationItem'
import { Type } from 'src/components/LiveNotificationItem/enums/type.enum'
import {
  AnalyticsFetchThreadsResponseDataItem,
  AnalyticsFetchThreadsSentimentType,
} from 'src/services/__generated__/api'
import { ReactComponent as ArrowSvg } from 'src/assets/svg/chevron.svg'
import { ReactComponent as CheckSvg } from 'src/assets/svg/check-blue.svg'
import { ReactComponent as DeselectSVG } from 'src/assets/svg/deselect.svg'
import { ReactComponent as CheckBox } from 'src/assets/svg/checkbox-white.svg'
import { ReactComponent as MoveTo } from 'src/assets/svg/move-to.svg'
import { ReactComponent as MoveToRelevant } from 'src/assets/svg/moveToRelevant.svg'
import { ReactComponent as Report } from 'src/assets/svg/report.svg'
import { ReactComponent as DeleteBtn } from 'src/assets/svg/delete-btn.svg'
import { ReactComponent as FilterChecked } from 'src/assets/svg/checked.svg'
import CardFilterButton from 'src/components/CardFilterButton'
import { sentimentFilters } from 'src/constants/sentimentAnalysesFilter'
import { ReportPopUpModal } from 'src/components/ReportPopUp'
import { DeletePopUpModal } from 'src/components/DeletePopUp'
import { useRemoveThreadMutation } from 'src/reactQuery/useRemoveThreadMutation'
import { useReportThreadMutation } from 'src/reactQuery/useReportThreadMutation'
import { useMakeIrrelevantMutation } from 'src/reactQuery/useMakeThreadIrrelevantMutation'
import { queryClient } from 'src/reactQuery'
import { QueryKey } from 'src/reactQuery/enums/queryKey.enum'
import { useMakeRelevantMutation } from 'src/reactQuery/useMakeThreadRelevantMutation'
import styles from './sentimentAnalysesNotifications.module.scss'
import liveNotificationsSectionStyles from '../../LiveNotificationsSection/liveNotifications.module.scss'

interface SentimentAnalysesNotificationsProps {
  allThreadsData?: AnalyticsFetchThreadsResponseDataItem[]
  handleThreadsScroll?: (
    event: UIEvent,
    sentiment?: AnalyticsFetchThreadsSentimentType
  ) => void
  hasFilters?: boolean
  negativeThreadsData?: AnalyticsFetchThreadsResponseDataItem[]
  neutralThreadsData?: AnalyticsFetchThreadsResponseDataItem[]
  onFilterClick?: () => void
  positiveThreadsData?: AnalyticsFetchThreadsResponseDataItem[]
}

export const SentimentAnalysesNotifications: FC<
  SentimentAnalysesNotificationsProps
> = ({
  allThreadsData,
  negativeThreadsData,
  neutralThreadsData,
  positiveThreadsData,
  handleThreadsScroll,
  onFilterClick,
  hasFilters,
}) => {
  const [filter, setFilter] = useState<string>(en.all)

  const [activeRelevance, setActiveRelevance] = useState('Relevant')

  const [checkedItems, setCheckedItems] = useState<Set<string>>(new Set())

  const [isAllChecked, setIsAllChecked] = useState(false)

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const [isReportClicked, setIsReportClicked] = useState(false)

  const [isDeleteClicked, setIsDeleteClicked] = useState(false)

  const [threadsData, setThreadsData] = useState<
    AnalyticsFetchThreadsResponseDataItem[]
  >([])

  const [irrelevantItems, setIrrelevantItems] = useState(new Set())

  const { mutate: removeThread } = useRemoveThreadMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.GET_ALL_SENTIMENT_ANALYSIS_THREADS],
      })
      setIsDeleteClicked(false)
    },
  })
  const { mutate: reportThread } = useReportThreadMutation({
    onSuccess: () => setIsReportClicked(false),
  })
  const { mutate: makeIrrelevant } = useMakeIrrelevantMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.GET_ALL_SENTIMENT_ANALYSIS_THREADS],
      })
    },
  })

  const { mutate: makeRelevant } = useMakeRelevantMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.GET_ALL_SENTIMENT_ANALYSIS_THREADS],
      })
    },
  })

  const filterItems = [en.all, en.positive, en.negative, en.neutral]

  const handleSelectAll = () => {
    const allItems = new Set<string>(
      threadsData
        .map((item) => item._id)
        .filter((id): id is string => typeof id === 'string')
    )

    setCheckedItems(allItems)

    setIsAllChecked(true)
  }

  const handleDeselectAll = () => {
    setCheckedItems(new Set())

    setIsAllChecked(false)
  }

  const handleCheckboxChange = (id: string | undefined, isChecked: boolean) => {
    setCheckedItems((prev) => {
      const newSet = new Set(prev)

      if (isChecked && id !== undefined) {
        newSet.add(id)
      } else if (id !== undefined) {
        newSet.delete(id)
      }

      setIsAllChecked(newSet.size === threadsData.length)

      return newSet
    })
  }

  const handleMoveToIrrelevant = async () => {
    const idsArray = Array.from(checkedItems)
    makeIrrelevant(idsArray)
    setIrrelevantItems(
      (prev) => new Set([...Array.from(prev), ...Array.from(checkedItems)])
    )
    setCheckedItems(new Set())
  }

  const handleMoveToRelevant = async () => {
    const idsArray = Array.from(checkedItems)
    makeRelevant(idsArray)

    setIrrelevantItems((prev) => {
      const updatedIrrelevantItems = new Set(prev)
      idsArray.forEach((id) => updatedIrrelevantItems.delete(id))
      return updatedIrrelevantItems
    })
    setCheckedItems(new Set())
  }

  useEffect(() => {
    let relevantData = allThreadsData || []

    if (activeRelevance === 'Relevant') {
      relevantData = relevantData.filter(
        (item) => !irrelevantItems.has(item._id)
      )
    } else if (activeRelevance === 'Irrelevant') {
      relevantData = relevantData.filter((item) =>
        irrelevantItems.has(item._id)
      )
    }

    switch (filter) {
      case en.positive:
        setThreadsData(relevantData.filter((item) => item.output === 'POS'))
        break
      case en.negative:
        setThreadsData(relevantData.filter((item) => item.output === 'NEG'))
        break
      case en.neutral:
        setThreadsData(relevantData.filter((item) => item.output === 'NEU'))
        break
      default:
        setThreadsData(relevantData)
    }
  }, [
    filter,
    activeRelevance,
    allThreadsData,
    irrelevantItems,
    negativeThreadsData,
    neutralThreadsData,
    positiveThreadsData,
  ])

  const getSentimentByFilter = ():
    | AnalyticsFetchThreadsSentimentType
    | undefined => {
    const filterToSentimentMap: {
      [key: string]: AnalyticsFetchThreadsSentimentType
    } = {
      [en.positive]: AnalyticsFetchThreadsSentimentType.POS,
      [en.negative]: AnalyticsFetchThreadsSentimentType.NEG,
      [en.neutral]: AnalyticsFetchThreadsSentimentType.NEU,
    }

    return filterToSentimentMap[filter]
  }

  const getTypeFromThread = (
    item: AnalyticsFetchThreadsResponseDataItem
  ): Type => {
    return (
      {
        [AnalyticsFetchThreadsSentimentType.POS]: Type.POSITIVE,
        [AnalyticsFetchThreadsSentimentType.NEG]: Type.NEGATIVE,
      }[item.output ?? ''] ?? Type.OTHER
    )
  }

  const getSentimentsFromThread = (
    item: AnalyticsFetchThreadsResponseDataItem
  ): string[] | undefined => {
    return {
      [AnalyticsFetchThreadsSentimentType.POS]: item.positive_keywords,
      [AnalyticsFetchThreadsSentimentType.NEG]: item.negative_keywords,
    }[item.output ?? '']
  }

  const handleSelect = (item: string) => {
    setActiveRelevance(item)
    setIsDropdownOpen(false)
  }

  const handleReport = (message: string) => {
    const idsArray = Array.from(checkedItems)
    reportThread({
      ids: idsArray,
      message: message,
    })
  }
  const handleRemove = () => {
    const idsArray = Array.from(checkedItems)
    removeThread(idsArray)
  }

  return (
    <div className={styles.sentimentAnalysesNotifications}>
      <div className={styles.chartInner}>
        <div className={styles.filtersWrapper}>
          {checkedItems.size > 0 ? (
            <div className={styles.selectionWrapper}>
              <div className={styles.icons}>
                <div
                  className={styles.iconWrapperCheck}
                  onClick={handleSelectAll}
                >
                  {isAllChecked ? (
                    <FilterChecked className={styles.checkedIcon} />
                  ) : (
                    <CheckBox className={styles.checkedIcon} />
                  )}

                  <div className={styles.infoMessageSelect}>Select All</div>
                </div>

                <div
                  className={styles.iconWrapperDeselect}
                  onClick={handleDeselectAll}
                >
                  <DeselectSVG className={styles.deselectIcon} />
                  <div className={styles.infoMessageDeselect}>Deselect all</div>
                </div>

                <div className={styles.line} />
                <div
                  className={styles.iconWrapperMoveTo}
                  onClick={
                    activeRelevance === 'Relevant'
                      ? handleMoveToIrrelevant
                      : handleMoveToRelevant
                  }
                >
                  {activeRelevance === 'Relevant' ? (
                    <MoveTo />
                  ) : (
                    <MoveToRelevant />
                  )}
                  <div className={styles.infoMessageMoveTo}>
                    {activeRelevance === 'Relevant'
                      ? 'Move to Irrelevant'
                      : 'Move to Relevant'}
                  </div>
                </div>

                <div
                  className={styles.iconWrapperReport}
                  onClick={() => setIsReportClicked(!isReportClicked)}
                >
                  <Report />

                  <div className={styles.infoMessageReport}>Report</div>
                </div>

                <div
                  className={styles.iconWrapperDelete}
                  onClick={() => setIsDeleteClicked(!isDeleteClicked)}
                >
                  <DeleteBtn />

                  <div className={styles.infoMessageDelete}>Delete</div>
                </div>
              </div>

              <div>
                {hasFilters && (
                  <CardFilterButton
                    className={styles.filterButton}
                    onClick={() => onFilterClick?.()}
                  />
                )}

                {isDeleteClicked && (
                  <DeletePopUpModal
                    isOpen={isDeleteClicked}
                    onDelete={handleRemove}
                    setIsOpen={setIsDeleteClicked}
                  />
                )}
              </div>

              {isReportClicked && (
                <ReportPopUpModal
                  isOpen={isReportClicked}
                  onReport={handleReport}
                  setIsOpen={setIsReportClicked}
                />
              )}
            </div>
          ) : (
            <>
              <div className={styles.dropdownWrapper}>
                <div
                  className={`${styles.relevanceWrapper} ${
                    isDropdownOpen ? styles.active : ''
                  }`}
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                  {activeRelevance}
                  <ArrowSvg
                    className={`${styles.arrowSvg} ${
                      isDropdownOpen ? styles.active : ''
                    }`}
                  />
                </div>

                {isDropdownOpen && (
                  <div className={styles.dropdown}>
                    {sentimentFilters.map((item, index) => (
                      <div
                        className={styles.dropdownList}
                        key={index}
                        onClick={() => handleSelect(item)}
                      >
                        {item}
                        {item === activeRelevance && <CheckSvg />}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className={styles.line} />

              {filterItems.map((item) => {
                const isActive = filter === item
                return (
                  <div
                    className={classNames(
                      styles.filterItem,
                      isActive && styles.filterItemActive
                    )}
                    key={item}
                    onClick={() => setFilter(item)}
                  >
                    {item}
                  </div>
                )
              })}

              {hasFilters && (
                <CardFilterButton
                  className={styles.filterButton}
                  onClick={() => onFilterClick?.()}
                />
              )}
            </>
          )}
        </div>

        <div
          className={liveNotificationsSectionStyles.scrollbarContainer}
          onScroll={(e) => {
            handleThreadsScroll?.(e, getSentimentByFilter())
          }}
        >
          {threadsData.length ? (
            threadsData.map((item) => (
              <LiveNotificationItem
                campaign={item.campaign_name}
                handleCheckboxChange={handleCheckboxChange}
                id={item?._id || ''}
                isChecked={item._id ? checkedItems.has(item._id) : false}
                key={`${item._id} - ${filter}`}
                keyword={item.keyword}
                language={item.language}
                link={item.link}
                originalLangCode={item.original_lang_code}
                originalText={item.text}
                score={item.score}
                sentiments={getSentimentsFromThread(item)}
                source={item.source}
                time={item.published_at}
                translatedText={item.translated}
                type={getTypeFromThread(item)}
              />
            ))
          ) : (
            <div className={styles.emptyState}>
              <div>{en.noPostsAvailable}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
