import variables from 'src/styles/variables.scss'
import { MILLISECONDS_IN_SECOND } from './time'

export const LIVE_NOTIFICATIONS_CONTAINER_HEIGHT = parseInt(
  variables.liveNotificationsContainerHeight,
  10
)

export const SENTIMENT_ANALYSIS_CONTAINER_HEIGHT = parseInt(
  variables.sentimentAnalysisContainerHeight,
  10
)

export const ADMIN_THREADS_CONTAINER_HEIGHT = parseInt(
  variables.adminThreadsContainerHeight,
  10
)

export const LIVE_NOTIFICATIONS_REFETCH_INTERVAL = 15 * MILLISECONDS_IN_SECOND
