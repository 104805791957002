import { FC } from 'react'
import { findAll } from 'highlight-words-core'
import classes from './highlightedText.module.scss'
import { Type as LiveNotificationType } from '../LiveNotificationItem/enums/type.enum'

interface HighlightedTextProps {
  keyword?: string
  sentiments: string[]
  textToHighlight?: string
  type?: LiveNotificationType
}

const HighlightedText: FC<HighlightedTextProps> = ({
  textToHighlight,
  keyword,
  sentiments,
  type = LiveNotificationType.OTHER,
}) => {
  if (!textToHighlight || !keyword) {
    return null
  }

  const chunks = findAll({
    autoEscape: true,
    caseSensitive: false,
    searchWords: [keyword, ...sentiments],
    textToHighlight,
  })

  return (
    <>
      {chunks.map((chunk) => {
        const { end, highlight, start } = chunk

        const textPart = textToHighlight.substring(start, end)

        if (highlight) {
          const isBold = textPart.toLowerCase().includes(keyword.toLowerCase())
          const className = isBold ? 'bold' : type

          return (
            <span
              className={classes[className]}
              key={`${textPart} : ${start} - ${end}`}
            >
              {textPart}
            </span>
          )
        }

        return <span key={`${textPart} : ${start} - ${end}`}>{textPart}</span>
      })}
    </>
  )
}

export default HighlightedText
