import { isProduction } from 'src/constants/env'

export const PRODUCTION_ANALYTICS_API_URL = 'https://analytics-api.hapttic.com'
export const PRODUCTION_SUGGESTIONS_API_URL =
  'https://suggestions-api.hapttic.com'
export const AUTH_API_URL = 'https://n1.noxtton.com'
export const DEV_ANALYTICS_API_URL = 'http://localhost:3001'
export const ANALYTICS_API_URL = isProduction
  ? PRODUCTION_ANALYTICS_API_URL
  : DEV_ANALYTICS_API_URL
