import { FC, useState, useEffect, useMemo } from 'react'
import { EChartOption } from 'echarts/lib/echarts'
import { EChart } from 'src/charts/EChart'
import { COLORS } from 'src/constants/colors'
import { getNumberOrZero } from 'src/utils/getNumberOrZero'
import styles from './mentionsChart.module.scss'
import { ChartDataItem } from './interfaces/chartDataItem.interface'

interface MentionsProps {
  negatives?: number
  negativesPercentage?: number
  neutrals?: number
  neutralsPercentage?: number
  positives?: number
  positivesPercentage?: number
}

export const MentionsChart: FC<MentionsProps> = ({
  negatives = 0,
  negativesPercentage = 0,
  neutrals = 0,
  neutralsPercentage = 0,
  positives = 0,
  positivesPercentage = 0,
}) => {
  const [label, setLabel] = useState<number>()

  const maxDataItem = Math.max(
    getNumberOrZero(negativesPercentage),
    getNumberOrZero(neutralsPercentage),
    getNumberOrZero(positivesPercentage)
  )

  const options: EChartOption<EChartOption.SeriesPie> = useMemo(() => {
    const chartData: ChartDataItem[] = [
      ...(negatives
        ? [
            {
              color: COLORS.burningOrange,
              name: 'negatives',
              percentage: negativesPercentage,
              value: negatives,
            },
          ]
        : []),
      ...(neutrals
        ? [
            {
              color: COLORS.grey200,
              name: 'neutrals',
              percentage: neutralsPercentage,
              value: neutrals,
            },
          ]
        : []),
      ...(positives
        ? [
            {
              color: COLORS.malibu,
              name: 'positives',
              percentage: positivesPercentage,
              value: positives,
            },
          ]
        : []),
    ]

    const options: EChartOption<EChartOption.SeriesPie> = {
      color: chartData.map((item) => item.color),
      series: [
        {
          data: chartData,
          emphasis: {
            // @ts-ignore-next-line
            scale: false,
          },
          height: 172,
          itemStyle: {
            borderColor: COLORS.white,
            borderWidth: 7,
          },
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          legend: {
            show: false,
          },
          radius: ['79%', '100%'],
          tooltip: {},
          type: 'pie',
          with: 172,
        },
      ],
      tooltip: {
        trigger: 'item',
      },
    }

    return options
  }, [negatives, neutrals, positives])

  useEffect(() => {
    setLabel(maxDataItem)
  }, [maxDataItem])

  return (
    <div className={styles.mentionsChart}>
      <div className={styles.label}>{label?.toFixed(0)}%</div>

      <EChart<ChartDataItem>
        onMouseOut={() => {
          setLabel(maxDataItem)
        }}
        onMouseOver={(event) => {
          setLabel(event.data.percentage)
        }}
        options={options}
      />
    </div>
  )
}
