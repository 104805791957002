import React, { FC } from 'react'
import classNames from 'classnames'
import en from 'src/constants/en'
import Button from 'src/components/Button'
import { FetchCampaignsResponse } from 'src/services/__generated__/api'
import { Card } from 'src/components/Card'
import styles from './chooseCampaignModalContent.module.scss'

export interface ChooseCampaignModalContentProps {
  campaignsData?: FetchCampaignsResponse
  closeAndClear: () => void
  handleNextModal: () => void
  selectedCampaignForNewKeyword?: string[]
  setSelectedCampaignForNewKeyword: (id: string[]) => void
}

export const ChooseCampaignModalContent: FC<
  ChooseCampaignModalContentProps
> = ({
  campaignsData,
  selectedCampaignForNewKeyword,
  setSelectedCampaignForNewKeyword,
  closeAndClear,
  handleNextModal,
}) => {
  return (
    <Card className={styles.chooseCampaignModalContent}>
      <div className={styles.modalHeader}>{en.addNewKeyword}</div>

      <div className={styles.modalBody}>
        <div className={styles.title}>{en.chooseCampaign}</div>

        {campaignsData?.campaigns?.map((item) => {
          const isActive =
            !!item._id && !!selectedCampaignForNewKeyword?.includes(item._id)

          return (
            <div
              aria-current={isActive}
              className={classNames(
                styles.listItem,
                isActive && styles.listItemActive
              )}
              key={item._id}
              onClick={() => {
                if (!item._id) {
                  return
                }

                if (isActive) {
                  setSelectedCampaignForNewKeyword(
                    selectedCampaignForNewKeyword?.filter(
                      (id) => id !== item._id
                    ) || []
                  )

                  return
                }

                setSelectedCampaignForNewKeyword([
                  ...(selectedCampaignForNewKeyword || []),
                  item._id,
                ])
              }}
            >
              {item.name}
            </div>
          )
        })}
      </div>

      <div className={styles.modalFooter}>
        <button
          className={styles.cancel}
          onClick={() => closeAndClear()}
          type="button"
        >
          {en.cancel}
        </button>

        <Button onClick={() => handleNextModal()} type="button">
          {en.next}
        </Button>
      </div>
    </Card>
  )
}
