import React, { FC, useEffect, useRef, useState } from 'react'
import EditIcon from 'src/assets/svg/edit.svg'
import { ReactComponent as CloseIcon } from 'src/assets/svg/close-line.svg'
import { ReactComponent as CheckIcon } from 'src/assets/svg/check.svg'
import styles from './editableTitle.module.scss'

export interface EditableTitleProps {
  baseTitle: string
  onTitleUpdate?: (newTitle: string) => void
}

export const EditableTitle: FC<EditableTitleProps> = ({
  baseTitle,
  onTitleUpdate,
}) => {
  const editInputRef = useRef<HTMLInputElement>(null)

  const [isEdit, setIsEdit] = useState<boolean>(false)

  const [newKeyword, setNewKeyword] = useState<string>(baseTitle || '')

  const onEditClick = () => {
    setIsEdit(true)

    setNewKeyword(baseTitle || '')
  }

  const handleSave = () => {
    onTitleUpdate?.(newKeyword)

    setIsEdit(false)
  }

  useEffect(() => {
    if (isEdit) {
      editInputRef.current?.focus()
    }
  }, [isEdit])

  return isEdit ? (
    <div className={styles.edit}>
      <input
        className={styles.input}
        onChange={(e) => setNewKeyword(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSave()
          }
        }}
        ref={editInputRef}
        type="text"
        value={newKeyword}
      />

      <CheckIcon className={styles.accept} onClick={() => handleSave()} />

      <CloseIcon className={styles.discard} onClick={() => setIsEdit(false)} />
    </div>
  ) : (
    <>
      <span className={styles.headerSpanTitle}>{baseTitle}</span>

      <span className={styles.iconContainer} onClick={() => onEditClick()}>
        <img alt="Edit" src={EditIcon} />
      </span>
    </>
  )
}
