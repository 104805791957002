import { useMutation } from '@tanstack/react-query'
import { AnalyticsApi } from 'src/services/analyticsApi'

export interface MakeThreadRelevantResponse {
  success?: boolean
}

export interface useMakeThreadRelevantMutationParams {
  onError?: () => void
  onSuccess?: () => void
}

const makeThreadsRelevant = async (
  ids: string[]
): Promise<MakeThreadRelevantResponse | undefined> => {
  const { data } =
    await AnalyticsApi.v1Private.campaignMakeThreadRelevantCreate({
      ids,
    })
  return data
}

export const useMakeRelevantMutation = ({
  onError,
  onSuccess,
}: useMakeThreadRelevantMutationParams = {}) => {
  return useMutation({
    mutationFn: makeThreadsRelevant,
    onError: () => {
      if (onError) {
        onError()
      }
    },
    onSuccess: () => {
      if (onSuccess) {
        onSuccess()
      }
    },
  })
}
