import { FC, useEffect, useRef } from 'react'
import { ScrollBarContainer } from 'src/components/ScrollBarContainer'
import { getNumberOrZero } from 'src/utils/getNumberOrZero'
import { TotalMentionPercentage } from 'src/components/TotalMentionPercentage'
import en from 'src/constants/en'
import { AnalyticsFetchQuickDetailsResponse } from 'src/services/__generated__/api'
import { PositivityLegend } from 'src/components/PositivityLegend'
import { PositivityTypes } from 'src/components/PositivityLegend/enums/positivityTypes.enum'
import { useGetInformationTooltipsQuery } from 'src/reactQuery/useGetInformationTooltips'
import { SectionTitle } from 'src/components/SectionTitle'
import styles from './quickDetailsSection.module.scss'
import { SourcesList } from './SourcesList'
import { QuickDetailsChart } from './QuickDetailsChart'

const LIST_ITEM_COLLAPSED_WIDTH = 209

interface QuickDetailsSectionProps {
  activeItemIndex: number
  chartData?: AnalyticsFetchQuickDetailsResponse
  isLoading?: boolean
  setScrollQuickDetailsToIndexCallback: (
    callback: (index: number) => void
  ) => void
  updateActiveItemIndex: (index: number) => void
}

export const QuickDetailsSection: FC<QuickDetailsSectionProps> = ({
  activeItemIndex,
  chartData,
  isLoading,
  setScrollQuickDetailsToIndexCallback,
  updateActiveItemIndex,
}) => {
  const { data: informationTooltips } = useGetInformationTooltipsQuery()

  const scrollableDivRef = useRef<HTMLDivElement | null>(null)

  const scrollQuickDetailsToIndex = (index: number) => {
    scrollableDivRef.current?.scrollTo({
      behavior: 'smooth',
      left: index * LIST_ITEM_COLLAPSED_WIDTH,
    })
  }

  useEffect(() => {
    setScrollQuickDetailsToIndexCallback(scrollQuickDetailsToIndex)
  }, [])

  const overallMentions =
    chartData?.reduce((mentionCount, campaign) => {
      return getNumberOrZero(campaign?.total) + mentionCount
    }, 0) || 0

  if (!chartData) {
    return null
  }

  return (
    <div className={styles.quickDetailsSection}>
      <SectionTitle
        isLoading={isLoading}
        title={en.quickDetails}
        tooltipContent={informationTooltips?.quickDetails}
      />

      <div className={styles.quickDetailsSectionInner}>
        <ScrollBarContainer isHorizontal ref={scrollableDivRef}>
          <div className={styles.scrollBarContainer}>
            {chartData?.map((campaign, index) => {
              const isActive = activeItemIndex === index

              const { name, total, topSources } = campaign

              return (
                <div
                  className={styles.cardWrapper}
                  key={`${name} - ${total}`}
                  onClick={() => updateActiveItemIndex(index)}
                >
                  <div
                    className={styles.card}
                    style={{
                      width: `${LIST_ITEM_COLLAPSED_WIDTH}px`,
                    }}
                  >
                    <QuickDetailsChart
                      campaign={campaign}
                      isActive={isActive}
                    />

                    <div className={styles.cardName}>{name}</div>

                    <TotalMentionPercentage
                      overallMentions={overallMentions}
                      totalMentions={getNumberOrZero(total)}
                    />
                  </div>

                  {isActive && (
                    <SourcesList
                      topSources={
                        topSources?.map(
                          (topSource) => topSource.source ?? ''
                        ) ?? []
                      }
                    />
                  )}
                </div>
              )
            })}
          </div>
        </ScrollBarContainer>
      </div>

      <div className={styles.footer}>
        <PositivityLegend
          types={[
            PositivityTypes.POSITIVE,
            PositivityTypes.NEUTRAL,
            PositivityTypes.NEGATIVE,
          ]}
        />
      </div>
    </div>
  )
}
