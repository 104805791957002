import { useQuery } from '@tanstack/react-query'
import { QueryKey } from 'src/reactQuery/enums/queryKey.enum'
import { AnalyticsApi } from 'src/services/analyticsApi'
import { DailyPositivityCampaignResponse } from 'src/services/__generated__/api'
import { UseGetDailyPositivityCampaignParams } from './interfaces/useGetDailyPositivityCampaignQuery.interface'

const getDailyPositivityCampaign = async (
  start?: string,
  end?: string
): Promise<DailyPositivityCampaignResponse | undefined> => {
  const { data } =
    await AnalyticsApi.v1Private.campaignFetchDailyPositivityCampaignList({
      fromDate: start,
      toDate: end,
    })

  return data
}

export const useGetDailyPositivityCampaignQuery = (
  params: UseGetDailyPositivityCampaignParams
) => {
  return useQuery({
    queryFn: () => getDailyPositivityCampaign(params.start, params.end),
    queryKey: [QueryKey.GET_DAILY_POSITIVITY_CAMPAIGN],
  })
}
