import { useMutation } from '@tanstack/react-query'
import { signUp } from 'src/services/napi/signUp'
import { User } from 'src/services/napi/signUp/interfaces/signUp.interface'
import { UseSignUpMutationParams } from './interfaces/useSignUpMutation.interfaces'

const handleSignUp = async (user: User): Promise<void> => {
  await signUp(user)
}

export const useSignUpMutation = ({
  onError,
  onSuccess,
}: UseSignUpMutationParams) => {
  return useMutation({
    mutationFn: handleSignUp,
    onError: () => {
      if (onError) {
        onError()
      }
    },
    onSuccess: () => {
      if (onSuccess) {
        onSuccess()
      }
    },
  })
}
