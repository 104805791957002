import React from 'react'
import classNames from 'classnames'
import styles from './switch.module.scss'

interface SwitchProps {
  isChecked?: boolean
  onChange?: (isChecked: boolean) => void
}

export const Switch: React.FC<SwitchProps> = ({ isChecked, onChange }) => {
  return (
    <label className={styles.switch}>
      <input
        checked={isChecked}
        onChange={() => onChange?.(!isChecked)}
        type="checkbox"
      />
      <span
        className={classNames(
          styles.slider,
          styles.round,
          isChecked && styles.checked
        )}
      />
    </label>
  )
}
