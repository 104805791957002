import { EChartOption } from 'echarts'
import { FC, useEffect, useMemo, useState } from 'react'
import { EChart } from 'src/charts/EChart'
import { COLORS } from 'src/constants/colors'
import en from 'src/constants/en'
import { getNumberOrZero } from 'src/utils/getNumberOrZero'
import styles from './hotLinksChart.module.scss'
import { ChartDataItem } from './interface/chartDataItem.interface'

interface HotLinksChartProps {
  negatives?: number
  negativesPercentage?: number
  neutrals?: number
  neutralsPercentage?: number
  positives?: number
  positivesPercentage?: number
}

export const HotLinksChart: FC<HotLinksChartProps> = ({
  negatives = 0,
  positives = 0,
  negativesPercentage = 0,
  positivesPercentage = 0,
  neutrals = 0,
  neutralsPercentage = 0,
}) => {
  const [label, setLabel] = useState<number>()

  const maxDataItem = Math.max(
    getNumberOrZero(negativesPercentage),
    getNumberOrZero(positivesPercentage),
    getNumberOrZero(neutralsPercentage)
  )

  const options: EChartOption<EChartOption.SeriesPie> = useMemo(() => {
    const chartData: ChartDataItem[] = [
      ...(negatives
        ? [
            {
              color: COLORS.burningOrange,
              name: en.negatives,
              percentage: negativesPercentage,
              value: negatives,
            },
          ]
        : []),
      ...(positives
        ? [
            {
              color: COLORS.malibu,
              name: en.positives,
              percentage: positivesPercentage,
              value: positives,
            },
          ]
        : []),
      ...(neutrals
        ? [
            {
              color: COLORS.grey200,
              name: en.neutrals,
              percentage: neutralsPercentage,
              value: neutrals,
            },
          ]
        : []),
    ]

    const options: EChartOption<EChartOption.SeriesPie> = {
      color: chartData.map((item) => item.color),
      series: [
        {
          data: chartData,
          emphasis: {
            // @ts-ignore-next-line
            scale: false,
          },
          itemStyle: {
            borderColor: COLORS.white,
            borderWidth: 5,
          },
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          legend: {
            show: false,
          },
          radius: ['72%', '100%'],
          type: 'pie',
        },
      ],
    }
    return options
  }, [negatives, positives])

  useEffect(() => {
    setLabel(maxDataItem)
  }, [maxDataItem])

  return (
    <div className={styles.hotLinksChart}>
      <div className={styles.label}>{label?.toFixed(0)}%</div>

      <EChart<ChartDataItem>
        onMouseOut={() => {
          setLabel(maxDataItem)
        }}
        onMouseOver={(event) => {
          setLabel(event?.data.percentage)
        }}
        options={options}
      />
    </div>
  )
}
