import { FC } from 'react'
import en from 'src/constants/en'
import { SOCIAL_ICONS } from 'src/constants/socialIcons'
import { capitalizeFirstLetter } from 'src/utils/capitalizeFirstLetter'
import styles from './topSources.module.scss'

interface TopSourcesProps {
  sources?: string[]
}

export const TopSources: FC<TopSourcesProps> = ({ sources }) => {
  return (
    <div className={styles.topSources}>
      <div className={styles.title}>{en.topSources}</div>

      {sources?.map((source) => {
        return (
          <div className={styles.listItem} key={source}>
            {SOCIAL_ICONS[source.toLowerCase()]}

            <span className={styles.label}>
              {capitalizeFirstLetter(source)}
            </span>
          </div>
        )
      })}
    </div>
  )
}
