import { FC, memo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import classNames from 'classnames'
import { capitalize } from 'lodash'
import { ScrollBarContainer } from 'src/components/ScrollBarContainer'
import { PositivityLegend } from 'src/components/PositivityLegend'
import en from 'src/constants/en'
import { PositivityTypes } from 'src/components/PositivityLegend/enums/positivityTypes.enum'
import { AnalyticsFetchHotLinksResponse } from 'src/services/__generated__/api'
import { useGetInformationTooltipsQuery } from 'src/reactQuery/useGetInformationTooltips'
import { SOCIAL_ICONS } from 'src/constants/socialIcons'
import { SectionTitle } from 'src/components/SectionTitle'
import styles from './hotLinks.module.scss'
import { HotLinksChart } from './HotLinksChart'

interface HotLinksSectionProps {
  chartData?: AnalyticsFetchHotLinksResponse
  isLoading?: boolean
}

export const HotLinksSection: FC<HotLinksSectionProps> = memo(
  ({ chartData, isLoading }) => {
    const { data: informationTooltips } = useGetInformationTooltipsQuery()

    return (
      <div className={styles.hotLinksSection}>
        <SectionTitle
          isLoading={isLoading}
          title={en.hotLinks}
          tooltipContent={informationTooltips?.hotLinks}
        />

        <ScrollBarContainer isHorizontal>
          <div className={styles.cardsWrapper}>
            {chartData?.map((item) => (
              <div
                className={styles.card}
                key={`${item.name} - ${item.campaign} - ${item.total} - ${item.date}`}
              >
                <div className={styles.cardHeader}>
                  {item?.source && SOCIAL_ICONS[item.source.toLowerCase()]}

                  <span
                    className={styles.cardTitle}
                    title={capitalize(item?.source)}
                  >
                    {capitalize(item?.source)}
                  </span>
                </div>

                <div className={styles.chartWrapper}>
                  <HotLinksChart
                    negatives={item.negatives}
                    negativesPercentage={item.negativePerc}
                    neutrals={item.neutrals}
                    neutralsPercentage={item.neutralPerc}
                    positives={item.positives}
                    positivesPercentage={item.positivePerc}
                  />
                </div>

                <div className={styles.nameWrapper}>
                  <div className={styles.name} title={item?.campaign}>
                    {item?.campaign}
                  </div>

                  <a
                    className={styles.iconWrapper}
                    href={item?.links?.[0]}
                    rel="noreferrer"
                    target="_black"
                  >
                    {item.links?.length ? (
                      <FontAwesomeIcon
                        className={styles.externalLinkIcon}
                        icon={faExternalLinkAlt}
                        title={item?.links?.[0]}
                      />
                    ) : null}
                  </a>
                </div>

                <div className={styles.date}>
                  {moment(item?.date).format('DD.MM.YYYY')}
                </div>

                <div className={styles.cardFooter}>
                  <div className={styles.cardFooterWrapper}>
                    <span className={styles.cardFooterWrapperSpan} />

                    <span>
                      {item?.positives
                        ?.toString()
                        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </span>
                  </div>

                  <div className={styles.cardFooterWrapper}>
                    <span
                      className={classNames(
                        styles.cardFooterWrapperSpan,
                        styles.neutral
                      )}
                    />

                    <span>
                      {item?.neutrals
                        ?.toString()
                        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </span>
                  </div>

                  <div className={styles.cardFooterWrapper}>
                    <span
                      className={classNames(
                        styles.cardFooterWrapperSpan,
                        styles.negative
                      )}
                    />

                    <span>
                      {item?.negatives
                        ?.toString()
                        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </ScrollBarContainer>

        <div className={styles.footer}>
          <PositivityLegend
            types={[
              PositivityTypes.POSITIVE,
              PositivityTypes.NEUTRAL,
              PositivityTypes.NEGATIVE,
            ]}
          />
        </div>
      </div>
    )
  }
)

HotLinksSection.displayName = 'HotLinksSection'
