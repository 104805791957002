import { FC } from 'react'
import en from 'src/constants/en'
import { capitalizeFirstLetter } from 'src/utils/capitalizeFirstLetter'
import styles from './topCampaigns.module.scss'

interface TopCampaignsProps {
  campaigns?: string[]
}

export const TopCampaigns: FC<TopCampaignsProps> = ({ campaigns }) => {
  return (
    <div className={styles.topCampaigns}>
      <div className={styles.title}>{en.topCampaigns}</div>

      {campaigns?.map((campaign) => {
        return (
          <div className={styles.listItem} key={campaign}>
            {capitalizeFirstLetter(campaign)}
          </div>
        )
      })}
    </div>
  )
}
