import en from 'src/constants/en'

export const mostMentionLabelByMentionType = (
  mentionType: string
): string | undefined => {
  const mentionLower = mentionType.toLowerCase()

  switch (mentionLower) {
    case 'pos':
    case 'positive':
      return en.mostlyPositive
    case 'neg':
    case 'negative':
      return en.mostlyNegative
    case 'neu':
    case 'neutral':
      return en.mostlyNeutral
    default:
      return undefined
  }
}
