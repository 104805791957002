import { useQuery } from '@tanstack/react-query'
import { QueryKey } from 'src/reactQuery/enums/queryKey.enum'
import { AnalyticsApi } from 'src/services/analyticsApi'
import { AnalyticsFetchDailyTrendsResponse } from 'src/services/__generated__/api'
import { UseGetDailyTrendsParams } from './interfaces/useGetDailyTrendsQuery.interface'

const getDailyTrends = async (
  start?: string,
  end?: string
): Promise<AnalyticsFetchDailyTrendsResponse | null> => {
  const { data } = await AnalyticsApi.v1Private.analyticsFetchDailyTrendsList({
    fromDate: start,
    toDate: end,
  })

  return data
}

export const useGetDailyTrendsQuery = (params: UseGetDailyTrendsParams) => {
  return useQuery({
    queryFn: () => getDailyTrends(params.start, params.end),
    queryKey: [QueryKey.GET_DAILY_TRENDS, ...Object.values(params)],
  })
}
