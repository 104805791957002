export const hasFields = <T extends object>(
  obj: any,
  ...fields: (keyof T)[]
): obj is T => {
  if (typeof obj !== 'object' || obj === null) {
    return false
  }

  return fields.every((field) => field in obj)
}
