import { AnalyticsFetchTopSourcesResponseItem } from 'src/services/__generated__/api'
import { getNumberOrZero } from 'src/utils/getNumberOrZero'

export const calculateToTalMentions = (
  mention: AnalyticsFetchTopSourcesResponseItem
): number => {
  return (
    getNumberOrZero(mention?.negatives) +
    getNumberOrZero(mention?.positives) +
    getNumberOrZero(mention?.neutrals)
  )
}
