import { ReactNode, forwardRef } from 'react'
import classNames from 'classnames'
import styles from './scrollBarContainer.module.scss'

interface ScrollBarContainerProps {
  children?: ReactNode
  className?: string
  isHorizontal?: boolean
}

export const ScrollBarContainer = forwardRef<
  HTMLDivElement,
  ScrollBarContainerProps
>(({ children, className, isHorizontal }, ref) => {
  return (
    <div
      className={classNames(
        styles.scrollbarContainer,
        isHorizontal ? styles.horizontal : styles.vertical,
        className
      )}
      ref={ref}
    >
      {children}
    </div>
  )
})

ScrollBarContainer.displayName = 'ScrollBarContainer'
