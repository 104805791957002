import { useQuery } from '@tanstack/react-query'
import { QueryKey } from 'src/reactQuery/enums/queryKey.enum'
import { AnalyticsApi } from 'src/services/analyticsApi'
import { FetchCampaignsResponse } from 'src/services/__generated__/api'
import { UseGetCampaignsQueryParams } from './interfaces/useGetCampaignsQuery.interface'

const getCampaigns = async (
  start?: string,
  end?: string
): Promise<FetchCampaignsResponse | undefined> => {
  const { data } = await AnalyticsApi.v1Private.campaignList({
    fromDate: start,
    toDate: end,
  })

  return data
}

export const useGetCampaignsQuery = (params: UseGetCampaignsQueryParams) => {
  return useQuery({
    queryFn: () => getCampaigns(params.start, params.end),
    queryKey: [QueryKey.GET_CAMPAIGNS, params.start, params.end],
  })
}
