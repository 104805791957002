import { useQuery } from '@tanstack/react-query'
import { QueryKey } from 'src/reactQuery/enums/queryKey.enum'
import { AnalyticsApi } from 'src/services/analyticsApi'
import { KeywordFetchSentimentAnalysis } from 'src/services/__generated__/api'
import { UseGetSentimentAnalysisByKeywordQueryParams } from './interfaces/useGetSentimentAnalysisByKeywordQuery.interface'

const getSentimentAnalysisByKeyword = async (
  campaign?: string,
  keyword?: string,
  start?: string,
  end?: string
): Promise<KeywordFetchSentimentAnalysis | undefined> => {
  const { data } =
    await AnalyticsApi.v1Private.keywordFetchSentimentAnalysisList({
      campaign,
      fromDate: start,
      keyword,
      toDate: end,
    })

  return data
}

export const useGetSentimentAnalysisByKeywordQuery = (
  params: UseGetSentimentAnalysisByKeywordQueryParams
) => {
  return useQuery({
    queryFn: () =>
      getSentimentAnalysisByKeyword(
        params.campaign,
        params.keyword,
        params.start,
        params.end
      ),
    queryKey: [QueryKey.GET_SENTIMENT_ANALYSIS_BY_KEYWORD, params],
  })
}
