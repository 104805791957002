import { FC, useEffect, useState, useCallback, useRef, RefObject } from 'react'
import TagsInput from 'react-tagsinput'
import { debounce } from 'lodash'
import classNames from 'classnames'
import { toast } from 'react-toastify'
import { useGetSuggestionsCategoriesQuery } from 'src/reactQuery/useGetSuggestionsCategoriesQuery'
import DeleteIcon from 'src/assets/svg/delete.svg'
import ChariotIcon from 'src/assets/svg/chariot.svg'
import SaveIcon from 'src/assets/svg/save.svg'
import en from 'src/constants/en'
import { Tag } from 'src/components/Tag'
import { COLORS } from 'src/constants/colors'
import hexToRgbA from 'src/utils/hexToRgba'
import copyIcon from 'src/assets/svg/copy-icon.svg'
import { QueryKey } from 'src/reactQuery/enums/queryKey.enum'
import {
  ENTER_KEY_CODE,
  INPUT_PROPS_NEGATIVE,
  INPUT_PROPS_POSITIVE,
  LETTERS_NUMBERS_AND_SPACES_REGEX,
  MAX_KEYWORD_SENTIMENTS,
} from 'src/constants/campaigns'
import {
  groupElementsThatMatchPredicateInArray,
  insertIntoArrayAtGivenIndex,
} from 'src/utils/arrayMethods'
import { SuggestionsApi } from 'src/services/suggestionsApi'
import {
  CampaignPayloadKeyword,
  SuggestionsSuperlativesComparativesItem,
} from 'src/services/__generated__/api'
import { BaseModal } from 'src/containers/BaseModal'
import { ConfirmationModalContent } from 'src/components/ConfirmationModalContent'
import { LATINS_NUMBERS } from 'src/constants/regex'
import { AutofillInput } from 'src/components/AutofillInput'
import styles from './keywordFormCard.module.scss'
import { HandleSuperlativesComparativesDataParams } from './interfaces/handleSuperlativesComparativesData.interface'
import { EditableTitle } from './EditableTitle'

interface KeywordFormCardProps {
  bookmark?: boolean
  handleDelete?: (keyword: string) => void
  keyword: string | undefined
  negatives?: string[]
  onChangeValue: (keywordData: CampaignPayloadKeyword) => void
  onKeywordUpdate?: (keyword: string) => void
  positives?: string[]
  reservedKeywords?: string[]
  saveAsTemplate?: boolean
}

const KeywordFormCard: FC<KeywordFormCardProps> = ({
  keyword,
  positives,
  negatives,
  onChangeValue,
  handleDelete,
  onKeywordUpdate,
  saveAsTemplate,
  bookmark,
  reservedKeywords = [],
}) => {
  const positiveInputRef = useRef<HTMLInputElement>(null)

  const negativeInputRef = useRef<HTMLInputElement>(null)

  const positiveTagsInputWrapperRef = useRef<HTMLDivElement>(null)

  const negativeTagsInputWrapperRef = useRef<HTMLDivElement>(null)

  const [open, setOpen] = useState<boolean>(true)

  const [positiveValue, setPositiveValue] = useState<string>()

  const [selectedCategory, setSelectedCategory] = useState<string>()

  const [negativeValue, setNegativeValue] = useState<string>()

  const [positiveAutofilldWord, setPositiveAutofillWord] = useState<string>()

  const [negativeAutofillWord, setNegativeAutofillWord] = useState<string>()

  const [positiveTags, setPositiveTags] = useState<string[]>(positives || [])

  const [negativeTags, setNegativeTags] = useState<string[]>(negatives || [])

  const [positiveSuggestions, setPositiveSuggestions] = useState<string[]>()

  const [negativeSuggestions, setNegativeSuggestions] = useState<string[]>()

  const [positiveGroupIndexes, setPositiveGroupIndexes] = useState<number[][]>()

  const [negativeGroupIndexes, setNegativeGroupIndexes] = useState<number[][]>()

  const [showModal, setShowModal] = useState(false)

  const allTags: string[] = [...positiveTags, ...negativeTags]

  const { data: suggestedCategories = [] } = useGetSuggestionsCategoriesQuery({
    queryKey: `${QueryKey.SUGGESTIONS_CATEGORIES}-${keyword}`,
    staleTime: Infinity,
    word: keyword,
  })

  const handleDeleteKeyword = () => {
    if (handleDelete) {
      handleDelete(keyword || '')
    }
  }

  const handleClickUpperPanel = () => {
    setOpen((prev) => !prev)
  }

  const arrayToLowercase = (array: string[]) => {
    return array.map((item) => item.toLowerCase())
  }

  const getGroupedTags = (
    tags: string[],
    superlativesComparativesItems: SuggestionsSuperlativesComparativesItem[]
  ): string[] => {
    let groupedTags = [...tags]

    superlativesComparativesItems.forEach(({ added, group }) => {
      if (!added?.length || !group?.length) {
        return
      }

      const tagIndex = groupedTags?.findIndex((tag) => group.includes(tag))

      if (tagIndex === -1) {
        return
      }

      insertIntoArrayAtGivenIndex(groupedTags, tagIndex + 1, ...added)

      groupedTags = groupElementsThatMatchPredicateInArray(groupedTags, (tag) =>
        [...added, ...group].includes(tag)
      )
    })

    superlativesComparativesItems.forEach(({ added, group }) => {
      if (!added || !group) {
        return
      }

      if (![...added, ...group].length) {
        return
      }

      groupedTags = groupElementsThatMatchPredicateInArray(groupedTags, (tag) =>
        [...added, ...group].includes(tag)
      )
    })

    return groupedTags
  }

  const getGroupedIndexes = (
    tags: string[],
    superlativesComparativesItems: SuggestionsSuperlativesComparativesItem[]
  ): number[][] => {
    const groupedIndexes: number[][] = []

    tags.forEach((tag, index) => {
      const groupIndex = superlativesComparativesItems.findIndex(
        ({ added, group }) => {
          return [...(added ?? []), ...(group ?? [])].includes(tag)
        }
      )

      if (groupIndex === -1) {
        return
      }

      if (!groupedIndexes[groupIndex]) {
        groupedIndexes[groupIndex] = []
      }

      groupedIndexes[groupIndex].push(index)
    })

    return groupedIndexes
  }

  const getShiftedGroupedIndexes = (
    groupedIndexes: number[][],
    from: number
  ): number[][] => {
    const result = groupedIndexes
      .map((group) => {
        return group
          .filter((value) => value !== from)
          .map((value) => (value < from ? value : value - 1))
      })
      .filter((group) => !!group.length)

    return result
  }

  const handleSuperlativesComparativesData = (
    params: HandleSuperlativesComparativesDataParams
  ): void => {
    const { data, negativeTags, positiveTags } = params

    if (data.positives) {
      const groupedTags = getGroupedTags(positiveTags, data.positives)
      const groupedIndexes = getGroupedIndexes(groupedTags, data.positives)

      setPositiveTags(groupedTags)
      setPositiveGroupIndexes(groupedIndexes)
    }

    if (data.negatives) {
      const groupedTags = getGroupedTags(negativeTags, data.negatives)
      const groupedIndexes = getGroupedIndexes(groupedTags, data.negatives)

      setNegativeTags(groupedTags)
      setNegativeGroupIndexes(groupedIndexes)
    }
  }

  const handlePositiveSuggestions = useCallback(
    debounce(async (value) => {
      if (!LATINS_NUMBERS.test(value)) {
        return
      }

      try {
        const { data } = await SuggestionsApi.v1.suggestionsSuggestWordList({
          word: value,
        })

        setPositiveAutofillWord(data?.suggested_word || '')
      } catch (error) {
        toast.error(en.somethingWentWrong)
      }
    }, 350),
    []
  )

  const handleNegativeSuggestions = useCallback(
    debounce(async (value) => {
      try {
        if (!LATINS_NUMBERS.test(value)) {
          return
        }

        const { data } = await SuggestionsApi.v1.suggestionsSuggestWordList({
          word: value,
        })

        setNegativeAutofillWord(data?.suggested_word || '')
      } catch (error) {
        toast.error(en.somethingWentWrong)
      }
    }, 350),
    []
  )

  const onPositiveInputChange = (value: string) => {
    setPositiveValue(value)

    if (value.length > 1) {
      handlePositiveSuggestions(value)
    }
  }

  const onNegativeInputChange = (value: string) => {
    setNegativeValue(value)

    if (value.length > 1) {
      handleNegativeSuggestions(value)
    }
  }

  const onPositiveChange = async (
    words: string[],
    changedIndexes?: number[]
  ): Promise<void> => {
    let latestWord: string | undefined = words[words.length - 1]

    if (latestWord) {
      latestWord = latestWord.trim()
      words.splice(-1, 1, latestWord)
    }

    const lowerCaseLatestWord = latestWord?.toLowerCase()

    const isRemoving = words.length < positiveTags.length

    if (words.length > MAX_KEYWORD_SENTIMENTS) {
      toast.error(en.SENTIMENT_ERROR_OVER_20_WORDS)

      return
    }

    if (keyword?.toLowerCase() === lowerCaseLatestWord) {
      toast.error(en.SENTIMENT_ERROR_USED_AS_KEYWORD)

      return
    }

    if (
      arrayToLowercase(positiveTags).includes(lowerCaseLatestWord) &&
      !isRemoving
    ) {
      toast.error(en.SENTIMENT_ERROR_ALREADY_IN_USE)

      return
    }

    if (arrayToLowercase(negativeTags).includes(lowerCaseLatestWord)) {
      toast.error(en.SENTIMENT_ERROR_ALREADY_USED_AS_NEGATIVE)

      return
    }

    if (
      arrayToLowercase(allTags).includes(lowerCaseLatestWord) &&
      !isRemoving
    ) {
      toast.error(en.SENTIMENT_ERROR_ALREADY_IN_USE)

      return
    }

    if (!LETTERS_NUMBERS_AND_SPACES_REGEX.test(latestWord)) {
      toast.error(en.SENTIMENT_ERROR_USED_WRONG_CHARACTERS)

      return
    }

    if (latestWord && latestWord.split(' ').length > 3) {
      toast.error(en.SENTIMENT_ERROR_TOO_MANY_WORDS)

      return
    }

    if (reservedKeywords.includes(lowerCaseLatestWord)) {
      toast.error(en.WORD_IS_ALREADY_IN_USE)

      return
    }

    setPositiveTags(words)

    const { data: synonymsAntonymsData } =
      await SuggestionsApi.v1.suggestionsSynonymsAntonymsList({
        word: latestWord,
      })

    setPositiveSuggestions(synonymsAntonymsData.synonyms)
    setNegativeSuggestions(synonymsAntonymsData.antonyms)

    if (isRemoving && changedIndexes?.length) {
      setPositiveGroupIndexes((prev) =>
        getShiftedGroupedIndexes(prev ?? [], changedIndexes[0])
      )
      return
    }

    const { data: superlativesComparativesData } =
      await SuggestionsApi.v1.suggestionsSuperlativesComparativesList({
        negatives: negativeTags.join(','),
        positives: words.join(','),
      })

    handleSuperlativesComparativesData({
      data: superlativesComparativesData,
      negativeTags,
      positiveTags: words,
    })
  }

  const onNegativeChange = async (
    words: string[],
    changedIndexes?: number[]
  ): Promise<void> => {
    let latestWord: string | undefined = words[words.length - 1]

    if (latestWord) {
      latestWord = latestWord.trim()
      words.splice(-1, 1, latestWord)
    }

    const lowerCaseLatestWord = latestWord?.toLowerCase()

    const isRemoving = words.length < negativeTags.length

    if (words.length > MAX_KEYWORD_SENTIMENTS) {
      toast.error(en.SENTIMENT_ERROR_OVER_20_WORDS)
      return
    }

    if (keyword?.toLowerCase() === lowerCaseLatestWord) {
      toast.error(en.SENTIMENT_ERROR_USED_AS_KEYWORD)
      return
    }

    if (
      arrayToLowercase(negativeTags).includes(lowerCaseLatestWord) &&
      !isRemoving
    ) {
      toast.error(en.SENTIMENT_ERROR_ALREADY_IN_USE)
      return
    }

    if (arrayToLowercase(positiveTags).includes(lowerCaseLatestWord)) {
      toast.error(en.SENTIMENT_ERROR_ALREADY_USED_AS_POSITIVE)
      return
    }

    if (
      arrayToLowercase(allTags).includes(lowerCaseLatestWord) &&
      !isRemoving
    ) {
      toast.error(en.SENTIMENT_ERROR_ALREADY_IN_USE)
      return
    }

    if (!LETTERS_NUMBERS_AND_SPACES_REGEX.test(latestWord)) {
      toast.error(en.SENTIMENT_ERROR_USED_WRONG_CHARACTERS)
      return
    }

    if (latestWord && latestWord.split(' ').length > 3) {
      toast.error(en.SENTIMENT_ERROR_TOO_MANY_WORDS)
      return
    }

    if (reservedKeywords.includes(lowerCaseLatestWord)) {
      toast.error(en.WORD_IS_ALREADY_IN_USE)

      return
    }

    setNegativeTags(words)

    const { data: synonymsAntonymsData } =
      await SuggestionsApi.v1.suggestionsSynonymsAntonymsList({
        word: latestWord,
      })

    setNegativeSuggestions(synonymsAntonymsData.synonyms)
    setPositiveSuggestions(synonymsAntonymsData.antonyms)

    if (isRemoving && changedIndexes?.length) {
      setNegativeGroupIndexes((prev) =>
        getShiftedGroupedIndexes(prev ?? [], changedIndexes[0])
      )
      return
    }

    const { data: superlativesComparativesData } =
      await SuggestionsApi.v1.suggestionsSuperlativesComparativesList({
        negatives: words.join(','),
        positives: positiveTags.join(','),
      })

    handleSuperlativesComparativesData({
      data: superlativesComparativesData,
      negativeTags: words,
      positiveTags,
    })
  }

  const handlePositivePaste = (value: string) => {
    const words = value.split(/[\s,]+/)
    const filteredWords = words.filter((word) => word.length > 0)

    const lowerCasePositiveTags = arrayToLowercase(positiveTags)
    const lowerCaseNegativeTags = arrayToLowercase(negativeTags)

    const uniqueWordsFromCopiedArray = filteredWords.filter((word) => {
      return !lowerCasePositiveTags.includes(word.toLowerCase())
    })

    const uniquePositiveWords = positiveTags.concat(uniqueWordsFromCopiedArray)

    const wordsThatExistInNegativeTags = uniquePositiveWords.filter((word) => {
      return lowerCaseNegativeTags.includes(word.toLowerCase())
    })

    onPositiveInputChange('')

    if (filteredWords.length <= 0) {
      toast.error(en.SENTIMENT_ERROR_NOTHING_TO_PASTE)
      return
    }

    if (uniqueWordsFromCopiedArray.length <= 0) {
      toast.error(en.SENTIMENT_ERROR_NO_UNIQUE_SENTIMENTS_PASTED)
      return
    }

    if (uniqueWordsFromCopiedArray.length > MAX_KEYWORD_SENTIMENTS) {
      toast.error(en.SENTIMENT_ERROR_OVER_20_WORDS)
      return
    }

    if (wordsThatExistInNegativeTags.length) {
      toast.error(
        en.SENTIMENT_ERROR_PASTE_POSITIVE +
          wordsThatExistInNegativeTags.toString()
      )
      return
    }

    setPositiveTags(uniquePositiveWords)
    toast.success(en.SENTIMENT_SUCCESS_PASTED)
  }

  const handleNegativePaste = (value: string) => {
    const words = value.split(/[\s,]+/)
    const filteredWords = words.filter((word) => word.length > 0)

    const lowerCasePositiveTags = arrayToLowercase(positiveTags)
    const lowerCaseNegativeTags = arrayToLowercase(negativeTags)

    const uniqueWordsFromCopiedArray = filteredWords.filter((word) => {
      return !lowerCaseNegativeTags.includes(word.toLowerCase())
    })

    const uniqueNegativeWords = negativeTags.concat(uniqueWordsFromCopiedArray)

    const wordsThatExistInPositiveTags = uniqueNegativeWords.filter((word) => {
      return lowerCasePositiveTags.includes(word.toLowerCase())
    })

    onNegativeInputChange('')

    if (filteredWords.length <= 0) {
      toast.error(en.SENTIMENT_ERROR_NOTHING_TO_PASTE)
      return
    }

    if (uniqueWordsFromCopiedArray.length <= 0) {
      toast.error(en.SENTIMENT_ERROR_NO_UNIQUE_SENTIMENTS_PASTED)
      return
    }

    if (uniqueWordsFromCopiedArray.length > MAX_KEYWORD_SENTIMENTS) {
      toast.error(en.SENTIMENT_ERROR_OVER_20_WORDS)
      return
    }

    if (wordsThatExistInPositiveTags.length) {
      toast.error(
        en.SENTIMENT_ERROR_PASTE_NEGATIVE +
          wordsThatExistInPositiveTags.toString()
      )
      return
    }

    setNegativeTags(uniqueNegativeWords)
    toast.success(en.SENTIMENT_SUCCESS_PASTED)
  }

  const copyTags = (sentimentType: string) => {
    switch (sentimentType) {
      case 'positive':
        navigator.clipboard.writeText(positiveTags.toString())
        break
      case 'negative':
        navigator.clipboard.writeText(negativeTags.toString())
        break
      default:
        break
    }
  }

  useEffect(() => {
    const val: CampaignPayloadKeyword = {
      categories: selectedCategory ? [selectedCategory] : [],
      name: keyword || '',
      negative: negativeTags,
      positive: positiveTags,
    }

    onChangeValue(val)
  }, [positiveTags, negativeTags, selectedCategory])

  const refreshTagGroupVisuals = (
    tagsInputWrapperRef: RefObject<HTMLDivElement>,
    groups: number[][] | undefined
  ): void => {
    if (!tagsInputWrapperRef.current) {
      return
    }

    const elements = tagsInputWrapperRef.current.querySelectorAll(
      '.react-tagsinput-tag'
    )

    elements.forEach((element) => {
      element.classList.remove('grouped', 'left', 'right')
    })

    groups?.forEach((elementIndexes) => {
      elementIndexes.forEach((elementIndex, indexInGroup) => {
        const classNames = ['grouped']

        if (indexInGroup === 0) {
          classNames.push('left')
        }

        if (indexInGroup === elementIndexes.length - 1) {
          classNames.push('right')
        }

        elements.item(elementIndex)?.classList.add(...classNames)
      })
    })
  }

  useEffect(() => {
    refreshTagGroupVisuals(positiveTagsInputWrapperRef, positiveGroupIndexes)
    refreshTagGroupVisuals(negativeTagsInputWrapperRef, negativeGroupIndexes)
  }, [
    positiveTagsInputWrapperRef.current,
    negativeTagsInputWrapperRef.current,
    positiveGroupIndexes,
    negativeGroupIndexes,
    positiveTags,
    negativeTags,
  ])

  return (
    <>
      <div className={styles.addNewThemeCard}>
        <div className={styles.header} onClick={handleClickUpperPanel}>
          <div
            className={styles.headerSpan}
            onClick={(e) => e.stopPropagation()}
          >
            <EditableTitle
              baseTitle={keyword || ''}
              onTitleUpdate={onKeywordUpdate}
            />

            {!open && (
              <span className={styles.headerCounts}>
                {`${en.sentiments}: ${en.positive}(${positiveTags.length}) ${en.negative}(${negativeTags.length})`}
              </span>
            )}
          </div>

          <span className={styles.headerSpan}>
            {bookmark ? (
              <span
                className={classNames(styles.bookmark, open && styles.open)}
              >
                {en.saveAsTemplate}
              </span>
            ) : null}

            {saveAsTemplate ? (
              <span
                className={classNames(
                  styles.iconContainer,
                  open ? styles.open : styles.displayNone
                )}
              >
                <img alt="Save" src={SaveIcon} />
              </span>
            ) : null}

            <button
              onClick={(event) => {
                event.stopPropagation()

                setShowModal(true)
              }}
              type="button"
            >
              <span
                className={classNames(
                  styles.iconContainer,
                  open ? styles.open : styles.displayNone
                )}
              >
                <img alt="Delete" src={DeleteIcon} />
              </span>
            </button>

            <span
              className={classNames(
                styles.iconContainerChariot,
                open && styles.iconContainerChariotOpen
              )}
            >
              <img alt="" src={ChariotIcon} />
            </span>
          </span>
        </div>

        <div
          className={classNames(styles.keywordContent, open && styles.expanded)}
        >
          <div className={styles.categoriesSection}>
            <label className={styles.label}>{en.categories}</label>

            <div className={styles.categoriesList}>
              {suggestedCategories?.map((category) => {
                const isActive = selectedCategory === category

                return (
                  <div className={styles.categoriesTag} key={category}>
                    <Tag
                      backgroundColor={
                        isActive
                          ? COLORS.malibu
                          : hexToRgbA(COLORS.periwinkle, 0.5)
                      }
                      color={isActive ? COLORS.white : COLORS.shark}
                      cursor="pointer"
                      fontSize="14px"
                      fontWeight={500}
                      handleClick={(value) =>
                        setSelectedCategory((prevCategory) =>
                          prevCategory === value ? undefined : value
                        )
                      }
                      height="36px"
                      key={category}
                      lineHeight="16.41px"
                      marginRight="0"
                      padding="10px 16px"
                      title={category}
                    />
                  </div>
                )
              })}
            </div>
          </div>

          <div className={styles.positiveKeys}>
            <label className={styles.label}>{en.positiveSentiments}</label>

            <div
              className={styles.tagsInputWrapper}
              onClick={() => {
                if (positiveInputRef.current) {
                  positiveInputRef.current.focus()
                }
              }}
              ref={positiveTagsInputWrapperRef}
            >
              <TagsInput
                addKeys={[ENTER_KEY_CODE]}
                inputProps={INPUT_PROPS_POSITIVE}
                maxTags={MAX_KEYWORD_SENTIMENTS + 1}
                onChange={(tags, changed, changedIndexes) =>
                  onPositiveChange(tags, changedIndexes)
                }
                renderInput={(props) => {
                  return (
                    <AutofillInput
                      addTag={() => {
                        if (positiveValue) {
                          props.addTag(positiveValue)
                          onPositiveInputChange('')
                        }
                      }}
                      autoFillClassName={styles.addNewThemeAutofill}
                      autofilldWord={positiveAutofilldWord || ''}
                      fillKey={() => {
                        if (positiveAutofilldWord) {
                          onPositiveInputChange(positiveAutofilldWord)
                        }
                      }}
                      inputClassName={styles.addNewThemeInput}
                      isInputKeyword
                      onChange={onPositiveInputChange}
                      onPaste={handlePositivePaste}
                      positives={positiveTags}
                      ref={positiveInputRef}
                      value={positiveValue || ''}
                    />
                  )
                }}
                value={positiveTags}
              />

              <img
                alt="copy"
                className={styles.copyIcon}
                onClick={() => {
                  copyTags('positive')
                  toast.success('Positive sentiments were copied successfully')
                }}
                src={copyIcon}
              />
            </div>

            <div className={styles.suggestions}>
              <span className={styles.suggestionsTitle}>{en.suggested}:</span>

              {positiveSuggestions?.map((item) => (
                <span
                  key={item}
                  onClick={() => onPositiveChange([...positiveTags, item])}
                >
                  <span className={styles.suggestionsLabel}>{item}</span>,{' '}
                </span>
              ))}
            </div>
          </div>

          <div
            className={classNames(
              styles.negativeKeys,
              'add-new-theme-negative-keys'
            )}
          >
            <label className={styles.label}>{en.negativeSentiments}</label>

            <div
              className={styles.tagsInputWrapper}
              onClick={() => {
                if (negativeInputRef.current) {
                  negativeInputRef.current.focus()
                }
              }}
              ref={negativeTagsInputWrapperRef}
            >
              <TagsInput
                addKeys={[ENTER_KEY_CODE]}
                inputProps={INPUT_PROPS_NEGATIVE}
                maxTags={MAX_KEYWORD_SENTIMENTS + 1}
                onChange={(tags, changed, changedIndexes) =>
                  onNegativeChange(tags, changedIndexes)
                }
                renderInput={(props) => {
                  return (
                    <AutofillInput
                      addTag={() => {
                        if (negativeValue) {
                          props.addTag(negativeValue)

                          onNegativeInputChange('')
                        }
                      }}
                      autoFillClassName={styles.addNewThemeAutofill}
                      autofilldWord={negativeAutofillWord || ''}
                      fillKey={() => {
                        if (negativeAutofillWord) {
                          onNegativeInputChange(negativeAutofillWord)
                        }
                      }}
                      inputClassName={styles.addNewThemeInput}
                      isInputKeyword
                      negatives={negativeTags}
                      onChange={onNegativeInputChange}
                      onPaste={handleNegativePaste}
                      ref={negativeInputRef}
                      value={negativeValue || ''}
                    />
                  )
                }}
                value={negativeTags}
              />

              <img
                alt="copy"
                className={styles.copyIcon}
                onClick={() => {
                  copyTags('negative')
                  toast.success('Negative sentiments were copied successfully')
                }}
                src={copyIcon}
              />
            </div>

            <div className={styles.suggestions}>
              <span className={styles.suggestionsTitle}>{en.suggested}:</span>

              {negativeSuggestions?.map((word) => (
                <span
                  key={word}
                  onClick={() => onNegativeChange([...negativeTags, word])}
                >
                  <span className={styles.suggestionsLabel}>{word}</span>,{' '}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>

      <BaseModal isOpen={!!showModal}>
        <ConfirmationModalContent
          onCancel={() => setShowModal(false)}
          onConfirm={() => handleDeleteKeyword()}
          title={`${en.areYouSureYouWantToDelete}?`}
        />
      </BaseModal>
    </>
  )
}

export default KeywordFormCard
