import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { BASE_API_DATE_FORMAT } from 'src/constants/dateFormats'
import { QueryKey } from 'src/reactQuery/enums/queryKey.enum'
import { AnalyticsApi } from 'src/services/analyticsApi'
import { AnalyticsFetchHomeCalendarResponse } from 'src/services/__generated__/api'
import { UseGetHomeCalendarParams } from './interfaces/useGetHomeCalendarQuery.interface'

const getHomeCalendar = async (
  start: string | null,
  end: string | null,
  current?: string
): Promise<AnalyticsFetchHomeCalendarResponse | undefined> => {
  if (!start || !end) {
    return undefined
  }

  const { data } = await AnalyticsApi.v1Private.analyticsFetchHomeCalendarList({
    currentDate: current || moment().format(BASE_API_DATE_FORMAT),
    fromDate: start,
    toDate: end,
  })

  return data
}

export const useGetHomeCalendarQuery = (params: UseGetHomeCalendarParams) => {
  return useQuery({
    queryFn: () => getHomeCalendar(params.start, params.end, params.current),
    queryKey: [QueryKey.GET_HOME_CALENDAR],
  })
}
