import { useQuery } from '@tanstack/react-query'
import { QueryKey } from 'src/reactQuery/enums/queryKey.enum'
import { fetchInformationTooltips } from 'src/services/napi/fetchInformationTooltips'
import { InformationTooltips } from 'src/services/napi/fetchInformationTooltips/interfaces/fetchInformationTooltips.interface'

const getInformationTooltips = async (): Promise<
  InformationTooltips | undefined
> => {
  const data = await fetchInformationTooltips()

  return data.en
}

export const useGetInformationTooltipsQuery = () => {
  return useQuery({
    queryFn: () => getInformationTooltips(),
    queryKey: [QueryKey.INFORMATION_TOOLTIPS],
    refetchInterval: Infinity,
  })
}
