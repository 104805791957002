import { forwardRef } from 'react'
import { COLORS } from 'src/constants/colors'
import { numberWithCommas } from 'src/utils/numberFormat'
import { addThreeDotsToString } from 'src/utils/addThreeDotsToString'
import { ReactComponent as LoudspeakerSvg } from 'src/assets/svg/loudspeaker.svg'
import { getRandomColor } from 'src/utils/getRandomColor'
import styles from './mostMentionsLegend.module.scss'
import { Legend } from '../interfaces/legend.interface'

const COLOR_PALETTE = [
  COLORS.malibu,
  COLORS.burningOrange,
  COLORS.periwinkle,
  COLORS.darkBlue,
  COLORS.pinkLace,
  COLORS.redOrange,
  COLORS.hpttSp4,
  COLORS.fog,
  COLORS.iron,
  '#1a1b21',
  '#6363ff',
  '#b8bec6',
  '#ffa07a',
  '#0911c2',
  '#e5ccff',
  '#ffa3a3',
  '#ff99ff',
  '#b1b3d5',
  '#6d9ae9',
  '#ff8a62',
  '#0d0fc0',
  '#d5b6ff',
  '#ff7777',
  '#d572ff',
  '#a1a3c5',
  '#5d8ee9',
  '#ff6b6b',
  '#2b2c38',
  '#414252',
  '#7273ff',
  '#8c93a3',
  '#d3f955',
  '#3acfff',
  '#ff8c6e',
  '#dffab3',
  '#b56eff',
  '#ffa3f0',
  '#b59eff',
  '#5a8de9',
  '#3f56d9',
  '#8a9bcf',
  '#d4e0ff',
  '#9c83ff',
  '#cc8a8a',
  '#8d86e1',
  '#5a5c6e',
  '#9799c3',
  '#7c7eb2',
  '#e6e7ff',
  '#f9b3c1',
  '#4f5272',
  '#ff7f9b',
  '#f0bff7',
  '#ff7ca1',
]

interface MostMentionsLegendProps {
  activeLegendItemIndex?: number
  legendData?: Legend[]
  onClickLegendItem: (itemIndex: number) => void
  resetToInitialState: () => void
}

export const MostMentionsLegend = forwardRef<
  HTMLDivElement,
  MostMentionsLegendProps
>(({ activeLegendItemIndex, legendData, onClickLegendItem }, ref) => {
  return (
    <div className={styles.mostMentionsLegend} ref={ref}>
      {legendData?.map(({ name, value }, index) => {
        return (
          <div
            className={styles.item}
            key={`${name} - ${value} - ${index}`}
            onClick={() => onClickLegendItem(index)}
          >
            <div
              className={styles.ball}
              style={{
                backgroundColor:
                  activeLegendItemIndex === index ||
                  activeLegendItemIndex === undefined
                    ? COLOR_PALETTE[index] ?? getRandomColor()
                    : COLORS.grey200,
              }}
            />

            <p className={styles.name}>{addThreeDotsToString(name, 25)}</p>

            <p className={styles.value}>{numberWithCommas(value)}</p>

            <LoudspeakerSvg className={styles.loudspeakerIcon} />
          </div>
        )
      })}
    </div>
  )
})

MostMentionsLegend.displayName = 'MostMentionsLegend'
