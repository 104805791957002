import { useState, useEffect, FC, Fragment, MouseEvent, useMemo } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import DateRangePicker, { OnSelectCallbackParam } from 'react-daterange-picker'
import { extendMoment } from 'moment-range'
import * as Moment from 'moment'
import moment from 'moment'
import classNames from 'classnames'
import { useGetCustomCalendarRangeQuery } from 'src/reactQuery/useGetCustomCalendarRangeQuery'
import { QuestionMarkTooltip } from 'src/components/QuestionMarkTooltip'
import { useQueryParams } from 'src/hooks/useQueryParams'
import { GlobalQueryParams } from 'src/interfaces/globalQueryParams.interface'
import en from 'src/constants/en'
import { FilterPeriod } from 'src/constants/filterPeriods/enums/filterPeriod.enum'
import { PERIOD_OPTIONS_TO_LABELS } from 'src/constants/filterPeriods'
import { getDateRangeByQueryParams } from 'src/utils/getDateRangeByQueryParams'
import { HandleSelectRangeParams } from './interfaces/handleSelectRange.interface'
import { PopupType } from './enums/popupType.enum'
import styles from './topBarDatePicker.module.scss'

const extendMomentMethod = extendMoment(Moment)

export const TopBarDatePicker: FC = () => {
  const { queryParams, updateQueryParams } = useQueryParams<GlobalQueryParams>()

  const [openPopupType, setOpenPopupType] = useState<PopupType | null>(null)

  const dates = getDateRangeByQueryParams(queryParams)

  const startDate = dates?.start
  const endDate = dates?.end

  const rangeForCalendar = extendMomentMethod.range(
    moment(startDate),
    moment(endDate)
  )

  const { data: customCalendarRange } = useGetCustomCalendarRangeQuery()

  const firstDate = moment(customCalendarRange?.firstDate)

  const lastDate = moment(customCalendarRange?.lastDate)

  const daysDiff = lastDate.diff(firstDate, 'days')

  const periodOptionKeys = useMemo<FilterPeriod[]>(() => {
    const periodOptionKeys: FilterPeriod[] = []

    periodOptionKeys.push(FilterPeriod.TODAY)

    if (daysDiff > 1) {
      periodOptionKeys.push(FilterPeriod.YESTERDAY)
    }

    if (daysDiff > 2) {
      periodOptionKeys.push(FilterPeriod.LAST_7_DAYS)
    }

    if (daysDiff >= 7) {
      periodOptionKeys.push(FilterPeriod.LAST_30_DAYS)
    }

    if (daysDiff >= 30) {
      periodOptionKeys.push(FilterPeriod.LAST_90_DAYS)
    }

    periodOptionKeys.push(FilterPeriod.CUSTOM)
    periodOptionKeys.push(FilterPeriod.LIFETIME)

    return periodOptionKeys
  }, [daysDiff])

  const handleSelectRange = (value: HandleSelectRangeParams): void => {
    const start = value.start?.startOf('day')?.toISOString(true)
    const end = value.end?.endOf('day')?.toISOString(true)

    if (start && end) {
      updateQueryParams({ from: start, period: FilterPeriod.CUSTOM, to: end })
    }
  }

  const dropdown = (event: MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation()
    event.preventDefault()

    setOpenPopupType((prevState) =>
      prevState === null ? PopupType.SELECT : null
    )
  }

  const closeDropdown = () => {
    setOpenPopupType(null)
  }

  const handleSelect = (period: FilterPeriod) => {
    updateQueryParams({
      from: undefined,
      period,
      to: undefined,
    })

    closeDropdown()
  }

  const handleClickCustom = () => {
    setOpenPopupType(PopupType.CALENDAR)
  }

  useEffect(() => {
    if (!queryParams.period) {
      updateQueryParams({
        period: FilterPeriod.YESTERDAY,
      })
    }
  }, [queryParams.period])

  return (
    <div className={styles.datepicker}>
      <OutsideClickHandler onOutsideClick={closeDropdown}>
        <div
          className={classNames(
            styles.dropdownEl,
            openPopupType === PopupType.SELECT && styles.expanded
          )}
          onClick={dropdown}
        >
          <input
            defaultChecked
            id="check"
            name="sortType"
            type="radio"
            value={queryParams.period}
          />

          <label
            className={classNames(
              styles.labelCheck,
              queryParams.period === FilterPeriod.CUSTOM &&
                styles.flexDirectionColumn
            )}
            htmlFor="check"
          >
            <span
              className={classNames(
                styles.periodLabel,
                queryParams.period === FilterPeriod.CUSTOM &&
                  styles.customPeriod
              )}
            >
              {queryParams.period &&
                PERIOD_OPTIONS_TO_LABELS[queryParams.period]}
            </span>

            {queryParams.period === FilterPeriod.CUSTOM ? (
              <span className={styles.periodDates}>
                {moment(startDate).format('D MMMM YYYY')}
                &nbsp;-&nbsp;
                {moment(endDate).format('D MMMM YYYY')}
              </span>
            ) : null}
          </label>

          {periodOptionKeys?.map((key, index) => {
            return (
              <Fragment key={key}>
                <input
                  id={`id${index}`}
                  name="sortType"
                  type="radio"
                  value={key}
                />

                <label
                  htmlFor={`id${index}`}
                  onClick={(event) => {
                    event.stopPropagation()
                    event.preventDefault()

                    if (key === FilterPeriod.CUSTOM) {
                      handleClickCustom()
                      return
                    }

                    handleSelect(key)
                  }}
                >
                  {PERIOD_OPTIONS_TO_LABELS[key]}
                </label>
              </Fragment>
            )
          })}
        </div>

        {openPopupType === PopupType.CALENDAR && (
          <div className={styles.calendarWrapper}>
            <DateRangePicker
              maximumDate={moment(customCalendarRange?.lastDate).toDate()}
              minimumDate={moment(customCalendarRange?.firstDate).toDate()}
              numberOfCalendars={1}
              onSelect={(value: OnSelectCallbackParam) => {
                handleSelectRange({
                  end: value.end as unknown as moment.Moment,
                  start: value.start as unknown as moment.Moment,
                })
              }}
              singleDateRange
              value={rangeForCalendar}
            />
          </div>
        )}
      </OutsideClickHandler>

      <div className={styles.calendarTooltip}>
        <QuestionMarkTooltip
          overlay={en.HEADER_DATEPICKER_TOOLTIP}
          placement="bottom"
        />
      </div>
    </div>
  )
}
