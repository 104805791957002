import { useQuery } from '@tanstack/react-query'
import { QueryKey } from 'src/reactQuery/enums/queryKey.enum'
import { AdminApi } from 'src/services/adminApi'
import {
  AdminFetchThreadsSentimentType,
  AdminFetchThreadsVectorType,
  AdminFetchThreadsResponseDataItem,
} from 'src/services/__generated__/api'
import { UseGetAdminThreadsQueryParams } from './interfaces/useGetAdminThreadsQuery.interface'

const getAdminThreads = async (
  lastDate?: string,
  limit?: number,
  source?: string,
  sentiment?: AdminFetchThreadsSentimentType
): Promise<AdminFetchThreadsResponseDataItem[] | null> => {
  if (!lastDate || !limit) {
    return null
  }

  const {
    data: { data },
  } = await AdminApi.v1Api.adminThreadsList({
    lastDate,
    limit,
    ...(source ? { source } : {}),
    ...(sentiment ? { sentiment } : {}),
    vector: AdminFetchThreadsVectorType.Desc,
  })

  return data ?? null
}

export const useGetAdminThreadsQuery = (
  params: UseGetAdminThreadsQueryParams
) => {
  return useQuery({
    queryFn: () =>
      getAdminThreads(
        params.lastDate,
        params.limit,
        params.source,
        params.sentiment
      ),
    queryKey: [QueryKey.GET_ADMIN_THREADS, ...Object.values(params)],
    refetchInterval: Infinity,
  })
}
