import { FC, ReactNode, MouseEventHandler } from 'react'
import classNames from 'classnames'
import {
  ButtonBackground,
  ButtonShape,
  ButtonTypes,
} from './interfaces/button.interface'
import styles from './button.module.scss'

interface ButtonProps {
  background?: ButtonBackground
  children?: ReactNode
  className?: string
  isDisabled?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
  shape?: ButtonShape
  type?: ButtonTypes
}

const Button: FC<ButtonProps> = ({
  background = 'malibu',
  children,
  className,
  onClick,
  shape = 'rect',
  type = 'button',
  isDisabled,
}) => {
  return (
    <button
      className={classNames(
        styles.button,
        styles[background],
        styles[shape],
        className,
        isDisabled && styles.disabled
      )}
      onClick={onClick}
      type={type}
    >
      {children}
    </button>
  )
}

export default Button
