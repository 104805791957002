import { FC } from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import { KeywordDetail } from 'src/pages/KeywordDetail'
import {
  CAMPAIGNS_ROUTE,
  CAMPAIGN_DETAILS_ROUTE,
  HOME_ROUTE,
  KEYWORDS_ROUTE,
  KEYWORD_DETAILS_ROUTE,
  LOGIN_ROUTE,
  SIGNUP_ROUTE,
  SETTINGS_ROUTE,
  NEW_CAMPAIGN_ROUTE,
  UPDATE_CAMPAIGN_ROUTE,
  THREADS_ROUTE,
} from 'src/constants/routes'
import { Campaigns } from 'src/pages/Campaigns'
import SignUp from 'src/pages/SignUp'
import { HomePage } from 'src/pages/HomePage'
import { AddNewCampaign } from 'src/pages/AddNewCampaign'
import UpdateTheme from 'src/pages/EditCampaign'
import { KeywordsPage } from 'src/pages/KeywordsPage'
import { Settings } from 'src/pages/Settings'
import ThreadsPage from 'src/pages/Threads'
import { Login } from 'src/pages/Login'
import ThemeDetail from 'src/pages/ThemeDetail'
import { PrivateRoute } from './PrivateRoute'

export const AppRouter: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          element={<PrivateRoute requiresAdminAccess />}
          path={THREADS_ROUTE}
        >
          <Route element={<ThreadsPage />} path={THREADS_ROUTE} />
        </Route>

        <Route element={<PrivateRoute />} path={UPDATE_CAMPAIGN_ROUTE}>
          <Route element={<UpdateTheme />} path={UPDATE_CAMPAIGN_ROUTE} />
        </Route>

        <Route element={<PrivateRoute />} path={CAMPAIGN_DETAILS_ROUTE}>
          <Route element={<ThemeDetail />} path={CAMPAIGN_DETAILS_ROUTE} />
        </Route>

        <Route element={<PrivateRoute />} path={KEYWORD_DETAILS_ROUTE}>
          <Route element={<KeywordDetail />} path={KEYWORD_DETAILS_ROUTE} />
        </Route>

        <Route element={<PrivateRoute />} path={CAMPAIGNS_ROUTE}>
          <Route element={<Campaigns />} path={CAMPAIGNS_ROUTE} />
        </Route>

        <Route element={<PrivateRoute />} path={HOME_ROUTE}>
          <Route element={<HomePage />} path={HOME_ROUTE} />
        </Route>

        <Route element={<PrivateRoute />} path={KEYWORDS_ROUTE}>
          <Route element={<KeywordsPage />} path={KEYWORDS_ROUTE} />
        </Route>

        <Route element={<PrivateRoute />} path={NEW_CAMPAIGN_ROUTE}>
          <Route element={<AddNewCampaign />} path={NEW_CAMPAIGN_ROUTE} />
        </Route>

        <Route element={<PrivateRoute />} path={SETTINGS_ROUTE}>
          <Route element={<Settings />} path={SETTINGS_ROUTE} />
        </Route>

        <Route element={<Login />} path={LOGIN_ROUTE}></Route>

        <Route element={<SignUp />} path={SIGNUP_ROUTE}></Route>

        <Route element={<Navigate to={LOGIN_ROUTE} />} path="*" />
      </Routes>
    </BrowserRouter>
  )
}
